<script setup>
const props = defineProps({
  withBorder: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <footer
    class="app-modal-footer"
    :class="{ 'app-modal-footer--with-border': props.withBorder }"
  >
    <slot />
  </footer>
</template>

<style lang="scss">
.app-modal-footer {
  padding: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  margin-top: auto;

  &--with-border {
    border-top: 1px solid $border-white;
    margin-top: 32px;
  }
}
</style>
