<script setup>
import { UiBanner } from "@/components";
import { computed } from "vue";
import { posthogCapture } from "@/scripts/posthog.js";

const emit = defineEmits(["dismiss"]);

const showAggressiveMessaging = computed(() => {
  return (
    window.$posthog?.getFeatureFlag("showExtensionBanner") ===
    "showExtensionBanner"
  );
});

const message = computed(() => {
  if (showAggressiveMessaging.value) {
    return "Your account is not complete. Download the Cloaked extension to finish setup.";
  }
  return "Get the most out of Cloaked - install the extension and make signups and logins a breeze.";
});

function dismissBanner() {
  localStorage.setItem("showExtensionBanner", "false");
  capturePosthog("user_clicked_remindlater");
  emit("dismiss");
}

function capturePosthog(eventName) {
  const featureFlag = window.$posthog?.getFeatureFlag("showExtensionBanner");
  posthogCapture(eventName, {
    showExtensionBannerFlag: featureFlag,
  });
}
</script>
<template>
  <UiBanner
    class="confirm-banner"
    :class="{ aggressive: showAggressiveMessaging }"
  >
    <div>{{ message }}</div>
    <div class="row">
      <a
        href="https://download.cloaked.app"
        target="_blank"
        class="button"
        @click="capturePosthog('user_clicked_downloadextension')"
        >Download extension</a
      >
      <div
        class="separator"
        :class="{ aggressive: showAggressiveMessaging }"
      ></div>
      <div @click="dismissBanner" class="button">Remind me later</div>
    </div>
  </UiBanner>
</template>
<style scoped lang="scss">
.confirm-banner {
  background-color: $color-brand-4-100;
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &.aggressive {
    background-color: $color-warning;
    color: $color-primary-100-light;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .separator {
      height: 20px;
      width: 1px;
      background-color: $color-primary-5-light;
      &.aggressive {
        background-color: $color-primary-5-dark;
      }
    }

    .button {
      cursor: pointer;
      font-weight: 500;
    }
  }
}
</style>
