<script setup>
import { useAttrs } from "vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import AppModal from "@/components/ui/AppModal.vue";
import Button from "@/components/Button.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";

defineEmits(["input", "discard"]);

const attrs = useAttrs();
</script>

<template>
  <AppModal v-bind="attrs">
    <AppModalContent>
      <AppModalTitle> Unsaved changes </AppModalTitle>
      <AppModalParagraph> You have unsaved changes. </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="$emit('discard')"> Discard </Button>
        <Button @click="$emit('input', false)"> Keep editing </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
