<script setup>
import { ChevronRight } from "@/assets/icons";
import { CardLogo } from "@/components";
import { computed } from "vue";

const props = defineProps({
  card: {
    type: Object,
    default: () => ({}),
  },
});

defineEmits(["click"]);

const cardTypeFormatted = computed(() => {
  return props.card.card_type.replace("-", " ");
});

const address = computed(() => {
  const {
    billing_name,
    billing_address_1,
    billing_address_2,
    billing_city,
    billing_state,
    billing_zip,
    billing_country,
  } = props.card;

  let line = "";

  const existingParts = [
    billing_name,
    billing_address_1,
    billing_address_2,
    billing_city,
    billing_state,
    billing_country,
    billing_zip,
  ].filter((part) => part);
  if (existingParts.length > 0) {
    line = existingParts.join(", ");
  }
  return line;
});
</script>

<template>
  <section class="credit-card-display" @click="$emit('click')">
    <CardLogo :cardType="card.card_type" />
    <div class="card-id">
      <div class="card-info">
        <div class="card-name">
          {{ cardTypeFormatted }}
        </div>
      </div>
      <div class="card-row">
        •••• {{ props.card.last_4_digits }}, Exp {{ props.card.expiry_date }}
      </div>

      <div class="card-row" v-if="address">
        {{ address }}
      </div>
    </div>
    <div class="card-actions">
      <ChevronRight />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.credit-card-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 10px;
  border-top: 1px solid $color-primary-10;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);

  &:first-of-type {
    border-top: 1px solid transparent;
  }

  &:last-of-type {
    border-bottom: 1px solid $color-primary-10;
  }

  &:hover {
    border-radius: 16px;
    border-top: 1px solid $color-primary-5;
    background: $color-primary-5;
    transform: scale(1.003);
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);

    > div:first-child {
      background-color: $color-primary-10;
    }
  }

  .card-id {
    flex: 1;
    width: 100%;
    justify-content: space-between;
    .card-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .card-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $color-primary-100;
        text-transform: capitalize;
      }
      .card-number {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.2px;
        color: $color-primary-70;
      }
    }
    .card-row {
      font-size: 13px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: left;
      letter-spacing: -0.2px;
      color: $color-primary-50;
    }
  }
  > svg {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border: 1px solid $color-primary-10;
    border-radius: 20px;
  }
  .card-actions {
    cursor: pointer;
    align-self: center;
    background-color: transparent;
    border: none;
    color: $color-primary-100;
    margin-left: 30px;
  }
}
</style>
