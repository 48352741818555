<script setup>
import { computed } from "vue";
import store from "@/store";
import {
  PreferencesPanel,
  FundingSource,
  CloakedCardsStatus,
} from "@/routes/modals/preferences";
const rightPanel = computed(() => {
  return store.state.ui.preference.right;
});
</script>

<template>
  <PreferencesPanel v-if="!rightPanel">
    <CloakedCardsStatus />
    <FundingSource />
  </PreferencesPanel>
</template>
