<template>
  <div class="app-modal-content">
    <slot />
  </div>
</template>

<style lang="scss">
.app-modal-content {
  max-height: calc(100vh - 64px);
  max-width: calc(100vw - 64px);
  overflow: auto;
  width: 512px;
  background: $color-surface;
  color: $color-primary-100;
  border-radius: 28px;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
