<template>
  <footer class="preferences-footer">
    <slot />
  </footer>
</template>

<style lang="scss">
.preferences-footer {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  width: 100%;
}

* + .preferences-footer {
  margin-top: 32px;
}
</style>
