<script setup>
import Subscribe from "@/components/feature/subscribe/Subscribe.vue";
import SubscribePaymentDefault from "@/components/feature/subscribe/SubscribePaymentDefault.vue";
import SubscribePaymentCountdown from "@/components/feature/subscribe/SubscribePaymentCountdown.vue";
import { usePostHogFeatureFlag } from "@/composables";

const { featureFlag, hasLoadedFeatureFlag } = usePostHogFeatureFlag(
  "checkout-discount-timer"
);
</script>

<template>
  <Subscribe
    :user="$attrs.headlessUser"
    v-bind="$attrs"
    class="data-delete-plan"
    v-if="hasLoadedFeatureFlag"
  >
    <template #payment="{ slotProps }">
      <SubscribePaymentCountdown
        v-if="featureFlag === '20-off-5-min'"
        v-bind="slotProps"
      />
      <SubscribePaymentDefault v-else v-bind="slotProps" />
    </template>
  </Subscribe>
</template>
