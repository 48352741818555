<script setup>
import * as d3 from "d3";
import { computed, onMounted, ref, watch, onBeforeUnmount } from "vue";
import DataRemovalGraph from "@/routes/DataDeletion/components/RequestGraph/DataRemovalGraph.vue";
import store from "@/store";
import moment from "moment";

const props = defineProps({
  rawGraphData: {
    type: Object,
    default: () => {},
  },
});

const mobilePlatform = ref(null);
const hideGraph = ref(false);

const graphDataFromAndroidJson = ref("");
const graphDataFromAndroid = ref({});
const colorSchemeFromMobile = ref(null);

const graphDataFormatted = ref([]);
const activeSeries = ref(null);

const formatDate = d3.timeFormat("%b %d, %Y");
const colorScale = d3.scaleOrdinal([
  "#00C47D",
  "#9C95DC",
  "#FBBC04",
  "#E94B6F",
  "#4CD0F5",
]);

onMounted(() => {
  // NOTE: This is for ios
  window.addEventListener("message", handleMessageDataFromIOS);
  window?.webkit?.messageHandlers?.cloakedApp?.postMessage({});

  // NOTE: cloakedMobile object will only exist on android, code will fail to execute if not in try/catch cus cloakedMobile is not defined in this component
  try {
    //NOTE: This is for android
    graphDataFromAndroidJson.value = cloakedMobile?.provideGraphJson();
    graphDataFromAndroid.value = JSON.parse(graphDataFromAndroidJson.value);
    colorSchemeFromMobile.value = cloakedMobile?.getColorScheme();
    mobilePlatform.value = "android";
  } catch {}
});

const lineColors = computed(() =>
  graphDataFormatted?.value?.map((_, index) => colorScale(index))
);
const allPoints = computed(() =>
  graphDataFormatted?.value?.flatMap((line) => line?.points)
);
const firstDate = computed(() => {
  return allPoints?.value?.length
    ? formatDate(d3.min(allPoints.value, (point) => point?.x))
    : null;
});

const lastDate = computed(() => {
  return allPoints?.value?.length
    ? formatDate(d3.max(allPoints.value, (point) => point?.x))
    : null;
});

const refreshTime = computed(() => {
  const lastCall =
    store.getters["dataDelete/enrollmentData"]?.apiCalledTimestamp ||
    new Date();
  return moment(lastCall).format("h:mm A");
});

function addInitialZeroValuesIfNeeded(graphData) {
  const hasNonZeroInitialValue = graphData.some(
    (dataSet) => dataSet.points.length && dataSet.points[0].y !== 0
  );
  if (hasNonZeroInitialValue) {
    graphData.forEach((dataSet) => {
      if (dataSet.points.length) {
        const firstDate = new Date(dataSet.points[0].x);
        const dateBeforeFirst = new Date(firstDate);
        dateBeforeFirst.setDate(firstDate.getDate() - 1);
        dataSet.points.unshift({ x: dateBeforeFirst, y: 0 });
      }
    });
  }
}

function convertToMidnightLocal(dateString) {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  return date;
}

function handleMessageDataFromIOS(message) {
  if (
    typeof message.data === "string" &&
    message.data.includes("graph_data") &&
    window.ENV.VUE_APP_REDIRECT_URI.includes(message?.origin)
  ) {
    mobilePlatform.value = "ios";
    handleGraphData(JSON.parse(message.data));
  }
  if (
    typeof message.data === "string" &&
    message.data.includes("colorScheme") &&
    window.ENV.VUE_APP_REDIRECT_URI.includes(message?.origin)
  ) {
    mobilePlatform.value = "ios";
    colorSchemeFromMobile.value = message.data.includes("dark")
      ? "dark"
      : "light";
  }
}

function handleGraphData(apiResponse) {
  const rawDataArray = apiResponse?.graph_data;

  if (!rawDataArray || !rawDataArray.length) {
    hideGraph.value = true;
    return;
  }
  hideGraph.value = false;
  const dates = rawDataArray.map((data) => data.date);
  const rawDataObj = {};
  rawDataArray.forEach((data) => (rawDataObj[data.date] = data));

  const datesSorted = dates.sort((a, b) => new Date(a) - new Date(b));

  const fullNameGraphData = { label: "Full name", points: [] };
  const emailGraphData = { label: "Email address", points: [] };
  const phoneGraphData = { label: "Phone number", points: [] };
  const addressGraphData = { label: "Address", points: [] };
  const relativesGraphData = { label: "Relatives", points: [] };
  const datesSortedWithFillers = [];

  const cumulatedInfo = {
    full_name: rawDataObj[datesSorted[0]]?.full_name || 0,
    email_addresses: rawDataObj[datesSorted[0]]?.email_addresses || 0,
    phone_numbers: rawDataObj[datesSorted[0]]?.phone_numbers || 0,
    addresses: rawDataObj[datesSorted[0]]?.addresses || 0,
    relatives: rawDataObj[datesSorted[0]]?.relatives || 0,
  };

  // Fill in data for all the missing days
  // Convert time to midnight local time

  datesSorted.forEach((date, index) => {
    const currentDate = convertToMidnightLocal(date);
    const info = rawDataObj[date];
    const millisecondsInOneDay = 1000 * 60 * 60 * 24;

    if (index === 0) {
      datesSortedWithFillers.push({
        date: currentDate,
        info: { ...cumulatedInfo },
      });
    } else {
      const previousDateRaw = datesSorted[index - 1];
      const previousDateInMidnightLocal =
        convertToMidnightLocal(previousDateRaw);
      const numOfMissingDays = Math.floor(
        (currentDate - previousDateInMidnightLocal) / millisecondsInOneDay
      );
      if (numOfMissingDays > 1) {
        const arrayFromLength = Array.from(
          { length: numOfMissingDays - 1 },
          (_, i) => i + 1
        ).reverse();

        arrayFromLength.map((offset) => {
          const fillerDate = new Date(currentDate);
          fillerDate.setDate(fillerDate.getDate() - offset);
          datesSortedWithFillers.push({
            date: fillerDate,
            info: { ...cumulatedInfo },
          });
          return offset;
        });
      }

      cumulatedInfo.full_name += info?.full_name ?? 0;
      cumulatedInfo.email_addresses += info?.email_addresses ?? 0;
      cumulatedInfo.phone_numbers += info?.phone_numbers ?? 0;
      cumulatedInfo.addresses += info?.addresses ?? 0;
      cumulatedInfo.relatives += info?.relatives ?? 0;
      datesSortedWithFillers.push({
        date: currentDate,
        info: { ...cumulatedInfo },
      });
    }

    if (index === datesSorted.length - 1) {
      const now = new Date();
      const day = now.getDate();
      const month = now.getMonth();
      const year = now.getFullYear();
      const today = convertToMidnightLocal(`${year}-${month + 1}-${day}`);
      const numOfMissingDaysFromToday = Math.floor(
        (today - currentDate) / millisecondsInOneDay
      );
      if (numOfMissingDaysFromToday > 0) {
        const arrayFromLength = Array.from(
          { length: numOfMissingDaysFromToday },
          (_, i) => i
        ).reverse();

        arrayFromLength.map((offset) => {
          const fillerDate = new Date(today);
          fillerDate.setDate(fillerDate.getDate() - offset);
          datesSortedWithFillers.push({
            date: fillerDate,
            info: { ...cumulatedInfo },
          });
          return offset;
        });
      }
    }
  });

  datesSortedWithFillers.forEach((sortedGraphObject, index) => {
    // This function ensures data continuity.
    // If the info parameter is provided, it adds a new point with the current date and the info value.
    // If the info parameter is not provided, it adds a new point with the current date and the y value of the previous point (or 0 if there is no previous point).
    const pushNewOrPersist = (points, info) => {
      points.push({ x: sortedGraphObject.date, y: info });
    };

    // Add data points for each type of information (full name, email, phone, address, relatives)
    pushNewOrPersist(
      fullNameGraphData.points,
      sortedGraphObject?.info?.full_name
    );
    pushNewOrPersist(
      emailGraphData.points,
      sortedGraphObject?.info?.email_addresses
    );
    pushNewOrPersist(
      phoneGraphData.points,
      sortedGraphObject?.info?.phone_numbers
    );
    pushNewOrPersist(
      addressGraphData.points,
      sortedGraphObject?.info?.addresses
    );
    pushNewOrPersist(
      relativesGraphData.points,
      sortedGraphObject?.info?.relatives
    );
  });

  addInitialZeroValuesIfNeeded([
    fullNameGraphData,
    emailGraphData,
    phoneGraphData,
    addressGraphData,
    relativesGraphData,
  ]);

  graphDataFormatted.value = [
    fullNameGraphData,
    emailGraphData,
    phoneGraphData,
    addressGraphData,
    relativesGraphData,
  ].sort((a, b) => {
    const aLastPoint = a.points[a.points.length - 1]?.y ?? 0;
    const bLastPoint = b.points[b.points.length - 1]?.y ?? 0;
    return bLastPoint - aLastPoint;
  });
}

function setActiveSeries(line) {
  // NOTE: disable hover effects on mobile
  if (!mobilePlatform.value) {
    activeSeries.value = line.label;
  }
}
function toggleActiveSeries(line) {
  if (activeSeries.value === line.label) {
    activeSeries.value = null;
  } else {
    activeSeries.value = line.label;
  }
}

function clearActiveSeries() {
  // NOTE: disable hover effects on mobile
  if (!mobilePlatform.value) {
    activeSeries.value = null;
  }
}

watch(
  () => props.rawGraphData,
  (newVal) => {
    if (newVal?.graph_data?.length) {
      handleGraphData(newVal);
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => graphDataFromAndroid.value,
  (newVal) => {
    if (newVal?.graph_data?.length) {
      handleGraphData(newVal);
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => colorSchemeFromMobile.value,
  (newVal) => {
    if (mobilePlatform.value) {
      if (newVal === "dark") {
        return store.dispatch("colorScheme/setDarkTheme");
      }
      return store.dispatch("colorScheme/setLightTheme");
    }
  },
  { immediate: true }
);
</script>

<template>
  <div v-show="!hideGraph" :class="{ 'full-screen': !!mobilePlatform }">
    <div class="data-removal-graph-container">
      <DataRemovalGraph
        :data="graphDataFormatted"
        :lineColors="lineColors"
        :activeSeries="activeSeries"
      />
    </div>
    <div class="data-removal-graph_timeline" v-if="firstDate || lastDate">
      <div class="data-removal-graph__timeline-columns">
        <div v-if="firstDate" class="data-removal-graph__timeline-column-left">
          <div class="data-removal-graph__timeline-row-header">Start</div>
          <div class="data-removal-graph__timeline-row-subheader">
            {{ firstDate }}
          </div>
        </div>
        <div v-if="lastDate" class="data-removal-graph__timeline-column-right">
          <div class="data-removal-graph__timeline-row-header">Today</div>
          <div class="data-removal-graph__timeline-row-subheader">
            {{ lastDate }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="data-removal-graph_bottom-row"
      :class="{ mobile: !!mobilePlatform }"
    >
      <div
        class="data-removal-graph_legend"
        :class="{ ios: mobilePlatform === 'ios' }"
      >
        <div
          v-for="(line, index) in graphDataFormatted"
          :key="index"
          @click="toggleActiveSeries(line)"
          @mouseover="setActiveSeries(line)"
          @mouseleave="clearActiveSeries"
          :class="{
            active: activeSeries === line.label,
            'not-mobile': !mobilePlatform,
          }"
          class="data-removal-graph_legend-item"
          :style="{
            '--hover-border-color': lineColors[index],
          }"
        >
          <div class="data-removal-graph_legend-label">{{ line.label }}</div>
          <div
            class="data-removal-graph_legend-color"
            :style="{
              backgroundColor: lineColors[index],
            }"
          ></div>
        </div>
      </div>
      <div class="data-removal-graph_refresh-time" v-if="!mobilePlatform">
        Stats refreshed today at {{ refreshTime }}
      </div>
    </div>
    <div class="data-removal-graph_refresh-time" v-if="!!mobilePlatform">
      Stats refreshed today at {{ refreshTime }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $color-surface;
}

.data-removal-graph-container {
  width: 100%;
  height: 200px;
}

.data-removal-graph_timeline {
  display: flex;
  flex-direction: column;
  color: $color-primary-100;
  padding: 0 10px;
  .data-removal-graph__timeline-columns {
    display: flex;
    justify-content: space-between;
    .data-removal-graph__timeline-column-left,
    .data-removal-graph__timeline-column-right {
      display: flex;
      flex-direction: column;
    }
    .data-removal-graph__timeline-column-left {
      align-items: flex-start;
      width: 100%;
    }
    .data-removal-graph__timeline-column-right {
      align-items: flex-end;
      width: 100%;
    }
  }
}

.data-removal-graph__timeline-row-header {
  font-weight: 600;
  font-size: 10px;
}

.data-removal-graph__timeline-row-subheader {
  font-size: 12px;
  font-weight: 400;
}

.data-removal-graph_bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  flex-wrap: wrap;
  padding: 5px 0;
  &.mobile {
    flex-wrap: nowrap;
    overflow: scroll;
    width: auto;
  }
}

.data-removal-graph_legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding: 0 5px 5px 5px;
  height: auto;
  &.ios {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    width: fit-content;
    justify-content: flex-start;
    gap: 8px;
    width: fit-content;
    @include hide-scrollbar;
  }
  @media (max-width: 768px) {
    gap: 4px;
  }
  .data-removal-graph_legend-item {
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.3s;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: default;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
      min-height: 35px;
    }
    &.not-mobile {
      flex-wrap: wrap;
      &:hover {
        transform: scale(1.03);
        border: 1px solid var(--hover-border-color);
      }
    }

    &.active {
      border: 1px solid var(--hover-border-color);
    }
    .data-removal-graph_legend-color {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      flex-shrink: 0;
    }
    .data-removal-graph_legend-label {
      font-size: 12px;
      fill: $color-primary-100;
      color: $color-primary-100;
      font-weight: 500;
      flex-grow: 1;
      text-wrap: nowrap;
      white-space: nowrap;
    }
  }
}

.data-removal-graph_refresh-time {
  font-size: 12px;
  color: $color-primary-100;
  padding: 0 10px;
  margin-top: 5px;
}
</style>
