<script setup>
import DataDeleteCard from "@/components/feature/data-delete/atoms/DataDeleteCard.vue";
import { computed, watch, defineEmits } from "vue";
import {
  useDateOfBirthParsing,
  useNameParsing,
  useRelativesParsing,
} from "@/components/feature/data-delete/composables";
import DataDeleteShare from "@/components/feature/data-delete/atoms/DataDeleteShare.vue";
import InlineSvg from "@/components/InlineSvg.vue";
import DataDeleteThreatLevel from "@/components/feature/data-delete/atoms/DataDeleteThreatLevel.vue";

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const { dateOfBirth } = useDateOfBirthParsing(props.result);

const driversLicense = computed(() => props.result.driversLicenses?.[0]);

const currentAddress = computed(() => props.result.locations?.[0]);
const currentStreet = computed(() => currentAddress.value.street);
const currentCity = computed(() => {
  return [
    currentAddress.value?.city?.toLowerCase(),
    currentAddress.value?.state?.abbreviation,
  ]
    .filter((value) => !!value)
    .join(", ");
});

const previousAddresses = computed(() => {
  const previousStatesAbbreviations = props.result.locations
    ?.slice(1)
    .map((location) => location.state?.abbreviation);

  const states = Array.from(new Set(previousStatesAbbreviations));

  return {
    count: previousStatesAbbreviations.length,
    states: [states.slice(0, -1).join(", "), states[states.length - 1]]
      .filter((value) => !!value)
      .join(" and "),
  };
});

const { name, nameAndAge } = useNameParsing(props.result);

const mapFamilyMembers = (relative) => {
  const name =
    relative.fullName ??
    [relative.firstName, relative.lastName]
      .filter((value) => !!value)
      .join(" ");

  return name?.toLowerCase();
};

const { partners, relatives } = useRelativesParsing(props.result.relatives);
const familyMembers = computed(() =>
  [...partners.value, ...relatives.value].map(mapFamilyMembers)
);

const isCardVisible = computed(
  () =>
    props.result.ssn ||
    dateOfBirth.value ||
    currentAddress.value ||
    driversLicense.value
);

const emit = defineEmits(["update:modelValue"]);
watch(
  () => isCardVisible.value,
  (isVisible) => emit("update:modelValue", !!isVisible),
  { immediate: true }
);
</script>

<template>
  <DataDeleteCard
    type="alert"
    class="data-delete-scan-results-card"
    v-if="isCardVisible"
  >
    <DataDeleteThreatLevel
      threat-level="high"
      :has-modal="false"
      class="data-delete-scan-results-card__threat"
    />
    <div v-if="name" class="data-delete-scan-results-card__column">
      <h2 class="data-delete-scan-results-card__name">
        {{ nameAndAge }}
      </h2>
      <p class="data-delete-scan-results-card__text">
        We detected confidential information in your scan. It's important to
        start deleting this immediately as it can be used for bank fraud, credit
        card fraud, tax fraud, and identity theft.
      </p>
    </div>
    <div
      v-if="result.ssn || dateOfBirth"
      class="data-delete-scan-results-card__column data-delete-scan-results-card__birth-info"
    >
      <div v-if="result.ssn">
        <h3 class="data-delete-scan-results-card__subtitle">SSN</h3>
        <span
          class="data-delete-scan-results-card__data data-delete-scan-results-card__data--large"
        >
          {{ result.ssn }}
        </span>
      </div>
      <div v-if="dateOfBirth">
        <h3 class="data-delete-scan-results-card__subtitle">Date of Birth</h3>
        <span
          class="data-delete-scan-results-card__data data-delete-scan-results-card__data--large"
        >
          {{ dateOfBirth }}
        </span>
      </div>
    </div>
    <div
      v-if="currentAddress || driversLicense"
      class="data-delete-scan-results-card__column"
    >
      <div v-if="currentAddress">
        <h3 class="data-delete-scan-results-card__subtitle">Addresses</h3>
        <div
          class="data-delete-scan-results-card__data data-delete-scan-results-card__data--large data-delete__capitalized"
        >
          <template v-if="currentStreet">
            {{ currentStreet }}
          </template>
          <br v-if="currentStreet && currentCity" />
          <template v-if="currentCity">
            {{ currentCity }}
          </template>
        </div>
        <div
          class="data-delete-scan-results-card__data"
          v-if="previousAddresses.count"
        >
          + {{ previousAddresses.count }} previous in
          {{ previousAddresses.states }}
        </div>
      </div>
      <div v-if="driversLicense">
        <h3 class="data-delete-scan-results-card__subtitle">
          Driver's License
        </h3>
        <span
          class="data-delete-scan-results-card__data data-delete-scan-results-card__data--large"
        >
          {{ driversLicense }}
        </span>
      </div>
    </div>
    <div v-if="familyMembers.length">
      <h3 class="data-delete-scan-results-card__subtitle">Family members</h3>
      <div
        class="data-delete-scan-results-card__data data-delete-scan-results-card__data--large data-delete-scan-results-card__data--name"
        v-for="(familyMember, index) in familyMembers.slice(0, 4)"
        :key="index"
      >
        {{ familyMember }}
      </div>
      <div
        v-if="familyMembers.length > 4"
        class="data-delete-scan-results-card__data"
      >
        +
        {{
          `${familyMembers.length - 4} ${
            familyMembers.length - 4 === 1 ? "other" : "others"
          }`
        }}
      </div>
      <DataDeleteShare class="data-delete-scan-results-card__share">
        Notify family
        <InlineSvg name="share" />
      </DataDeleteShare>
    </div>
  </DataDeleteCard>
</template>

<style lang="scss">
.data-delete-scan-results-card {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media all and (min-width: $screen-xl) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    padding: 24px;
  }

  & &__threat {
    max-width: 260px;
    margin: 0 auto 8px;
    grid-column: 1/5;

    @media all and (min-width: $screen-xl) {
      max-width: 440px;
      margin: 0 auto 16px;
    }

    .data-delete__title {
      font-size: 18px;
      letter-spacing: -0.4px;

      @media all and (min-width: $screen-xl) {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.6px;
      }
    }

    .threat-level__progress-wrapper {
      margin-top: 4px;
    }

    .threat-progress__icon,
    .threat-progress__indicator {
      transition: none !important;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__birth-info {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media all and (min-width: $screen-xl) {
      display: flex;
      flex-direction: column;
    }
  }

  &__name {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    text-transform: capitalize;

    @media all and (min-width: $screen-xl) {
      font-size: 24px;
      letter-spacing: -0.5px;
    }
  }

  &__text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
  }

  &__subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;

    @media all and (min-width: $screen-xl) {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }

  &__data {
    color: $color-alert;
    font-size: 12px;
    font-weight: 400;

    @media (min-width: $screen-xl) {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.1px;
    }

    &--large {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;

      @media (min-width: $screen-xl) {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
      }
    }

    &--name {
      text-transform: capitalize;
    }
  }

  &__share {
    margin-top: 12px;

    @media (min-width: $screen-xl) {
      margin-top: 15px;
    }
  }
}
</style>
