/* Constants for email and phone contacts, passed to <ContactsViewEdit /> and used in modal. Do not change unless API endpoint has changes */
export const CONTACT_TYPE = {
  PHONE: "phone",
  EMAIL: "email",
};
export const CONTACT_CARD_MODE = {
  VIEW: "view",
  EDIT: "edit",
  BLOCK: "block",
  ADD: "add",
};
export const CONTACT_STATUS = {
  APPROVED: "approved",
  BLOCKED: "blocked",
  UNKNOWN: "unknown",
};
export const CONTACT_ACTION = {
  APPROVE: "approve",
  BLOCK: "block",
};
export const CONTACT_ACTION_TO_STATUS = {
  approve: "approved",
  block: "blocked",
};

export const SUPPORTED_CARDS_COUNTRIES = ["US"];

export const getShouldShowCards = (user) => {
  if (!user) return false;
  const userCountry = user?.country_at_registration
    ? user?.country_at_registration
    : user?.current_country;
  return (
    user?.cloaked_card_enabled &&
    SUPPORTED_CARDS_COUNTRIES.includes(userCountry)
  );
};

export const FILE_PERMISSIONS = {
  TEXT_ALLOWED: ["png", "jpg", "jpeg", "pdf"],
  // NOTE: format is ".ext: mime-type"
  BANNED: [
    ".ade: application/msaccess",
    ".adp: application/msaccess",
    ".apk: application/vnd.android.package-archive",
    ".appx: application/vnd.ms-appx",
    ".appxbundle: application/vnd.ms-appx-bundle",
    ".bat: application/x-msdownload",
    ".cab: application/vnd.ms-cab-compressed",
    ".chm: application/vnd.ms-htmlhelp",
    ".cmd: text/cmd",
    ".com: application/x-msdownload",
    ".cpl: application/x-cpl",
    ".diagcab: application/vnd.ms-cab-diagnostic",
    ".diagcfg: application/vnd.ms-cab-diagnostic-config",
    ".diagpack: application/vnd.ms-cab-diagnostic-package",
    ".dll: application/x-msdownload",
    ".dmg: application/x-apple-diskimage",
    ".ex: application/x-msdownload",
    ".ex_: application/x-msdownload",
    ".exe: application/x-msdownload",
    ".hta: application/hta",
    ".img: application/octet-stream",
    ".ins: application/x-internet-signup",
    ".iso: application/octet-stream",
    ".isp: application/x-internet-signup",
    ".jar: application/java-archive",
    ".jnlp: application/x-java-jnlp-file",
    ".js: application/javascript",
    ".jse: application/javascript",
    ".lib: application/octet-stream",
    ".lnk: application/x-ms-shortcut",
    ".mde: application/msaccess",
    ".msc: application/octet-stream",
    ".msi: application/x-msdownload",
    ".msix: application/x-msix-package",
    ".msixbundle: application/vnd.ms-appx-msixbundle",
    ".msp: application/mspatch",
    ".mst: application/octet-stream",
    ".nsh: application/octet-stream",
    ".pif: application/x-ms-shortcut",
    ".ps1: application/octet-stream",
    ".scr: application/x-msdownload",
    ".sct: application/x-sct",
    ".shb: application/x-shockwave-flash",
    ".svg: image/svg+xml",
    ".sys: application/octet-stream",
    ".vb: application/octet-stream",
    ".vbe: application/octet-stream",
    ".vbs: text/vbscript",
    ".vhd: application/x-virtualbox-vhd",
    ".vxd: application/vnd.visio",
    ".wsc: text/scriptlet",
    ".wsf: application/x-wsf",
    ".wsh: application/x-wsh",
    ".xll: application/xll.ms-excel",
  ],
};

export const STRIPE_MANAGE_URL = window.ENV.VUE_APP_STRIPE_MANAGE_URL;

export const ACCOUNT_RESTRICTED_STATE = "CANCELED";

export const VIEW_TYPE = {
  LIST: "list",
  GRID: "grid",
};

export const MAIN_INBOX_PAGE_NAMES = [
  "Inbox",
  "Texts",
  "Calls",
  "Emails",
  "Requests",
  "Starred",
];
export const SPECIAL_INBOX_PAGE_NAMES = [
  "CategoryInbox",
  "CloakInbox",
  "FavoritesInbox",
  "RecentInbox",
  "StarredInbox",
];
export const INBOX_ROUTE_TYPE_CONVERSION = {
  Inbox: "all",
  Emails: "email",
  Texts: "message",
  Calls: "call",
  Requests: "requests",
  Starred: "starred",
};

export const INBOX_ATTACHMENT_MAX_FILE_LIMIT = 5;

export const INBOX_TYPES = {
  EMAIL: "email",
  MESSAGE: "message",
  CALL: "call",
};

export const SUBSCRIPTION_STORES_FORMATTED = {
  stripe: "Stripe",
  app_store: "the App Store",
  play_store: "the Play Store",
  paypal: "PayPal",
  unknown: "", //Most legacy users
  manual_upgrade: "", //Legacy users OR Important People that we wanted to have access to all paid features including DD
};

export const SUBSCRIPTION_STORES = {
  STRIPE: "stripe",
  APP_STORE: "app_store",
  PLAY_STORE: "play_store",
  PAYPAL: "paypal",
  UNKNOWN: "unknown",
  MANUAL_UPGRADE: "manual_upgrade",
};

export const GENERIC_ALL_FEATURES = [
  "Unlimited identities",
  "Unlimited email addresses",
  "Unlimited usernames and passwords",
  "Your own encrypted database",
  "Personalized communication controls",
];

export const PAID_US_FEATURES = [
  ...GENERIC_ALL_FEATURES,
  "Identity Theft Protection",
  "Data Deletion",
];

export const PAID_CA_FEATURES = [...GENERIC_ALL_FEATURES, "Data Deletion"];

export const US_UPGRADE_FEATURES = [
  "Identity Theft Protection",
  "Data Deletion",
];

export const CA_UPGRADE_FEATURES = ["Data Deletion"];

export const EXPIRED_RESTRICTEDPLAN_FEATURES = [
  "Editing of existing identities",
  "Unlimited phone numbers",
  "Unlimited email addresses",
  "Unlimited usernames and passwords",
  "Unlimited identities",
  "Personalized communication controls",
];

export const RESTRICTEDPLAN_FEATURES = [
  "Previously created identities",
  "Sending and receiving calls, texts, and emails",
];

export const DEFAULT_PASSWORD_SETTINGS = {
  letters: true,
  numbers: true,
  symbols: true,
  similar: false,
  size: 16,
  words: false,
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DATA_REMOVAL_STATUS = {
  SCANNING: "scanning",
  NO_RECORDS_FOUND: "no_records_found",
  REMOVAL_IN_PROGRESS: "removal_in_progress",
  REMOVED: "removed",
  ACTION_REQUIRED: "action_required",
};
