import { SubscriptionService } from "@/api";
import { loadStripe } from "@stripe/stripe-js";
import { useToast } from "@/hooks";
import { authDecrypt } from "@/scripts/actions/encryption";
import { usePlansModal } from "@/components/feature/subscribe/composables";
const toast = useToast();

const INTENT_EXPIRATION_IN_MILLISECONDS = 3600 * 1000;

let initPromise = null;

const { openPlansModal } = usePlansModal();

export default {
  namespaced: true,
  state: {
    stripe: null,
    plans: [],
    intents: [],
    isModalOpen: false,
    callback: null,
    defaultPromo: null,
    subscriptionDetails: null,
    invitations: [],
  },
  mutations: {
    SET_STRIPE: (state, payload) => (state.stripe = payload),
    SET_PLANS: (state, payload) => (state.plans = payload),
    SET_INTENTS: (state, payload) => (state.intents = payload),
    SET_INVITATIONS: (state, payload) => (state.invitations = payload),
    SET_IS_MODAL_OPEN: (state, payload) => {
      state.isModalOpen = payload;
      SubscriptionService.getSubscription();
    },
    SET_CALLBACK: (state, payload) => (state.callback = payload),
    SET_DEFAULT_PROMO_CODE: (state, payload) => (state.defaultPromo = payload),
    SET_SUBSCRIPTION_DETAILS: (state, payload) =>
      (state.subscriptionDetails = payload),
  },
  actions: {
    async loadStripe({ commit }) {
      const stripe = await loadStripe(window.ENV.VUE_APP_STRIPE);
      commit("SET_STRIPE", stripe);
      return stripe;
    },
    async fetchPlans({ commit }, type = null) {
      const plans = await SubscriptionService.getSubscriptionPlans(type);
      commit("SET_PLANS", plans);
      return plans;
    },
    async fetchInvitations({ commit }) {
      const { results: invitations } =
        await SubscriptionService.getPlanInvitations();

      const decryptedInvitations = await Promise.all(
        invitations.map(async (encryptedInvite) => ({
          ...encryptedInvite,
          recipient_email: await authDecrypt(encryptedInvite.recipient_email),
        }))
      );

      commit("SET_INVITATIONS", decryptedInvitations);
      return invitations;
    },
    async sendInvite({ dispatch }, email) {
      await SubscriptionService.invitePlanMember(email);
      await dispatch("fetchInvitations");
    },
    async removeMember({ dispatch }, id) {
      await SubscriptionService.removePlanMember(id);
      await dispatch("fetchInvitations");
    },
    async acceptInvite({ dispatch }, payload) {
      await SubscriptionService.acceptInvitation(payload);
      await SubscriptionService.getSubscription();
    },
    async cancelSubscription() {
      await SubscriptionService.removeSubscription();
      await SubscriptionService.getSubscription();
    },
    async fetchIntent(
      { state, getters, commit },
      { productId, promoCode = null }
    ) {
      const cachedIntent = getters.getIntent(productId, promoCode);

      if (
        cachedIntent &&
        cachedIntent.timestamp + INTENT_EXPIRATION_IN_MILLISECONDS >
          new Date().getTime()
      ) {
        return cachedIntent.intent;
      }

      if (cachedIntent) {
        commit(
          "SET_INTENTS",
          state.intents.filter(
            (intent) => intent.timestamp !== cachedIntent.timestamp
          )
        );
      }

      let intent;
      try {
        intent = await SubscriptionService.getPaymentIntent(
          productId,
          promoCode
        );
      } catch (e) {
        try {
          intent = await SubscriptionService.getPaymentIntent(
            productId,
            promoCode
          );
        } catch (eb) {}
      }

      if (intent) {
        const newIntent = {
          productId,
          promoCode,
          intent,
          timestamp: new Date().getTime(),
        };

        commit("SET_INTENTS", [...state.intents, newIntent]);

        return newIntent.intent;
      }

      return false;
    },
    async fetchSubscriptionDetails({ commit }) {
      let result = null;

      try {
        result = await SubscriptionService.getSubscriptionDetails().catch(
          () => {}
        );
      } finally {
        commit("SET_SUBSCRIPTION_DETAILS", result);
      }

      return result;
    },
    async init({ dispatch }) {
      await dispatch("authentication/waitForAuthentication", null, {
        root: true,
      });

      initPromise =
        initPromise ??
        Promise.all([
          dispatch("loadStripe"),
          dispatch("fetchPlans"),
          dispatch("fetchInvitations"),
        ]);

      return initPromise;
    },
    openSubscriptionModal({ commit, getters }, details) {
      if (getters.hasFamilyPlans) {
        openPlansModal("subscribe");
      } else {
        if (details) {
          const { callback = null, promoCode = null } = details;
          commit("SET_CALLBACK", callback);
          commit("SET_DEFAULT_PROMO_CODE", promoCode);
        }
        commit("SET_IS_MODAL_OPEN", true);
      }
    },
    closeModal({ commit }) {
      commit("SET_IS_MODAL_OPEN", false);
      commit("SET_CALLBACK", null);
      commit("SET_DEFAULT_PROMO_CODE", null);
    },
    awaitSubscriptionChange({ rootGetters }) {
      // backend is not in sync with payment providers, after payment, it takes a few moments for subscription status to update
      return new Promise((resolve) => {
        let pollingInterval = null;
        let count = 0;
        pollingInterval = setInterval(() => {
          const oldProductId =
            rootGetters["settings/getSubscription"]?.product_identifier;

          SubscriptionService.getSubscription()
            .then((newSubscription) => {
              const newProductId = newSubscription?.product_identifier;

              if (
                rootGetters["settings/isSubscribed"] &&
                newProductId !== oldProductId
              ) {
                resolve();
                clearInterval(pollingInterval);
              } else {
                count++;
                if (count === 10) {
                  toast.success(
                    "Still fetching your subscription status, please hold..."
                  );
                } else if (count >= 60) {
                  // after 1 minute, stop polling so this doesnt ping forever
                  throw new Error(
                    "Subscription status not updated after 1 minute"
                  );
                }
              }
            })
            .catch(() => {
              count++;
              if (count >= 60) {
                clearInterval(pollingInterval);
                return toast.error(
                  "There was an error fetching your subscription status. Please try refreshing the page."
                );
              }
            });
        }, 1000);
      });
    },
  },
  getters: {
    getStripe: (state) => state.stripe,
    getPlans: (state) => state.plans,
    getIntent: (state) => (productId, promoCode) =>
      state.intents.find(
        (intent) =>
          intent.productId === productId && intent.promoCode === promoCode
      ) ?? null,
    getInvitations: (state) => state.invitations,
    isModalOpen: (state) => state.isModalOpen,
    getCallback: (state) => state.callback,
    getSubscriptionDetails: (state) => state.subscriptionDetails,
    hasFamilyPlans: (state) =>
      state.plans?.some((plan) => plan.max_members > 1),
  },
};
