<script setup>
import store from "@/store";
import { onBeforeMount, ref, watch } from "vue";
import Countdown from "@/components/Countdown.vue";
import { timeTo } from "@/components/feature/onboarding/utils";
import { MagicWand } from "@/assets/icons";

const props = defineProps({
  expires: {
    type: Date,
    required: true,
  },
});

function upgradeModal() {
  store.dispatch("subscription/openSubscriptionModal");
}

onBeforeMount(() => store.dispatch("subscription/init"));

const countdown = ref(timeTo(props.expires));

const emit = defineEmits(["expired"]);

watch(
  () => countdown.value,
  (newValue) => {
    if (newValue <= 0) {
      emit("expired");
    }
  },
  { immediate: true }
);
</script>

<template>
  <section>
    <span
      >25% off for a limited time
      &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;<Countdown
        :expires="expires"
        :value="countdown"
        @input="(event) => (countdown = event)"
        class="welcome-discount__countdown"
      />&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;<a
        @click="upgradeModal"
      >
        <MagicWand />
        Subscribe today</a
      ></span
    >
  </section>
</template>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background: $color-primary-100;
  display: flex;
  align-items: center;
  justify-content: center;

  .welcome-discount__countdown {
    width: 65px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-0;

    a {
      color: $color-primary-0;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    svg {
      margin-right: 8px;
      vertical-align: middle;
    }
  }
}
</style>
