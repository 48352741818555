<script setup>
import { computed, markRaw } from "vue";
import Button from "./WalletSettingsButton";
import { useRoute } from "@/hooks";
import store from "@/store";
import PatchCurrentFundingSource from "@/components/modals/Wallet/PatchCurrentFundingSource.vue";

const route = useRoute();

const currentCard = computed(() => {
  if (route.params.id && store.state.cards.cards.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  } else {
    return "";
  }
});

const fundingSources = computed(() => {
  return store.state.cards.fundingSources.results;
});

const currentCardFundingSource = computed(() => {
  if (currentCard.value?.funding_source) {
    return fundingSources.value.find(
      (source) => source.id === currentCard.value.funding_source
    );
  } else {
    return null;
  }
});

const currentCardProvider = computed(() => {
  return currentCardFundingSource.value?.provider;
});

const fundingSourcesFiltered = computed(() => {
  return store.state.cards.fundingSources.results.filter(
    (source) => source.provider === currentCardProvider.value
  );
});

const subtext = computed(() => {
  const pan = "•••• " + lastFourDigits();
  const note = currentCardFundingSource.value?.note;

  if (pan && note) {
    return `${pan} • ${note}`;
  } else if (currentCardFundingSource.value?.pan_last_four) {
    return pan;
  } else {
    return null;
  }
});

const loading = computed(() => {
  if (!currentCardFundingSource.value) {
    return true;
  } else {
    return false;
  }
});

function lastFourDigits() {
  const pan = currentCardFundingSource.value?.pan_last_four;
  if (pan) {
    return pan.slice(-4);
  } else {
    return null;
  }
}

const openFundingSources = () => {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(PatchCurrentFundingSource),
      props: {
        isVisible: true,
        sources: fundingSourcesFiltered.value,
        currentSource: currentCardFundingSource.value,
        currentCardID: currentCard.value.id,
      },
    },
  });
};
</script>

<template>
  <Button
    :loading="loading"
    icon="bank"
    text="Funding source"
    :title="currentCardFundingSource?.card_brand || 'Unknown'"
    :subtext="subtext || 'Unknown'"
    @click="openFundingSources"
  />
</template>
