<script setup>
import { computed, ref } from "vue";
import { ModalTemplate } from "@/components";
import store from "@/store";
import inlineSvg from "@/components/InlineSvg.vue";
import Button from "@/components/Button.vue";
import IdentityService from "@/api/actions/identity-service";
import CardsServices from "@/api/actions/cards-services";
import { useToast } from "@/hooks";

const toast = useToast();

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  id: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(["refresh"]);

function closeModal() {
  store.dispatch("closeModal");
}

const cardSettings = computed(() => {
  return store.state.cards?.cardSettings;
});

const sources = computed(() => {
  const results = store.state.cards?.fundingSources?.results;

  return results?.sort((a, b) => b.primary - a.primary);
});

const primarySource = computed(() => {
  return sources?.value.find((source) => source.primary).id;
});

const fundingSource = ref(null);

const selectedSource = computed(() => {
  return fundingSource.value || primarySource.value;
});

function selectSource(source) {
  fundingSource.value = source.id;
}

const showAll = ref(true);

const dollars = ref(
  convertToDollarFormatted(cardSettings?.value?.spending_limit) || ""
);

const sendUserHash = ref("");

function convertToDollarFormatted(amount) {
  return (amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace(/\.0+$/, "")
    .replace(/\$/g, "");
}

function convertToCent(amount) {
  return Number(amount.replace(/[^\d.]/g, "")) * 100;
}

function updateCents(value) {
  dollars.value = value;
  form.value.amount = convertToCent(value);
}

const form = ref({
  amount: cardSettings?.value?.spending_limit || 0,
  period: cardSettings?.value?.period || "daily",
  source: selectedSource?.value,
});

const generating = ref(false);

function generateLiteCard(id) {
  generating.value = true;

  IdentityService.createIdentity({
    website_url: "cloaked.app",
    nickname: "Gift Card",
  })
    .then((response) => {
      const identityId = response.data.id;

      CardsServices.createIdentityCard(identityId, {
        type: "MULTI_USE",
        currency: "usd",
        funding_source: form.value.source,
        transaction_period_limit: form.value.amount,
        transaction_period: "monthly",
        valid_for_months: 12,
        transaction_period_max_transactions: 100,
        shared_username: sendUserHash.value,
      })
        .then(() => {
          CardsServices.getCardList().then(() => {
            closeModal();
            toast.success("Card generated successfully");
          });
        })
        .catch((err) => {
          IdentityService.deleteCloak(identityId).then(() => {
            store.dispatch("removeCloaks", [identityId]);
            toast.error(err?.response?.data?.message);
            generating.value = false;
          });
        });
    })
    .catch(() => {
      generating.value = false;
    });
}
</script>

<template>
  <ModalTemplate :show="props.isVisible" @close="closeModal">
    <template #header>
      <div class="title">
        <h1>Generate Gift Card</h1>
      </div>
    </template>
    <template #body>
      <div class="amount">
        <inlineSvg name="cash-filled" />
        <input
          type="text"
          v-model="dollars"
          @input="updateCents($event.target.value)"
        />
      </div>

      <div class="user-hash">
        <inlineSvg name="user-icon" />
        <input type="text" v-model="sendUserHash" placeholder="Username" />
      </div>

      <div class="funding-sources">
        <div v-if="showAll">
          <div
            class="funding-source"
            :class="{ selected: source.id === selectedSource }"
            @click="selectSource(source)"
            v-for="source in sources
              .slice()
              .filter((source) => source.id === selectedSource)"
            :key="source.id"
          >
            <inlineSvg name="bank" />
            <div class="information">
              <h1>{{ source.card_brand }}</h1>
              <p>
                <span>**** {{ source.pan_last_four }}</span>
                <span v-if="source.nickname">• {{ source.nickname }}</span>
              </p>
            </div>

            <span class="default-pill" v-if="source.primary">Default</span>

            <div class="selected-icon"></div>
          </div>
        </div>

        <div v-else>
          <div
            class="funding-source"
            v-for="source in sources"
            :key="source.id"
            :class="{ selected: source.id === selectedSource }"
            @click="selectSource(source)"
          >
            <inlineSvg name="bank" />
            <div class="information">
              <h1>{{ source.card_brand }}</h1>
              <p>
                <span>**** {{ source.pan_last_four }}</span>
                <span v-if="source.nickname">• {{ source.nickname }}</span>
              </p>
            </div>

            <span class="default-pill" v-if="source.primary">Default</span>

            <div class="selected-icon"></div>
          </div>
        </div>

        <span class="show" @click="showAll = !showAll">
          {{ showAll ? "Show all" : "Show less" }}
        </span>
      </div>
    </template>
    <template #footer>
      <Button @click="closeModal" type="secondary">Cancel</Button>
      <Button
        @click="generateLiteCard(props.id)"
        :loading="generating"
        :disabled="generating"
        >Generate</Button
      >
    </template>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
.title {
  p {
    margin-top: 8px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1px;
    color: $color-primary-70;
  }
}

.amount {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: $color-primary-100;
    width: 36px;
    height: auto;
  }

  input {
    width: 100%;
    padding: 20px 72px 20px 72px;
    background-color: $color-primary-5;
    border: 0;
    border-radius: 24px;
    color: $color-primary-100;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  &:after {
    content: "USD";
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: $color-primary-20;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
  }
}

.user-hash {
  position: relative;
  margin-top: 24px;

  svg {
    position: absolute;
    top: 50%;
    left: 22px;
    transform: translateY(-50%);
    color: $color-primary-100;
    width: 26px;
    height: auto;
  }

  input {
    width: 100%;
    padding: 20px 20px 20px 72px;
    background-color: $color-primary-5;
    border: 0;
    border-radius: 24px;
    color: $color-primary-100;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: $color-primary-20;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
  }
}

.funding-sources {
  margin-top: 24px;

  .funding-source {
    border: 1px solid $color-primary-10;
    border-radius: 16px;
    padding: 16px;
    margin-top: 4px;
    position: relative;
    color: $color-primary-100;

    &:hover {
      background-color: $color-primary-5;
      cursor: pointer;
    }

    &.selected {
      .selected-icon {
        &:after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background-color: $color-primary-100;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }

    .information {
      padding-left: 40px;
      padding-right: 40px;

      h1 {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.1px;
        color: $color-primary-100;
        text-transform: capitalize;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 4px;

        span {
          display: inline-block;

          &:nth-of-type(2) {
            margin-left: 4px;
          }
        }
      }
    }

    .default-pill {
      position: absolute;
      top: 50%;
      right: 56px;
      transform: translateY(-50%);
      background-color: $color-success;
      color: $white;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 4px 10px;
      border-radius: 19px;
    }

    .selected-icon {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid $color-primary-100;
    }
  }

  .show {
    margin-top: 12px;
    margin-left: 16px;
    font-size: 12px;
    display: inline-block;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
