<script setup>
import { posthogCapture } from "@/scripts/posthog.js";

function openQuickLinks(externalUrl) {
  posthogCapture("user_clicked_article", {
    website: externalUrl,
  });
  window.open(externalUrl, "_blank");
}
</script>
<template>
  <section class="links">
    <h1 class="title">Quick links</h1>
    <div
      class="link"
      @click="
        openQuickLinks(
          'https://help.cloaked.app/hc/en-us/articles/19471112047764-Housekeeping-How-to-get-fully-set-up'
        )
      "
    >
      How to complete your Cloaked account set up
    </div>
    <div
      class="link"
      @click="
        openQuickLinks(
          'https://help.cloaked.app/hc/en-us/articles/11841989344404-How-do-Phone-Calls-and-Text-Messages-Work'
        )
      "
    >
      How do Cloaked phone calls and text messages work?
    </div>
    <div
      class="link"
      @click="
        openQuickLinks(
          'https://help.cloaked.app/hc/en-us/articles/19026756336916-How-To-Manage-Email-Phone-Forwarding-via-Dashboard'
        )
      "
    >
      How to manage email and phone forwarding
    </div>
    <div
      class="link"
      @click="
        openQuickLinks(
          'https://help.cloaked.app/hc/en-us/articles/20198975857684-Frequently-Asked-Questions-about-Autofill'
        )
      "
    >
      Frequently asked questions about Autofill
    </div>
    <div
      class="link"
      @click="
        openQuickLinks(
          'https://www.cloaked.com/faq/5-exciting-ways-to-use-the-cloaked-mobile-app'
        )
      "
    >
      5 exciting ways to use the Cloaked mobile app
    </div>
  </section>
</template>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-style: normal;
  color: $color-primary-100;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}

.links {
  display: flex;
  padding: 24px 24px 40px 32px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 20px;
  background-color: $color-primary-5;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 48px;
  }
  .link {
    cursor: pointer;
    color: $color-primary-70;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    text-decoration-line: underline;
    transition: all 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);

    &:hover {
      transition: all 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transform: scale(1.005);
      color: $color-primary-100;
    }
  }
}
</style>
