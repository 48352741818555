<script setup>
import * as d3 from "d3";
import { computed, onMounted, ref, watch, onBeforeUnmount } from "vue";
import DataRemovalGraphTooltip from "@/routes/DataDeletion/components/RequestGraph/DataRemovalGraphTooltip.vue";

const props = defineProps({
  data: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every(
        (line) =>
          typeof line.label === "string" &&
          Array.isArray(line.points) &&
          line.points.every(
            (item) =>
              item.hasOwnProperty("x") &&
              item.x instanceof Date &&
              item.hasOwnProperty("y") &&
              typeof item.y === "number"
          )
      );
    },
  },
  lineColors: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((color) => typeof color === "string");
    },
  },
  activeSeries: {
    type: String,
    required: false,
    default: null,
  },
});

const tooltip = ref(null);
const tooltipData = ref(null);
const tooltipLeft = ref(0);
const shouldDrawInnerLines = ref(true);
const chartDrawn = ref(false);
const isLoading = ref(true); // Add loading state
const hasValidContainerSize = ref(false);

const margin = { top: 20, right: 10, bottom: 20, left: 10 };

const container = computed(() =>
  d3.select(".data-removal-graph_chart-container")
);

const stackedData = computed(() => {
  const stack = d3
    .stack()
    .keys(d3.range(props.data.length))
    .value((d, key) => d[key].y)
    .order(d3.stackOrderReverse); // Reverse the order of stacking
  return stack(d3.transpose(props.data.map((line) => line.points))).map(
    (_, index, layers) => layers[index]
  );
});

onMounted(() => {
  drawSkeleton();
  window.addEventListener("resize", drawChart);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", drawChart);
});

function setupChartDimensions() {
  const containerWidth = container?.value
    ?.node()
    ?.getBoundingClientRect()?.width;
  const containerHeight = container.value
    ?.node()
    ?.getBoundingClientRect()?.height;

  const width = containerWidth - margin.left - margin.right;
  const height = containerHeight - margin.top - margin.bottom;

  const svg = d3
    .select("#myChart")
    .attr("height", height + margin.top + margin.bottom)
    .attr("width", width + margin.left + margin.right)
    .select("g");

  if (!svg.empty()) {
    svg.remove();
  }

  const chartGroup = d3
    .select("#myChart")
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  return { width, height, chartGroup };
}

function drawSkeleton() {
  const { width, height, chartGroup } = setupChartDimensions();
  if (width <= 0 || height <= 0) return;
  hasValidContainerSize.value = true;
  // Draw skeleton lines
  const skeletonLines = 3;
  const skeletonLineHeight = height / (skeletonLines - 1);
  for (let i = 0; i < skeletonLines; i++) {
    chartGroup
      .append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", i * skeletonLineHeight)
      .attr("y2", i * skeletonLineHeight)
      .style("stroke", "darkgrey");
  }

  chartGroup
    .append("line")
    .attr("class", "vertical-line-highlight")
    .attr("x1", 0)
    .attr("x2", 0)
    .attr("y1", 0)
    .attr("y2", height)
    .style("stroke", "lightgrey")
    .style("stroke-dasharray", "2,2")
    .style("opacity", 1);

  chartGroup
    .append("line")
    .attr("class", "vertical-line-highlight")
    .attr("x1", width)
    .attr("x2", width)
    .attr("y1", 0)
    .attr("y2", height)
    .style("stroke", "lightgrey")
    .style("stroke-dasharray", "2,2")
    .style("opacity", 1);
}

function drawChart() {
  isLoading.value = false;
  const { width, height, chartGroup } = setupChartDimensions();
  const allData = props.data.flatMap((line) => line.points);
  const x = d3
    .scaleTime()
    .domain(d3.extent(allData, (d) => d?.x))
    .range([0, width]);

  const sumYValues = {};
  allData.forEach((d) => {
    const xValue = d.x;
    if (!sumYValues[xValue]) {
      sumYValues[xValue] = 0;
    }
    sumYValues[xValue] += d.y;
  });

  const yMax = d3.max(Object.values(sumYValues));
  const yDomainMax = (yMax * 4) / 3;
  const yDomainMaxRounded = Math.ceil(yDomainMax / 10) * 10;
  const yTickValues = shouldDrawInnerLines.value
    ? [0, yDomainMaxRounded / 2, yDomainMaxRounded]
    : [0, yDomainMaxRounded];
  const y = d3.scaleLinear().domain([0, yDomainMaxRounded]).range([height, 0]);

  const yAxis = d3
    .axisLeft(y)
    .tickValues(yTickValues)
    .tickSize(-width)
    .tickPadding(0);

  drawAxes(chartGroup, x, y, yAxis, width, height);
  drawAreas(chartGroup, x, y);
  drawLines(chartGroup, x, y);
  drawHoverEffects(chartGroup, x, y, width, height, allData);
  drawYAxisLabels(chartGroup, yAxis, margin);
}

function drawAxes(chartGroup, x, y, yAxis, width, height) {
  chartGroup.append("g").call(yAxis).attr("class", "data-removal-graph_y-axis");

  drawVerticalLines(chartGroup, x, y, width, height);
}

function drawVerticalLines(chartGroup, x, y, width, height) {
  if (!shouldDrawInnerLines.value) return;
  // Calculate the interval for drawing vertical lines based on minimum pixel width and dividing by 7
  const minPixelWidth = 50; // Set your desired minimum pixel width between vertical lines
  const xPoints = props.data.flatMap((line) =>
    line.points.map((point) => point.x)
  );
  const uniqueXPoints = Array.from(
    new Set(xPoints.map((d) => d.getTime()))
  ).map((d) => new Date(d));
  const totalWidth = width;
  const intervalByPixelWidth = Math.ceil(
    uniqueXPoints.length / (totalWidth / minPixelWidth)
  );
  const intervalByWeek = Math.ceil(uniqueXPoints.length / 7);
  const intervalByMonth = Math.ceil(uniqueXPoints.length / 4);
  let interval;
  if (intervalByWeek > intervalByPixelWidth) {
    interval = intervalByWeek;
  } else if (intervalByPixelWidth < intervalByMonth) {
    interval = intervalByMonth;
  } else {
    interval = intervalByPixelWidth;
  }

  const highlightedVerticalLines = uniqueXPoints.filter(
    (_, index) => index % interval === 0 || index === uniqueXPoints.length - 1
  );

  const otherVerticalLines = uniqueXPoints.filter(
    (_, index) =>
      !(index % interval === 0 || index === uniqueXPoints.length - 1)
  );

  // Draw all vertical lines at 10% opacity
  chartGroup
    .selectAll(".vertical-line")
    .data(otherVerticalLines)
    .enter()
    .append("line")
    .attr("class", "vertical-line")
    .attr("x1", (d) => x(d))
    .attr("x2", (d) => x(d))
    .attr("y1", 0)
    .attr("y2", 0) // Start at y=0 for animation
    .style("stroke", "lightgrey")
    .style("stroke-dasharray", "2,2")
    .style("opacity", 0.3)
    .transition()
    .duration(1000)
    .attr("y2", height); // Animate to the final height

  // Draw selected vertical lines at full opacity
  chartGroup
    .selectAll(".vertical-line-highlight")
    .data(highlightedVerticalLines)
    .enter()
    .append("line")
    .attr("class", "vertical-line-highlight")
    .attr("x1", (d) => x(d))
    .attr("x2", (d) => x(d))
    .attr("y1", 0)
    .attr("y2", (d, i) =>
      i === 0 || i === highlightedVerticalLines.length - 1 ? height : 0
    ) // Draw first and last line without animation
    .style("stroke", "lightgrey")
    .style("stroke-dasharray", "2,2")
    .style("opacity", 1)
    .transition()
    .duration(1000)
    .attr("y2", (d, i) =>
      i === 0 || i === highlightedVerticalLines.length - 1 ? height : height
    ); // Animate other lines to the final height
}

function drawAreas(chartGroup, x, y) {
  const area = d3
    .area()
    .x((d) => x(d.data[0].x))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(d3.curveMonotoneX);

  chartGroup
    .selectAll(".data-removal-graph_area")
    .data(stackedData.value)
    .enter()
    .append("path")
    .attr("class", "data-removal-graph_area")
    .attr("d", area)
    .style("fill", (d, i) => props.lineColors[i])
    .style("opacity", 0);
}

function drawLines(chartGroup, x, y) {
  const line = d3
    .line()
    .x((d) => x(d.data[0].x))
    .y((d) => y(d[1]))
    .curve(d3.curveMonotoneX);

  const path = chartGroup
    .selectAll(".data-removal-graph_line")
    .data(stackedData.value)
    .enter()
    .append("path")
    .attr("class", "data-removal-graph_line")
    .attr("d", line)
    .style("stroke", (d, i) => props.lineColors[i]) // Reverse the color order
    .style("fill", "none");

  // Animate the drawing of the lines from left to right and from 0 height to actual height
  path
    .attr("stroke-dasharray", function () {
      return this.getTotalLength();
    })
    .attr("stroke-dashoffset", function () {
      return this.getTotalLength();
    })
    .transition()
    .duration(1000)
    .ease(d3.easeCircleIn)
    .attr("stroke-dashoffset", 0)
    .on("end", () => {
      chartDrawn.value = true;
    });

  // Dim lines that are not active
  path.style("opacity", (d, i) => {
    const lineLabel = props.data[props.data.length - 1 - i].label; // Reverse the index
    return props.activeSeries && props.activeSeries !== lineLabel ? 0.3 : 1;
  });
}

function drawCircles(chartGroup, x, y, closestData) {
  const circlesData = stackedData.value
    .map((layer, index) => {
      const dataPoint = layer.find(
        (d) => d.data[0].x.getTime() === closestData.x.getTime()
      );
      return dataPoint
        ? {
            x: dataPoint.data[0].x,
            y: dataPoint[1],
            color: props.lineColors[index], // Reverse the color order
          }
        : null;
    })
    .filter((d) => d !== null);

  const circles = chartGroup
    .selectAll(".data-removal-graph_circle")
    .data(circlesData, (d) => d.x); // Use x value as key

  // Handle the enter selection
  circles
    .enter()
    .append("circle")
    .attr("class", "data-removal-graph_circle")
    .attr("r", 5) // Set the radius directly
    .style("fill", (d) => d.color)
    .merge(circles) // Merge enter and update selections
    .attr("cx", (d) => x(d.x))
    .attr("cy", (d) => y(d.y))
    .style("display", "block");

  // Handle the exit selection
  circles.exit().remove();

  return circles;
}

function drawTooltip(tooltipElement, closestData, x, y, width) {
  const tooltipOffset = -10; // Add an offset to the tooltip left
  const tooltipLeftValue =
    x(closestData.x) -
    tooltipElement?.node()?.getBoundingClientRect().width +
    tooltipOffset;
  const tooltipRightValue = x(closestData.x) + tooltipOffset * -3;
  tooltipData.value = {
    formattedDate: d3.timeFormat("%a %b %d, %Y")(closestData.x),
    data: props.data
      .map((line, index) => ({
        label: line.label,
        value: line.points.find(
          (item) => item.x.getTime() === closestData.x.getTime()
        )?.y,
        color: props.lineColors[index], // Reverse the color order
      }))
      .filter((item) => item.value !== undefined),
  };
  tooltipLeft.value =
    tooltipLeftValue < 0 ? tooltipRightValue : tooltipLeftValue;
  tooltipElement?.transition().duration(0).style("opacity", 1);
}

function drawHoverEffects(chartGroup, x, y, width, height, allData) {
  const hoverLine = chartGroup
    .append("line")
    .attr("class", "data-removal-graph_hover-line");
  const tooltipElement = d3.select(tooltip?.value?.$el);

  container.value
    .on("mousemove", function (event) {
      const [mouseX, _] = d3.pointer(event);
      const adjustedMouseX = mouseX - margin.left;

      const closestData = props.data
        .flatMap((line) => line.points)
        .reduce((prev, curr) => {
          return Math.abs(x(curr.x) - adjustedMouseX) <
            Math.abs(x(prev.x) - adjustedMouseX)
            ? curr
            : prev;
        });

      drawCircles(chartGroup, x, y, closestData);

      hoverLine
        .attr("x1", x(closestData.x))
        .attr("x2", x(closestData.x))
        .attr("y1", 0)
        .attr("y2", height)
        .style("display", "block")
        .style("stroke", "darkgray")
        .style("stroke-dasharray", "3,0");

      drawTooltip(tooltipElement, closestData, x, y, width);
    })
    .on("click", function (event) {
      const [mouseX, _] = d3.pointer(event);
      const adjustedMouseX = mouseX - margin.left;

      const closestData = props.data
        .flatMap((line) => line.points)
        .reduce((prev, curr) => {
          return Math.abs(x(curr.x) - adjustedMouseX) <
            Math.abs(x(prev.x) - adjustedMouseX)
            ? curr
            : prev;
        });

      drawCircles(chartGroup, x, y, closestData);

      hoverLine
        .attr("x1", x(closestData.x))
        .attr("x2", x(closestData.x))
        .attr("y1", 0)
        .attr("y2", height)
        .style("display", "block")
        .style("stroke", "darkgray")
        .style("stroke-dasharray", "3,0");

      drawTooltip(tooltipElement, closestData, x, y, width);
    })
    .on("mouseout", function (event) {
      if (!event.relatedTarget || !this.contains(event.relatedTarget)) {
        chartGroup
          .selectAll(".data-removal-graph_circle")
          .style("display", "none");
        hoverLine.style("display", "none");
        tooltipElement?.transition().duration(300).style("opacity", 0);
      }
    });
}

function drawYAxisLabels(chartGroup, yAxis, margin) {
  const newGroup = chartGroup
    .append("g")
    .attr("transform", `translate(-${margin.left}, -${margin.top})`);

  chartGroup.selectAll(".data-removal-graph_y-axis text").each(function () {
    const text = d3.select(this);
    const bbox = text.node().getBBox();
    const shift = 0;
    const parentTransform = text.node().parentNode.getCTM();
    const absoluteX = parentTransform.e;
    const absoluteY = bbox.y + parentTransform.f;
    text.style("display", "none");
    const rect = newGroup
      .append("rect")
      .attr("x", absoluteX - 6 + shift)
      .attr("y", absoluteY)
      .attr("width", bbox.width + 12)
      .attr("height", bbox.height + 4)
      .attr("rx", 8)
      .attr("ry", 8)
      .attr("class", "y-axis-bubble")
      .style("opacity", 0) // Start with opacity 0 for animation
      .transition()
      .duration(500)
      .style("opacity", 1); // Animate to final opacity

    newGroup
      .append("text")
      .attr("x", absoluteX + shift + bbox.width / 2)
      .attr("y", absoluteY + bbox.height / 2 + 4)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("opacity", 0) // Start with opacity 0 for animation
      .text(text.text())
      .attr("font-size", "12px")
      .attr("class", "y-axis-text")
      .transition()
      .duration(500)
      .style("opacity", 1); // Animate to final opacity
  });
}

function checkContainerSize() {
  const containerWidth = container.value.node().getBoundingClientRect().width;
  const containerHeight = container.value.node().getBoundingClientRect().height;

  if (containerWidth > 0 && containerHeight > 0) {
    hasValidContainerSize.value = true;
    if (props.data.length) {
      drawChart();
    } else {
      drawSkeleton();
    }
  } else {
    requestAnimationFrame(checkContainerSize);
  }
}

watch(
  () => props.activeSeries,
  (newVal, oldVal) => {
    if (newVal !== oldVal && chartDrawn.value) {
      const svg = d3.select("#myChart");
      svg
        .selectAll(".data-removal-graph_line")
        .transition()
        .duration(300)
        .style("opacity", (d, i) => {
          const lineLabel = props.data[i].label;
          return props.activeSeries && props.activeSeries !== lineLabel
            ? 0.3
            : 1;
        });

      svg
        .selectAll(".data-removal-graph_area")
        .transition()
        .duration(300)
        .style("opacity", (d, i) => {
          const lineLabel = props.data[i].label;
          return props.activeSeries && props.activeSeries === lineLabel
            ? 0.7
            : 0;
        });
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => props.data,
  (value) => {
    if (value?.length) {
      setTimeout(checkContainerSize, 2000);
    }
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <div class="data-removal-graph_chart-container">
    <svg id="myChart"></svg>
    <div class="data-removal-graph_tooltip-container">
      <DataRemovalGraphTooltip
        ref="tooltip"
        class="data-removal-graph_tooltip"
        :tooltipData="tooltipData"
        :tooltipLeft="tooltipLeft"
      />
    </div>
  </div>
</template>

<style lang="scss">
.data-removal-graph_chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}

.data-removal-graph_line {
  fill: none;
  stroke-width: 3;
}
.data-removal-graph_tooltip-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0px;
}
.data-removal-graph_tooltip {
  position: relative;
  background-color: transparent;
  border: 1px solid $color-primary-10;
  opacity: 0;
  padding: 10px;
  pointer-events: auto;
  font-size: 12px;
  color: $color-primary-100;
  cursor: pointer;
  box-shadow: 0 0 10px rgba($black, 0.1);
  border-radius: 5px;
}
.data-removal-graph_circle {
  display: none;
}
.data-removal-graph_hover-line {
  stroke: lightgrey;
  stroke-width: 1;
  stroke-dasharray: 3, 3;
  display: none;
}
.data-removal-graph_y-axis path {
  stroke-width: 0;
}

.tick-text,
.tick text {
  font-size: 12px;
  fill: $color-primary-100;
}
.tick line {
  stroke: $color-primary-70;
  stroke-width: 1px;
}
.tick-text,
.tick text {
  font-size: 12px;
  fill: $color-primary-100;
}
.tooltip-line {
  position: absolute;
  height: 1px;
  display: none;
}
.vertical-line {
  stroke: $color-primary-70;
  stroke-width: 1;
  stroke-dasharray: 2, 2;
}

.y-axis-bubble {
  fill: $color-primary-5;
  stroke: $color-primary-100;
}

.y-axis-text {
  fill: $color-primary-100;
}
</style>
