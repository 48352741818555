import { timeTo } from "@/components/feature/onboarding/utils";
import store from "@/store";
import { HAS_SEEN_PLANS_MODAL } from "@/scripts/userFlags";

export default {
  namespaced: true,
  state: {
    isWelcomeModalOpen: false,
    uiType: "control",
  },
  mutations: {
    SET_IS_WELCOME_MODAL_OPEN: (state, payload) => {
      state.isWelcomeModalOpen = payload;
    },
    SET_UI_TYPE: (state, payload) => (state.uiType = payload),
  },
  actions: {
    loadWelcomeUi({ commit }) {
      const value = window.$posthog?.getFeatureFlag(
        "mobile_pricing_experiment"
      );
      if (value) {
        commit("SET_UI_TYPE", value);
      }
    },
  },
  getters: {
    isModalOnlyGroup: (state) => false,
    isModalDiscountGroup: (state) => {
      const value =
        window.$posthog?.getFeatureFlag("mobile_pricing_experiment") ||
        state.uiType;
      return value === "control";
    },
    welcomeDiscountExpires: (state, getters, rootState) => {
      const userCreatedAt = rootState.authentication?.user?.created_at;
      return userCreatedAt
        ? new Date(new Date(userCreatedAt).getTime() + 3 * 24 * 60 * 60 * 1000)
        : null;
    },
    hasWelcomeDiscountNotExpired: (state, getters) =>
      getters["welcomeDiscountExpires"]
        ? timeTo(getters["welcomeDiscountExpires"]) > 0
        : false,
    hasWelcomeDiscountBanner: (state, getters, rootState, rootGetters) =>
      rootGetters["settings/isTrial"] &&
      getters.isModalDiscountGroup &&
      getters.hasWelcomeDiscountNotExpired,
    hasWelcomeModal: (state, getters, rootState, rootGetters) => {
      return (
        rootGetters["settings/isTrial"] &&
        (getters.isModalDiscountGroup || getters.isModalOnlyGroup) &&
        rootState.flags.onboarding !== null &&
        !store.getters.getFlag(HAS_SEEN_PLANS_MODAL)
      );
    },
    isWelcomeModalOpen: (state) => state.isWelcomeModalOpen,
  },
};
