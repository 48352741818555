<script setup></script>

<template>
  <div class="loading-skel-wrapper">
    <div class="skeleton-row" v-for="index in 5" :key="index">
      <div class="small-circle"></div>
      <div class="col">
        <div class="long-oval"></div>
        <div class="short-oval"></div>
      </div>

      <div class="right-most-oval"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading-skel-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid $color-primary-10;
}

.skeleton-row {
  --linear-gradient: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0.4) 30%,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.4) 70%
    )
    right/300% 100%;
  @at-root .theme-dark & {
    --linear-gradient: linear-gradient(
        -90deg,
        rgba(25, 30, 35, 0.2) 30%,
        rgba(25, 30, 35, 0.7),
        rgba(25, 30, 35, 0.2) 70%
      )
      right/300% 100%;
  }
  border-radius: 12px;
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $color-surface;
  border-bottom: 1px solid $color-primary-10;
  gap: 12px;
  padding: 10px;
  padding-top: 20px;

  $total_elements: 5; // however many are needed on screen
  $opacity_step: calc(1 / $total_elements);
  @for $i from 1 through $total_elements {
    &:nth-child(#{$i}) {
      opacity: 1 - ($opacity_step * ($i - 1));
    }
  }

  .small-circle {
    background-color: $color-primary-10;
    border-radius: 50%;
    height: 16px;
    width: 16px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  flex-grow: 1;
  .long-oval {
    height: 11px;
    width: 100%;
    border-radius: 30px;
    background-color: $color-primary-30;
    -webkit-mask: var(--linear-gradient);
    background-repeat: no-repeat;
    animation: shimmer 1s infinite;
    animation-delay: 1s;
  }
  .short-oval {
    height: 11px;
    width: 60%;
    border-radius: 30px;
    background-color: $color-primary-30;
    -webkit-mask: var(--linear-gradient);
    background-repeat: no-repeat;
    animation: shimmer 1s infinite;
    animation-delay: 2s;
  }
}

.right-most-oval {
  margin-left: 48px;
  height: 11px;
  width: 10%;
  border-radius: 30px;
  background-color: $color-primary-30;
  -webkit-mask: var(--linear-gradient);
  background-repeat: no-repeat;
  animation: shimmer 1s infinite;
  animation-delay: 2s;
}

@media (max-width: 1050px) {
  .skeleton-row .ovals-group .long-oval {
    width: 150px;
  }
  .skeleton-row .ovals-group .short-oval {
    width: 64px;
  }
  .skeleton-row .skel-group .short-oval {
    width: 64px;
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
</style>
