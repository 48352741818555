<script setup>
import moment from "moment";
import { get } from "lodash-es";
import { UiBanner } from "@/components";
import store from "@/store";
import router from "@/routes/router";

import { computed, nextTick } from "vue";

const user = computed(() => {
  return store?.state?.authentication?.user;
});

const deleteDateLabel = computed(() => {
  const deleteDate = get(user.value, "deletion_date");
  let then = moment().add(30, "days");

  if (deleteDate) {
    then = moment(deleteDate);
  }

  return then.format("LL");
});

function openRestoreAccount() {
  if (router.currentRoute.name !== "settings.account") {
    router.push({
      name: "settings.account",
    });
  }

  nextTick(() => {
    store.commit("openPreference", {
      selected: "account",
      right: "manage-account",
      step: "restore",
    });
  });
}

function openManageAccount() {
  if (router.currentRoute.name !== "settings.account") {
    router.push({
      name: "settings.account",
    });
  }

  nextTick(() => {
    store.commit("openPreference", {
      selected: "account",
      step: "manage",
      right: "manage-account",
    });
  });
}
</script>

<template>
  <UiBanner danger class="account-delete-banner">
    Your account will be permanently deleted on
    <strong>{{ deleteDateLabel }}.</strong>
    <span class="faux-link" @click="openRestoreAccount"
      >Restore your account</span
    >
    |
    <span class="faux-link" @click="openManageAccount">Manage account</span>
  </UiBanner>
</template>

<style lang="scss" scoped>
.account-delete-banner {
  .faux-link {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
