<script setup>
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";
import InboxService from "@/api/actions/inbox-service";

const unreadCount = ref({
  all: 0,
  emails: 0,
  messages: 0,
  calls: 0,
  requests: 0,
  starred: 0,
});

const allUnread = computed(() => {
  return (
    unreadCount.value.emails +
    unreadCount.value.messages +
    unreadCount.value.calls +
    unreadCount.value.requests +
    unreadCount.value.starred
  );
});

onMounted(() => {
  fetchUnreadCount();
  window.addEventListener("inbox-updated", fetchUnreadCount);
});

onBeforeUnmount(() => {
  window.removeEventListener("inbox-updated", fetchUnreadCount);
});

function fetchUnreadCount() {
  InboxService.getUnreadCount().then((response) => {
    unreadCount.value = response.data;
  });
}
</script>

<template>
  <MenuBlock title="Inbox">
    <MenuItem
      link="/inbox"
      name="All Inboxes"
      icon="inbox-filled"
      :count="allUnread"
    />
    <MenuItem
      link="/inbox/emails"
      name="Emails"
      icon="mail-outline"
      :count="unreadCount.emails"
      nested
    />
    <MenuItem
      link="/inbox/texts"
      name="Texts"
      icon="text-outline"
      :count="unreadCount.messages"
      nested
    />
    <MenuItem
      link="/inbox/calls"
      name="Calls"
      icon="phone-outline"
      :count="unreadCount.calls"
      nested
    />
    <MenuItem
      link="/inbox/requests"
      name="Requests"
      icon="requests-outline"
      :count="unreadCount.requests"
      nested
    />
    <MenuItem
      link="/inbox/starred"
      name="Starred"
      icon="star-outline"
      :count="unreadCount.starred"
      nested
    />
  </MenuBlock>
</template>
