<script setup>
/*
Data structure for tableData to populate this table component:

prop tableData: tableRow[]

interface tableRow = {
  header: string,
  content: [
    { 
      title: string,
      // optional 
      subtitle?: string
    }
  ],
};

*/

const props = defineProps({
  tableContent: {
    type: Array,
    required: true,
  },
});
</script>

<template>
  <div class="table-content">
    <table>
      <tr v-for="tableRow in props.tableContent" :key="tableRow.header">
        <th>{{ tableRow.header }}:</th>
        <td>
          <div v-for="tableData in tableRow.content" :key="tableData.title">
            <p>
              {{ tableData.title }}
              <span v-if="tableData.subtitle" class="td-subtitle">{{
                tableData.subtitle
              }}</span>
              <span v-if="tableData.original" class="td-subtitle"
                >&#60;{{ tableData.original }}&#62;</span
              >
            </p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped lang="scss">
.table-content {
  padding: 14px 0px 0px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  word-break: normal;

  table {
    text-align: left;
  }

  th {
    font-weight: 500;
    color: $color-primary-50;
    vertical-align: top;
    max-width: 72px;
  }
  tr:last-child {
    th,
    td {
      padding: 0px 42px 0px 0px;
    }
  }

  td {
    padding: 0px 0px 14px;
    word-break: break-all;
    color: $color-primary-100;

    .td-subtitle {
      color: $color-primary-70;
      display: block;
      width: 100%;
    }
  }

  .modal-flex {
    display: flex;
    flex-direction: column;
  }
}
</style>
