<script setup>
import {
  AccountRecoveryPhone,
  AccountRecoveryEmail,
  PreferencesHeader,
  PreferencesTitle,
  PreferencesParagraph,
  PreferencesPanel,
} from "@/routes/modals/preferences";
import ValueDisplay from "@/components/ui/value-display";
import EmailService from "@/api/actions/email-service";
import PhoneService from "@/api/actions/phone-service";

import store from "@/store";
import router from "@/routes/router";
import { reactive, onBeforeMount, computed } from "vue";

const emit = defineEmits(["toggleBack"]);

onBeforeMount(() => {
  refreshEmails();
  refreshPhones();
});

const state = reactive({
  emails: [],
  phones: [],
});

const showRecoveryPassphraseSection = computed(() => {
  return (
    store.state.authentication?.user?.encryption_status === 1 ||
    store.state.authentication?.user?.encryption_status === 3
  );
});

function refreshEmails() {
  return EmailService.getUserEmails().then(({ data }) => {
    state.emails = data.results;
  });
}

function refreshPhones() {
  return PhoneService.getUserPhoneNumbers().then(({ data }) => {
    state.phones = data.results;
  });
}

function deleteEmail(emailId) {
  state.emails = state.emails.filter((item) => item.id !== emailId);
}

function deletePhone(phoneId) {
  state.phones = state.phones.filter((item) => item.id !== phoneId);
}

function goToRecoveryPassphrase() {
  router.push({ name: "settings.recovery" });
}
</script>

<template>
  <PreferencesPanel class="panel-offset">
    <template v-slot:header>
      <PreferencesHeader @go-back="emit('toggleBack')" />
    </template>

    <PreferencesTitle>Maintain your account access</PreferencesTitle>

    <PreferencesParagraph>
      These can be used to make sure it's really you signing in, reach you if
      there's suspicious activity in your account, or recover a lost password.
    </PreferencesParagraph>

    <div class="section forward-panel">
      <AccountRecoveryPhone
        @toggleBack="emit('toggleBack')"
        @refresh="refreshPhones"
        @delete="(phoneId) => deletePhone(phoneId)"
        :phones="state.phones"
      />
      <AccountRecoveryEmail
        @toggleBack="emit('toggleBack')"
        @refresh="refreshEmails"
        @delete="(emailId) => deleteEmail(emailId)"
        :emails="state.emails"
      />
      <ValueDisplay
        v-if="showRecoveryPassphraseSection"
        label="Download recovery kit"
        @click="goToRecoveryPassphrase"
      />
    </div>
  </PreferencesPanel>
</template>

<style lang="scss">
.forward-panel {
  margin-top: 24px;
}
</style>
