<script setup>
import inlineSvg from "@/components/InlineSvg";

defineProps({
  checked: {
    type: Boolean,
    default: false,
  },
  pending: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["click"]);
</script>
<template>
  <button
    @click="emits('click')"
    class="checkbox"
    :class="{ checked: checked, pending: pending }"
  >
    <inlineSvg name="check" key="check" v-if="checked" />
    <inlineSvg name="dash" key="dash" v-else-if="pending" />
  </button>
</template>
<style scoped lang="scss">
.checkbox {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 0;
  margin: 0;
  border: 2px solid $color-primary-30;
  color: $color-primary-100;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
    max-width: 9px;
    max-height: 9px;
  }
  &.checked {
    border: none;
    background-color: $color-primary-100;
    color: $color-primary-0;
  }

  &.pending {
    border-color: $color-primary-100;
  }
}
</style>
