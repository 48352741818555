<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import { ArrowRight, Checkmark } from "@/assets/icons";
import { useAttrs } from "vue";
import { useSubscriptionPlans } from "@/composables";
const attrs = useAttrs();

const props = defineProps(["value"]);
const emit = defineEmits(["input", "subscribe"]);

const { isLoadingPlans, annualPlan, monthlyPlan } = useSubscriptionPlans();
</script>

<template>
  <AppModal v-if="props.value">
    <AppModalContent class="modal-welcome">
      <AppModalTitle class="modal-welcome__title">
        Welcome to Cloaked!
      </AppModalTitle>
      <AppModalParagraph class="modal-welcome__subtitle"
        >It’s time to decentralize your data.</AppModalParagraph
      >
      <div class="modal-welcome__content">
        <slot name="cta">
          <div class="modal-welcome__cta">
            <div class="modal-welcome__pricing">
              <template v-if="isLoadingPlans">
                <span class="modal-welcome__pricing-monthly--skeleton" />
                <span class="modal-welcome__pricing-yearly--skeleton" />
              </template>
              <template v-else>
                <span class="modal-welcome__pricing-monthly">
                  ${{ monthlyPlan?.monthlyPrice }}/mo</span
                >
                <span class="modal-welcome__pricing-yearly">
                  or {{ annualPlan?.totalPrice }}/year
                </span>
              </template>
            </div>
            <button
              class="modal-welcome__cta-button"
              @click="
                emit('input', false);
                emit('subscribe');
              "
            >
              Subscribe today <ArrowRight class="modal-welcome__arrow-icon" />
            </button>
          </div>
        </slot>
        <ul class="modal-welcome__list">
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />Ongoing data
            removal*
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />$1M identity
            theft insurance*
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />Unlimited phone
            numbers*
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />Password breach
            detection
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />Unlimited inboxes
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />
            Unlimited encrypted calls, texts, and emails
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />
            Complete password management
          </li>
          <li class="modal-welcome__list-item">
            <Checkmark class="modal-welcome__checkmark-icon" />
            Advanced communication settings
          </li>
        </ul>
      </div>
      <AppModalParagraph class="modal-welcome__note">
        *During your trial, you won't have access to identity theft coverage,
        data deletion and monitoring, or more than 15 Cloaked phone numbers.
      </AppModalParagraph>
      <button class="modal-welcome__dismiss" @click="emit('input', false)">
        Experience Cloaked free for 14 days
        <ArrowRight class="modal-welcome__arrow-icon" />
      </button>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.modal-welcome {
  text-align: center;

  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.5px;
  }

  &__subtitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1px;
  }

  &__content {
    padding: 0 32px;
    margin-top: 24px;
  }

  &__pricing {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &-monthly {
      color: $color-primary-100;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.5px;

      &--skeleton {
        height: 24px;
        width: 116px;
        border-radius: 16px;

        @at-root .theme-dark & {
          @include base-skeleton($color-primary-10);
        }

        @at-root .theme-light & {
          @include base-skeleton($color-primary-20);
        }
      }
    }

    &-yearly {
      color: $color-primary-100;
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      margin-left: auto;

      &--skeleton {
        height: 12px;
        width: 76px;
        border-radius: 8px;
        margin-left: auto;

        @at-root .theme-dark & {
          @include base-skeleton($color-primary-10);
        }

        @at-root .theme-light & {
          @include base-skeleton($color-primary-20);
        }
      }
    }
  }

  &__list {
    margin-top: 14px;

    &-item {
      margin-top: 5px;
      text-align: left;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__checkmark-icon {
    width: 24px;
    height: 24px;
    padding: 4px;
  }

  &__cta {
    padding: 18px 20px;
    border-radius: 16px;
    border: 1px solid $color-primary-10;
    background: $color-primary-1;

    &-button {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-primary-0-light;
      padding: 13px 20px;
      width: 100%;
      background: $cloaked-gradient;
      border: 0;
      border-radius: 100px;
      margin-top: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }
  }

  &__dismiss {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px 20px;
    margin: 0 auto 16px;
    color: $color-primary-100;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  &__arrow-icon {
    transition: transform 100ms ease-out;

    @at-root .modal-welcome__cta-button:hover &,
      .modal-welcome__dismiss:hover & {
      transform: translateX(3px);
    }
  }

  &__note {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-70;
    margin: 16px 0;
  }
}
</style>
