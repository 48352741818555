<script setup>
import AccountDeleteBanner from "@/components/global/AccountDeleteBanner";
import ConfirmBanner from "@/components/global/ConfirmBanner";
import DownloadExtensionBanner from "@/components/global/DownloadExtensionBanner.vue";
import WelcomeDiscount from "@/components/Navigation/NavigationWelcomeDiscount.vue";
import UpgradeAccount from "@/components/Navigation/NavigationUpgradeAccount.vue";
import {
  onMounted,
  computed,
  watch,
  onBeforeUnmount,
  ref,
  onBeforeMount,
} from "vue";
import store from "@/store";
import FaunaBrokeBanner from "@/components/banners/FaunaBroke.vue";
import { HAS_ACTIVATED_PLUGIN } from "@/scripts/userFlags";
import EmailService from "@/api/actions/email-service";
import NavigationPayNow from "@/components/Navigation/NavigationPayNow.vue";

const emit = defineEmits(["visible"]);

const showExtensionBanner = ref(
  localStorage.getItem("showExtensionBanner") === "true"
);

onMounted(() => {
  window.addEventListener("cloak:refresh-emails", getEmail);
  getEmail();
  const showExtBanner = localStorage.getItem("showExtensionBanner");
  if (showExtBanner === null) {
    showExtensionBanner.value = !extensionInstalled.value;
    localStorage.setItem("showExtensionBanner", !extensionInstalled.value);
  }
});

onBeforeMount(() => {
  store.dispatch("subscription/fetchSubscriptionDetails");
});

onBeforeUnmount(() => {
  window.removeEventListener("cloak:refresh-emails", getEmail);
});

const dataLoaded = ref(false);

const verifiedPrimaryEmail = computed(() => {
  const allEmails = store.getters["settings/getPersonalEmails"];
  return allEmails.find((f) => f.verified && f.primary);
});

const firstUnverifiedEmail = computed(() => {
  const allEmails = store.getters["settings/getPersonalEmails"];
  return allEmails.find((e) => !e.verified);
});

const extensionInstalled = computed(() => {
  return (
    store.getters.getFlag(HAS_ACTIVATED_PLUGIN) ||
    store.getters["browser/pluginDetected"]
  );
});

const hasVisibleBanner = computed(() => {
  if (dataLoaded.value) {
    return (
      [
        !verifiedPrimaryEmail.value,
        isCancelled.value,
        faunaBroke.value,
        showExtensionBanner.value,
        showWelcomeDiscount.value,
        isTrialing.value,
      ].filter((d) => !!d).length > 0
    );
  }
  return false;
});

const hasWelcomeDiscount = ref(
  store.getters["welcomeUi/hasWelcomeDiscountBanner"]
);

const showWelcomeDiscount = computed(() => {
  // NOTE: hasWelcomeDiscount default is set to the same getter here
  // but its value gets updaetd based on expiration
  // we want to hide the banner if either of these conditions are false
  // return (
  //   hasWelcomeDiscount.value &&
  //   store.getters["welcomeUi/hasWelcomeDiscountBanner"]
  // );
  return false;
});

const faunaBroke = computed(() => {
  return false;
});

const isCancelled = computed(() => {
  return store.getters["settings/isCancelled"];
});

const isPendingDeletion = computed(() => {
  return store.getters["authentication/isPendingDeletion"];
});

const isTrialing = computed(() => {
  return (
    store.getters["subscription/getSubscriptionDetails"]
      ?.provider_subscription_status === "trialing"
  );
});

watch(
  () => hasVisibleBanner.value,
  (newValue) => {
    emit("visible", newValue);
  },
  { immediate: true, deep: true }
);

watch(
  () => extensionInstalled.value,
  (newValue) => {
    if (newValue) {
      showExtensionBanner.value = false;
      localStorage.setItem("showExtensionBanner", false);
    }
  },
  { immediate: true }
);

function getEmail() {
  return EmailService.getUserEmails().then(({ data }) => {
    dataLoaded.value = true;
  });
}

function dismissExtBanner() {
  showExtensionBanner.value = false;
  localStorage.setItem("showExtensionBanner", false);
}
</script>

<template>
  <div class="app-banners" v-if="hasVisibleBanner">
    <FaunaBrokeBanner v-if="faunaBroke" />
    <AccountDeleteBanner v-else-if="isPendingDeletion" />
    <WelcomeDiscount
      v-else-if="showWelcomeDiscount"
      @expired="hasWelcomeDiscount = false"
      :expires="store.getters['welcomeUi/welcomeDiscountExpires']"
    />
    <UpgradeAccount v-else-if="isCancelled" />
    <NavigationPayNow v-else-if="isTrialing" />
    <template v-else>
      <ConfirmBanner
        v-if="!verifiedPrimaryEmail"
        :email="firstUnverifiedEmail"
      />
      <DownloadExtensionBanner
        v-else-if="showExtensionBanner"
        @dismiss="dismissExtBanner"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.app-banners {
  position: fixed;
  width: 100%;
  top: -9999px;
  left: -9999px;
  z-index: 1000;

  @media (min-width: 768px) {
    top: 0;
    left: 0;
  }
}
</style>
