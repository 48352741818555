<script setup>
import { MoreInfoQIcon } from "@/assets/icons";
import { computed } from "vue";
import CustomField from "@/components/cloakDetails/CustomFields/CustomField.vue";
import UiTooltip from "@/components/ui/ui-tooltip.vue";
import CustomFieldAdd from "@/components/cloakDetails/CustomFields/CustomFieldAdd.vue";
import CustomFieldForm from "@/components/cloakDetails/CustomFields/CustomFieldForm/CustomFieldForm.vue";
import CustomFieldModalUnsavedChanges from "@/components/cloakDetails/CustomFields/CustomFieldModal/CustomFieldModalUnsavedChanges.vue";
import CustomFieldModalConfirmDelete from "@/components/cloakDetails/CustomFields/CustomFieldModal/CustomFieldModalConfirmDelete.vue";

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  existingAddresses: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isConfirmDeleteModalShown: {
    type: Boolean,
    default: false,
  },
  isUnsavedChangesModalShown: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [Object, null],
    default: null,
  },
  readOnly: Boolean,
  identityId: Number,
});

defineEmits([
  "input",
  "add",
  "edit",
  "delete",
  "set-show-confirm-delete-modal",
  "set-show-unsaved-changes-modal",
]);

const orderedItems = computed(() => {
  const typeOrder = [
    "text",
    "address",
    "auth",
    "bank",
    "date",
    "identification",
    "url",
  ];

  return [...props.items].sort((a, b) => {
    if (a.type !== b.type) {
      return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
    }

    return a.label > b.label ? 1 : -1;
  });
});
</script>

<template>
  <section class="custom-fields">
    <header>
      <h3 class="custom-fields__header">
        <span>Other information</span>
        <UiTooltip
          title="Store new custom information to identities, making them even more powerful."
          position="top"
          max-width="192"
          align-x="center"
        >
          <MoreInfoQIcon />
        </UiTooltip>
      </h3>
    </header>
    <ul v-if="orderedItems && orderedItems.length" class="custom-fields__list">
      <li
        v-for="item in orderedItems"
        :key="item.id"
        class="custom-fields__list-item"
      >
        <CustomFieldForm
          v-if="value && value.id === item.id && value.value"
          :value="value"
          @input="
            ($event) => {
              $emit('input', $event);
            }
          "
          form-type="edit"
          :field-type="value.type"
          @cancel="$emit('set-show-unsaved-changes-modal', true)"
          @save="$emit('edit')"
          :isLoading="isLoading"
        />
        <CustomField
          v-else
          :type="item.type"
          :label="item.label"
          :value="item.value"
          :is-secret="item.isSecret"
          :identityId="props.identityId"
          @edit="$emit('input', JSON.parse(JSON.stringify(item)))"
          @delete="
            $emit('input', { id: item.id });
            $emit('set-show-confirm-delete-modal', true);
          "
        />
      </li>
    </ul>
    <CustomFieldAdd
      v-if="!readOnly && (value === null || value.id)"
      class="custom-fields__add"
      @add="(type) => $emit('input', { type })"
    />
    <CustomFieldForm
      v-else-if="!readOnly"
      :value="value"
      @input="
        ($event) => {
          $emit('input', $event);
        }
      "
      form-type="new"
      :field-type="value?.type"
      :existingAddresses="existingAddresses"
      @cancel="$emit('set-show-unsaved-changes-modal', true)"
      @save="$emit('add')"
      :isLoading="isLoading"
    />
    <CustomFieldModalUnsavedChanges
      :value="isUnsavedChangesModalShown"
      @input="$emit('set-show-unsaved-changes-modal', $event)"
      @discard="
        $emit('input', null);
        $emit('set-show-unsaved-changes-modal', false);
      "
    />
    <CustomFieldModalConfirmDelete
      :value="isConfirmDeleteModalShown"
      @input="$emit('set-show-confirm-delete-modal', $event)"
      @delete="$emit('delete')"
      :isLoading="isLoading"
    />
  </section>
</template>

<style lang="scss" scoped>
.custom-fields {
  padding: 20px 24px;

  &__header {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-100;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
  }

  &__list {
    &-item {
      padding: 12px 0;
    }
  }

  &__add {
    margin-top: 8px;
  }
}
</style>
