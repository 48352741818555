<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import UiTooltip from "@/components/ui/ui-tooltip.vue";
import { posthogCapture } from "@/scripts/posthog.js";

const emit = defineEmits(["clickWrapper", "clickCreate"]);
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  website: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  article: {
    type: String,
    required: false,
    default: null,
  },
});

function openBreachArticle(externalUrl) {
  posthogCapture("user_clicked_breachednews", {
    website: externalUrl,
  });
  window.open(externalUrl, "_blank");
}
</script>
<template>
  <div class="home-cloak-wrapper">
    <div class="logo-name-wrapper">
      <img
        :src="require(`@/assets/icons/brandLogos/${props.icon}.png`)"
        :key="`${Math.random()}`"
        class="icon"
      />
      <div class="title">
        {{ props.name }}
      </div>
    </div>
    <div class="icon-row">
      <UiTooltip
        align-x="center"
        title="View article"
        position="bottom"
        class="icon-wrapper"
        v-if="props.article"
        @click="openBreachArticle(props.article)"
      >
        <inlineSvg name="news-article" class="add" />
      </UiTooltip>
      <UiTooltip
        align-x="center"
        title="Create new identity"
        position="bottom"
        class="icon-wrapper"
        @click="emit('clickCreate')"
      >
        <inlineSvg name="add-currentColor" class="add" />
      </UiTooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-cloak-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 7px;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  .add {
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    color: $color-primary-100;
    visibility: hidden;
  }
  &:hover {
    background-color: $color-primary-5;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    opacity: 0.7;
    transform: scale(1.01);
    background-color: $color-primary-5;
    .add {
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      visibility: visible;
    }
  }

  > svg {
    color: $color-primary-100;
  }
  .logo-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .icon {
      flex-shrink: 0;
      height: 30px;
      width: 30px;
    }

    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color-primary-90;
    }
    .subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-primary-70;
    }
  }

  .icon-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .icon-wrapper {
      cursor: pointer;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      &:hover {
        background-color: $color-primary-10;
        transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        > svg {
          transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
          transform: scale(1.03) translate3d(0, 0, 0);
        }
      }
    }
  }
}
</style>
