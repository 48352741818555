<script setup>
import { computed, onMounted } from "vue";

import store from "@/store";
import Button from "@/components/Button.vue";
import DataDeletePrivacyPolicy from "@/components/feature/data-delete/DataDeletePrivacyPolicy.vue";
import inlineSvg from "@/components/InlineSvg.vue";

defineEmits(["update-status"]);

const props = defineProps({
  status: {
    type: String,
    required: true,
    validator: (value) =>
      ["review-required", "info-required", "submitting", "submitted"].includes(
        value
      ),
  },
  request: {
    type: Object,
  },
});

const currentRequest = computed(() => {
  if (props.status === "submitted") {
    if (store.getters["dataDelete/currentRequest"].pii) {
      return JSON.parse(store.getters["dataDelete/currentRequest"].pii);
    }
  }
  return props.request;
});

const formatter = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "long",
  year: "numeric",
});

const formattedSearchDate = computed(() => {
  const dateTimeObj =
    typeof currentRequest.value.searchDate === "string"
      ? new Date(props.request.searchDate)
      : currentRequest.value.searchDate;
  return formatter.format(dateTimeObj);
});

const formattedName = computed(() => {
  if (currentRequest.value.name) {
    return currentRequest.value.name?.trim().toLowerCase();
  }
  return "";
});
</script>

<template>
  <div class="data-remove-overview">
    <h1 class="data-remove__title">Review your submission</h1>
    <p class="data-remove__text">
      Confirm that your details are correct and fill in any missing info before
      we start your data removal request.
    </p>

    <div
      class="data-remove-card-box"
      :class="`data-remove-card-box--review-required`"
      @click="$emit('update-status', 'info-required')"
    >
      <div class="data-remove-card-box__content">
        <div
          class="data-remove-card-box__status"
          :class="`data-remove-card-box__status--review-required`"
        >
          Additional info required for submission
        </div>
        <div class="data-remove-card-box__name" v-if="formattedName">
          <span>{{ formattedName }}</span>
          <span v-if="currentRequest.age" class="data-remove-card-box__age">{{
            `, ${currentRequest.age}`
          }}</span>
        </div>

        <div class="data-remove-card-box__date">
          Searched on {{ formattedSearchDate }}
        </div>
      </div>
    </div>
    <DataDeletePrivacyPolicy class="data-remove-overview__privacy-policy">
      Cloaked protects your privacy.
    </DataDeletePrivacyPolicy>
    <Button
      type="primary"
      size="2xl"
      class="data-remove-overview__setup"
      @click="$emit('update-status', 'info-required')"
    >
      Review submission
      <inlineSvg name="arrow-right" />
    </Button>
  </div>
</template>

<style lang="scss">
.data-remove-overview {
  padding: 24px;
  max-width: 548px;

  &__setup {
    margin-top: 24px;
    animation: appear-bottom-5 0.5s forwards ease-out;
    min-width: 100%;
    max-width: 100%;
    margin-top: 32px;
    @media screen and (max-width: $screen-lg) {
      bottom: 24px;
      position: fixed;
      max-width: 500px;
      min-width: 100px;
      width: calc(100% - 48px);
    }
  }

  .data-remove__text {
    margin-top: 4px;
    margin-bottom: 24px;
  }

  &__card {
    margin-top: 24px;
  }

  &__privacy-policy {
    margin-top: 16px;

    .data-delete-privacy-policy__icon {
      color: $color-success;
    }
  }
}

.data-remove-card-box {
  border-radius: 16px;
  border: 1px solid $color-primary-20;
  background: rgba(255, 255, 255, 0.1);
  background-blend-mode: screen;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  gap: 16px;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__status {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--info-required {
      color: $color-info;
    }
    &--review-required {
      color: $color-info;
      margin-bottom: 6px;
    }

    &--submitting,
    &--submitted {
      color: $color-success;
    }
  }

  &__name {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    text-transform: capitalize;
  }

  &__date {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-100;
    margin-top: 6px;

    &.success {
      color: $color-success;
    }
  }
}
</style>
