<script setup>
import OnboardingPage from "@/components/feature/onboarding/page/OnboardingPage.vue";
import { onMounted, ref, onBeforeMount, watch, computed } from "vue";
import UserService from "@/api/actions/user-service";
import { HAS_ONBOARDED_V4, ONBOARD_SELECTED_VALUE } from "@/scripts/userFlags";
import EmailService from "@/api/actions/email-service";
import PhoneService from "@/api/actions/phone-service";
import store from "@/store";

const form = ref({
  forwardEmail: "Not now",
  email: "",
  emailVerificationCodeRequested: false,
  emailVerificationCode: "",
  isEmailVerified: false,
  forwardPhone: "Yes",
  phone: "",
  phone2fa: null,
  phoneVerificationCodeRequested: false,
  phoneVerificationCode: "",
  isPhoneVerified: false,
  firstSteps: "Data deletion",
});

const ddInAppSearchEnabled = computed(() => {
  return store.getters["dataDelete/getDdInAppSearchEnabled"];
});

watch(
  () => ddInAppSearchEnabled.value,
  (value) => {
    if (value) {
      form.value.firstSteps = "Data deletion";
    }
  }
);

onMounted(() => {
  if (!form.value.email) {
    EmailService.getUserEmails().then(({ data }) => {
      let find = data.results.filter((f) => f.verified && f.primary);
      let current;
      if (find.length > 0) {
        current = find[0].email;
      } else if (!current) {
        current = data.results[0]?.email;
      }
      if (current) {
        form.value.email = current;
      }
    });
  }
});

onMounted(() => {
  if (!form.value.phone) {
    PhoneService.getUserPhoneNumbers().then(({ data }) => {
      let find = data.results.filter((f) => f.verified && f.primary);
      let current;
      if (find.length > 0) {
        current = find[0].phone_number;
      } else if (!current) {
        current = data.results[0]?.phone_number;
      }
      if (current) {
        form.value.phone = current;
      }
    });
  }
});

const onFinished = () => {
  UserService.setFlag({
    name: HAS_ONBOARDED_V4,
    value: true,
  });
  UserService.setFlag({
    name: ONBOARD_SELECTED_VALUE,
    value: form.value.firstSteps,
  });
};
</script>

<template>
  <OnboardingPage :value="form" @input="form = $event" @finished="onFinished" />
</template>
