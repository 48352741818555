<script setup>
import inlineSvg from "@/components/InlineSvg";
import { useRoute } from "@/hooks";
import { computed } from "vue";
import store from "@/store";
import { IdentityIcon } from "@/components";
import CreateCard from "./WalletSettings/CreateCardButton";
import DefaultFundingSource from "./WalletSettings/DefaultFundingSourceButton.vue";
import SpendingLimit from "./WalletSettings/SpendingLimitButton.vue";
import AvailableSpendindButton from "./WalletSettings/AvailableSpendindButton.vue";
import CardInformationButton from "./WalletSettings/CardInformationButton.vue";
import FundingSourceButton from "./WalletSettings/FundingSourceButton.vue";

const route = useRoute();

const props = defineProps({
  listType: String,
});

function identity(id) {
  const identity = store.state.localdb.db_cloaks.find((item) => item.id === id);
  return identity;
}

const card = computed(() => {
  if (route.params.id && store.state.cards.cards.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  }
  return "";
});

function openIdentity(identity) {
  if (
    !store.state.rightPanel.cloak ||
    store.state.rightPanel.cloak.id !== identity.id
  ) {
    store.dispatch("fetchPopulatedData", identity).then((data) => {
      store.commit("compose", null);
      store.dispatch("openCloakDetails", {
        cloak: data,
        skipNav: true,
      });
    });
  }
}

function openCard(card) {
  store.commit("addCard", card);
}

function openSetting(value) {
  store.commit("openSettings");
}

function openFundingSources() {
  store.commit("openFundingSources");
}
</script>

<template>
  <div class="settings">
    <header>
      <div class="title">
        <router-link to="/wallet" class="back-button" v-if="route.params.id">
          <inlineSvg name="chevron-left" />
        </router-link>

        <div class="icons">
          <div
            class="icon"
            v-if="
              route.params.id &&
              card &&
              props.listType !== 'Canceled' &&
              props.listType !== 'Gifted'
            "
            @click="openCard(card)"
          >
            <inlineSvg name="cog" />
            <span>Card settings</span>
          </div>

          <div
            class="icon"
            @click="openFundingSources(true)"
            v-if="!route.params.id && !card"
          >
            <inlineSvg name="card" /> <span>Funding sources</span>
          </div>

          <div
            class="icon"
            @click="openSetting(true)"
            v-if="!route.params.id && !card"
          >
            <inlineSvg name="cog" /> <span>Wallet settings</span>
          </div>
        </div>
      </div>

      <div
        class="identity"
        v-if="
          route.params.id &&
          card &&
          identity(card.identity_id) &&
          props.listType !== 'Canceled'
        "
        @click="openIdentity(identity(card.identity_id))"
      >
        <IdentityIcon
          :identity="identity(card.identity_id)"
          :override="{ height: '32px', width: '32px' }"
        />
        <div class="info">
          <h1>{{ identity(card.identity_id)?.nickname || "(Unnamed)" }}</h1>

          <inlineSvg
            name="lock-filled"
            v-if="
              card.state === 'locked_by_user' ||
              (card.state === 'locked_by_system' &&
                props.listType !== 'Canceled')
            "
          />
        </div>
      </div>
    </header>

    <div class="buttons">
      <CreateCard v-if="!route.params.id" />
      <AvailableSpendindButton
        v-if="route.params.id && props.listType !== 'Canceled'"
      />
      <SpendingLimit
        v-if="
          route.params.id &&
          props.listType !== 'Canceled' &&
          props.listType !== 'Gifted'
        "
      />
      <DefaultFundingSource v-if="!route.params.id" />
      <CardInformationButton v-if="!route.params.id" />
      <FundingSourceButton
        v-if="
          route.params.id &&
          props.listType !== 'Canceled' &&
          props.listType !== 'Gifted'
        "
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.settings {
  width: 100%;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    position: relative;
    height: 32px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .icons {
        display: flex;
        margin-left: auto;
        .icon {
          color: $color-primary-100;
          margin-left: 10px;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          border-radius: 10px;
          padding: 8px 10px;

          &:first-child {
            margin-left: 0;
          }

          &:hover {
            cursor: pointer;
            background-color: $color-primary-10;
          }

          &:after {
            content: "";
            width: 0;
            height: 0;
            border-radius: 50%;
            background-color: $color-primary-100;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.4s ease-in-out;
            z-index: -1;
          }

          svg {
            width: 18px;
            height: 18px;
            display: block;
          }

          span {
            margin-left: 5px;
            display: inline-block;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
          }

          &.active {
            color: $color-primary-0;
            &:after {
              width: 28px;
              height: 28px;
            }
          }
        }
      }

      .back-button {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;

        svg {
          width: 14px;
          height: 14px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $color-primary-100;
        }

        &:hover {
          background-color: $color-primary-10;
          cursor: pointer;
        }
      }

      h1 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
        color: $color-primary-100;
      }
    }

    .identity {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      .info {
        margin-left: 8px;
        display: flex;
        gap: 6px;
        align-items: center;

        h1 {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.4px;
          color: $color-primary-100;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg {
          width: 16px;
          height: 16px;
          color: $color-primary-100;
        }
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-top: 16px;
    width: 100%;

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
