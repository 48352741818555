<template>
  <div class="input-validation-error">
    <slot />
  </div>
</template>

<style lang="scss">
.input-validation-error {
  margin-top: 4px;
  font-size: 12px;
  line-height: 18px;
  color: $color-alert;
}
</style>
