<script setup></script>
<template>
  <div class="ui-action-row-wrapper">
    <div class="row-header">
      <h2>Broker</h2>
      <h2>Status</h2>
      <h2>Request sent</h2>
    </div>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.ui-action-row-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .row-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    padding: 16px 0 0 0;

    h2 {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      width: 25%;
    }
  }
}
</style>
