<script setup>
import DataDeletePageResultsCardHigh from "@/components/feature/data-delete/DataDeletePageResultsCardHigh.vue";
import DataDeletePageResultsCardLow from "@/components/feature/data-delete/DataDeletePageResultsCardLow.vue";
import DataDeletePageResultsCardMedium from "@/components/feature/data-delete/DataDeletePageResultsCardMedium.vue";
import DataDeleteCard from "@/components/feature/data-delete/atoms/DataDeleteCard.vue";
import { ref, watch, defineEmits } from "vue";
import { useNameParsing } from "@/components/feature/data-delete/composables";

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
  phone: {
    type: String,
    required: true,
  },
  isForcingNewSearch: {
    type: Boolean,
    default: false,
  },
});

const { name, nameAndAge } = useNameParsing(props.result);

const hasHighRisk = ref(false);
const hasMediumRisk = ref(false);
const hasLowRisk = ref(false);

const emit = defineEmits(["threat-level", "on-not-me"]);
watch(
  () => ({
    hasHighRisk: hasHighRisk.value,
    hasMediumRisk: hasMediumRisk.value,
  }),
  ({ hasHighRisk, hasMediumRisk }) =>
    emit(
      "threat-level",
      hasHighRisk ? "high" : hasMediumRisk ? "medium" : "low"
    ),
  { immediate: true }
);
</script>

<template>
  <DataDeleteCard class="data-delete-results-card">
    <div>
      <h2
        class="data-delete-results-card__name data-delete__capitalized"
        v-if="name"
      >
        {{ nameAndAge }}
      </h2>
      <p
        class="data-delete-results-card__link data-delete-results-card__not-me"
        v-on:click="$emit('on-not-me')"
        v-if="!isForcingNewSearch"
      >
        Not you?
      </p>
    </div>
    <DataDeletePageResultsCardHigh
      v-model="hasHighRisk"
      :result="result"
      class="data-delete-results-card__card"
    />
    <DataDeletePageResultsCardMedium
      v-model="hasMediumRisk"
      :result="result"
      class="data-delete-results-card__card"
    />
    <DataDeletePageResultsCardLow
      v-model="hasLowRisk"
      :result="result"
      class="data-delete-results-card__card"
    />
  </DataDeleteCard>
</template>

<style lang="scss">
.data-delete-results-card {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  margin-top: 24px;

  @media all and (min-width: $screen-xl) {
    margin-top: 0;
  }

  &__name {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: center;
  }

  & &__card {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    & > * {
      animation: appear-bottom-5 0.4s forwards ease-out;
      opacity: 0;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          opacity: 0;
          animation-delay: $i * 0.03s;
        }
      }
    }
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.9;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      inset: -14px 0 -14px 0;
    }

    @media all and (min-width: $screen-xl) {
      font-size: 15px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
