<script setup>
import { nextTick } from "vue";

import Warning from "@/assets/icons/warning-hex.svg";
import router from "@/routes/router";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";

const emit = defineEmits(["close"]);

async function close() {
  emit("close");
}

function navigate() {
  close().then(() => {
    nextTick(() => {
      router.push({ name: "settings.recovery" });
    });
  });
}
</script>
<template>
  <AppModalContent class="download-recovery" @close="close">
    <span class="download-recovery__icon">
      <Warning />
    </span>
    <AppModalTitle>Are you sure you have your recovery key?</AppModalTitle>
    <AppModalParagraph>
      Before continuing, be sure you’ve saved your recovery key in a secure
      location.
    </AppModalParagraph>
    <AppModalParagraph>
      Cloaked has no record or way to recover your key if you lose it. We will
      be unable to help you regain access to your account if you lose your
      password and don’t have this key.
    </AppModalParagraph>
    <AppModalFooter>
      <Button type="primary" size="xl" @click="navigate">
        Download recovery key
      </Button>
      <Button type="secondary" size="xl" @click="close"
        >Yes, I have my key</Button
      >
    </AppModalFooter>
  </AppModalContent>
</template>

<style lang="scss" scoped>
.download-recovery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__icon {
    border-radius: 500px;
    height: 72px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 32px;
  }
}
</style>
