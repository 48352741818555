<template>
  <label
    class="data-delete-input"
    :class="{ 'data-delete-input--error': $attrs.error }"
  >
    <slot />
    <span class="data-delete-input__wrapper">
      <input
        :value="$attrs.modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown="$emit('keydown', $event)"
        v-bind="$attrs"
        class="data-delete-input__input"
      />
      <slot name="after-input" />
    </span>
    <slot name="after" />
  </label>
</template>

<style lang="scss">
.data-delete-input {
  &__wrapper {
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 18px 16px;
    border-radius: 10px;
    border: 1px solid $color-primary-50;
    color: $color-primary-100;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    background-color: transparent;

    @media (min-width: $screen-md) {
      padding: 28px 20px;
      font-size: 15px;
    }

    &::placeholder {
      font-weight: 400;
      color: $color-primary-100;
      opacity: 0.4;
    }

    &:hover {
      border: 1px solid $color-primary-60;
    }

    &:focus {
      outline: 2px solid $color-primary-100;
    }

    &:disabled {
      border: 1px solid $color-primary-10;
      color: $color-primary-60;
      cursor: not-allowed;

      &::placeholder {
        opacity: 0.3;
      }
    }

    @at-root .data-delete-input--error & {
      border: 1px solid $color-alert !important;
    }
  }
}
</style>
