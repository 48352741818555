const packageJsonVersion = require("../package.json")?.version || "";

global.ENV = {
  DASHBOARD_VERSION: packageJsonVersion || null,
};

[
  "VUE_APP_API",
  "VUE_APP_AUTOCLOAK_API",
  "VUE_APP_SECRET",
  "VUE_APP_CLIENT_ID",
  "VUE_APP_STRIPE",
  "VUE_APP_REDIRECT_URI",
  "VUE_APP_STRIPE_PAYLINK",
  "VUE_APP_POSTHOG_PROJECT_API_KEY",
  "VUE_APP_POSTHOG_API_HOST",
  "VUE_APP_PAYPAL_CLIENT",
  "VUE_APP_EXTENSION_CLIENT_ID",
  "VUE_APP_API_MOCK",
  "VUE_APP_API_MOCK_PLAYBACK",
  "VUE_APP_HEADLESS_ID",
  "VUE_APP_CAPTCHA_KEY",
  "VUE_APP_SEARCH_API",
  "VUE_APP_STRIPE_MANAGE_URL",
  "VUE_APP_SHARE_URL",
  "VUE_APP_AF_SIGNUP_URL",
  "VUE_APP_AUTH_V3_ROUTES_ENABLED",
].map((env_var) => {
  if (!global.ENV[env_var] && process.env[env_var]) {
    global.ENV[env_var] = process.env[env_var];
  }
});
