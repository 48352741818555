<script setup>
import axios from "axios";
import CloaksList from "@/routes/your-cloaks/CloaksList";
import { reactive, computed, onMounted, markRaw } from "vue";
import store from "@/store";
import NoIdentities from "@/routes/your-cloaks/NoIdentities.vue";
import { cloakHelpers, search } from "@/scripts";
import { useRoute } from "@/hooks";
import AddCloakToCategory from "@/routes/modals/categories/AddCloakToCategory";
import CategoryService from "@/api/actions/category-service";
import EditCategoryButton from "@/components/categories/EditCategoryButton.vue";

const route = useRoute();

const PAGE_SIZE = 30;

const state = reactive({
  page: 0,
  loading: false,
  filter: cloakHelpers.getFilterFromRoute(route),
  sort: route.query.ordering || "-created_at",
  showAddCloaksModal: false,
  fetchingCategory: false,
  category: store.state.categories.custom.find(
    (cat) => cat.id == route?.params?.id
  ),
});

onMounted(() => {
  getCategoryData();
});

const cloaks = computed(() => {
  return store.state.localdb.db_cloaks;
});

const categoryId = computed(() => {
  return route.params.id;
});

const allCloaks = computed(() => {
  if (cloaks.value) {
    return cloaks.value.filter(
      (cloak) =>
        cloak?.categories?.includes(parseInt(categoryId.value)) || cloak.id < 0
    );
  }
  return [];
});

const identitySearch = computed(() => {
  return store.getters.getIdentitySearch;
});

const cloaksToShow = computed(() => {
  const cloaksFilteredByFilters = cloakHelpers.getAvailableCloakFilters({
    allCloaks: allCloaks.value,
    sort: state.sort,
    filter: state.filter,
  });

  const cloaksFilteredBySearch = search.searchLocalCloaksSingleList(
    cloaksFilteredByFilters,
    identitySearch.value
  );

  return cloaksFilteredBySearch;
});

const identityList = computed(() => {
  const numToShow = state.page * PAGE_SIZE;
  let slicedCloakList = cloaksToShow.value.slice(0, numToShow);
  return slicedCloakList;
});

const allCloaksLoaded = computed(() => {
  return state.page * PAGE_SIZE >= cloaksToShow.value.length;
});

const availabileFilters = computed(() => {
  const matchingIdentityFilters = cloakHelpers.getAvailableIdentityFilters(
    allCloaks.value
  );
  return matchingIdentityFilters;
});

const category = computed(() => {
  return state.category;
});

function loadNextPage() {
  state.loading = true;
  const newPage = state.page + 1;
  state.page = newPage;
  state.loading = false;
}

function setFilter(filter) {
  if (filter !== state.filter) {
    state.filter = filter;
  } else {
    state.filter = "";
  }
}

function setSort(sort) {
  state.sort = sort;
}

function toggleAddCloaksModal(show) {
  if (show) {
    store.dispatch("openModal", {
      customTemplate: {
        template: markRaw(AddCloakToCategory),
        props: {
          category: category.value,
          isModalVisible: true,
          identitiesInCategory: allCloaks.value,
        },
        events: {
          addCloaksToCategory: (newCloaks) => addCloaksToCategory(newCloaks),
        },
      },
    });
  }
}

function addCloaksToCategory(newCloaks) {
  const identityIds = newCloaks.map((c) => c.id);
  CategoryService.addCloaksToCategory(categoryId.value, identityIds);
}

const source = axios.CancelToken.source();
function getCategoryData() {
  if (!state.fetchingCategory) {
    state.fetchingCategory = true;
    CategoryService.refreshCategory(source, categoryId.value)
      .then(({ data }) => {
        state.category = data;
        state.fetchingCategory = false;
      })
      .catch(() => {
        state.fetchingCategory = false;
      });
  }
}
</script>

<template>
  <div>
    <div v-if="!identityList.length && allCloaksLoaded">
      <EditCategoryButton :categoryId="categoryId" />
      <NoIdentities
        identity-type="category"
        :callback="() => toggleAddCloaksModal(true)"
      />
    </div>
    <CloaksList
      v-else
      ref="cloaklist"
      title="Category"
      hoverText="Add cloak to category"
      :loading="state.loading"
      :identityList="identityList"
      :allCloaksLoaded="allCloaksLoaded"
      :filterOptions="availabileFilters"
      @loadNextPage="loadNextPage"
      @sort="(e) => setSort(e)"
      @filter="(e) => setFilter(e)"
    />
  </div>
</template>

<style lang="scss" scoped>
.empty-state {
  width: 100%;
  padding: 100px 20px 120px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.full {
    height: 100vh;
    padding: 0;
    position: absolute;
    top: 0;
    left: 240px;
    width: calc(100% - 240px);
  }

  .content {
    max-width: 634px;
    text-align: center;
    display: inline-block;

    h1 {
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: $color-primary-100-60;
      margin-top: 20px;
    }
  }
}

.title {
  padding: 0 36px;
  margin-bottom: 7px;
  margin-top: 24px;

  h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }
}
</style>
