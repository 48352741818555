import store from "@/store";
import { ref, watchEffect } from "vue";
import { PAYMENT_PROVIDER } from "@/composables/useSubscriptionPlans";

export const usePaymentIntent = (plan, promoCode) => {
  const isLoadingIntent = ref(false);
  const paymentIntent = ref(null);

  const fetchIntent = async () => {
    paymentIntent.value = null;

    if (plan.value?.provider !== PAYMENT_PROVIDER.STRIPE) {
      return;
    }

    isLoadingIntent.value = true;
    paymentIntent.value = await store
      .dispatch("subscription/fetchIntent", {
        productId: plan.value.product_id,
        promoCode: promoCode.value?.code,
      })
      .finally(() => {
        isLoadingIntent.value = false;
      });
  };

  watchEffect(() => {
    fetchIntent();
  });

  return {
    isLoadingIntent,
    paymentIntent,
  };
};
