<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import { useSlots } from "vue";

const slots = useSlots();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    default: "See scan results",
  },
  largeButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["submit"]);
</script>
<template>
  <div class="footer">
    <slot />
    <div
      @click="emit('submit')"
      type="primary"
      :class="{
        disabled: props.disabled,
        large: props.largeButton,
      }"
      class="button"
    >
      <span>{{ props.buttonText }}</span>
      <inlineSvg name="arrow-right" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-primary-100;
  gap: 8px;
  margin-top: 24px;

  .button {
    cursor: pointer;
    display: flex;
    padding: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: $color-primary-0;
    background-color: $color-primary-100;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    border-radius: 56px;
    width: 185px;
    flex-shrink: 0;
    z-index: 2;
    &.large {
      width: 244px;
      span {
        text-align: center;
        flex-grow: 1;
      }
    }
  }
}
</style>
