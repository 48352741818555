<script setup>
// TODO: refactor shared logic using composables
import {
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  nextTick,
  watch,
  reactive,
  markRaw,
} from "vue";

import store from "@/store";
import { useRoute } from "@/hooks";
import { getPosthog } from "@/scripts/posthog";

import AppCaptcha from "@/components/AppCaptcha.vue";
import HeadlessIframe from "@/routes/guest/HeadlessIframe";
import DataDeleteService from "@/api/actions/data-delete-service";

import { stepToComponent } from "./utils";
import DataDeletePageBackground from "@/components/feature/data-delete/DataDeletePageBackground.vue";
import { SubscriptionService } from "@/api";
import { PH_EVENT_USER_CLICKED_DATA_DELETION_SETUP_ACCOUNT_BUTTON } from "@/scripts/posthogEvents";
import DataDeletePage from "@/components/feature/data-delete/DataDeletePage.vue";
import DataDeletePageAlreadyStarted from "@/components/feature/data-delete/DataDeletePageAlreadyStarted.vue";
import UserService from "@/api/actions/user-service";
import { DATA_DELETE_REQUESTED } from "@/scripts/userFlags";
import {
  useDataDeleteInput,
  useDataDeleteFormatting,
  useDataDeleteSessionStorage,
  useDataDeleteBrokerScan,
} from "@/components/feature/data-delete/composables";
import { useThemeQueryParameter } from "@/composables";
import { posthogCapture } from "@/scripts/posthog.js";
import DataDeleteScanResultsCard from "@/components/feature/data-delete/DataDeleteScanResultsCard.vue";
import DataDeleteTryAgain from "@/components/feature/data-delete/DataDeleteTryAgain.vue";
import DataDeleteScanResults from "@/components/feature/data-delete/DataDeleteScanResults.vue";

const iframeChannel = ref(null);

const route = useRoute();

useThemeQueryParameter();

const { dataDeleteInputForm } = useDataDeleteInput();
const { formattedPhoneNumber, formattedUserName } =
  useDataDeleteFormatting(dataDeleteInputForm);

const error = ref(null);
const hasSearchError = ref(false);
const step = ref(stepToComponent.StepLoader);
const searchStep = ref("initial");
const searchResults = ref([]);
const numTotalResults = ref(0);

const {
  clearSearchProgressFromSessionStorage,
  saveSearchProgressToSessionStorage,
} = useDataDeleteSessionStorage(dataDeleteInputForm, searchResults);

onBeforeMount(() => {
  clearSearchProgressFromSessionStorage();

  getPosthog().then((posthog) => {
    posthog?.identify(route?.query?.vid);
    posthog?.startSessionRecording();
  });
});

onBeforeUnmount(() => {
  getPosthog().then((posthog) => {
    posthog?.stopSessionRecording();
  });
});

const statusState = reactive({
  hasAlreadyStartedDD: false,
});

async function searchPublicRecords({
  firstName,
  lastName,
  state,
  phoneNumber,
  age,
  email,
  useArray = false,
}) {
  if (!(firstName && lastName) && !phoneNumber) {
    forceNewSearch();
    return;
  }

  isFetching.value = true;
  const { data } = await DataDeleteService.getPublicRecords({
    firstName,
    lastName,
    state,
    phoneNumber,
    age,
    email,
    useArray,
  });

  if (data.in_progress) {
    statusState.hasAlreadyStartedDD = true;
    return;
  }

  if (data.hasError) {
    hasSearchError.value = true;
    step.value = stepToComponent.StepResults;
    return;
  }

  // for security reasons data.results always contains at most 1 result
  numTotalResults.value = data.numTotalResults ?? 0;

  if (data?.results?.length) {
    searchComplete.value = true;
    searchResults.value = data.results;

    if (isValidScanPayload.value) {
      step.value = markRaw(DataDeleteScanResults);
    } else {
      step.value = stepToComponent.StepResults;
    }
  } else {
    searchAlternateOptions({
      firstName,
      lastName,
      state,
      phoneNumber,
      age,
      useArray,
    });
  }

  isFetching.value = false;
}

const searchAlternateOptions = ({
  firstName,
  lastName,
  state,
  age,
  useArray = false,
}) => {
  step.value = stepToComponent.StepSearch;
  if (!firstName && !lastName) {
    searchStep.value = "name";
  } else if (!age) {
    searchStep.value = "age";
  } else if (!state) {
    searchStep.value = "state";
  } else {
    step.value = stepToComponent.StepLoader;
    searchComplete.value = true;
    useArray = true;
    // todo: implement array
  }
};

const searchComplete = ref(false);
const isFetching = ref(false);
const isForcingNewSearch = ref(false);

function forceNewSearch() {
  isForcingNewSearch.value = true;
  searchComplete.value = false;
  searchResults.value = [];
  dataDeleteInputForm.value = {};
  searchAlternateOptions(dataDeleteInputForm.value);
}

function setStep(value) {
  step.value = value;
}

const hasToken = computed(
  () => !!store.state.authentication.guest?.access_token
);

watch(
  () => hasToken.value,
  (value) => {
    if (value && initialized.value) {
      nextTick(() => {
        performSearch();
        SubscriptionService.getSubscription();
      });
    }
  }
);

function performSearch() {
  searchPublicRecords({
    firstName: dataDeleteInputForm.value.firstName,
    lastName: dataDeleteInputForm.value.lastName,
    state: dataDeleteInputForm.value.state,
    age: dataDeleteInputForm.value.age,
    email: dataDeleteInputForm.value.email,
    phoneNumber: formattedPhoneNumber,
  });
}

const initialized = ref(false);
onMounted(() => {
  store.dispatch("authentication/setGuestToken", null);
  nextTick(() => {
    initialized.value = true;
  });
});

const captcha = ref({});
const showCaptcha = ref(null);

const iframe = computed(() => iframeChannel.value);

const isIframeReady = ref(false);

watch(
  [
    () => showCaptcha.value,
    () => iframe.value,
    () => captcha.value,
    () => isIframeReady.value,
  ],
  ([showCaptchaValue, iframeValue, captchaValue, isIframeReadyValue]) => {
    if (iframeValue && isIframeReadyValue) {
      if (showCaptchaValue === true) {
        if (captchaValue?.token) {
          iframeChannel.value.getAccount(captchaValue.token);
          return;
        }
        return;
      } else if (showCaptchaValue === false) {
        /* Only do this if captcha is not required (false), not null */
        iframeChannel.value.getAccount("-");
      }
    }
    return;
  }
);

function setupUser(payload) {
  iframeChannel.value.setupUser(payload);
}

const isSubscribed = computed(() => {
  return store.getters["settings/isSubscribed"];
});

const storeSearchProgressInSessionStorage = () => {
  saveSearchProgressToSessionStorage();

  posthogCapture(PH_EVENT_USER_CLICKED_DATA_DELETION_SETUP_ACCOUNT_BUTTON, {
    isForcingNewSearch: isForcingNewSearch.value,
  });
};

function setSetup() {
  storeSearchProgressInSessionStorage();

  if (isSubscribed.value) {
    step.value = stepToComponent.StepUsername;
  } else {
    step.value = stepToComponent.StepPayment;
  }
}

const contactInformation = ref(null);

function setPaid(payload = {}) {
  contactInformation.value = payload.contactInformation ?? null;

  step.value = stepToComponent.StepUsername;
}

function setSearchStep(value) {
  searchStep.value = value;
}

const compRef = ref(null);

function handleError(message) {
  error.value = message;
  if (message && compRef.value.hasError) {
    compRef.value.hasError();
  }
}

const headlessUser = ref(null);
watch(
  () => headlessUser.value,
  (value) => {
    if (value) {
      UserService.setFlag({
        name: DATA_DELETE_REQUESTED,
        value: true,
      });
    }
  }
);

const { progress, records, initiateScan, hasScanError } =
  useDataDeleteBrokerScan();

const scanPayload = computed(() => {
  const person = searchResults.value[0];
  const searchLocation = person.locations.find(
    (location) => location.city && location.state
  );

  return {
    firstName: person.firstName,
    lastName: person.lastName,
    age: person.age,
    city: searchLocation.city,
    state: searchLocation.state.abbreviation,
    phone: formattedPhoneNumber,
  };
});

const isValidScanPayload = computed(() =>
  ["firstName", "lastName", "age", "city", "state"].every(
    (field) => !!scanPayload.value[field]
  )
);

watch(
  () => searchResults.value,
  () => {
    if (searchResults.value?.length && isValidScanPayload.value) {
      initiateScan(scanPayload.value);
    }
  }
);
</script>

<template>
  <DataDeletePage class="data-delete-scan-page">
    <DataDeletePageBackground />
    <AppCaptcha
      v-model="captcha"
      v-if="showCaptcha === true && isIframeReady && iframe"
    />
    <DataDeletePageAlreadyStarted v-if="statusState.hasAlreadyStartedDD" />
    <DataDeleteTryAgain v-else-if="hasScanError" />
    <Transition v-else mode="out-in" appear>
      <Component
        :is="step"
        class="data-delete__page"
        :userName="formattedUserName"
        :phone="dataDeleteInputForm.phone"
        :searchResults="searchResults"
        :is-fetching="isFetching"
        :value="dataDeleteInputForm"
        :numTotalResults="numTotalResults"
        :searchStep="searchStep"
        :isForcingNewSearch="isForcingNewSearch"
        @setStep="setStep"
        @input="dataDeleteInputForm = $event"
        @setSearchStep="setSearchStep"
        @searchPublicRecords="searchPublicRecords"
        @setup="setSetup"
        @complete="setSetup"
        @setUser="setupUser"
        :hasToken="hasToken"
        :error="error"
        :hasError="hasSearchError"
        :isSubscribed="isSubscribed"
        :headlessUser="headlessUser"
        @subscribed="setPaid"
        ref="compRef"
        :searchComplete="searchComplete"
        :defaultCredentials="contactInformation"
        :progress="progress"
        :records="records"
      >
        <template
          #before-table
          v-if="
            searchResults[0] &&
            records?.length &&
            progress.brokerCountScanned / progress.brokerCountTotal > 0.1
          "
        >
          <DataDeleteScanResultsCard
            :result="searchResults[0]"
            class="data-delete-scan-page__card"
          />
        </template>
      </Component>
    </Transition>
    <HeadlessIframe
      ref="iframeChannel"
      @error="handleError"
      @iframe-ready="isIframeReady = true"
      @user-ready="headlessUser = $event"
      @show-captcha="showCaptcha = $event"
    />
  </DataDeletePage>
</template>

<style lang="scss">
.data-delete-scan-page {
  &__card {
    max-width: 1010px;
    margin: 24px auto 0;
    animation: appear-bottom-5 0.3s forwards ease-out;
    opacity: 0;

    @media all and (min-width: $screen-xl) {
      margin: 52px auto 0;
    }
  }

  .data-delete-scan-results__table {
    margin-top: 16px;

    @media all and (min-width: $screen-xl) {
      margin-top: 34px;
    }
  }

  .data-delete-results-card__not-me {
    display: none;
  }
}
</style>
