<script setup>
import Add from "@/assets/icons/add.svg";
import Chevron from "@/assets/icons/chevron.svg";
import CheckMarkRounded from "@/assets/icons/check-mark-rounded.svg";
import CheckMark from "@/assets/icons/check-mark.svg";
import Ignored from "@/assets/icons/ignored.svg";
import {
  LABEL_IGNORE,
  CUSTOM_LABELS as customLabels,
} from "@/store/modules/accounts-importer/shared.js";
import { computed, ref } from "vue";
import store from "@/store";
import { vOnClickOutside } from "@vueuse/components";

const emit = defineEmits(["input"]);
const props = defineProps({
  value: {
    type: String,
    required: false,
  },
});
const isOpen = ref(false);
const labels = computed(() => store.getters["accountsImporter/LABELS"]);
const isIgnored = computed(() => props.value === LABEL_IGNORE);
const label = computed(() => {
  if (isIgnored.value) {
    return "(Ignored column)";
  }
  return props.value || "Choose label (required)";
});
const icon = computed(() => {
  if (isIgnored.value) {
    return Ignored;
  }

  return props.value ? CheckMarkRounded : Add;
});
const isV2User = computed(() => store.getters.isV2User);
function onLabelClicked(label) {
  emit("input", label);
  isOpen.value = false;
}
</script>

<template>
  <div class="label-select" v-on-click-outside="() => (isOpen = false)">
    <button
      @click="isOpen = !isOpen"
      @keydown.esc="isOpen = false"
      class="label-select__button"
      :class="{
        'label-select__button--open': isOpen,
        'label-select__button--ignored': isIgnored,
        'label-select__button--no-label': !value,
      }"
    >
      <Component :is="icon" class="label-select__icon" />{{ label
      }}<Chevron class="label-select__chevron" />
    </button>
    <transition name="label-dropdown-fade">
      <ul v-show="isOpen" class="label-select__dropdown">
        <li
          v-for="label in labels"
          :key="label.label"
          class="label-select__label"
          :class="{
            'label-select__label--warning': label.isWarning,
            'label-select__label--padded': !!value && label.label !== value,
            'label-select__label--selected': label.label === value,
          }"
          @click="onLabelClicked(label)"
        >
          <CheckMark
            v-if="label.label === value"
            class="label-select__label-icon"
          />
          {{ label.label }}
        </li>
        <template v-if="isV2User">
          <li
            class="label-select__dropdown-title"
            :class="{ 'label-select__dropdown-title--padded': !!value }"
          >
            Other information
          </li>
          <li
            v-for="label in customLabels"
            :key="label.label"
            class="label-select__label"
            :class="{
              'label-select__label--warning': label.isWarning,
              'label-select__label--padded': !!value && label.label !== value,
              'label-select__label--selected': label.label === value,
            }"
            @click="onLabelClicked(label)"
          >
            <CheckMark
              v-if="label.label === value"
              class="label-select__label-icon"
            />
            {{ label.label }}
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss">
.label-select {
  position: relative;

  &__button {
    width: 100%;
    min-width: 200px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 6px 8px;
    background: $color-surface;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.04), 0 1px 4px rgba(1, 2, 24, 0.08);
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    color: $color-primary-100;
    transition: all 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transform: scale(1.02);
      transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }

    &--no-label {
      color: $color-alert;
    }

    &--ignored {
      color: $color-primary-50;
    }

    &--open {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    padding: 4px;
    background: $color-primary-0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 40px rgba($black, 0.16);
    border-radius: 12px;
    z-index: 1;
    overflow: auto;
    max-height: calc(100vh - 300px);

    @at-root .theme-dark & {
      box-shadow: 0 5px 40px rgba($white, 0.16);
    }

    &-title {
      color: $color-primary-100;
      font-size: 12px;
      font-family: $poppins;
      font-weight: 600;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 12px;
      margin-top: 12px;

      &--padded {
        margin-left: 8px;
      }
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &__chevron {
    margin-left: auto;
    transition: transform 0.1s ease-out;

    @at-root .label-select__button--open & {
      transform: rotate(180deg);
    }
  }

  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: $color-primary-100;
    padding: 0 12px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 8px;
    }

    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      background: $color-primary-5;
      transform: scale(1.02);
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }

    &--warning {
      color: $color-alert;
    }

    &--padded {
      padding-left: calc(11px + 32px);
    }

    &--selected {
      color: #6251f8;
    }
  }
}

.label-dropdown-fade-enter-active,
.label-dropdown-fade-leave-active {
  transition: all 0.15s ease-in-out;
}

.label-dropdown-fade-enter,
.label-dropdown-fade-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
</style>
