import { createMessaging } from "@/scripts/messaging/messaging";
import store from "@/store";

export const extensionMessaging = createMessaging({
  targetOrigin: window.location.origin,
  sourceId: "dashboard",
  callback: () => {
    store.dispatch("browser/savePluginDetected");
  },
});
