<script setup>
import { ArrowRight, Checkmark } from "@/assets/icons";
import { ref } from "vue";
import Countdown from "@/components/Countdown.vue";
import { timeTo } from "@/components/feature/onboarding/utils";
import ModalWelcomeDefault from "@/components/feature/onboarding/ModalWelcomeDefault.vue";
import { useAttrs } from "vue";
const attrs = useAttrs();

const props = defineProps({
  expires: {
    type: [Date, null],
    required: true,
  },
  value: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input", "subscribe"]);

const countdown = ref(timeTo(props.expires));
</script>

<template>
  <ModalWelcomeDefault
    :value="props.value"
    @input="(value) => $emit('input', value)"
    @subscribe="emit('subscribe')"
  >
    <template #cta>
      <div class="modal-welcome__cta" v-if="countdown > 0">
        <div class="modal-welcome__pricing modal-welcome__pricing--discount">
          <span class="modal-welcome__pricing-monthly"> Only $4.49/mo </span>
          <span class="modal-welcome__pricing-yearly"> or $42.99/year </span>
        </div>
        <button
          class="modal-welcome__cta-button"
          @click="
            emit('input', false);
            emit('subscribe');
          "
        >
          Subscribe today <ArrowRight class="modal-welcome__arrow-icon" />
        </button>
        <div class="modal-welcome__cta-discount">
          Sale ends in
          <Countdown
            :value="countdown"
            @input="(event) => (countdown = event)"
            :expires="expires"
            class="modal-welcome__cta-countdown"
          />
        </div>
      </div>
    </template>
  </ModalWelcomeDefault>
</template>

<style lang="scss">
.modal-welcome {
  &__pricing {
    &--discount {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }

  &__cta {
    &-discount {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      margin-top: 16px;
    }

    &-countdown {
      width: 65px;
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
