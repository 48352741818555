<script setup>
import IdentitySharing from "@/components/cloakDetails/IdentitySharing/IdentitySharing.vue";
import { ref, computed, watch, onBeforeMount } from "vue";
import { IdentityService } from "@/api";
import { encryptSharingData } from "@/scripts/identitySharing";
import {
  generateNewPassword,
  generateSharingEncryption,
  identityKeyToLabel,
  identityKeyToType,
} from "@/components/cloakDetails/IdentitySharing/utils";
import { authDecrypt } from "@/scripts/actions/encryption";
import { useToast } from "@/hooks";

const toast = useToast();

const props = defineProps({
  identity: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["refresh"]);
const isLoading = ref(false);
const isGeneratingLink = ref(false);
const isGeneratingPassword = ref(false);
const sharing = ref(null);
const activeModal = ref(null);
const isModalOpen = ref(false);
const isTooltipOpen = ref(false);
const isShared = computed(() => !!props.identity.sharing);

const resetChanges = () => {
  sharing.value = {
    data: props.identity.sharing?.data ?? [],
    private_key: props.identity.sharing?.private_key ?? null,
    public_key: props.identity.sharing?.public_key ?? null,
    salt: props.identity.sharing?.salt ?? null,
    expires_at: props.identity.sharing?.expires_at ?? null,
    shared_at: props.identity.sharing?.shared_at ?? null,
    shared_url: props.identity.sharing?.shared_url ?? null,
    recipient_shared_password:
      props.identity.sharing?.recipient_shared_password ?? null,
    decryptedPassword: props.identity.sharing?.decryptedPassword,
    onetimeview: props.identity.sharing?.onetimeview ?? false,
  };
};

const refreshIdentity = () =>
  new Promise((resolve) => {
    const unwatch = watch(
      () => props.identity.sharing,
      () => {
        resetChanges();
        unwatch();
        resolve();
      },
      { deep: true }
    );

    emit("refresh", { id: props.identity.id });
  });

watch(
  () => isShared.value,
  (newValue) => {
    if (!isModalOpen.value) {
      activeModal.value = newValue
        ? "IdentitySharingModalPublished"
        : "IdentitySharingModalCreate";
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => isModalOpen.value,
  (newValue) => {
    if (!newValue) {
      activeModal.value = isShared.value
        ? "IdentitySharingModalPublished"
        : "IdentitySharingModalCreate";

      if (!isShared.value) {
        resetChanges();
      }
    }
  },
  { immediate: true, deep: true }
);

onBeforeMount(() => {
  resetChanges();
});

const getExistingPermission = ({ identityKey, id }) => {
  if (id) {
    return props.identity.sharing?.data?.find?.(
      (item) => item.id.toString() === id.toString()
    );
  }

  if (identityKey) {
    return (
      props.identity.sharing?.data?.find?.(
        (item) => item.type === identityKeyToType[identityKey]
      ) ??
      sharing.value?.data?.find?.(
        (item) => item.type === identityKeyToType[identityKey]
      )
    );
  }
};

const generateNewPermission = ({ identityKey }) => {
  const patchedValue = (identityKey) => {
    const value = props.identity[identityKey];

    if (
      identityKey === "website_url" &&
      !(value.startsWith("http://") || value.startsWith("https://"))
    ) {
      return `https://${value}`;
    }

    return value;
  };

  return {
    id:
      props.identity[`cloaked_${identityKey}`]?.id?.toString() ??
      self.crypto.randomUUID(),
    label: identityKeyToLabel[identityKey],
    type: identityKeyToType[identityKey],
    sharedValue: patchedValue(identityKey),
    isSecret: identityKey === "password",
  };
};

const generateNewCustomFieldPermission = (customField) => {
  return {
    id: customField.id,
    label: customField.type.includes("totp")
      ? "One-time passcode"
      : customField.label,
    type: customField.type,
    sharedValue: customField.value,
    isSecret: customField.isSecret,
  };
};

const permissions = ref([]);

const hasFullPermissions = computed(() => {
  return permissions.value.length === sharing.value.data.length;
});

watch(
  () => props.identity,
  async () => {
    const result = [];

    for (let key of Object.keys(identityKeyToType)) {
      // this is a hack to fix a BE hack that for some reason adds cloaked URL when identity doesn't have any URL
      if (
        key === "website_url" &&
        props.identity[key] === "https://cloaked.app"
      ) {
        continue;
      }

      if (props.identity[key]) {
        result.push(
          getExistingPermission({ identityKey: key }) ??
            generateNewPermission({ identityKey: key })
        );
      }
    }

    for (let customField of props.identity.customFields || []) {
      result.push(
        getExistingPermission({ id: customField.id }) ??
          generateNewCustomFieldPermission(customField)
      );
    }

    const passwordPermission = result.find(
      (permission) => permission.type === identityKeyToType.password
    );

    if (passwordPermission) {
      passwordPermission.sharedValue = await authDecrypt(
        passwordPermission.sharedValue
      );
    }

    permissions.value = result;
  },
  { immediate: true, deep: true }
);

const onCreate = async () => {
  try {
    isLoading.value = true;

    const { publicKey, privateKey, password, salt } =
      await generateSharingEncryption();

    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 1);

    const encryptedData = await encryptSharingData(sharing.value.data, {
      public_key: publicKey,
    });

    await IdentityService.createSharing(props.identity.id, {
      data: encryptedData,
      private_key: privateKey,
      public_key: publicKey,
      recipient_shared_password: password,
      salt,
      expires_at: expirationDate.toISOString(),
      onetimeview: false,
      has_full_permissions: hasFullPermissions.value,
    });

    await refreshIdentity();
    activeModal.value = "IdentitySharingModalPublished";
    toast.success("Identity shared.");
  } catch (e) {
    toast.error("There was an issue saving. Try again in a moment.");
  } finally {
    isLoading.value = false;
  }
};

const onUpdate = async () => {
  try {
    isLoading.value = true;

    const isUpdatingExpiration =
      props.identity.sharing.expires_at !== sharing.value.expires_at ||
      props.identity.sharing.shared_at !== sharing.value.shared_at ||
      props.identity.sharing.onetimeview !== sharing.value.onetimeview;

    const encryptedData = await encryptSharingData(sharing.value.data, {
      public_key: sharing.value.public_key,
    });

    // eslint-disable-next-line no-unused-vars
    const { decryptedPassword, ...previousSharingPayload } = sharing.value;

    await IdentityService.patchSharing(props.identity.id, {
      ...previousSharingPayload,
      data: encryptedData,
      has_full_permissions: hasFullPermissions.value,
    });

    await refreshIdentity();
    activeModal.value = "IdentitySharingModalPublished";

    isUpdatingExpiration
      ? toast.success("New expiration published.")
      : toast.success("New permissions published.");
  } catch (e) {
    toast.error("There was an issue saving. Try again in a moment.");
  } finally {
    isLoading.value = false;
  }
};

const onDelete = async () => {
  try {
    isLoading.value = true;
    await IdentityService.deleteSharing(props.identity.id);

    await refreshIdentity();
    activeModal.value = "IdentitySharingModalCreate";
    isModalOpen.value = false;

    toast.success("Identity is no longer being shared.");
  } catch (e) {
    toast.error("There was an issue saving. Try again in a moment.");
  } finally {
    isLoading.value = false;
  }
};

const onGenerateNewLink = async () => {
  try {
    isGeneratingLink.value = true;
    isGeneratingPassword.value = true;

    const { publicKey, privateKey, password, salt } = await generateNewPassword(
      sharing.value
    );

    const encryptedData = await encryptSharingData(sharing.value.data, {
      public_key: publicKey,
    });

    // eslint-disable-next-line no-unused-vars
    const { decryptedPassword, ...previousSharingPayload } = sharing.value;

    await IdentityService.patchSharing(props.identity.id, {
      ...previousSharingPayload,
      data: encryptedData,
      private_key: privateKey,
      public_key: publicKey,
      recipient_shared_password: password,
      salt,
    });

    await IdentityService.generateNewSharingLink(props.identity.id);

    await refreshIdentity();

    toast.success("New link and password published.");
  } catch (e) {
    toast.error("There was an issue saving. Try again in a moment.");
  } finally {
    isGeneratingLink.value = false;
    isGeneratingPassword.value = false;
  }
};

const onGenerateNewPassword = async () => {
  try {
    isGeneratingPassword.value = true;

    const { publicKey, privateKey, password, salt } = await generateNewPassword(
      sharing.value
    );

    const encryptedData = await encryptSharingData(sharing.value.data, {
      public_key: publicKey,
    });

    // eslint-disable-next-line no-unused-vars
    const { decryptedPassword, ...previousSharingPayload } = sharing.value;

    await IdentityService.patchSharing(props.identity.id, {
      ...previousSharingPayload,
      data: encryptedData,
      private_key: privateKey,
      public_key: publicKey,
      recipient_shared_password: password,
      salt,
    });

    await refreshIdentity();

    toast.success("New password published.");
  } catch (e) {
    toast.error("There was an issue saving. Try again in a moment.");
  } finally {
    isGeneratingPassword.value = false;
  }
};

const onExpired = async () => {
  activeModal.value = "IdentitySharingModalExpired";
  toast.success("Share link expired.");
  await IdentityService.deleteSharing(props.identity.id);
  await refreshIdentity();
};

const hasAnnouncementTooltip = ref(false);

watch(
  () => isTooltipOpen.value,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      setTimeout(() => {
        hasAnnouncementTooltip.value = false;
      }, 250);
    }
  },
  { deep: true }
);

function handleSetActiveModal(event) {
  activeModal.value = event;
}
</script>

<template>
  <IdentitySharing
    :identity="identity"
    :permissions="permissions"
    :isShared="isShared"
    :active-modal="activeModal"
    :isLoading="isLoading"
    :isGeneratingLink="isGeneratingLink"
    :isGeneratingPassword="isGeneratingPassword"
    :isTooltipOpen="isTooltipOpen"
    :hasAnnouncementTooltip="hasAnnouncementTooltip"
    :sharing="sharing"
    :value="isModalOpen"
    @input="
      (event) => {
        isModalOpen = event;
      }
    "
    @set-active-modal="handleSetActiveModal"
    @set-is-tooltip-open="isTooltipOpen = $event"
    @update-sharing="sharing = $event"
    @discard-changes="resetChanges"
    @create="onCreate"
    @update="onUpdate"
    @delete="onDelete"
    @generate-new-link="onGenerateNewLink"
    @generate-new-password="onGenerateNewPassword"
    @expired="onExpired"
  />
</template>
