<script setup>
import { computed } from "vue";
import store from "@/store";
const kycValidated = computed(() => {
  return store.state.authentication?.user?.cloaked_card_kyc_configured;
});
</script>

<template>
  <div class="status">
    <div class="title">
      <h1>Status</h1>

      <p class="approved" v-if="kycValidated">Approved for Cloaked Pay</p>
      <p class="rejected" v-else>Not approved for Cloaked Pay</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.status {
  .title {
    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5px;
      color: $color-primary-100;
    }
    p {
      margin-top: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: $color-primary-70;
      padding-left: 22px;
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-primary-100;
        position: absolute;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.approved {
        &:before {
          background-color: $color-success;
        }
      }
      &.rejected {
        &:before {
          background-color: $color-alert;
        }
      }
    }
  }
}
</style>
