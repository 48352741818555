<template>
  <h1 class="data-delete-modal-title">
    <slot />
  </h1>
</template>

<style lang="scss">
.data-delete-modal-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  letter-spacing: -0.5px;
}
</style>
