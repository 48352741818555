<script setup>
import { ModalTemplate } from "@/components";
import store from "@/store";
import router from "@/routes/router";
import Button from "@/components/Button.vue";
import { computed } from "vue";
import DetailSection from "@/components/Wallet/RightPanel/DetailSection.vue";
import CardsServices from "@/api/actions/cards-services";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});

function closeModal() {
  store.dispatch("closeModal");
  router.replace("/wallet", undefined, { shallow: true });
}

const statements = computed(() => {
  return store.state.cards?.statements?.results;
});

function openStatement(id) {
  CardsServices.getCardStatement(id).then((response) => {
    const base64String = response.data;
    const binaryString = atob(base64String);

    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    window.open(url, "_blank");
  });
}
</script>

<template>
  <ModalTemplate :show="props.isVisible" @close="closeModal">
    <template #header>
      <h2>Statements</h2>
    </template>
    <template #body>
      <p v-if="!statements">No statements available</p>
      <div v-else class="statements">
        <DetailSection
          :title="statement.name"
          icon="document"
          link
          v-for="statement in statements"
          :key="statement.id"
          @click="openStatement(statement.id)"
        />
      </div>
    </template>
    <template #footer>
      <Button @click="closeModal" type="secondary">Close</Button>
    </template>
  </ModalTemplate>
</template>

<style scoped>
.statements {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
