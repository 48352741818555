<script setup></script>

<template>
  <h3 class="preferences-title">
    <slot />
  </h3>
</template>

<style lang="scss" scoped>
.preferences-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: $color-primary-100;
  margin-bottom: 16px;
  margin-top: 40px;
}
</style>
