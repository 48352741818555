<template>
  <div class="settings-paragraph"><slot /></div>
</template>

<style lang="scss">
.settings-paragraph {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: $color-primary-100;

  p {
    margin-top: 22px;
    &:first-of-type {
      margin-top: 0;
    }
    a {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
