<script setup>
import { ModalTemplate, Button } from "@/components";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import store from "@/store";
import EmailService from "@/api/actions/email-service";
import PhoneService from "@/api/actions/phone-service";
import { computed, onMounted, reactive, useAttrs } from "vue";
import { useToast } from "@/hooks";

const props = defineProps({
  params: Object,
});
const attrs = useAttrs();
const state = reactive({
  code: "",
  loading: false,
  token: null,
});
onMounted(() => {
  resend();
});
const emit = defineEmits(["toggle"]);
const toast = useToast();

const type = computed(() => {
  return props.params.type;
});
const displayType = computed(() => {
  return props.params.type === "email" ? "email address" : "phone number";
});
const contact = computed(() => {
  return props.params.contact;
});
const id = computed(() => {
  return props.params.id;
});

const confirm = () => {
  props.params.confirm();
};
const close = () => {
  store.dispatch("closeModal");
};
const resend = async () => {
  try {
    state.loading = true;
    state.code = "";
    if (type.value === "email") {
      const { data } = await EmailService.sendVerificationCode(id.value);
      if (!data.verified) {
        state.token = data.session_token;
      }
    } else {
      const { data } = await PhoneService.sendVerificationCode(id.value);
      if (!data.verified) {
        state.token = data.session_token;
      }
    }
    setTimeout(() => {
      state.loading = false;
    }, 1000);
  } catch (e) {
    state.loading = false;
    toast.error("Something went wrong, please try again");
  }
};

const verify = async () => {
  const payloadKey = type.value === "email" ? "email" : "phone_number";
  try {
    let confirm;
    if (payloadKey === "phone_number") {
      confirm = await PhoneService.verifyVerificationCode(id.value, {
        security_code: state.code,
        [payloadKey]: contact.value,
        session_token: state.token,
      });
    } else {
      confirm = await EmailService.verifyVerificationCode(id.value, {
        security_code: state.code,
        [payloadKey]: contact.value,
        session_token: state.token,
      });
    }

    await confirm;
    props.params.confirm(this);
    store.dispatch("closeModal");
    window.dispatchEvent(new CustomEvent("cloak:refresh-emails"));
  } catch (e) {
    state.loading = false;
    toast.error("Incorrect code, please try again");
    return;
  }
};

defineExpose({
  close,
  confirm,
  resend,
  verify,
});
</script>
<template>
  <ModalTemplate :show="true" show-close-in-header @close="close" width="512px">
    <template #header>
      <h1>Verify your {{ displayType }}</h1>
    </template>
    <template #body>
      <p class="verification-cta">
        Enter the 6-digit code sent to <strong>{{ contact }}</strong>
      </p>

      <div>
        <OnboardingInputCode
          v-bind="attrs"
          ref="code_input"
          :disabled="state.loading"
          :value="state.code"
          @input="state.code = $event"
          @keydown.enter="verify"
        />
      </div>
    </template>
    <template #footer>
      <Button
        :loading="state.loading"
        :disabled="state.loading"
        type="secondary"
        @click="resend"
      >
        Resend code
      </Button>

      <Button @click="verify" :disabled="state.code && state.code.length !== 6">
        Verify
      </Button>
    </template>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
.content .modal-body p.verification-cta {
  font-size: 16px;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: -5px;
}
.error svg {
  position: relative;
  top: 2px;
  margin-right: 6px;
}
</style>
