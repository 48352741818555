export const KoreanRegionsList = [
  { label: "Seoul", value: "KR-11" },
  { label: "Busan", value: "KR-21" },
  { label: "Daegu", value: "KR-23" },
  { label: "Incheon", value: "KR-24" },
  { label: "Gwangju", value: "KR-25" },
  { label: "Daejeon", value: "KR-26" },
  { label: "Ulsan", value: "KR-27" },
  { label: "Sejong Special Autonomous City", value: "KR-28" },
  { label: "Gyeonggi-do", value: "KR-41" },
  { label: "Gangwon-do", value: "KR-42" },
  { label: "Chungcheongbuk-do", value: "KR-43" },
  { label: "Chungcheongnam-do", value: "KR-44" },
  { label: "Jeonbuk-do", value: "KR-45" },
  { label: "Jeonnam-do", value: "KR-46" },
  { label: "Gyeongsangbuk-do", value: "KR-47" },
  { label: "Gyeongsangnam-do", value: "KR-48" },
  { label: "Jeju-do", value: "KR-49" },
];
