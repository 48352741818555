<script setup>
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";

import store from "@/store";
import { computed } from "vue";
import { constants } from "@/scripts";

const user = computed(() => {
  return store.state.authentication?.user;
});

const userCountry = computed(() => {
  return user?.value?.country_at_registration
    ? user?.value?.country_at_registration
    : user?.value?.current_country;
});

const isSupportedCardCountry = computed(() => {
  return constants.SUPPORTED_CARDS_COUNTRIES.includes(userCountry.value);
});

const payEnabled = computed(() => {
  return user?.value?.cloaked_card_enabled;
});

const kycValidated = computed(() => {
  return user?.value?.cloaked_card_kyc_configured;
});
</script>

<template>
  <MenuBlock title="Pay" v-if="isSupportedCardCountry && payEnabled">
    <MenuItem
      link="/wallet"
      :name="kycValidated || !payEnabled ? 'Wallet' : 'Activate Now'"
      icon="credit-card"
    />
  </MenuBlock>
</template>
