<script setup>
import { computed, reactive, markRaw } from "vue";
import store from "@/store";
import InformationHeader from "./InformationHeader.vue";
// import Button from "@/components/Button.vue";
import DetailSection from "./DetailSection.vue";
import moment, { max } from "moment";
import CardsService from "@/api/actions/cards-services";
import TransactionNoteModal from "@/components/Wallet/RightPanel/TransactionNoteModal.vue";

const state = reactive({
  loading: false,
});

const transaction = computed(() => {
  return store.state.cards.rightPanel?.transaction;
});

const date = computed(() => {
  return moment(transaction.value.created_at).format("MMMM DD, YYYY, h:mm A");
});

async function save(value) {
  state.loading = true;

  CardsService.patchTransactionNote(transaction.value.id, {
    note: value,
  })
    .then(() => {
      store.dispatch("updateTransactions", {
        ...transaction.value,
        note: value,
      });

      state.loading = false;
    })
    .catch((error) => {
      state.loading = false;
    });
}

function openNoteModal() {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(TransactionNoteModal),
      props: {
        isVisible: true,
        maxlength: 10000,
      },
    },
  });
}
</script>

<template>
  <div>
    <InformationHeader :information="transaction" />

    <div class="details">
      <DetailSection :title="date" icon="calendar" />

      <DetailSection
        title="Description"
        :description="transaction.merchant_name || 'N/A'"
        icon="description"
      />

      <DetailSection
        title="Category"
        :description="transaction.merchant_category_name || 'N/A'"
        icon="category"
      />

      <DetailSection
        title="Notes"
        :description="transaction.note"
        icon="edit-pencil-larger"
        link
        @click="openNoteModal"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.details {
  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.cloak-identifier-section__group {
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
