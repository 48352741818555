<script setup>
import store from "@/store";
import { IdentityIcon } from "@/components";
import IdentitySharingContainer from "@/components/cloakDetails/IdentitySharing/IdentitySharingContainer.vue";
import IdentityService from "@/api/actions/identity-service";

import { reactive, computed, watch } from "vue";

const props = defineProps(["cloak", "readOnly"]);

const emit = defineEmits(["refresh"]);

const state = reactive({
  nickname: props.cloak.nickname,
  cloakCopyForUpdate: null,
});

const hasIdentitySharingV1 = computed(() => {
  return store.getters.isV2User;
});

function save() {
  const payload = {
    nickname: state.nickname,
  };
  store.dispatch("updateCloaks", [
    {
      ...props.cloak,
      ...payload,
    },
  ]);
  state.cloakCopyForUpdate = { ...props.cloak };
  IdentityService.updateCloak(props.cloak.id, payload).then(() => {
    emit("refresh", {
      ...state.cloakCopyForUpdate,
      nickname: payload.nickname,
    });
  });
}

const hasIdentifiers = computed(() => {
  return (
    (!!props.cloak?.website_url &&
      !props.cloak?.website_url.includes("cloaked.app")) ||
    !!props.cloak?.username ||
    !!props?.cloak?.email ||
    !!props.cloak?.password ||
    !!props?.cloak?.phone ||
    (!!props?.cloak?.stored_autofill?.custom_field &&
      Object.keys(props?.cloak?.stored_autofill?.custom_field).length)
  );
});

const showShare = computed(() => {
  return hasIdentitySharingV1.value && hasIdentifiers.value;
});

watch(
  () => props.cloak,
  (newValue, oldValue) => {
    if (newValue.nickname !== oldValue.nickname) {
      state.nickname = newValue.nickname;
    }
  },
  { deep: true }
);
</script>

<template>
  <div class="cloak-nickname-section">
    <IdentityIcon
      :identity="props.cloak"
      :override="{ height: '40px', width: '40px' }"
    />

    <input
      class="input-nickname"
      aria-id="CloakNicknameInput"
      ref="cloakNickname"
      :disabled="props.readOnly"
      :value="state.nickname"
      @input="(event) => (state.nickname = event.target.value)"
      type="text"
      placeholder="Add name or URL"
      :title="state.nickname"
      autocomplete="off"
      maxlength="50"
      data-lpignore="true"
      @blur="save"
      @keydown.enter="save"
    />
    <IdentitySharingContainer
      v-if="showShare"
      :identity="props.cloak"
      @refresh="$emit('refresh', $event)"
      class="cloak-nickname-section__sharing"
    />
  </div>
</template>

<style lang="scss" scoped>
.cloak-nickname-section {
  padding: 9px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .input-nickname {
    border: none;
    outline: none;
    margin-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
    background: $color-surface;
    width: 100%;
  }

  &__sharing {
    margin-left: 12px;
  }
}
</style>
