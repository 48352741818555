<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import { useWindowSize } from "@vueuse/core";
import { computed, ref } from "vue";
import SubscribeSectionTitle from "@/components/feature/subscribe/SubscribeSectionTitle.vue";
import SubscribeCard from "@/components/feature/subscribe/SubscribeCard.vue";

const { width } = useWindowSize();
const isDesktop = computed(() => width.value >= 1050);

const isExpanded = ref(false);
</script>

<template>
  <div class="subscribe-benefits">
    <SubscribeSectionTitle>
      What’s included with your plan
    </SubscribeSectionTitle>
    <SubscribeCard
      class="subscribe-benefits__card"
      :class="{ 'subscribe-benefits--expanded': isExpanded || isDesktop }"
      @click="isExpanded = !isExpanded"
    >
      <div class="subscribe-benefits__content">
        <section class="subscribe-benefits__section">
          <h3 class="subscribe-benefits__title">24/7 Monitoring & Removal</h3>
          <div class="subscribe-benefits__list-container">
            <Transition name="plan-list-expand">
              <ul
                class="subscribe-benefits__list"
                v-show="isExpanded || isDesktop"
              >
                <li>Ongoing data deletion</li>
                <li>Password breach detection</li>
              </ul>
            </Transition>
          </div>
          <img
            src="@/assets/images/subscription/plan-monitoring.webp"
            class="subscribe-benefits__icon"
            alt="Cloaked Monitoring"
            width="207"
            height="120"
          />
        </section>
        <section class="subscribe-benefits__section">
          <h3 class="subscribe-benefits__title">Hide My Identity & Inbox</h3>
          <div class="subscribe-benefits__list-container">
            <Transition name="plan-list-expand">
              <ul
                class="subscribe-benefits__list"
                v-show="isExpanded || isDesktop"
              >
                <li>Unlimited aliases for your phone, email, & credit card</li>
              </ul>
            </Transition>
          </div>
          <img
            src="@/assets/images/subscription/plan-identity.webp"
            class="subscribe-benefits__icon"
            alt="Cloaked Identity"
            width="207"
            height="120"
          />
        </section>
        <section class="subscribe-benefits__section">
          <h3 class="subscribe-benefits__title">
            $1M Identity Theft Insurance
          </h3>
          <div class="subscribe-benefits__list-container">
            <Transition name="plan-list-expand">
              <ul
                class="subscribe-benefits__list"
                v-show="isExpanded || isDesktop"
              >
                <li>9 to 5 chat & email support</li>
                <li>File a loss claim 24/7</li>
              </ul>
            </Transition>
          </div>
          <img
            src="@/assets/images/subscription/plan-insurance.webp"
            class="subscribe-benefits__icon"
            alt="Cloaked Insurance"
            width="207"
            height="120"
          />
        </section>
        <section class="subscribe-benefits__section">
          <h3 class="subscribe-benefits__title">Encrypted Password Manager</h3>
          <div class="subscribe-benefits__list-container">
            <Transition name="plan-list-expand">
              <ul
                class="subscribe-benefits__list"
                v-show="isExpanded || isDesktop"
              >
                <li>Auto-create, auto-fill, and auto-save logins</li>
              </ul>
            </Transition>
          </div>
          <img
            src="@/assets/images/subscription/plan-passwords.webp"
            class="subscribe-benefits__icon"
            alt="Cloaked Password Manager"
            width="207"
            height="120"
          />
        </section>
      </div>
      <footer class="subscribe-benefits__footer" v-if="!isDesktop">
        <span class="subscribe-benefits__divider" />
        <button class="subscribe-benefits__toggle">
          {{ isExpanded ? "Show less" : "Show more" }}
          <inlineSvg name="minus-outline" v-show="isExpanded" key="hide" />
          <inlineSvg name="plus-outline" v-show="!isExpanded" key="show" />
        </button>
        <span class="subscribe-benefits__divider" />
      </footer>
    </SubscribeCard>
  </div>
</template>

<style lang="scss">
$plans-card-animation-duration: 300ms;
$plans-card-animation-delay: 0ms;

.subscribe-benefits {
  width: 100%;

  &__card {
    margin-top: 8px;
    cursor: pointer;

    @media all and (min-width: $screen-xl) {
      cursor: default;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    padding: 8px 0;
  }

  $space-allocated-to-icons: calc(100vw - 2 * 22px - 2 * 16px - 200px);

  &__section {
    position: relative;
    padding-right: clamp(0px, $space-allocated-to-icons, 120px);
    margin-bottom: 8px;

    @media all and (min-width: $screen-xl) {
      padding-right: 120px;
    }

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        z-index: -#{$i};

        .subscribe-benefits__icon {
          transform: translateY(-12px * ($i - 1));

          @at-root .subscribe-benefits--expanded & {
            transform: translateY(0);
          }
        }
      }
    }
  }

  &__title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    transition: transform $plans-card-animation-duration
      $plans-card-animation-delay ease-out;
    width: clamp(0px, $space-allocated-to-icons, 107px);
    filter: drop-shadow(0px 7px 26px rgba(0, 0, 0, 0.4));
  }

  &__list {
    list-style-type: disc;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-70;
    padding-left: 24px;
    padding-bottom: 16px;
    margin-top: 0;

    @at-root .subscribe-benefits__section:last-child & {
      padding-bottom: 0;
    }

    &-container {
      overflow: hidden;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__divider {
    flex-grow: 1;
    height: 1px;
    background-color: $color-primary-100;
    opacity: 0.3;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: transparent;
    border: none;
    color: $color-primary-100;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    padding: 10px 8px;
    width: 107px;

    @at-root .subscribe-benefits:hover &,
      &:hover {
      opacity: 0.8;
    }
  }

  .plan-list-expand-enter-active {
    transition: margin-top $plans-card-animation-duration ease-out,
      opacity $plans-card-animation-duration $plans-card-animation-delay
        ease-out;
  }

  .plan-list-expand-leave-active {
    transition: margin-top $plans-card-animation-duration
        $plans-card-animation-delay ease-in,
      opacity $plans-card-animation-duration ease-in;
  }

  .plan-list-expand-enter-from,
  .plan-list-expand-leave-to {
    opacity: 0;
    margin-top: -100%;
  }
}
</style>
