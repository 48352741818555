<script setup>
import { ref } from "vue";
import { usePlanMembers } from "@/components/feature/subscribe/composables";
import SubscriptionInputInvite from "@/routes/modals/preferences/SubscriptionInputInvite.vue";
import SubscriptionModalExceededInvites from "@/routes/modals/preferences/SubscriptionModalExceededInvites.vue";

const { freeSpots, inviteMember, isSendingInvite } = usePlanMembers();
const memberEmail = ref("");

const isLimitExceededModalOpen = ref(false);

const onInvite = async () => {
  if (!isSendingInvite.value) {
    try {
      await inviteMember(memberEmail);
      memberEmail.value = "";
    } catch (e) {
      isLimitExceededModalOpen.value = true;
    }
  }
};
</script>

<template>
  <div class="plan-member-add" v-if="freeSpots">
    <p class="plan-member-add__spots">
      {{ freeSpots }} member {{ freeSpots === 1 ? "spot" : "spots" }} open
    </p>
    <SubscriptionInputInvite
      v-model="memberEmail"
      @submit="onInvite"
      :is-loading="isSendingInvite"
    />
  </div>
  <SubscriptionModalExceededInvites
    :value="isLimitExceededModalOpen"
    @input="isLimitExceededModalOpen = $event"
  />
</template>

<style lang="scss">
.plan-member-add {
  &__spots {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
  }

  .app-form-input {
    margin-top: 16px;
  }
}
</style>
