<script setup></script>
<template>
  <div class="body-section">
    <img
      src="@/assets/icons/data-delete/monitoring.png"
      alt="Monitoring icon"
      width="360"
      height="240"
      class="monitoring-icon"
    />
    <slot />
  </div>
</template>
<style scoped lang="scss">
.body-section {
  color: $color-primary-100;
  border-radius: 12px;
  border: 1px solid $color-primary-10;
  background-image: linear-gradient(
    to bottom right,
    $color-surface,
    $color-primary-5
  );
  padding: 32px 24px;
  overflow: hidden;
  position: relative;
  margin-top: 24px;
  .monitoring-icon {
    position: absolute;
    top: -85px;
    right: -130px;
  }
}
</style>
