<template>
  <div class="ui-menu-scroll-body">
    <slot />
  </div>
</template>

<style lang="scss">
.ui-menu-scroll-body {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @include custom-scroll-bar();
}
</style>
