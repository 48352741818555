export default {
  namespaced: true,
  state: {
    screenWidth: null,
  },
  mutations: {
    SET_SCREEN_WIDTH: (state, payload) => (state.screenWidth = payload),
  },
  actions: {
    init({ commit }) {
      const onWindowResize = () =>
        commit("SET_SCREEN_WIDTH", window.innerWidth);

      window.addEventListener("resize", onWindowResize, { passive: true });
      onWindowResize();
    },
  },
  getters: {
    isSmallScreen: (state) => state.screenWidth <= 760,
  },
};
