<script setup>
import {
  IconHome,
  IconWork,
  IconPoBox,
  IconOther,
} from "@/assets/icons/custom-fields/address-types";

defineProps({
  type: {
    type: String,
    validator: (value) => ["home", "work", "po-box", "other"].includes(value),
  },
});

const iconByType = {
  home: IconHome,
  work: IconWork,
  "po-box": IconPoBox,
  other: IconOther,
};
</script>

<template>
  <Component :is="iconByType[type]" class="custom-field-address-icon" />
</template>

<style lang="scss">
.custom-field-address-icon {
  color: $color-primary-100;
}
</style>
