<script setup>
import {
  useBillingCycle,
  usePlanOptions,
  usePlans,
} from "@/components/feature/subscribe/composables";
import ButtonToggle from "@/components/ButtonToggle.vue";
import ChoosePlanOption from "@/components/feature/subscribe/ChoosePlanOption.vue";
import ChoosePlanOptionSkeleton from "@/components/feature/subscribe/ChoosePlanOptionSkeleton.vue";

const props = defineProps({
  intent: {
    type: String,
    default: "subscribe",
  },
  default: {
    type: String,
    default: "individual",
    validator: (value) => ["individual", "couple", "family"].includes(value),
  },
});

const { activePlan, subscribe, isLoadingPlans } = usePlans();

const { selectedBillingCycle, allBillingCycles } = useBillingCycle("annually");

const { billingCycleOptions, selectedPlanOption } = usePlanOptions({
  intent: props.intent,
  selectedBillingCycle,
  defaultSelectedOption: props.default,
});
</script>

<template>
  <div class="choose-plan-picker">
    <div class="choose-plan-picker__billing-cycle">
      <label class="choose-plan-picker__billing-cycle-label">
        <template v-if="intent === 'cancel'"> Your options </template>
        <template v-else-if="!!activePlan"> Switch plans </template>
        <template v-else> Choose plan </template>
      </label>
      <ButtonToggle
        v-model="selectedBillingCycle"
        :options="allBillingCycles"
        class="choose-plan-picker__billing-cycle-toggle"
      />
    </div>
    <fieldset class="choose-plan-picker__plans" v-if="isLoadingPlans">
      <ChoosePlanOptionSkeleton type="individual" />
      <ChoosePlanOptionSkeleton type="couple" />
      <ChoosePlanOptionSkeleton type="family" />
    </fieldset>
    <fieldset class="choose-plan-picker__plans" v-else>
      <ChoosePlanOption
        v-for="option in billingCycleOptions"
        :key="option.id"
        :option="option"
        :disabled="
          (option.id === activePlan?.product_id &&
            $store.getters['settings/getSubscription'].owner) ||
          (!$store.getters['settings/getSubscription'].owner &&
            !!option.stripePlan)
        "
        v-model="selectedPlanOption"
      />
    </fieldset>
  </div>
</template>

<style lang="scss">
.choose-plan-picker {
  &__billing-cycle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    &-label {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    &-toggle {
      flex-shrink: 0;
    }
  }

  &__plans {
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0;
  }

  &__cta {
    width: 100%;
    position: sticky;
    bottom: 0;
    margin-top: 16px;
    z-index: 101;
  }
}
</style>
