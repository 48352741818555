<script setup>
import {
  usePlans,
  usePlansModal,
} from "@/components/feature/subscribe/composables";
import SubscriptionFamilyPlansBanner from "@/routes/modals/preferences/SubscriptionFamilyPlansBanner.vue";
import SubscriptionFamilyPlansBilling from "@/routes/modals/preferences/SubscriptionFamilyPlansBilling.vue";
import SubscriptionFamilyPlansMembers from "@/routes/modals/preferences/SubscriptionFamilyPlansMembers.vue";

const { activePlan } = usePlans();

const { openPlansModal } = usePlansModal();

const onSwitchPlans = () => openPlansModal("subscribe");
const onUpgradePlan = () => openPlansModal("subscribe");
</script>

<template>
  <div class="subscription-family-plans">
    <SubscriptionFamilyPlansBilling
      :active-plan="activePlan"
      @switch-plans="onSwitchPlans"
    />
    <SubscriptionFamilyPlansMembers
      :active-plan="activePlan"
      v-if="activePlan?.max_members > 1"
    />
    <SubscriptionFamilyPlansBanner
      v-if="!activePlan || $store.getters['settings/getSubscription'].owner"
      :active-plan="activePlan"
      @upgrade-plan="onUpgradePlan"
    />
  </div>
</template>

<style lang="scss">
.subscription-family-plans {
  max-width: 660px;
  padding: 20px;

  @media all and (min-width: 768px) {
    padding: 40px 80px;
  }
}
</style>
