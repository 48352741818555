<template>
  <p class="preferences-paragraph"><slot /></p>
</template>

<style lang="scss">
.preferences-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-70;

  strong {
    font-weight: 600;
  }

  + .preferences-paragraph {
    margin-top: 21px;
  }

  // + .preferences-title {
  //   margin-top: 32px;
  // }
}
</style>
