import { reactive } from "vue";
export const toastState = reactive({
  message: null,
  type: null,
  show: false,
});

const offsetTime = 3.5 * 1000;
let timeout;
function reset() {
  toastState.show = false;

  clearTimeout(timeout);
  timeout = setTimeout(() => {
    toastState.message = null;
    toastState.type = null;
  }, 1000);
}

function showCloak(type, content) {
  toastState.type = type;
  toastState.message = content;
  toastState.show = true;

  clearTimeout(timeout);
  timeout = setTimeout(reset, offsetTime);
}

export default function useToast() {
  function success(message) {
    showCloak("success", message);
  }
  function error(message) {
    showCloak("error", message);
  }
  return {
    success,
    error,
  };
}
