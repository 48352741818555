<script setup>
import { computed, onBeforeUnmount } from "vue";
import { timeTo } from "@/components/feature/onboarding/utils";

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  expires: {
    type: Date,
    required: true,
  },
});

const emit = defineEmits(["input"]);

const updateCountdown = () => emit("input", timeTo(props.expires));

const interval = setInterval(updateCountdown, 1000);
onBeforeUnmount(() => clearInterval(interval));

const formattedCountdown = computed(() => {
  // moment unfortunately will not return more than 24 hours in its format 🙄
  let seconds = Math.floor(props.value / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  seconds = `0${seconds}`.slice(-2);
  minutes = `0${minutes}`.slice(-2);
  hours = `0${hours}`.slice(-2);

  return `${hours}:${minutes}:${seconds}`;
});
</script>

<template>
  <span class="countdown">{{ formattedCountdown }}</span>
</template>

<style lang="scss">
.countdown {
  background: $cloaked-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
