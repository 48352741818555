<script setup>
import ModalTemplate from "@/components/ModalTemplate.vue";
import { computed } from "vue";
import inlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";

const emit = defineEmits(["close"]);

const close = () => {
  store.dispatch("toggleInsuranceModal", false);
};

const showInsuranceModal = computed(() => {
  return store.state.ui.modals.insuranceModal;
});
</script>

<template>
  <ModalTemplate
    :show="showInsuranceModal"
    width="920px"
    withoutBodyPadding
    @close="close"
  >
    <template #body>
      <div class="modal-content">
        <div class="image">
          <img src="@/assets/images/insurance-hero.png" />
        </div>

        <div class="text">
          <div class="header">
            <h2>Now included with your current, paid subscription!</h2>
            <h1><inlineSvg name="user-shield" /> Identity Theft Protection</h1>
          </div>

          <div class="content-area">
            <p>
              Thank you for your loyalty to Cloaked. Now included with your
              current, paid subscription — at no additional cost —
              <strong>Identity Theft Protection</strong>. You now have
              <strong
                >$1 million dollars in comprehensive Identity Theft insurance
                coverage*</strong
              >
              with additional features.
            </p>

            <ul>
              <li>
                <inlineSvg name="checkmark-plain" /> $1 million in Identity
                Theft coverage
              </li>
              <li>
                <inlineSvg name="checkmark-plain" /> Unlimited chat and email
                support
              </li>
              <li>
                <inlineSvg name="checkmark-plain" /> File a claim anytime 24/7
                available
              </li>
              <li>
                <inlineSvg name="checkmark-plain" /> Live consultation with an
                expert
              </li>
            </ul>
          </div>

          <div class="enquiry">
            <div class="numbers">
              <div class="number">
                <h1>
                  Have general inquiries related to Identity Theft Protection?
                </h1>
                <p>Please call 1-877-755-0826</p>
              </div>

              <div class="number">
                <h1>Need to file an Identity Theft Insurance Claim?</h1>
                <p>Please call 1-866-434-3572</p>
              </div>
            </div>

            <div class="terms">
              <a
                href="https://www.cloaked.com/id-theft-protection-terms-and-conditions"
                target="_blank"
                >Cloaked Identity Theft Protection full terms and conditions
                <inlineSvg name="arrow-ne"
              /></a>
              <div>
                *Cloaked Identity Theft Insurance only provided during a
                current, paid subscription offering, and not during the Cloaked
                free trial. Coverage is limited to U.S. individuals, and may not
                be available in all jurisdictions.
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
.modal-content {
  padding: 10px;
  display: flex;
  align-items: center;

  .image {
    width: 415px;
    height: calc(100% - 20px);
    background: linear-gradient(180deg, $black 65%, lighten($black, 8%));
    border-radius: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .text {
    padding: 36px 24px;
    width: calc(100% - 415px);
    margin-left: 415px;

    .header {
      margin-bottom: 17px;

      h2 {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        color: $color-brand-6-100;
        margin-bottom: 12px;
      }

      h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.84px;
        display: flex;
        align-items: center;

        svg {
          width: 35px;
          height: 35px;
          padding: 6px;
          background-color: $color-brand-4-100;
          border-radius: 12px;
          margin-right: 12px;
          color: $white;
        }
      }
    }

    .content-area {
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: $color-primary-70;
        margin-bottom: 16px;
      }

      ul {
        li {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          padding-left: 31px;
          margin-top: 12px;
          position: relative;

          &:first-child {
            margin-top: 0;
          }

          svg {
            position: absolute;
            left: 3px;
            top: 50%;
            width: 20px;
            height: 20px;
            @include transform(translateY(-50%));
            padding: 4px;
            border: 1px solid $color-brand-4-100;
            border-radius: 50%;
            color: $color-brand-4-100;
          }
        }
      }
    }

    .enquiry {
      margin-top: 16px;

      .numbers {
        .number {
          margin-top: 8px;
          background-color: $color-primary-10;
          padding: 16px;
          border-radius: 10px;

          &:first-child {
            margin-top: 0;
          }

          h1 {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            color: $color-primary-100;
            margin-bottom: 4px;
          }

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: $color-primary-100;
          }
        }
      }

      .terms {
        margin-top: 16px;
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $color-primary-70;
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
          &:hover {
            text-decoration: underline;
          }

          svg {
            width: 10px;
            height: 10px;
            color: $color-primary-100;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
