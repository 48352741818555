import "./init";
import "vue-multiselect/dist/vue-multiselect.min.css";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import Multiselect from "vue-multiselect";
import VueObserveVisibility from "vue-observe-visibility";
import { useToast } from "@/hooks";
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/routes/router";
import store from "./store";
import posthog from "posthog-js";
import { getPosthog } from "@/scripts/posthog";

// Importing the global styles
import "@/assets/scss/recursive/_reset.scss";
import "@/assets/scss/recursive/_fonts.scss";

const app = createApp(App);

app.use({
  install(app) {
    const toast = useToast();
    app.$toast = toast;
  },
});

app.use({
  async install(app) {
    if (!global.ENV.VUE_APP_POSTHOG_PROJECT_API_KEY) {
      return;
    }
    const $posthog = await getPosthog();
    window.$posthog = app.$posthog = $posthog;
  },
});
app.use(VueObserveVisibility);
app.use(VueTelInput);

app.component("multiselect", Multiselect);

app.use(router);
app.use(store);
router.isReady().then(() => app.mount("#app"));

store.dispatch("screen/init");
