<script setup>
import {
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  nextTick,
  watch,
  markRaw,
} from "vue";

import store from "@/store";
import { useRoute } from "@/hooks";
import { getPosthog } from "@/scripts/posthog";

import AppCaptcha from "@/components/AppCaptcha.vue";
import HeadlessIframe from "@/routes/guest/HeadlessIframe";
import { stepToComponent } from "./utils";
import DataDeletePageBackground from "@/components/feature/data-delete/DataDeletePageBackground.vue";
import { SubscriptionService } from "@/api";
import { PH_EVENT_USER_CLICKED_DATA_DELETION_SETUP_ACCOUNT_BUTTON } from "@/scripts/posthogEvents";
import DataDeletePage from "@/components/feature/data-delete/DataDeletePage.vue";
import UserService from "@/api/actions/user-service";
import { DATA_DELETE_REQUESTED } from "@/scripts/userFlags";
import {
  useDataDeleteInput,
  useDataDeleteBrokerScan,
  useDataDeleteSessionStorage,
} from "@/components/feature/data-delete/composables";
import { useThemeQueryParameter } from "@/composables";
import DataDeleteScanResults from "@/components/feature/data-delete/DataDeleteScanResults.vue";
import DataDeleteTryAgain from "@/components/feature/data-delete/DataDeleteTryAgain.vue";
import DataDeletePageSearchStateAndCity from "@/components/feature/data-delete/DataDeletePageSearchStateAndCity.vue";
import { posthogCapture } from "@/scripts/posthog.js";

const iframeChannel = ref(null);

const route = useRoute();

useThemeQueryParameter();

const { dataDeleteInputForm } = useDataDeleteInput();

const hasRequiredParams = ref(
  dataDeleteInputForm.value.city && dataDeleteInputForm.value.state
);

const { saveSearchProgressToSessionStorage } =
  useDataDeleteSessionStorage(dataDeleteInputForm);

const error = ref(null);
const step = ref(stepToComponent.StepLoader);

onBeforeMount(() => {
  getPosthog().then((posthog) => {
    posthog?.identify(route?.query?.vid);
    posthog?.startSessionRecording();
  });
});

onBeforeUnmount(() => {
  getPosthog().then((posthog) => {
    posthog?.stopSessionRecording();
  });
});

function setStep(value) {
  step.value = value;
}

const hasToken = computed(
  () => !!store.state.authentication.guest?.access_token
);

watch(
  () => hasToken.value && hasRequiredParams.value,
  (value) => {
    if (value && initialized.value) {
      nextTick(() => {
        performSearch();
        SubscriptionService.getSubscription();
      });
    }
  }
);

const { progress, records, initiateScan, hasScanError } =
  useDataDeleteBrokerScan();

function performSearch() {
  initiateScan(dataDeleteInputForm.value);
  step.value = markRaw(DataDeleteScanResults);
}

const initialized = ref(false);
onMounted(() => {
  store.dispatch("authentication/setGuestToken", null);
  nextTick(() => {
    initialized.value = true;
  });
});

const captcha = ref({});
const showCaptcha = ref(null);

const iframe = computed(() => iframeChannel.value);

const isIframeReady = ref(false);

watch(
  [
    () => showCaptcha.value,
    () => iframe.value,
    () => captcha.value,
    () => isIframeReady.value,
  ],
  ([showCaptchaValue, iframeValue, captchaValue, isIframeReadyValue]) => {
    if (iframeValue && isIframeReadyValue) {
      if (showCaptchaValue === true) {
        if (captchaValue?.token) {
          iframeChannel.value.getAccount(captchaValue.token);
          return;
        }
        return;
      } else if (showCaptchaValue === false) {
        /* Only do this if captcha is not required (false), not null */
        iframeChannel.value.getAccount("-");
      }
    }
    return;
  }
);

function setupUser(payload) {
  iframeChannel.value.setupUser(payload);
}

const isSubscribed = computed(() => {
  return store.getters["settings/isSubscribed"];
});

const storeSearchProgressInSessionStorage = () => {
  saveSearchProgressToSessionStorage();

  posthogCapture(PH_EVENT_USER_CLICKED_DATA_DELETION_SETUP_ACCOUNT_BUTTON);
};

function setSetup() {
  storeSearchProgressInSessionStorage();

  if (isSubscribed.value) {
    step.value = stepToComponent.StepUsername;
  } else {
    step.value = stepToComponent.StepPayment;
  }
}

const contactInformation = ref(null);

function setPaid(payload = {}) {
  contactInformation.value = payload.contactInformation ?? null;

  step.value = stepToComponent.StepUsername;
}
function setSearchStep(value) {
  searchStep.value = value;
}

const compRef = ref(null);

function handleError(message) {
  error.value = message;
  if (message && compRef.value.hasError) {
    compRef.value.hasError();
  }
}

const headlessUser = ref(null);
watch(
  () => headlessUser.value,
  (value) => {
    if (value) {
      UserService.setFlag({
        name: DATA_DELETE_REQUESTED,
        value: true,
      });
    }
  }
);
</script>

<template>
  <DataDeletePage>
    <DataDeletePageBackground />
    <AppCaptcha
      v-model="captcha"
      v-if="showCaptcha === true && isIframeReady && iframe"
    />
    <DataDeleteTryAgain v-if="hasScanError" />
    <DataDeletePageSearchStateAndCity
      v-else-if="!hasRequiredParams"
      :value="dataDeleteInputForm"
      @input="dataDeleteInputForm = $event"
      @submit="hasRequiredParams = true"
    />
    <Transition v-else mode="out-in" appear>
      <Component
        :is="step"
        class="data-delete__page"
        :phone="dataDeleteInputForm.phone"
        :value="dataDeleteInputForm"
        @setStep="setStep"
        @input="dataDeleteInputForm = $event"
        @setSearchStep="setSearchStep"
        @setup="setSetup"
        @complete="setSetup"
        @setUser="setupUser"
        :hasToken="hasToken"
        :error="error"
        :isSubscribed="isSubscribed"
        :headlessUser="headlessUser"
        @subscribed="setPaid"
        ref="compRef"
        :defaultCredentials="contactInformation"
        :progress="progress"
        :records="records"
      />
    </Transition>
    <HeadlessIframe
      ref="iframeChannel"
      @error="handleError"
      @iframe-ready="isIframeReady = true"
      @user-ready="headlessUser = $event"
      @show-captcha="showCaptcha = $event"
    />
  </DataDeletePage>
</template>
