<script setup>
import { useAttrs } from "vue";
// TODO: make this one more flexible
import Popper from "@/components/Popper.vue";
import ChevronTooltip from "@/assets/icons/chevron-tooltip.svg";
const attrs = useAttrs();
</script>

<template>
  <Popper
    v-bind="attrs"
    width="250px"
    placement="left"
    :offset-along="12"
    :offset-away="16"
  >
    <slot />
    <template #content>
      <div class="app-tooltip-intro__content">
        <slot name="content" />
        <ChevronTooltip class="app-tooltip-intro__chevron" />
      </div>
    </template>
  </Popper>
</template>

<style lang="scss">
.app-tooltip-intro {
  &__content {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.2px;
    padding: 12px 16px;
    background-color: $color-primary-100;
    color: $color-primary-0;
    border-radius: 8px;

    button {
      background: none;
      padding: 0;
      border: none;
      margin-top: 8px;
      text-decoration: underline;
      color: $color-primary-0;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__chevron {
    color: $color-primary-100;
    position: absolute;
    left: 100%;
    top: 22px;
  }
}
</style>
