<script setup>
import Button from "@/components/Button.vue";
import InlineSvg from "@/components/InlineSvg.vue";
import { email as isValidEmail } from "@/scripts/validation";
import { computed, ref } from "vue";
import AppFormError from "@/components/AppFormError.vue";
import AppFormInput from "@/components/AppFormInput.vue";
import { useWindowSize } from "@vueuse/core";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { width } = useWindowSize();
const isMobile = computed(() => width.value <= 768);

const emit = defineEmits(["submit", "update:modelValue"]);

const validationError = ref(null);

const error = computed(() => validationError.value);

const onSubmit = async () => {
  validationError.value = !isValidEmail(props.modelValue)
    ? "Please provide a valid email!"
    : null;

  if (!validationError.value) {
    emit("submit", props.modelValue);
  }
};
</script>

<template>
  <AppFormInput
    :value="modelValue"
    @input="$emit('update:modelValue', $event)"
    type="email"
    :placeholder="
      isMobile ? 'Enter email address' : 'Enter a family member’s email address'
    "
    @keydown.enter="onSubmit"
    :error="!!error"
    class="subscription-invite"
  >
    <template #after-input>
      <Button
        class="subscription-invite__send"
        @click="onSubmit"
        :disabled="!modelValue || isLoading"
      >
        <template v-if="isLoading">
          Inviting
          <InlineSvg name="spinner" class="subscription-invite__icon" />
        </template>
        <template v-else>
          Invite
          <InlineSvg
            name="data-delete/send"
            class="subscription-invite__icon"
          />
        </template>
      </Button>
    </template>
    <template #after>
      <AppFormError v-if="error">
        {{ error }}
      </AppFormError>
    </template>
  </AppFormInput>
</template>

<style lang="scss">
.subscription-invite {
  .app-form-input__input {
    padding-right: 130px;
  }

  & &__send {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%) !important;

    @media (min-width: 700px) {
      right: 20px;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}
</style>
