<script setup>
import { ModalX } from "@/assets/icons";
import * as colors from "@/assets/scss/recursive/_colors-exported.scss";

import { useAttrs } from "vue";

const props = defineProps({
  isAbsolute: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    // not actually white don't worry, this is a scss var
    default: colors.primaryWhiteLt,
  },
});
const attrs = useAttrs();

const emit = defineEmits(["close", "input"]);

function onClose() {
  emit("close");
  emit("input", false);
}
</script>

<template>
  <button v-bind="attrs" @click="onClose" class="app-close-button">
    <ModalX
      class="app-modal-close"
      :class="{ 'app-modal-close--absolute': props.isAbsolute }"
      :style="{ color: props.color }"
      @click="onClose"
    />
  </button>
</template>

<style lang="scss">
.app-close-button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
}
.app-modal-close {
  padding: 24px;
  box-sizing: content-box;
  cursor: pointer;
  color: $color-primary-100;

  &:hover {
    opacity: 0.8;
  }

  &--absolute {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
