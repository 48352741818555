<script setup>
import { computed } from "vue";

const props = defineProps({
  tooltipData: {
    type: Object,
    validator: (value) => {
      if (value === null) return true;
      return (
        value.hasOwnProperty("formattedDate") &&
        typeof value.formattedDate === "string" &&
        Array.isArray(value.data) &&
        value.data.every(
          (item) =>
            typeof item.label === "string" &&
            typeof item.value === "number" &&
            typeof item.color === "string"
        )
      );
    },
  },
  tooltipLeft: {
    type: Number,
    required: true,
  },
});

const totalRecordsRemoved = computed(() => {
  return props.tooltipData?.data.reduce((acc, item) => acc + item.value, 0);
});
</script>

<template>
  <div class="data-removal-graph_tooltip" :style="{ left: tooltipLeft + 'px' }">
    <div class="tooltip-records-removed">
      {{ totalRecordsRemoved }}
      {{ totalRecordsRemoved == 1 ? "record" : "records" }} removed
    </div>
    <div class="tooltip-header">as of {{ tooltipData?.formattedDate }}</div>
    <div
      v-for="(item, index) in tooltipData?.data"
      :key="index"
      class="tooltip-item"
    >
      <div
        class="tooltip-color-indicator"
        :style="{ backgroundColor: item.color }"
      ></div>
      <div>{{ item.value }} {{ item.label }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.data-removal-graph_tooltip {
  position: relative;
  background-color: $color-surface;
  border: 1px solid $color-primary-10;
  color: $color-primary-100;
  padding: 10px;
  box-shadow: 0 0 10px rgba($black, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.tooltip-header {
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 8px;
}

.tooltip-records-removed {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 2px;
}

.tooltip-item {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
}

.tooltip-color-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
