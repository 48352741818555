<template>
  <div class="app-modal-custom-wrapper">
    <slot />
  </div>
</template>

<style lang="scss">
.app-modal-custom-wrapper {
  padding: 0 32px;
}
</style>
