<script setup>
import store from "@/store";
import { onBeforeMount } from "vue";

function upgradeModal() {
  store.dispatch("subscription/openSubscriptionModal");
}

onBeforeMount(() => store.dispatch("subscription/init"));
</script>

<template>
  <section>
    <span
      >Your free trial has expired.<a @click="upgradeModal"
        >Subscribe today</a
      ></span
    >
  </section>
</template>

<style lang="scss" scoped>
section {
  height: 40px;
  width: 100%;
  background: $cloaked-gradient;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $white;

    a {
      color: $white;
      font-weight: 600;
      text-decoration: underline;
      margin-left: 12px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
