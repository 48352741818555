<script setup>
import { lowerCase } from "lodash-es";
import { Eye, EyeSlash, ChevronDown } from "@/assets/icons";
import SectionList from "@/components/SectionList.vue";
import UiMenu from "@/components/UiMenu/UiMenu.vue";

import { reactive, computed, watch, ref } from "vue";

const emit = defineEmits(["input", "option", "save", "focus", "blur"]);

const props = defineProps({
  value: {
    type: [String, Number],
    default: "",
  },
  pattern: RegExp,
  max: Number,
  label: String,
  placeholder: String,
  format: Function,
  type: {
    type: String,
    default: "text",
  },
  options: {
    type: Array,
    default: null,
  },
  error: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const input = ref(null);

const state = reactive({
  passwordIsVisible: false,
  menuIsVisible: false,
  innerValue: "",
  active: 0,
  hasChanges: false,
});

const innerValueDisplay = computed(() => {
  if (props.format) {
    return props.format(state.innerValue);
  }
  return state.innerValue;
});

const inputs = computed(() => {
  return document.getElementsByClassName("pref-input-items");
});

const hasActions = computed(() => {
  return props.type === "password";
});

const hasMenu = computed(() => {
  return props.options && props.options.length;
});

const visibleOptions = computed(() => {
  if (!props.options) {
    return [];
  }

  return props.options
    .filter((option) => {
      if (!state.hasChanges) {
        return true;
      }

      const a = lowerCase(option);
      const b = lowerCase(state.innerValue);

      return a.includes(b);
    })
    .map((o) => ({ title: o }));
});

function findIndex(target) {
  return [].findIndex.call(inputs.value, (e) => e === target);
}
function moveFocus(index) {
  if (inputs.value[index]) {
    inputs.value[index].focus();
  }
}
function moveNext(event) {
  const index = findIndex(event.target);
  moveFocus(index + 1);
}
function movePrev(event) {
  const index = findIndex(event.target);
  moveFocus(index - 1);
}
function handleKeydown(event) {
  if (props.pattern && event.key && !event.metaKey) {
    if (!event.key.match(props.pattern)) {
      event.preventDefault();
      return false;
    }
  }
}
function handleInput(event) {
  state.hasChanges = true;

  if (hasMenu.value && !state.menuIsVisible) {
    state.menuIsVisible = true;
  }
  let value = event.target.value;
  if (props.max) {
    value = value.slice(0, props.max);
  }
  emit("input", value);
  state.innerValue = value;
}

function togglePasswordReveal() {
  state.passwordIsVisible = !state.passwordIsVisible;
}

function handleFocus() {
  emit("focus");
  if (hasMenu.value) {
    state.menuIsVisible = true;
  }
}

function handleBlur() {
  state.menuIsVisible = false;
  emit("blur");
}

function handleEnter(event) {
  if (hasMenu.value && visibleOptions.value.length) {
    emit("option", visibleOptions.value[state.active].title);

    state.menuIsVisible = false;
  }

  if (hasMenu.value && !visibleOptions.value.length) {
    emit("option", state.innerValue);
  }
  const index = findIndex(event.target);
  if (index === inputs.value.length - 1) {
    emit("save");
  } else {
    moveNext(event);
  }
}

function handleSetActive(index) {
  state.active = index;
}

function handleSelect(item) {
  emit("option", item.title);
  state.menuIsVisible = false;
}

function handleDown() {
  const max = visibleOptions.value.length - 1;
  state.active += 1;

  if (state.active > max) {
    state.active = max;
  }
}

function handleUp() {
  state.active -= 1;

  if (state.active < 0) {
    state.active = 0;
  }
}

function handleEsc() {
  state.menuIsVisible = false;
  state.innerValue = props.value;
  state.hasChanges = false;
}

watch(
  () => props.type,
  () => {
    state.passwordIsVisible = false;
  },
  { deep: true }
);

watch(
  () => props.value,
  () => {
    state.innerValue = props.value;
    state.hasChanges = false;
  },
  { immediate: true, deep: true }
);

watch(
  () => visibleOptions.value,
  () => {
    state.active = 0;
  },
  { deep: true }
);
</script>

<template>
  <div
    class="preferences-input"
    :class="{
      'preferences-input--error': props.error,
      'preferences-input--has-actions': hasActions,
    }"
  >
    <label>{{ props.label }}</label>

    <div class="preferences-input__wrapper">
      <input
        ref="input"
        class="pref-input-items"
        :placeholder="props.placeholder"
        :readonly="props.readonly"
        :disabled="props.disabled"
        autocomplete="off"
        data-lpignore="true"
        data-form-type="other"
        :type="
          props.type === 'password' && !state.passwordIsVisible
            ? 'password'
            : 'text'
        "
        @input="handleInput"
        :value="innerValueDisplay"
        @keypress="handleKeydown"
        @keydown.esc.stop="handleEsc"
        @keydown.enter="handleEnter"
        @keydown.down="handleDown"
        @keydown.up="handleUp"
        @focus="handleFocus"
        @blur="handleBlur"
        :maxlength="props.max"
        @keydown.prevent.tab.exact="moveNext"
        @keydown.prevent.shift.tab="movePrev"
      />
      <div
        v-if="props.type === 'password' || $slots.actions || hasMenu"
        class="preferences-input__actions"
      >
        <slot name="actions" />

        <button
          v-if="props.type === 'password'"
          class="preferences-input__password-reveal"
          @click="togglePasswordReveal"
        >
          <EyeSlash v-if="state.passwordIsVisible" />
          <Eye v-else />
        </button>
        <button
          v-if="hasMenu"
          class="preferences-input__down-chevron"
          @click="state.menuIsVisible = true"
        >
          <ChevronDown />
        </button>
      </div>

      <UiMenu
        v-if="hasMenu"
        :value="state.menuIsVisible && !!visibleOptions.length"
        placement="bottom-start"
        width="300px"
        height="350px"
        @close="state.menuIsVisible = false"
        :has-click-outside-close="false"
      >
        <template #content>
          <SectionList
            :items="visibleOptions"
            :active="state.active"
            @setActive="handleSetActive"
            @select="handleSelect"
          />
        </template>
      </UiMenu>
    </div>
    <div v-if="props.error && props.errorMessage" class="errorMessage">
      {{ props.errorMessage }}
    </div>
  </div>
</template>

<style lang="scss">
.preferences-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  // --inner-border-color: none;
  // --outer-border-color: $color-background;
  --input-padding: 16px;
  --actions-width: 60px;

  &__wrapper {
    width: 100%;
    height: 60px;
    position: relative;
  }

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-100;
    display: inline-block;
    margin-bottom: 5px;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .hidden_custom_input_cloaked {
    height: 1px;
    opacity: 0.01;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
  }
  input {
    height: 60px;
    width: 100%;
    background: $color-primary-5;
    border: 2px solid $color-primary-5;
    border-radius: 10px;
    padding: 0 var(--input-padding);
    box-shadow: inset 0px 0px 0px 1px var(--inner-border-color);
    color: $color-primary-100;
  }
  .preferences-input__wrapper:focus-within {
    input {
      outline: none;
      --inner-border-color: $color-primary-100;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    width: var(--actions-width);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__password-reveal {
    background: none;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    // padding: 0;

    svg {
      width: 28px;
      height: 28px;
      color: $color-primary-100;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__down-chevron {
    background: none;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    // padding: 0;

    svg {
      width: 20px;
      height: 20px;
      color: $color-primary-100;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &--has-actions {
    input {
      padding-right: calc(var(--input-padding) + var(--actions-width));
    }
  }

  &--error {
    input {
      outline: none;
      border: 1px solid $color-alert;
      color: $color-alert;
    }

    input {
      &:focus {
        border: 1px solid $color-alert;
      }
    }
    .errorMessage {
      color: $color-alert;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 4px;
      height: 18px;
      position: relative;
      bottom: -4px;
    }
  }

  + .preferences-input {
    margin-top: 13px;
  }
}
</style>
