<script setup>
import { computed, markRaw } from "vue";
import { useRoute, useToast } from "@/hooks";
import store from "@/store";
import InformationHeader from "./InformationHeader.vue";
import DetailSection from "./DetailSection.vue";
import CardsServices from "@/api/actions/cards-services";
import IdentityService from "@/api/actions/identity-service";
import PatchCurrentFundingSource from "@/components/modals/Wallet/PatchCurrentFundingSource.vue";

const route = useRoute();

const toast = useToast();

const card = computed(() => {
  if (route.params.id && store.state.cards?.cards?.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  }
  return {};
});

function identity(id) {
  const identity = store.state.localdb.db_cloaks.find((item) => item.id === id);
  return identity;
}

function toggleMerchantLock() {
  const newLockedValue = !card.value.is_merchant_locked;

  store.dispatch("updateCard", {
    ...card.value,
    is_merchant_locked: newLockedValue,
  });

  CardsServices.patchUpdateCloakedCardDetails(card.value.id, {
    is_merchant_locked: newLockedValue,
  })
    .then((response) => {
      IdentityService.patchIdentityUpdatedAt(card.value.identity_id);
      if (response.data.is_merchant_locked) {
        toast.success("Merchant locking enabled");
      } else {
        toast.success("Merchant locking disabled");
      }
    })
    .catch((error) => {
      toast.error(error.response.data.detail);
      store.dispatch("updateCard", {
        ...card.value,
        is_merchant_locked: !newLockedValue,
      });
    });
}

function lockCard() {
  store.dispatch("updateCard", { ...card.value, state: "locked_by_user" });

  CardsServices.lockCard(card.value.identity_id, card.value.id)
    .then((response) => {
      toast.success("Card locked");
    })
    .catch((error) => {
      toast.error(error.response.data.detail);
      store.dispatch("updateCard", { ...card.value, state: "unlocked" });
    });
}

function unlockCard() {
  store.dispatch("updateCard", { ...card.value, state: "unlocked" });

  CardsServices.unlockCard(card.value.identity_id, card.value.id)
    .then((response) => {
      toast.success("Card unlocked");
    })
    .catch((error) => {
      toast.error(error.response.data.detail);
      store.dispatch("updateCard", { ...card.value, state: "unlocked" });
    });
}

function toggleCardLock() {
  const state = card.value.state;

  if (state === "locked_by_user" || state === "locked_by_system") {
    unlockCard();
  } else {
    lockCard();
  }
}

const currentCard = computed(() => {
  if (route.params.id && store.state.cards.cards.results) {
    return store.state.cards.cards.results.find(
      (card) => card.id === route.params.id
    );
  } else {
    return "";
  }
});

const fundingSources = computed(() => {
  return store.state.cards.fundingSources.results;
});

const currentCardFundingSource = computed(() => {
  if (currentCard.value?.funding_source) {
    return fundingSources.value.find(
      (source) => source.id === currentCard.value.funding_source
    );
  } else {
    return null;
  }
});

const currentCardProvider = computed(() => {
  return currentCardFundingSource.value?.provider;
});

const fundingSourcesFiltered = computed(() => {
  return store.state.cards.fundingSources.results.filter(
    (source) => source.provider === currentCardProvider.value
  );
});

const openFundingSources = () => {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(PatchCurrentFundingSource),
      props: {
        isVisible: true,
        sources: fundingSourcesFiltered.value,
        currentSource: currentCardFundingSource.value,
        currentCardID: currentCard.value.id,
      },
    },
  });
};

const subtext = computed(() => {
  const pan = "•••• " + currentCardFundingSource.value?.pan_last_four;
  const nickname = currentCardFundingSource.value?.nickname;

  if (pan && nickname) {
    return `${pan} • ${nickname}`;
  } else if (currentCardFundingSource.value?.pan_last_four) {
    return pan;
  } else {
    return null;
  }
});

const loading = computed(() => {
  if (!currentCardFundingSource.value) {
    return true;
  } else {
    return false;
  }
});

function lastFourDigits() {
  const pan = currentCardFundingSource.value?.pan_last_four;
  if (pan) {
    return pan.slice(-4);
  } else {
    return null;
  }
}

const cardState = computed(() => {
  const state = card.value.state;
  if (state === "locked_by_user" || state === "locked_by_system") {
    return true;
  } else {
    return false;
  }
});
</script>

<template>
  <div>
    <InformationHeader :information="card" />

    <div class="details">
      <DetailSection
        title="Lock card"
        description="Disable all transactions while card is locked"
        icon="lock-filled"
        multiLine
        toggle
        :toggleStatus="cardState"
        @toggleClicked="toggleCardLock"
        :disabled="card.state === 'locked_by_system'"
      />

      <DetailSection
        title="Merchant locking"
        description="Lock this card to a single merchant after the first transaction"
        icon="merchant-lock"
        multiLine
        toggle
        :toggleStatus="card.is_merchant_locked"
        @toggleClicked="toggleMerchantLock"
      />

      <div class="sources">
        <DetailSection
          :loading="loading"
          icon="bank"
          :description="
            currentCardFundingSource?.card_brand + '\n' + subtext || 'Unknown'
          "
          multiLine
          title="Funding Source"
          @click="openFundingSources"
          link
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.details {
  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sources {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
