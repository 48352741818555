<template>
  <svg
    width="172"
    height="25"
    viewBox="0 0 172 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="onboarding-page-logo"
  >
    <path
      d="M7.91248 24.4958C7.91248 24.4958 -1.83649 20.9137 0.308286 17.1326C1.67314 14.7445 4.79281 17.3316 9.66729 19.9186L7.91248 24.4958Z"
      fill="currentColor"
    />
    <path
      d="M19.8062 23.3015C27.2154 23.8985 30.4967 20.5392 31.2766 19.5442C31.2766 19.5442 26.8533 13.8963 24.2465 10.1076C22.4714 7.52763 20.1968 3.85944 18.8953 1.7302C18.4322 0.972557 17.3269 1.00404 16.8546 1.7557C12.0168 9.45378 3.10845 15.2244 1.11133 16.3363C2.69066 15.6796 5.57266 17.7293 9.47225 19.9184C9.47225 19.9184 12.3697 21.4291 14.3309 22.1273C16.8462 23.0229 19.8062 23.3015 19.8062 23.3015Z"
      fill="#FF550C"
    />
    <path
      d="M39.1707 15.3358C39.1707 13.4987 39.5635 11.8965 40.3491 10.5294C41.1347 9.14085 42.2233 8.07276 43.6149 7.3251C45.0066 6.55607 46.6003 6.17156 48.3959 6.17156C50.7079 6.17156 52.6158 6.72697 54.1196 7.83778C55.646 8.92724 56.6673 10.4653 57.1835 12.4519H52.0995C51.8302 11.6829 51.37 11.0848 50.7191 10.6575C50.0906 10.2089 49.305 9.98465 48.3623 9.98465C47.0155 9.98465 45.9493 10.4546 45.1637 11.3945C44.3781 12.3131 43.9853 13.6268 43.9853 15.3358C43.9853 17.0234 44.3781 18.3371 45.1637 19.277C45.9493 20.1956 47.0155 20.6549 48.3623 20.6549C50.2702 20.6549 51.5159 19.8431 52.0995 18.2196H57.1835C56.6673 20.1422 55.646 21.6696 54.1196 22.8017C52.5933 23.9339 50.6854 24.5 48.3959 24.5C46.6003 24.5 45.0066 24.1262 43.6149 23.3785C42.2233 22.6095 41.1347 21.5414 40.3491 20.1742C39.5635 18.7857 39.1707 17.1729 39.1707 15.3358Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M64.2982 0.5V24.2116H59.5845V0.5H64.2982Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M76.1857 24.5C74.39 24.5 72.7739 24.1262 71.3373 23.3785C69.9008 22.6095 68.7673 21.5307 67.9368 20.1422C67.1287 18.7537 66.7247 17.1515 66.7247 15.3358C66.7247 13.52 67.14 11.9179 67.9705 10.5294C68.8234 9.14085 69.9794 8.07276 71.4384 7.3251C72.8973 6.55607 74.5247 6.17156 76.3203 6.17156C78.116 6.17156 79.7434 6.55607 81.2023 7.3251C82.6613 8.07276 83.8061 9.14085 84.6366 10.5294C85.4895 11.9179 85.916 13.52 85.916 15.3358C85.916 17.1515 85.4783 18.7537 84.6029 20.1422C83.75 21.5307 82.5828 22.6095 81.1013 23.3785C79.6423 24.1262 78.0038 24.5 76.1857 24.5ZM76.1857 20.5908C77.0386 20.5908 77.8354 20.3985 78.5762 20.014C79.3393 19.6081 79.9454 19.01 80.3943 18.2196C80.8432 17.4292 81.0677 16.468 81.0677 15.3358C81.0677 13.6482 80.5963 12.3558 79.6536 11.4586C78.7333 10.5401 77.5998 10.0808 76.253 10.0808C74.9063 10.0808 73.7727 10.5401 72.8525 11.4586C71.9546 12.3558 71.5057 13.6482 71.5057 15.3358C71.5057 17.0234 71.9434 18.3264 72.8188 19.245C73.7166 20.1422 74.8389 20.5908 76.1857 20.5908Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M87.1094 15.2717C87.1094 13.4773 87.4797 11.8858 88.2205 10.4973C88.9836 9.10881 90.0049 8.04072 91.2843 7.29306C92.5862 6.54539 94.034 6.17156 95.6276 6.17156C97.0193 6.17156 98.2313 6.43858 99.2639 6.97263C100.319 7.50668 101.161 8.17957 101.789 8.99132V6.45995H106.536V24.2116H101.789V21.6162C101.183 22.4493 100.341 23.1435 99.2639 23.6989C98.2089 24.233 96.9856 24.5 95.5939 24.5C94.0227 24.5 92.5862 24.1155 91.2843 23.3465C90.0049 22.5774 88.9836 21.4987 88.2205 20.1101C87.4797 18.7003 87.1094 17.0875 87.1094 15.2717ZM101.789 15.3358C101.789 14.2463 101.565 13.3171 101.116 12.5481C100.667 11.7577 100.061 11.1595 99.2975 10.7537C98.5344 10.3264 97.7151 10.1128 96.8397 10.1128C95.9643 10.1128 95.1563 10.3158 94.4155 10.7216C93.6748 11.1275 93.0688 11.7256 92.5974 12.516C92.1485 13.285 91.924 14.2036 91.924 15.2717C91.924 16.3398 92.1485 17.2797 92.5974 18.0915C93.0688 18.8818 93.6748 19.4907 94.4155 19.9179C95.1787 20.3451 95.9868 20.5587 96.8397 20.5587C97.7151 20.5587 98.5344 20.3558 99.2975 19.9499C100.061 19.5227 100.667 18.9246 101.116 18.1555C101.565 17.3652 101.789 16.4252 101.789 15.3358Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M121.131 24.2116L114.802 16.6495V24.2116H110.088V0.5H114.802V13.99L121.064 6.45995H127.192L118.976 15.3678L127.259 24.2116H121.131Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M146.037 14.9513C146.037 15.5921 145.992 16.1689 145.903 16.6816H132.267C132.379 17.9633 132.85 18.9673 133.681 19.6936C134.511 20.4199 135.533 20.783 136.745 20.783C138.495 20.783 139.741 20.0674 140.482 18.6362H145.566C145.027 20.3451 143.995 21.755 142.468 22.8658C140.942 23.9553 139.068 24.5 136.846 24.5C135.05 24.5 133.434 24.1262 131.997 23.3785C130.583 22.6095 129.472 21.5307 128.664 20.1422C127.879 18.7537 127.486 17.1515 127.486 15.3358C127.486 13.4987 127.879 11.8858 128.664 10.4973C129.45 9.10881 130.55 8.04072 131.964 7.29306C133.378 6.54539 135.005 6.17156 136.846 6.17156C138.619 6.17156 140.201 6.53471 141.593 7.26101C143.007 7.98732 144.096 9.02336 144.859 10.3692C145.645 11.6936 146.037 13.221 146.037 14.9513ZM141.155 13.6696C141.133 12.516 140.695 11.5975 139.842 10.9139C138.989 10.2089 137.946 9.85648 136.711 9.85648C135.544 9.85648 134.556 10.1983 133.748 10.8818C132.963 11.5441 132.48 12.4733 132.3 13.6696H141.155Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M147.214 15.2717C147.214 13.4773 147.584 11.8858 148.325 10.4973C149.088 9.10881 150.12 8.04072 151.422 7.29306C152.724 6.54539 154.172 6.17156 155.766 6.17156C156.978 6.17156 158.134 6.4279 159.233 6.94059C160.333 7.43191 161.209 8.09412 161.86 8.92724V0.5H166.641V24.2116H161.86V21.5841C161.276 22.4599 160.457 23.1649 159.402 23.6989C158.347 24.233 157.124 24.5 155.732 24.5C154.161 24.5 152.724 24.1155 151.422 23.3465C150.12 22.5774 149.088 21.4987 148.325 20.1101C147.584 18.7003 147.214 17.0875 147.214 15.2717ZM161.893 15.3358C161.893 14.2463 161.669 13.3171 161.22 12.5481C160.771 11.7577 160.165 11.1595 159.402 10.7537C158.639 10.3264 157.819 10.1128 156.944 10.1128C156.069 10.1128 155.261 10.3158 154.52 10.7216C153.779 11.1275 153.173 11.7256 152.702 12.516C152.253 13.285 152.028 14.2036 152.028 15.2717C152.028 16.3398 152.253 17.2797 152.702 18.0915C153.173 18.8818 153.779 19.4907 154.52 19.9179C155.283 20.3451 156.091 20.5587 156.944 20.5587C157.819 20.5587 158.639 20.3558 159.402 19.9499C160.165 19.5227 160.771 18.9246 161.22 18.1555C161.669 17.3652 161.893 16.4252 161.893 15.3358Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
    <path
      d="M170.134 0.5C170.346 0.5 170.536 0.546659 170.704 0.639977C170.872 0.729838 171.004 0.85599 171.099 1.01843C171.194 1.17742 171.242 1.35714 171.242 1.5576C171.242 1.75806 171.194 1.93951 171.099 2.10196C171.004 2.2644 170.872 2.39228 170.704 2.4856C170.536 2.57546 170.346 2.62039 170.134 2.62039C169.918 2.62039 169.726 2.57546 169.558 2.4856C169.39 2.39228 169.258 2.2644 169.163 2.10196C169.068 1.93951 169.021 1.75806 169.021 1.5576C169.021 1.35714 169.068 1.17742 169.163 1.01843C169.258 0.85599 169.39 0.729838 169.558 0.639977C169.726 0.546659 169.918 0.5 170.134 0.5ZM170.134 2.41301C170.397 2.41301 170.609 2.33352 170.77 2.17454C170.934 2.01555 171.017 1.80991 171.017 1.5576C171.017 1.3053 170.934 1.09965 170.77 0.940667C170.609 0.781681 170.397 0.702188 170.134 0.702188C169.867 0.702188 169.653 0.781681 169.492 0.940667C169.335 1.09965 169.256 1.3053 169.256 1.5576C169.256 1.80991 169.335 2.01555 169.492 2.17454C169.653 2.33352 169.867 2.41301 170.134 2.41301ZM170.627 1.33986C170.627 1.42281 170.604 1.49366 170.556 1.55242C170.512 1.60772 170.45 1.64746 170.37 1.67166L170.677 2.10196L170.331 2.10714L170.068 1.69239H169.975V2.10714H169.69V0.992511H170.216C170.34 0.992511 170.439 1.02362 170.512 1.08583C170.589 1.14804 170.627 1.23272 170.627 1.33986ZM169.975 1.46947H170.2C170.24 1.46947 170.273 1.4591 170.298 1.43836C170.328 1.41762 170.342 1.38825 170.342 1.35023C170.342 1.31221 170.328 1.28456 170.298 1.26728C170.273 1.24654 170.24 1.23617 170.2 1.23617H169.975V1.46947Z"
      fill="currentColor"
      class="onboarding-page-logo__text"
    />
  </svg>
</template>
