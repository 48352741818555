<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";
function downloadMobile() {
  store.dispatch("toggleMobileAppModal", true);
}
</script>
<template>
  <section class="mobile-section">
    <img
      src="@/assets/images/logo-tile.png"
      alt="Cloaked Logo Tile"
      height="89"
      class="icon"
    />
    <h1>Download Cloaked for iOS and Android</h1>
    <h2>Hide your identity and inboxes anywhere you go.</h2>
    <button @click="downloadMobile" class="button">
      Download
      <inlineSvg name="arrow-down" />
    </button>
  </section>
</template>
<style lang="scss" scoped>
.mobile-section {
  color: $color-primary-100;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid $color-primary-10;
  .icon {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  h2 {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
  }
  .button {
    cursor: pointer;
    margin-top: 24px;
    display: flex;
    height: 40px;
    padding: 11px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 999px;
    border: 1px solid $color-primary-100;
    background-color: transparent;
    color: $color-primary-100;
    > svg {
      height: 16px;
      width: 16px;
    }
  }
}
</style>
