<script setup>
import { ref } from "vue";

const faqs = ref([
  {
    question: "What is a Data Broker?",
    answer: [
      "Data brokers are websites that buy and sell personal, identifiable information about you and people you know. They will most often do this without your consent.",
      "There are several risks with a data broker possessing your personal information (records): identity theft, financial fraud, targeted advertising, and more.",
    ],
  },
  {
    question: "What is a record?",
    answer: [
      "A record can be your name, email address(es), phone number(s), address(es), or a relative(s). We submit requests to have this data removed on your behalf. Some data brokers may have more than one profile on you with multiple records within each.",
      "Cloaked calculates your individual pieces of information found on a data broker to display your Total Records Removed number.",
    ],
  },
  {
    question: "How does Cloaked remove my data?",
    answer: [
      "We submit requests to have this data removed on your behalf. Some data brokers may have more than one profile on you with multiple records within each.",
      "In some cases, you will need to manually remove your data from a broker from the Action Required section, which could impact your Estimated Completion date.",
    ],
  },
  {
    question: "How long does it take to delete my data?",
    answer: [
      "Cloaked adds the total estimated removal time per data broker to provide an Estimated Completion Date.",
      "In some cases, you will need to manually remove your data from a broker from the Action Required section, which could impact your Estimated Completion date.",
      "In essence, the time it takes to delete your data completely varies but you can expect the majority of your data across the data brokers we scan to be removed within approximately 45 days.",
    ],
  },
  {
    question: "How do I fix wrong information or add additional information?",
    answer: [
      "Reach out to support at support@cloaked.app and let us know what needs to be changed. We'll do our best to accommodate changes or additions.",
    ],
  },
]);

const activeIndex = ref(null);

function toggleAnswer(index) {
  activeIndex.value = activeIndex.value === index ? null : index;
}
</script>

<template>
  <div class="faq-section">
    <div
      v-for="(faq, index) in faqs"
      :key="index"
      class="faq-item"
      @click="toggleAnswer(index)"
    >
      <div class="faq-text-wrapper">
        <div class="faq-question">
          <span class="icon">{{ activeIndex === index ? "-" : "+" }}</span>
          <span>{{ faq.question }}</span>
        </div>
        <div
          class="faq-answer-wrapper"
          :class="{ opened: activeIndex === index }"
        >
          <div class="faq-answer" :class="{ opened: activeIndex === index }">
            <p v-for="(answer, index) in faq.answer" :key="index">
              <span
                v-if="
                  faq.question ===
                  'How do I fix wrong information or add additional information?'
                "
              >
                Reach out to support at
                <span class="email">support@cloaked.app</span> and let us know
                what needs to be changed. We'll do our best to accommodate
                changes or additions.
              </span>
              <span v-else> {{ answer }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.faq-section {
  padding: 24px;
  background-color: $color-surface;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid $color-primary-10;
  background-color: $color-primary-1;
  border-radius: 12px;
  padding: 24px 24px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  color: $color-primary-100;
  gap: 16px;
  transition: all 0.3s ease-in-out;
  @at-root .theme-dark & {
    background-color: $color-primary-5;
  }
  &:hover {
    transform: scale(1.003);
    border: 1px solid $color-primary-100;
    transition: all 0.3s ease-in-out;
  }
}

.faq-text-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: $color-primary-100;

  .faq-question {
    display: flex;
    gap: 16px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    .icon {
      width: 11px;
    }
  }

  .faq-answer-wrapper {
    font-size: 16px;
    font-weight: 400;
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    transition: grid-template-rows 0.3s ease-out, opacity 0.3s ease-out;
    &.opened {
      grid-template-rows: 1fr;
      opacity: 1;
    }

    .faq-answer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: hidden;
      padding-left: 24px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
      transition: all 0.3s ease;
      &.opened {
        padding-top: 16px;
        transition: all 0.3s ease;
      }
    }
  }
}

.email {
  text-decoration: underline;
}
</style>
