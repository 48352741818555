<script setup>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";

import store from "@/store";
import DataRemovePage from "@/components/feature/data-delete/DataRemovePage.vue";

import { SubscriptionService } from "@/api";

const isSubscribed = computed(() => store.getters["settings/isSubscribed"]);

onBeforeMount(() => {
  SubscriptionService.getSubscription();
});

const state = ref({
  step: 0,
  data: [],
  selected: null,
});

onMounted(() => {
  if (isSubscribed.value) {
    state.value.step = 1;
  }
});

watch(
  () => isSubscribed.value,
  (newValue) => {
    if (newValue) {
      state.value.step = 1;
    }
  }
);

const currentComponent = computed(() => {
  return DataRemovePage;
});

function step(add) {
  let nextStep = state.value.step;
  nextStep = nextStep + add;
  if (nextStep < 0) {
    nextStep = 0;
  } else if (nextStep > 2) {
    nextStep = 2;
  }
  state.value.step = nextStep;
}
</script>

<template>
  <Component
    :is="currentComponent"
    :value="state"
    @input="(value) => (state = value)"
    @next="step(1)"
    @back="step(-1)"
  />
</template>
