<script setup>
import { computed } from "vue";
import store from "@/store";
import CloakedLogo from "@/assets/images/CloakedLogo.svg";
import Categories from "./NavigationLeftPanel/CategoriesMenuBlock.vue";
import Inbox from "./NavigationLeftPanel/InboxMenuBlock.vue";
import Identities from "./NavigationLeftPanel/IdentitiesMenuBlock.vue";
import Wallet from "./NavigationLeftPanel/WalletMenuBlock.vue";
import Home from "./NavigationLeftPanel/HomeMenuBlock.vue";
import { PlusIcon, Compose as ComposeIcon } from "@/assets/icons";
import Button from "@/components/Button.vue";

function newIdentity() {
  store.dispatch("openCloakCreate");
}

function openCompose() {
  store.commit("compose", {});
}

const subscriptionState = computed(() => {
  return store.state.settings.subscription?.state;
});
</script>

<template>
  <section>
    <div class="logo">
      <router-link to="/">
        <CloakedLogo />
      </router-link>
    </div>

    <nav>
      <Home />
      <Identities />
      <Inbox />
      <Wallet />
      <Categories />
    </nav>

    <div class="buttons" v-if="subscriptionState !== 'CANCELLED'">
      <Button type="primary" fullWidth @click="newIdentity()">
        <template #icon>
          <PlusIcon />
        </template>
        New identity
      </Button>
      <Button type="secondary" fullWidth @click="openCompose">
        <template #icon>
          <ComposeIcon />
        </template>
        Compose message
      </Button>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  width: 240px;
  height: 100vh;
  background-color: $color-surface;
  position: fixed;
  left: -240px;
  top: 0;
  border-right: 1px solid $color-primary-10;
  overflow: hidden;
  z-index: 2;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  @media (min-width: 768px) {
    left: 0;
  }

  .app--visible-banner & {
    top: $ui-banner-height;
    height: calc(100vh - $ui-banner-height);
  }

  .logo {
    color: $color-primary-100;
    position: sticky;
    top: 0;
    background-color: $color-surface;
    padding: 20px 18px 7.5px 18px;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-primary-10;

    svg {
      width: 97px;
      height: auto;
    }
  }

  nav {
    padding: 25px 10px 10px 0;
    position: relative;
    z-index: 2;
    flex: 1;
    overflow: auto;
    @include custom-scroll-bar();
  }

  .buttons {
    position: sticky;
    bottom: 0;
    background-color: $color-surface;
    padding: 16px;
    z-index: 3;
    border-top: 1px solid $color-primary-10;

    button {
      margin-top: 8px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
</style>
