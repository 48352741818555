<script setup>
import { reactive, ref, watch, onMounted, useAttrs } from "vue";

const attrs = useAttrs();

const emit = defineEmits(["input", "keydown"]);

const props = defineProps({
  largeFont: {
    type: Boolean,
    default: false,
  },
});

const inputRef = ref(null);

const state = reactive({
  isFocused: false,
  focusStyle: {},
});

onMounted(() => {
  inputRef.value?.focus();
});

function onInput(event) {
  event.target.value = event.target.value.slice(0, 6);
  emit("input", event.target.value);
}

watch(
  () => attrs.value,
  (newValue, oldValue) => {
    const lengthChange = Math.abs(newValue.length - oldValue?.length || 0);

    state.focusStyle = {
      transition: `all ${0.1 + (lengthChange - 1) * 0.025}s ease-out`,
      transform: `translateX(${Math.min(newValue.length, 5) * 76}px)`,
    };
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <span
    class="onboarding-input-code"
    :class="{ 'onboarding-input-code--focused': state.isFocused }"
  >
    <input
      v-bind="attrs"
      type="number"
      class="onboarding-input-code__input"
      @focus="state.isFocused = true"
      @blur="state.isFocused = false"
      @input="onInput"
      @keydown="$emit('keydown', $event)"
      ref="inputRef"
    />
    <span class="onboarding-input-code__background">
      <span
        class="onboarding-input-code__focus"
        :style="state.focusStyle"
      ></span>
      <span
        class="onboarding-input-code__placeholder"
        v-for="i in 6"
        :key="i"
        :class="{
          'onboarding-input-code__placeholder--focused':
            i - 1 === Math.min(attrs.value.length, 5) && state.isFocused,
          'onboarding-input-code__placeholder--large-font': props.largeFont,
        }"
      >
        {{ attrs.value[i - 1] }}
      </span>
    </span>
  </span>
</template>

<style lang="scss">
.onboarding-input-code {
  display: block;
  margin: 32px auto 0;
  position: relative;
  height: 60px;
  width: calc(64px * 6 + 12px * 5);

  /* Hides up/down selectors in number input Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__input {
    position: absolute;
    /* color + caret-color must be transparent (+ opacity cannot be zero)
    to visually hide the input field but still allow
    touch copy/pasting on mobile. */
    opacity: 0.1;
    color: transparent;
    caret-color: transparent;
    background-color: transparent;
    z-index: 1;
    inset: 0;
    border: none;
    height: 60px;
    width: calc(64px * 6 + 12px * 5);
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    -o-user-select: auto;
    user-select: auto;

    &:focus {
      outline: none;
    }
  }

  &__background {
    display: flex;
    justify-content: space-between;
    position: absolute;
    inset: 0;
  }

  &__focus {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 62px;
    height: calc(100% - 2px);
    border: 1px solid $color-primary-100;
    border-radius: 10px;
    opacity: 0;
    display: block;

    @at-root .onboarding-input-code--focused & {
      opacity: 1;
    }
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 64px;
    background: $color-primary-5;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    color: $color-primary-100;

    &--large-font {
      font-size: 24px;
      font-weight: 500;
    }
    &--focused {
      background: $color-primary-10;
      caret-color: $color-primary-100;
    }
  }
}
</style>
