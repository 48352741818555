<script setup>
import { computed } from "vue";
import { toastState } from "@/hooks";

const message = computed(() => toastState.message);
const isError = computed(() => toastState.type === "error");
const show = computed(() => toastState.show);
</script>
<template>
  <Transition>
    <div class="toast-wrapper" v-if="show">
      <div
        class="toast-item"
        :class="{ error: isError }"
        v-html="message"
      ></div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 500ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.toast-wrapper {
  position: fixed;
  bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  pointer-events: none;

  .toast-item {
    margin: 0 auto;
    background-color: $color-primary-100;
    color: $color-primary-0;
    padding: 16px 40px;
    border-radius: 8px;
    font-size: 14px;
  }
}

.error {
  color: $white !important;
  background-color: $color-alert !important;
}
</style>
