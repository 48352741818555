<script setup>
import { computed, reactive, onMounted, nextTick } from "vue";
import store from "@/store";
import { useToast } from "@/hooks";
import { SubscriptionService } from "@/api";
import moment from "moment";
import { humanize } from "@/scripts/timestamp_format";
import { LinkOutIcon } from "@/assets/icons";
import router from "@/routes/router";
import {
  PreferencesHeader,
  PreferencesTitle,
  PreferencesParagraph,
  PreferencesPanel,
  PreferencesFooter,
  PreferencesCheckParagraph,
  PreferencesInput,
} from "@/routes/modals/preferences";

import {
  SUBSCRIPTION_STORES_FORMATTED,
  STRIPE_MANAGE_URL,
} from "@/scripts/constants";

import ExportData from "./ExportData";

import UserService from "@/api/actions/user-service";
import { Button } from "@/components";
import { password_confirm } from "@/scripts/actions/encryption";
import AuthService from "@/api/actions/auth-service";

const isSubscribed = computed(() => store.getters["settings/isSubscribed"]);
const isStoreManual = computed(() => store.getters["settings/isStoreManual"]);
const isStoreUnknown = computed(() => store.getters["settings/isStoreUnknown"]);
const getSubscription = computed(
  () => store.getters["settings/getSubscription"]
);
const isStoreStripe = computed(() => store.getters["settings/isStoreStripe"]);
const subStore = computed(() => store.getters["settings/getStore"]);
const isRestrictedPlan = computed(() => store.getters["settings/isCancelled"]);
const isLegacy = computed(() => store.getters["settings/isLegacy"]);
const isNew = computed(() => store.getters["settings/isTrial"]);

const emit = defineEmits(["toggleBack", "refreshUser"]);

const toast = useToast();

const CancelSubScriptionMessage = {
  stripe:
    "Your subscription is managed by Stripe. You will first need to cancel your subscription via Stripe then come back to this screen to delete your account.",
  app_store:
    "Your subscription is managed by the Apple App Store. You will first need to cancel your subscription from iOS Settings then come back to this screen to delete your account.",
  play_store:
    "Your subscription is managed by your Google account. Please open Cloaked from your Android device or Google to cancel your subscription then come back to this screen to delete your account.",
  paypal:
    "Your subscription is managed by your PayPal account. You will first need to cancel your subscription via Paypal then come back to this screen to delete your account.",
  unknown: "You do not have a subscription.",
  manual_upgrade: "You do not have a subscription.",
};

const state = reactive({
  step: 0,
  steps: ["password", "confirmation", "before-export", "export"],
  understandsDataWillBeDeleted: false,
  understandsAccessToServicesWillBeLost: false,
  understandsDataCannotBeRetrieved: false,
  loadingDelete: false,
  password: "",
  invalidPassword: false,
  loadingPassword: false,
  deletionDate: null,
});

onMounted(() => {
  SubscriptionService.getStripeInfo();
});

const href = computed(() => {
  let stripeUrl = STRIPE_MANAGE_URL;
  SubscriptionService.getStripeInfo();

  if (stripeEmail.value) {
    stripeUrl += `?prefilled_email=${stripeEmail.value}`;
  }
  return stripeUrl;
});
const stripeEmail = computed(() => {
  const stripeUserData = store.getters["settings/getStripe"];
  return stripeUserData?.email || null;
});

const annualOrMonthly = computed(() => {
  if (
    getSubscription?.value?.product_identifier
      ?.toLowerCase()
      .includes("annual") ||
    getSubscription?.value?.product_plan_title?.toLowerCase().includes("annual")
  ) {
    return "annual";
  } else if (
    getSubscription?.value?.product_identifier
      ?.toLowerCase()
      .includes("monthly") ||
    getSubscription?.value?.product_plan_title
      ?.toLowerCase()
      .includes("monthly")
  ) {
    return "monthly";
  }
  return "";
});

const deleteDateLabel = computed(() => {
  if (state.deletionDate) {
    return moment(state.deletionDate).format("MMMM Do, YYYY");
  }
  return null;
});

const deletesIn = computed(() => {
  if (state.deletionDate) {
    return humanize(moment(state.deletionDate));
  }
  return "";
});

const currentStep = computed(() => state.steps[state.step]);

const toggleBack = () => {
  emit("toggleBack", {});
};

const user = computed(
  () => store.state.authentication?.user || store.state.user
);

function insertUser(data) {
  store.commit("authentication/setUser", data);
  emit("refreshUser");
}

const nextStep = () => {
  let next = state.step + 1;
  const maxStep = state.steps.length - 1;

  if (next >= maxStep) {
    next = maxStep;
  }

  state.step = next;
};

const handleDelete = async () => {
  const userId = user.value.id;

  const payload = {
    state: "pending_deletion",
    immediate_delete: false,
  };

  state.loadingDelete = true;
  try {
    const res = await UserService.deleteUserAccount({ userId, payload });
    const { data } = res;
    insertUser(data);
    state.deletionDate = data.deletion_date;
    nextStep();
  } catch (e) {
    toast.error("Error scheduling delete action");
  } finally {
    state.loadingDelete = false;
  }
};

const handleLater = () => {
  toggleBack();
};

const handleExportData = () => {
  nextStep();
};

const handleGoBack = () => {
  if (state.steps[state.step] === "before-export") {
    toggleBack();
  } else if (state.step > 0) {
    state.step = state.step - 1;
  } else {
    toggleBack();
  }
};

const validatePassword = async () => {
  state.loadingPassword = true;
  const hash = await password_confirm(state.password);
  AuthService.confirmPassword(user.value.id, hash)
    .then(() => {
      state.invalidPassword = false;
      if (store.state.authentication?.user?.state === "pending_deletion") {
        nextStep();
      }
      nextStep();
      state.password = "";
    })
    .catch(() => {
      state.invalidPassword = true;
      state.password = "";
      toast.error("Invalid password, please try again");
    })
    .finally(() => {
      state.loadingPassword = false;
      state.password = "";
    });
};

const storeName = computed(() => {
  return SUBSCRIPTION_STORES_FORMATTED?.[subStore.value] || null;
});

function upgradeModal() {
  store.dispatch("subscription/openSubscriptionModal");
}
function openRestoreAccount() {
  if (router.currentRoute.name !== "settings.account") {
    router.push({
      name: "settings.account",
    });
  }

  nextTick(() => {
    store.commit("openPreference", {
      selected: "account",
      right: "manage-account",
      step: "restore",
    });
  });
}
</script>
<template>
  <PreferencesPanel class="delete-account">
    <template v-slot:header>
      <PreferencesHeader @go-back="handleGoBack" />
    </template>

    <template v-if="currentStep === 'password'">
      <div
        v-if="
          (isSubscribed || isLegacy || isNew) &&
          Object.keys(SUBSCRIPTION_STORES_FORMATTED).includes(subStore)
        "
      >
        <PreferencesTitle
          v-if="!isStoreManual && !isStoreUnknown && !isLegacy && !isNew"
          >You have an active {{ annualOrMonthly }} subscription
          <br />
          ({{ storeName }})</PreferencesTitle
        >
        <PreferencesTitle v-else
          >You do not have an active subscription</PreferencesTitle
        >
        <PreferencesParagraph
          v-if="!isStoreManual && !isStoreUnknown && !isLegacy && !isNew"
        >
          {{ CancelSubScriptionMessage[subStore] }}
          <br />
          <a
            v-if="isStoreStripe"
            class="manage-link"
            :href="href"
            target="_blank"
          >
            Manage subscription &ensp; <LinkOutIcon
          /></a>
        </PreferencesParagraph>
        <PreferencesParagraph v-else-if="isNew">
          <span
            ><a @click="upgradeModal"
              ><u>Click here activate your subscription</u></a
            ></span
          >
        </PreferencesParagraph>
        <PreferencesParagraph v-else>
          You are a legacy user.
        </PreferencesParagraph>
      </div>
      <div v-else-if="isRestrictedPlan">
        <PreferencesTitle>Your subscription is inactive</PreferencesTitle>
        <PreferencesParagraph>
          <span
            ><a @click="upgradeModal"
              ><u>Click here to restore subscription</u></a
            ></span
          >
        </PreferencesParagraph>
      </div>
      <div v-else>
        <PreferencesTitle>Could not load plan</PreferencesTitle>
        <PreferencesParagraph>
          We are unable to fetch your subscription information at this time.
          Ensure that you have canceled your subscription before deleting your
          account. If you need help, please contact customer support at:
          support@cloaked.app
        </PreferencesParagraph>
      </div>

      <br />
      <PreferencesTitle
        v-bind:class="[
          isSubscribed && !isStoreManual && !isStoreUnknown
            ? `delete-account__disabled`
            : '',
        ]"
        >Continue to delete account</PreferencesTitle
      >
      <PreferencesParagraph
        v-bind:class="[
          isSubscribed && !isStoreManual && !isStoreUnknown
            ? `delete-account__disabled`
            : '',
        ]"
        >To continue deleting your account, enter your account
        password.</PreferencesParagraph
      >

      <PreferencesInput
        :value="state.password"
        @input="(event) => (state.password = event)"
        v-bind:class="[
          (isSubscribed && !isStoreManual && !isStoreUnknown) ||
          (!Object.keys(SUBSCRIPTION_STORES_FORMATTED).includes(subStore) &&
            !isNew &&
            !isRestrictedPlan)
            ? `delete-account__disabled`
            : '',
        ]"
        label="Password"
        type="password"
        placeholder="Your Password"
        :error="state.invalidPassword"
        :disabled="
          state.loadingPassword ||
          (isSubscribed && !isStoreManual && !isStoreUnknown)
        "
        @save="validatePassword"
      />
    </template>

    <template v-if="currentStep === 'confirmation'">
      <PreferencesTitle>Confirm account deletion</PreferencesTitle>

      <PreferencesCheckParagraph
        class="disclaimer-row delete-account__check"
        :value="state.understandsDataWillBeDeleted"
        @input="(event) => (state.understandsDataWillBeDeleted = event)"
      >
        I understand that my account will be permanently deleted and all data
        associated with my Cloaked identities.
      </PreferencesCheckParagraph>

      <PreferencesCheckParagraph
        class="disclaimer-row delete-account__check"
        :value="state.understandsCancelBilling"
        @input="(event) => (state.understandsCancelBilling = event)"
      >
        If you have an active subscription, you will continue to be billed even
        after your account is deleted until canceled.
      </PreferencesCheckParagraph>
    </template>

    <template v-if="currentStep === 'before-export' && deleteDateLabel">
      <PreferencesTitle>
        Your account will be deleted on {{ deleteDateLabel }}
      </PreferencesTitle>
      <PreferencesParagraph>
        Your account will be deleted <strong> {{ deletesIn }}</strong
        >, until then you'll have access to your Cloaked account and be able to
        restore your account.
        <br />
        <span
          ><a @click="openRestoreAccount"
            ><u>Click here to restore your account</u></a
          ></span
        >
      </PreferencesParagraph>

      <PreferencesTitle>Your data is important to us</PreferencesTitle>

      <PreferencesParagraph>
        We value your data and privacy. We strongly encourage you to export your
        data so that you can retain access to it after your account is deleted
        on <strong>{{ deleteDateLabel }}</strong
        >. After that date, it will be permanently deleted and Cloaked will be
        unable to retrieve it.
      </PreferencesParagraph>

      <PreferencesParagraph
        >Click the button below to begin exporting.</PreferencesParagraph
      >
    </template>
    <template v-if="currentStep === 'before-export' && !deleteDateLabel">
      <PreferencesParagraph>
        Currently we are unable to get this information. Please contact customer
        support at: support@cloaked.app
      </PreferencesParagraph>
    </template>

    <template v-if="currentStep === 'export'">
      <ExportData view-step="export" nav-disabled @toggleBack="toggleBack" />
    </template>

    <template v-slot:footer>
      <PreferencesFooter v-if="currentStep === 'password'">
        <Button
          v-bind:class="[
            isSubscribed && !isStoreManual && !isStoreUnknown
              ? `delete-account__disabled`
              : '',
          ]"
          @click="validatePassword"
          :loading="state.loadingPassword"
          :disabled="state.loadingPassword || !state.password"
          >Continue</Button
        >
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'confirmation'">
        <Button
          type="danger"
          :disabled="
            !state.understandsDataWillBeDeleted ||
            !state.understandsCancelBilling ||
            state.loadingPassword
          "
          @click="handleDelete"
        >
          Delete my account
        </Button>
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'before-export'">
        <Button type="secondary" @click="handleLater"
          >I'll do this later</Button
        >
        <Button @click="handleExportData">Export account data</Button>
      </PreferencesFooter>
    </template>
  </PreferencesPanel>
</template>

<style lang="scss">
.delete-account {
  .preferences-input {
    margin-top: 37px;
  }

  & &__check {
    align-items: center;
    gap: 24px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid $color-primary-20;

    span {
      color: $color-primary-100;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
    }
    &:hover {
      opacity: 0.9;
    }
  }
  a {
    cursor: pointer;
    color: $color-primary-100;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;

    &:hover {
      opacity: 0.8;
    }
  }
  &__disabled {
    color: $color-primary-100;
    opacity: 0.4;
  }
}
</style>
