<template>
  <div>
    <h1>1. Your Cloaked Pay and This Agreement</h1>
    <p>
      Your Cloaked Pay Account (“Account”) is issued by Patriot Bank, N.A.
      (“Issuing Bank”). As applicable, Credit is provided by Cloaked Pay, LLC
      (“Cloaked” or “Cloaked Pay”). This Cloaked Pay Agreement, the Fees Table,
      and any changes we make to the agreement between you and us (“Agreement”)
      in the future governs the use of your Account. The Agreement takes effect
      when you use a Card; or, if you don’t use a Card, 30 days after we provide
      you the Agreement unless you contact us to cancel your Account. You may
      only use your Account for consumer purposes, and you cannot use your
      Account for business purposes.
    </p>

    <p>
      The Cloaked Charge Card is not like other cards you may have used before,
      and it is important that you understand what makes it unique. Please
      review this Agreement carefully before you use your Account. If you do not
      understand any portion of this Agreement, please get in touch with us
      before you use your Account, and we will be glad to help explain how the
      Account works.
    </p>

    <p>
      This Agreement requires any legal disputes between you and us to be
      decided by arbitration and not in a court of law, and it also forbids you
      from participating in a class action against us unless you exercise your
      right to reject arbitration as described in the arbitration provision in
      Section 11 of this Agreement.<br />
    </p>
    <h1>2. Definitions</h1>
    <p>
      Defined terms used in the Agreement. We may also define other important
      terms within the body of the Agreement itself.<br />
      “App” means the Servicer mobile application or www.cloaked.app.
    </p>

    <p>
      "Billing Cycle” means the period between the dates that we create a
      billing statement for your Account; our Billing Cycle is every thirty (30)
      days. <br />
      “Card” means one or more cards or other devices we issue to you to access
      your Account.
    </p>

    <p>
      “Linked Account“ means the debit card, checking account or other account
      type with ACH, credit card, or any combination of the three payment
      accounts issued by a U.S. financial institution that you link.
    </p>

    <p>
      “Servicer” refers to Cloaked Pay, LLC, or a future card servicer, as
      applicable.
    </p>

    <p>
      “Spending Limit” means the maximum amount of credit we allow you to spend
      on any single Transaction or on any given day.<br />
      “Statement Balance” means the amount you owe on your billing statement.
    </p>

    <p>
      “Transaction” means any transaction used to buy or lease goods or
      services, any other permitted transaction, and any adjustments to
      transactions made to your Account.
    </p>

    <p>
      “Transaction Amount” means the amount of a Transaction, inclusive of any
      third-party or transaction fees charged to you as part of the
      Transaction.<br />
      “Transaction Payment” means a payment made for the Transaction Amount.
    </p>

    <p>
      “We,” “us” and “our” mean, depending on context, Issuing Bank, Cloaked
      Pay, LLC, and/or Servicer.
    </p>

    <p>“You” means the Account owner.</p>
    <h1>3. Your Account and How it Works</h1>
    <p>
      a. Use of Your Account and Cards. To create an Account and to use your
      Cards, you must connect at least one Linked Account that we support
      through the App. If you add more than one Linked Account, you need to
      choose which Linked Account is your primary Linked Account through the App
      (“Primary Linked Account”). Any Linked Accounts that are not Primary
      Linked Accounts are alternative Linked Accounts (“Alternative Linked
      Accounts”). You agree that any and all Linked Accounts may be used for
      purposes of repayment. As discussed in section 3(b), we determine your
      Spending Limit, including any Spending Limit you request, in part, based
      on the daily balances, Linked Account transactions, or credit available in
      your Linked Account(s).
    </p>
    <p>
      b. Spending Limit. Your Spending Limit is how much credit we allow you to
      spend. You set an initial Spending Limit when you open your Account, which
      we confirm is payable through your Linked Account(s). We then set one or
      more Spending Limits that we calculate on an ongoing basis using the
      amounts you have in your Linked Accounts, your Account history, and other
      factors. We may increase or decrease your Spending Limit at any time,
      subject to applicable law. Your Spending Limits may differ from the credit
      limits, spending limits, and funds availability limits imposed on you by
      the issuers of your Linked Accounts. Because we determine your Spending
      Limit based on the most recent information available to us when you
      request a specific Spending Limit and each time you perform a Transaction,
      your Spending Limit could vary. While we usually decline any Transactions
      that would cause you to exceed your Spending Limit, we may authorize
      Transactions that cause your Account balance to exceed your Spending
      Limit. If we do, we do not give up any of our rights under this Agreement
      to continue to implement controls on your Spending Limit, and any
      Transaction approvals we make that exceed your Spending Limit do not
      constitute a guarantee that we will permit you to exceed your Spending
      Limit again. You are responsible for paying all amounts you owe us under
      this Agreement.
    </p>
    <p>
      Example: Andrea checks her App on Monday morning and sees she has a
      Spending Limit of $2,000. Andrea did not use her Card that Monday, but her
      Linked Account was debited Monday evening because Andrea made a
      transaction with another merchant using her Linked Account. Andrea checks
      her App on Tuesday morning and sees she has a Spending Limit of $1,900.
      Spending Limit is less because her Linked Account has less money in it on
      Tuesday than it had on Monday.
    </p>
    <p>
      c. Using your Spending Limit. To use a Card to access your Spending Limit,
      you must not have a balance or pending balance on your Account. This means
      you must have made Transaction Payments for all Transactions from the
      current Billing Cycle and all required payments for Statement Balances
      from prior billing statements. If you have made all such payments in full,
      you may use your Card for a Transaction, including any Transaction fees,
      up to your Spending Limit. To use your Account again after your
      Transaction, you must make a Transaction Payment or pay your Statement
      Balance. If you don’t, you cannot use a Card for a Transaction. If you try
      to do so, we will decline the Transaction. You can find instructions on
      how to make Transaction Payments and Statement Balance payments in Section
      4 below.
    </p>
    <p>
      d. Holds and Additional Charges. Certain merchants may place
      “authorization holds” for specified amounts when you use a Card. For
      example, a hotel may place a hold for a specified amount of funds beyond
      the cost of the stay of your hotel rental. To the extent we are able, we
      treat a merchant’s “hold” differently in this situation than we treat
      pending balances for other Transactions. For such holds, we will deduct
      the “hold” amount from your Spending Limit but we will not require you to
      repay the “hold” amount to use your Account again unless the merchant
      settles the “hold” by charging you for the “hold” amount. If the merchant
      releases the “hold” instead of charging you for the “hold” amount, the
      “hold” will be released from your Spending Limit once it is removed from
      your Account. We do not control how long it takes for a “hold” to be
      removed. You agree that we are not liable to you for any losses caused by
      Transactions that are, or are not, categorized as “holds,” including any
      limitations on your ability to use your Account because of such
      categorization. You are responsible for all repayment obligations based on
      how the Transaction is categorized. Some merchants assess additional
      charges, such as tips, beyond the initial Transaction amount. For
      additional charges, we will charge your Card for the remaining balance of
      the Transaction that the merchant charges you beyond the amount of your
      initial Transaction. The additional charge may cause you to exceed
      yourSpending Limit. You agree that you owe us any additional charges,
      notwithstanding that amount being above your Spending Limit or requested
      limit on a Card.
    </p>
    <p>
      e. No Authorized Users. We will not issue an additional Card to allow
      anyone other than you to access your Account. You are the only person who
      can use your Card or Account. If you authorize someone else to use your
      Card or Account, you are responsible for all Transactions and/or fees
      incurred by that person, including if that person exceeds your authority,
      loses your card, or otherwise fails to protect your credentials. You are
      solely responsible for the use of your Card and Account in accordance with
      the terms and conditions of this Agreement.
    </p>
    <h1>4. Transaction Payments, Statements, and How to Make Payments</h1>
    <p>
      a. Promise to Pay. When you use your Account, you promise to pay us all
      amounts charged to your Account. This includes all Transactions, fees, and
      other amounts we bill to you on your Statement Balance. You can find the
      fees and other amounts we charge in the App.
    </p>
    <p>
      b. Payments Required to Access Spending Limit. To access your Spending
      Limit after you complete a Transaction and incur a balance or pending
      balance on your Account, you must pay us the Transaction Amount plus fees,
      if any, you have incurred since completing the Transaction. You can pay us
      by (i) making a Transaction Payment, (ii) paying your Statement Balance,
      or (iii) paying the full amount shown on your Account. If your payment is
      declined, returned or reversed by the issuer of your Linked Account, you
      will be unable to access your Spending Limit until we receive your full
      payment, and you will owe us for the amount that was returned or reversed.
    </p>
    <ul>
      <li>
        Transaction Payments. After you use your Card, you can make a
        Transaction Payment from your Linked Account(s) to regain access to your
        Spending Limit. Once you make a Transaction Payment and pay any other
        fees you owe us in full (if any) you may generally resume using your
        Spending Limit. Partial payments will not permit you to regain access to
        your Spending Limit.
      </li>
      <li>
        Billing Cycle and Billing Statements. We will send you a billing
        statement electronically at the end of each Billing Cycle, if applicable
        law requires. Unless we make other arrangements with you, we will
        deliver any required billing statements as described in our E-Sign
        Consent Agreement If you receive a billing statement and you have a
        Statement Balance, payment of the Statement Balance is due upon receipt
        of your billing statement. Once you pay your Statement Balance, you
        generally may resume using your Spending Limit.
      </li>
    </ul>
    <p>
      c. Payment Methods and Authorization: You can pay through the App using
      one of two electronic methods described below. We do not accept cash or
      check payments. Each payment must be in U.S. dollars. Each time you
      authorize us to initiate a payment from your Linked Account(s), you are
      promising us that you are legally authorized to use the Linked Account(s)
      to make the payment. When you provide us with your Linked Account(s)
      information, you authorize us to (i) retain the information to initiate
      payments based on your instructions; (ii) credit your Linked Account(s);
      and (iii) correct any payment, credit, or other errors.
    </p>
    <p>
      Payments initiated by 11:59 p.m. (EST) will be credited that same day.
    </p>
    <ul>
      <li>
        Preauthorized Transfers: You may authorize us to initiate recurring or
        automatic payments from your Linked Account(s) for Transaction Payments
        and for the amount of each Statement Balance (“AutoPay”). If you select
        AutoPay, you authorize us to initiate repayment from your Linked Account
        (A) for a Transaction Payment after you complete a Transaction, and (B)
        for the Statement Balance on the date we send you a billing statement.
        If more than one of your Linked Accounts is set up for AutoPay, and if
        your Primary Linked Account lacks sufficient funds to cover the amount
        of your AutoPay, we will deduct the remaining amounts from your
        Alternative Linked Accounts that are set up for AutoPay. It is important
        that you correctly order your Linked Accounts for repayment purposes
        because we will initiate repayments from your Linked Accounts in the
        order that you select. We are not responsible for any overdraft fees or
        any penalties imposed on you by the issuer of your Linked Account that
        result from our attempt to process an AutoPay payment.
      </li>
      <li>
        One-Time Transfers: You may initiate a one-time transfer from your
        Linked Account by logging in to the App.
      </li>
    </ul>
    <p>Examples:</p>
    <ul>
      <li>
        Andrea selects AutoPay when opening her Account. Andrea opens her App
        and sees she has a $1,000 Spending Limit. Andrea buys a television for
        $500 using a Card. Because Andrea selected AutoPay, we immediately
        initiated a Transaction Payment to debit her Linked Account for $500
        after her purchase of the television using a Card was complete. Andrea
        opens her App one hour later to order food online and the App shows that
        she has a $500 Spending Limit and that she is eligible to use a Card to
        order the food because she initiated a Transaction Payment using
        AutoPay.
      </li>
      <li>
        Bob does not select AutoPay when he opens his Account. Bob opens his App
        and sees he has a $1,000 Spending Limit. Bob buys a couch for $250 using
        a Card. Because Bob did not select AutoPay, Bob will be unable to use
        the remaining amount of his Spending Limit that day, or use his Spending
        Limit on any future day until Bob initiates a one-time transfer for the
        full amount owed. Before Bob initiates a one-time transfer, Bob attempts
        to use his Card to initiate another purchase and is declined. Bob then
        opens the App and initiates a Transaction Payment using a one-time
        transfer. Bob can now use his Card again up to the remaining Spending
        Limit.
      </li>
      <li>
        Carol does not select AutoPay when she opens her Account. Carol opens
        her App and sees she has a $1,000 Spending Limit. Carol buys a bike for
        $250 using a Card. Because Carol did not select AutoPay, Carol will be
        unable to use the remaining amount of her Spending Limit that day, or
        use her Spending Limit on any future day until Carol initiates a
        one-time transfer for the full amount owed. Carol does not pay until she
        receives her billing statement. Upon receiving her billing statement,
        Carol initiates a one-time transfer for the Statement Balance amount.
        Carol is now eligible to use her Card up to the Spending Limit.
      </li>
    </ul>
    <p>
      d. Payment Cancellations: You may cancel a scheduled payment by contacting
      us. We must receive your cancellation request three business days before
      your scheduled payment. We may cancel your payment(s) for any reason we
      deem appropriate, and we will notify you of such cancellation.
    </p>
    <p>
      e. Non-Conforming Payments: We may reject any AutoPay or one-time payments
      you attempt to make that do not comply with the payment instructions in
      Section 4(c) or on your billing statement. We may permit new payment
      methods in our discretion.
    </p>
    <p>
      f. Spending Limit Availability: After you make a payment, we can
      immediately restore your ability to use your Spending Limit, or we can
      delay your ability to use your Spending Limit for a period of time. We
      will credit your payment when you make it, but we reserve the right to
      delay your ability to use your Spending Limit at our discretion. You can
      check your Spending Limit, including whether you are authorized to use
      your Spending Limit, by checking the App or contacting us.
    </p>
    <p>
      g. Linked Account Agreements. Your Linked Account is issued by a financial
      institution and is governed by an agreement between you and the Linked
      Account issuer. Those agreements between you and the Linked Account issuer
      likely govern any transaction from the Linked Account issuer that results
      in you repaying any amount you owe to us. For example, if your Linked
      Account is a credit card, the issuer of that credit card may treat your
      repayment to us as a “cash advance” rather than a “purchase” transaction
      and the issuer may have different terms, including different annual
      percentage rates and different grace periods for such transactions. You
      are solely responsible for complying with the terms of your agreement with
      the issuer of your Linked Account, including any repayment obligations you
      have for transactions you undertake using your Linked Account. If you have
      any questions, contact the issuer of your Linked Account.
    </p>
    <h1>5. Additional Account and Card Use Terms</h1>
    <p>
      a. Single-Use and Multi-Use Cards: When using your Account, you can access
      single-use Cards and multi-use Cards. If you create a single-use Card, you
      can only use the card number associated with that Card one time. If you
      create multi-use Cards, you can use the card number associated with that
      Card until that Card expires or you have otherwise set up limitations on
      its use. We do not charge any fees for creating single or multi-use Cards.
    </p>
    <p>
      Example: Andrea checks her App, and sees she has a $1,000 Spending Limit.
      Andrea creates a single-use Card to purchase a television online for $500.
      Andrea’s Spending Limit is reduced by $500. Because the Card was a
      single-use Card, Andrea cannot use the number associated with that Card
      again.
    </p>
    <p>
      b. Eligible Transactions: You may use your Account for Transactions. You
      may not use or permit anyone else to use your Account for:
    </p>
    <ul>
      <li>
        Any illegal purpose, including in connection with unlawful domestic or
        international gambling websites or escort services.
      </li>
      <li>
        Cash advances or cash equivalents, such as purchasing cryptocurrency,
        travelers checks, money orders, peer-to-peer transfers, lottery tickets,
        gaming chips and other methods used to gamble, and other wagers or
        betting transactions.
      </li>
      <li>Cannabis/Marijuana</li>
      <li>Balance transfers.</li>
    </ul>
    <p>
      c. Recurring Transactions: If you authorize a merchant or any other person
      to charge your Account for recurring Transactions, you must ensure that
      you are eligible to use your Spending Limit when the merchant or other
      person charges your Account. If your Account has a balance, your Account
      is closed or suspended, your Card number changes, or there is any other
      reason for which you are not eligible to use your Spending Limit and we
      decline the Transaction, we will not be responsible for any losses
      associated with the declined recurring Transaction. If your Account is
      closed, suspended, or your Card number changes, you are responsible for
      contacting the merchant or other person you are paying by a recurring
      Transaction, and for any amounts owed to us.
    </p>
    <p>
      Example: Andrea set up a recurring Transaction with a streaming video
      service using a Card. The streaming video service charges consumers on the
      15th of each month. On the 15th, Andrea had made all payments on her
      Account and was eligible to use her Spending Limit that day. The streaming
      video service charged her Card. Andrea had AutoPay setup, so we
      immediately initiated a Transaction Payment to Andrea’s Linked Account,
      meaning that Andrea could use her Card for a new Transaction.
    </p>
    <p>
      d. Declined Transactions: We may decline Transactions under this Agreement
      for any reason. This includes if we suspect fraud or there is actual
      fraud, the Transaction would violate applicable law, you have not made a
      Transaction Payment or not made all required payments on any billing
      statements, you are in default under this Agreement, or if you exceed your
      Spending Limit. We are not responsible for any losses associated with a
      declined Transaction, even if you were eligible to use your Spending Limit
      and the Transaction Amount was within your Spending Limit. We are not
      responsible if a merchant or another person refuses to accept your Card.
    </p>
    <p>
      e. Foreign Transactions. When you make a Transaction with your Account in
      a currency other than U.S. dollars, your Transaction will be converted
      into U.S. dollars by Mastercard. Mastercard will use the exchange rate in
      effect on the day the Transaction is processed. This exchange rate is
      based on rates observed in the wholesale market or government-mandated
      rates, where applicable. The exchange rate may differ from the rate as of
      the Transaction date or the date the Transaction is posted on your
      Account. This is because the exchange rate can fluctuate between the time
      the Transaction is made and the time it is processed. For a returned or
      exchanged item that you purchased in a foreign currency, the exchange rate
      will be determined on the return date.
    </p>
    <p>
      f. Authorization to Connect to Linked Account(s): To add a Linked Account
      to your Account, the third-party service that we use will ask you to
      provide your online banking credentials. We currently use Plaid for this
      service, but we may use others. You can only provide credentials for
      Linked Accounts that you own. By providing your credentials to the
      third-party service provider, you authorize us to use the information from
      your Linked Account to provide you with services for your Account,
      including to help determine your Spending Limit. Your authorization will
      remain in effect until you revoke it, which may affect your ability to
      receive the services. Plaid’s terms of use and privacy policy are here:
      https://plaid.com/legal.
    </p>
    <p>
      g. Changes to Account Information. You provided certain personal
      information to us when you opened your Account. You agree to notify us
      immediately of any changes to this information. If you do not notify us of
      changes, or if we ask you to verify your Account information and you
      cannot, we may suspend or close your Account. We may also require
      additional information, supplemental information, or both to the
      information you provided in your application. The supplemental information
      may affect your Spending Limit.
    </p>
    <p>
      h. Returns and Credit Balances: When you return an item purchased using
      your Account, the refund will appear as a credit on your Account. You may
      request a refund of a credit balance at any time in writing. If you don’t
      request a refund, we’ll apply the credit balance to new Transactions
      unless we are required by law to provide you with a refund.
    </p>
    <p>
      i. Lost or Stolen Cards and Liability for Unauthorized Use. You must
      contact us immediately if your Account or Card information is lost or
      stolen, any access credentials have been compromised, or your Account has
      been accessed or used without your permission. You agree to help us
      investigate any related matters. If you assist and we determine that you
      are not responsible, you will not be liable for any unauthorized
      Transactions made on your Account.
    </p>
    <h1>6. Interest and Fees</h1>
    <p>a. No Interest. We do not charge you interest on your Account.</p>
    <p>
      b. Cloaked Pay availability is included in your subscription to Cloaked,
      which includes all Cloaked core and Cloaked Pay products and services. If
      you are eligible for Cloaked Pay, you can gain Cloaked Pay access with an
      active and paid Cloaked subscription.
    </p>
    <h1>7. Consent to Communications and Call Monitoring</h1>
    <p>
      a. Consent to Communications. You consent to us, as well as any other
      owner or servicer of your Account, contacting you through any
      communication channel, for any purpose and at any time, as permitted by
      applicable law. This includes contacting you by phone, text message,
      email, mobile application push notifications, and other electronic
      notification methods enabled by the software or devices you use to access
      your Account. We may communicate with you using any telephone number(s)
      (including wireless, landline, and voice over IP numbers, or any numbers
      that convert to a wireless number) or email address(es) that you give or
      have given to us. We may use an automated dialer or artificial voice
      and/or prerecorded messages when we contact you. We may contact you on a
      mobile, wireless, or similar device, even if your carrier charges you for
      it. Your carrier’s message and data rates may vary. We are not liable for
      delayed or undelivered messages.
    </p>
    <p>
      You agree that you will immediately notify us if you change your telephone
      number(s) or are no longer the subscriber or usual user of a telephone
      number you gave us. If you have a right under applicable law, you can
      withdraw your consent to receive phone calls and text messages that are
      made using an automatic telephone dialing system or that deliver a
      prerecorded and/or artificial voice message. To revoke your consent, you
      must contact us per this Agreement.
    </p>
    <p>
      You must promptly notify us of any change to your name, mailing address,
      email address, or phone number by contacting us. We may rely on your phone
      number, email address, or mail address as it appears on our records for
      any Account communications we send you until you notify us of any changes.
    </p>
    <p>
      b. Call Monitoring: You consent to us monitoring and recording any calls
      between you and us. This is for quality control purposes. You can opt out
      of call monitoring at any time by contacting us.
    </p>
    <p>
      c. Notices. We send any notices by email to the address you gave us or by
      text message to the phone number you gave us. We may also deliver notices
      to your billing address. We consider a notice sent as soon as we mail it.
      We consider an electronic notice sent as soon as we send it, unless we
      receive notification that the electronic notice was undeliverable.
    </p>
    <h1>
      8. Default, Effects of Default, and Closing or Suspending Your Account
    </h1>
    <p>
      a. Default: Your Account will be in default if any of the events below
      occur:
    </p>
    <ul>
      <li>You fail to make a payment when due on your billing statement.</li>
      <li>
        Any required payment you make is rejected or we cannot process it.
      </li>
      <li>You breach any term of this Agreement.</li>
      <li>
        You file for bankruptcy, or some other insolvency proceeding is filed by
        or against you.
      </li>
      <li>
        You’re declared incompetent or mentally incapacitated, or in the event
        of your death.
      </li>
      <li>
        You use your Card(s) for any unlawful or unpermitted transactions.
      </li>
      <li>You remove all your Linked Accounts from your Account.</li>
    </ul>
    <p>
      b. Consequences of being in default: If your Account is in default, we may
      take any of the actions below without notifying you, to the extent
      permitted under applicable law:
    </p>
    <ul>
      <li>Lower your Spending Limit;</li>
      <li>Decline or limit your ability to make Transactions;</li>
      <li>Begin collections activities;</li>
      <li>Suspend or close one or more of your Cards or your Account;</li>
      <li>
        Require you to immediately pay your total outstanding balance; and/or
      </li>
      <li>Take any other action permitted by law.</li>
    </ul>
    <p>
      c. Closing your Account. You may close your Account at any time by
      contacting us. We may suspend or close your Account, or suspend or cancel
      any Card or other feature, at any time and for any reason permitted by
      applicable law. We may suspend or close your Account because of our
      business needs rather than your actions or inactions. If your Account is
      closed, you must immediately repay your total outstanding balance.
    </p>
    <p>
      d. Collection Costs. To the extent allowed by law, you’re liable to us for
      our legal costs if we refer collection of your Account to a lawyer who
      isn’t our salaried employee. These costs may include reasonable attorneys’
      fees and costs and expenses of any legal action.
    </p>
    <h1>
      9. Other Important Information about your Account and this Agreement
    </h1>
    <p>
      a. Governing Law. This Agreement is governed by Federal law; if Federal
      law does not govern, the law of Connecticut will apply, regardless of
      where you live or use your Account. We accept this Agreement in
      Connecticut, all decisions to grant the Cloaked Pay are made in
      Connecticut.
    </p>
    <p>
      b. Changes To This Agreement. We may change, add, or delete the terms of
      this Agreement at any time. We will notify you of any changes as required
      by law, including by electronic means in accordance with the E-Sign
      Consent Agreement we provided to you. If you do not agree to any changes,
      we may require you to close your Account, or you may close your Account.
      If your Account is closed because you do not agree to a change, and you
      have a right to reject the change under applicable law, that change will
      not apply to your Account. All other changes to the Agreement will apply
      to your Account while you repay any remaining balance.
    </p>
    <p>
      c. Servicer and Service Providers. We have partnered with a Servicer to
      provide you with certain services in connection with your Card and
      Account. You will interact with the Servicer on behalf of us through the
      App. These services may include, but are not limited to, Account
      management and customer service. The Servicer may act on our behalf and
      perform our obligations under this Agreement. We may share any information
      you provide to us in connection with your Card and Account with the
      Servicer for them to provide you with these services. We or the Servicer
      may use other service providers to assist in providing you with your
      Account and related services. We or they may share any information you
      provide to us in connection with your Card and Account with the Servicer
      for them to provide you with these services. All sharing will be in
      accordance with our privacy policy and applicable law.
    </p>
    <p>
      d. Correcting Errors. If we incorrectly apply a payment or credit to your
      Account, make an incorrect payment to you or anyone else on your behalf,
      or make any other error regarding your Account, we may correct the error.
      We may do so by making adjustments to your Account; requiring you to repay
      us for any incorrect payments, credits or transfers; or taking any other
      actions we determine are appropriate to correct the error. You will
      cooperate with us to correct any of these errors.
    </p>
    <p>
      e. Protecting Your Account Information and Credentials. You are solely
      responsible for maintaining the security of your Account information,
      including your Card(s) and any authentication credentials used in
      connection with your Account. This includes, but is not limited to, your
      username, password, biometrics, and any other data you use to log into
      your Account through the App. Do not share your Account information or
      credentials with anyone. You could be liable for any Transactions they
      make using your Account.
    </p>
    <p>
      f. Mobile Phone or Other Devices. Phones, tablets, and other electronic
      devices can store your Card, including, for example, in a mobile wallet.
      You can use these devices and associated applications to make
      Transactions. This Agreement applies to such Transactions. Methods used to
      store your Card to make Transactions may have separate terms. You, and not
      us, are responsible if you violate those terms, or for any consequences
      resulting from any violation.
    </p>
    <p>
      g. Assignment. We may sell, assign, or transfer any or all of our rights
      and obligations under this Agreement to a third party without restriction
      and without your prior consent. The person or entity to whom we make such
      sale, assignment, or transfer can exercise all our rights and is subject
      to all your obligations under this Agreement that we sold, assigned, or
      transferred. You cannot sell, assign, or transfer your Account or any of
      your obligations under this Agreement.
    </p>
    <p>
      h. Severability. If any provision of this Agreement is held invalid or
      unenforceable, such provision will be struck from this Agreement, and the
      remaining provisions will remain in effect.
    </p>
    <p>
      i. Right to Delay Enforcement. We can delay or fail to enforce any of our
      rights under this Agreement without losing any of them.
    </p>
    <p>
      j. Events Beyond Our Control. From time to time, our services might be
      unavailable because of circumstances beyond our control. If this happens,
      you might be unable to use your Account. We are not responsible or liable
      to you if this happens.
    </p>
    <p>
      k. Contacting Us. Unless we tell you otherwise, you can contact us through
      our Servicer at support@cloaked.app.
    </p>
    <p>
      l. Headings. The headings in this Agreement are for convenience. The
      headings don’t limit or define the scope of this Agreement or any of your
      or our rights or obligations under this Agreement.
    </p>
    <h1>10. Your Billing Rights – Keep This Document for Future Use</h1>
    <p>
      This notice tells you about your rights and our responsibilities under the
      Fair Credit Billing Act.
    </p>
    <p>What To Do If You Find A Mistake On Your Statement</p>
    <p>
      If you think there is an error on your statement, you may contact us
      through the App, via email at support@cloaked.app, or write to us at:
    </p>
    <p>
      Cloaked Pay<br />
      1075 Westford Street<br />
      Suite 305<br />
      Lowell, MA 01851
    </p>
    <p>In your letter, give us the following information:</p>
    <ul>
      <li>Account information: Your name and Account number.</li>
      <li>Dollar amount: The dollar amount of the suspected error.</li>
      <li>
        Description of problem: If you think there is an error on your billing
        statement, describe what you believe is wrong and why you believe it is
        a mistake.
      </li>
    </ul>
    <p>You must contact us:</p>
    <ul>
      <li>Within 60 days after the error appeared on your statement.</li>
      <li>
        At least 3 business days before an automated payment is scheduled, if
        you want to stop payment on the amount you think is wrong.
      </li>
    </ul>
    <p>
      You must notify us of any potential errors in writing at the above address
      or through the App. You may call us, but if you do, we are not required to
      investigate any potential errors and you may have to pay the amount in
      question.
    </p>
    <p>What Will Happen After We Receive Your Letter</p>
    <p>When we receive your letter, we must do two things:</p>
    <ul>
      <li>
        Within 30 days of receiving your letter, we must tell you that we
        received your letter. We will also tell you if we have already corrected
        the error.
      </li>
      <li>
        Within 90 days of receiving your letter, we must either correct the
        error or explain to you why we believe the bill is correct.
      </li>
    </ul>
    <p>While we investigate whether or not there has been an error:</p>
    <ul>
      <li>
        We cannot try to collect the amount in question, or report you as
        delinquent on that amount.
      </li>
      <li>
        The charge in question may remain on your statement, and we may continue
        to charge you interest on that amount.
      </li>
      <li>
        While you do not have to pay the amount in question, you are responsible
        for the remainder of your balance.
      </li>
      <li>We can reduce your Spending Limit by the disputed amount.</li>
    </ul>
    <p>After we finish our investigation, one of two things will happen:</p>
    <ul>
      <li>
        If we made a mistake: You will not have to pay the amount in question or
        any interest or other fees related to that amount.
      </li>
      <li>
        If we do not believe there was a mistake: You will have to pay the
        amount in question, along with applicable fees. We will send you a
        statement of the amount you owe and the date payment is due. We may then
        report you as delinquent if you do not pay the amount we think you owe.
      </li>
    </ul>
    <p>
      If you receive our explanation but still believe your bill is wrong, you
      must write to us within 10 days telling us that you still refuse to pay.
      If you do so, we cannot report you as delinquent without also reporting
      that you are questioning your bill. We must tell you the name of anyone to
      whom we reported you as delinquent, and we must let those organizations
      know when the matter has been settled between us.
    </p>
    <p>
      If we do not follow all of the rules above, you do not have to pay the
      first $50 of the amount you question even if your bill is correct.
    </p>
    <p>Your Rights If You Are Dissatisfied With Your Card Purchases</p>
    <p>
      If you are dissatisfied with the goods or services that you have purchased
      with your Card, and you have tried in good faith to correct the problem
      with the merchant, you may have the right not to pay the remaining amount
      due on the purchase.
    </p>
    <p>To use this right, all of the following must be true:</p>
    <ul>
      <li>
        The purchase must have been made in your home state or within 100 miles
        of your current mailing address, and the purchase price must have been
        more than $50. (Note: Neither of these are necessary if your purchase
        was based on an advertisement we mailed to you, or if we own the company
        that sold you the goods or services.)
      </li>
      <li>
        You must have used your credit card for the purchase. Purchases made
        with cash advances from an ATM or with a check that accesses your credit
        card account do not qualify.
      </li>
      <li>You must not yet have fully paid for the purchase.</li>
    </ul>
    <p>
      If all of the criteria above are met and you are still dissatisfied with
      the purchase, contact us in writing at the address above or through the
      App.
    </p>
    <p>
      While we investigate, the same rules apply to the disputed amount as
      discussed above. After we finish our investigation, we will tell you our
      decision. At that point, if we think you owe an amount and you do not pay,
      we may report you as delinquent.
    </p>
    <h1>11. Arbitration Agreement</h1>
    <p>
      a. How to Reject Arbitration. You can reject this Arbitration Agreement by
      providing us a written rejection to support@cloaked.app. You must send us
      this written rejection within forty-five (45) days of opening your
      Account. The written rejection must clearly state that you are rejecting
      the Arbitration Agreement and it should include your name, physical
      address and email address associated with your Account, and Account
      number. This is the sole method for rejecting the Arbitration Agreement.
      If your written rejection meets these requirements, the Arbitration
      Agreement will not apply to you except for any Claims pending when you
      send your written rejection.
    </p>
    <p>
      b. Arbitration of Claims. Arbitration is often used to resolve Claims more
      efficiently than a trial, particularly where the amount of the Claims is
      small. This section constitutes your agreement to arbitrate (“Arbitration
      Agreement”) all claims, disputes, or controversies, including initial
      claims, counter-claims, cross-claims, and third-party claims, arising
      under or in connection with (i) this Agreement (including the validity,
      enforceability, interpretation, scope, or application of this Agreement or
      the Arbitration Agreement along with any renewals, extensions, addendums,
      or modifications), your Account, or your relationship with us; (ii) the
      servicing of your Account by our Servicer or other third-party service
      providers; (iii) the marketing or other statements related to your Account
      or this Agreement; (iv) your application for, or the approval or
      establishment of your Account; and (v) any other agreement related to your
      Account or related service (collectively, a “Claims”). Except as stated
      below, all Claims are subject to arbitration, no matter what legal theory
      they are based on (whether based upon contract; tort, intentional or
      otherwise; constitution; statute; common law; or equity) or what remedies
      they seek (damages or injunctive or declaratory relief). Arbitration
      applies to any and all such Claims, whether pre-existing, currently
      existing, or those that arise in the future. This includes Claims made by
      us against you, by you or anyone connected to you against us, our
      Servicer, parties with whom we contract to offer the Card services, or any
      of our subsidiaries, affiliates, agents, employees, predecessors,
      successors, or assignees (collectively, for purposes of this Arbitration
      Agreement only, “we,” “us,” or “our”).
    </p>
    <p>
      c. Trial Waiver and Class Action and Class Arbitration Waiver. YOU ARE
      WAIVING THE RIGHT TO LITIGATE A CLAIM IN COURT AND TO HAVE A JURY TRIAL ON
      ANY CLAIM. ANY ARBITRATION UNDER THIS AGREEMENT WILL ONLY BE ON AN
      INDIVIDUAL BASIS. CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY
      GENERAL ACTIONS, AND CONSOLIDATIONS WITH OTHER ARBITRATIONS ARE NOT
      PERMITTED. AN ARBITRATOR CAN AWARD RELIEF ONLY ON AN INDIVIDUAL BASIS. YOU
      WILL NOT HAVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR AS A PRIVATE
      ATTORNEY GENERAL RELATING TO ANY CLAIM AGAINST US RELATED TO THIS
      AGREEMENT.
    </p>
    <p>
      d. Procedure. All Claims will be decided finally and exclusively by
      binding individual arbitration with a single arbitrator administered by
      the American Arbitration Association (“AAA”) according to this provision
      and the AAA rules and procedures, including Consumer Arbitration Rules
      (collectively, the “AAA Rules”), which may limit the discovery available
      to you or to us. You can obtain copies of the AAA Rules and forms by
      contacting AAA at 1-800-778-7879 or www.adr.org. The party who wants to
      arbitrate must notify the other party in writing. We will send notice to
      your last address on file. You must send notice to 1075 Westford St #305,
      Lowell, MA 01851. A single arbitrator will resolve the Claims. The
      arbitrator must be a member of the state bar where the arbitration is held
      and with expertise in the substantive laws applicable to the Claim. Unless
      the parties to the arbitration agree otherwise, any arbitration hearings
      will take place in a reasonably convenient location for both parties with
      due consideration of their ability to travel and other pertinent
      circumstances, or via videoconference if permitted by law. If your Claim
      is for $10,000 or less, you may choose whether the arbitration will be
      conducted solely based on documents submitted to the arbitrator, through a
      telephonic hearing, or by an in-person hearing as established by the AAA
      Rules. If your Claim exceeds $10,000, the right to a hearing will be
      determined by the AAA Rules. No matter how the arbitration is conducted,
      the arbitrator will issue a reasoned written decision sufficient to
      explain the essential findings and conclusions on which the award is
      based. The arbitrator will apply applicable substantive law consistent
      with the Federal Arbitration Act, 9 U.S.C. §§ 1-16 (“FAA”) and applicable
      statutes of limitations and will honor claims of privilege recognized at
      law. All aspects of the arbitration proceeding, and any ruling, decision,
      or award by the arbitrator, will be strictly confidential for the benefit
      of all parties.
    </p>
    <p>
      e. Arbitration Costs. We will pay the initial filing fee to commence
      arbitration. All other arbitration fees will be allocated in accordance
      with the applicable AAA Rules. If we prevail, we will not recover our
      arbitration fees unless the arbitrator decides your Claim was frivolous.
      Each party is responsible for its own attorney’s fees, expert fees, and
      any other expenses, unless the arbitrator awards such fees or expenses to
      the other party based on applicable law.
    </p>
    <p>
      f. Arbitration Award and Appeal. The arbitrator’s award will be binding on
      the parties and may be entered as a judgment in any court of competent
      jurisdiction, unless a party exercises any right of appeal provided by the
      FAA. While an arbitrator may award declaratory or injunctive relief, the
      arbitrator may do so only for the individual party seeking relief and only
      as needed to provide relief warranted by the individual party’s Claims.
      The arbitrator’s decision and judgment criteria will not have a
      precedential or collateral estoppel effect on Claims asserted by any
      individual or entity who was not party to the arbitration.
    </p>
    <p>
      g. Severability. If any provision of this Arbitration Agreement is found
      unenforceable, the unenforceable provision will be severed, and the
      remaining arbitration terms will be enforced (but in no case will there be
      a class or representative arbitration). If a court or arbitrator decides
      that this Arbitration Agreement cannot be enforced as to a particular
      Claim for relief, then that Claim (and only that Claim) must be severed
      from the arbitration and may be brought in court.
    </p>
    <p>
      h. Exceptions. Individual Claims filed in small claims court are not
      subject to this Arbitration Agreement if the Claim remains in small claims
      court. Further, while the arbitrator alone will have the authority to
      interpret the scope and enforceability of this Arbitration Agreement, any
      Claim concerning the scope or enforceability of the prohibition on class,
      collective, or representative Claims, or Claims in arbitration for public
      injunctive relief, will be resolved by the state and federal courts in
      Connecticut in accordance with Connecticut law.
    </p>
    <p>
      i. Survival. The Arbitration Agreement will survive repayment of all
      amounts owed to us; the closing, termination, canceling, or suspension of
      your Account; the sale of your Account or amounts owed on your Account to
      another person or entity; and any bankruptcy by you to the extent allowed
      by applicable bankruptcy law.
    </p>
    <h1>12. Limitation of Liability</h1>
    <p>
      You agree that we, our service providers (including the Bank and Cloaked
      Pay, LLC, or Creditors), agents, officers, directors, and employees (and
      the same of our service providers, subsidiaries and affiliates themselves)
      (collectively, the “Indemnified Parties”) will not be liable for anything
      that we do when following your instructions. In addition, the Indemnified
      Parties will not be liable if any such Indemnified Party does not follow
      your instructions if we reasonably believe that your instructions are not
      permitted under applicable law or would expose us to potential loss or
      civil or criminal liability, or conflict with customary banking practices.
      To the extent you are entitled to relief under this Agreement, the total
      liability of the Indemnified Parties to you will not exceed $500, except
      pursuant to Section 5(i) and Section 10 or as otherwise required by
      applicable law. THE INDEMNIFIED PARTIES WILL NOT BE LIABLE FOR INDIRECT,
      SPECIAL, OR CONSEQUENTIAL DAMAGES REGARDLESS OF THE FORM OF ACTION AND
      EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF WE
      FAIL TO STOP PAYMENT ON AN ITEM, OR PAY AN ITEM BEARING AN UNAUTHORIZED
      SIGNATURE, FORGED SIGNATURE, OR FORGED ENDORSEMENT OR ALTERATION, OUR
      LIABILITY, IF ANY, WILL BE LIMITED TO THE FACE AMOUNT OF THE ITEM. This
      section will survive termination of your Account and termination of this
      Agreement.
    </p>
    <h1>13. No Warranties</h1>
    <p>
      THE USE OF “CLOAKED” IN THIS SECTION AND THE LIMITATION OF LIABILITY
      SECTION MEANS CLOAKED, ITS PROCESSORS, ITS SUPPLIERS, BANK, CREDITORS, AND
      OTHER PARTNERS, AND ITS LICENSORS (AND THEIR RESPECTIVE SUBSIDIARIES,
      AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES).
    </p>
    <p>
      THE SERVICES ARE PROVIDED “AS IS” WITHOUT REPRESENTATION OR WARRANTY,
      WHETHER IT IS EXPRESS, IMPLIED, OR STATUTORY. WITHOUT LIMITING THE
      FOREGOING, CLOAKED SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
    </p>
    <p>
      CLOAKED DOES NOT WARRANT OR GUARANTEE THAT THE SERVICES ARE ACCURATE,
      RELIABLE OR CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT
      THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
      UNINTERRUPTED, ERROR-FREE, WITHOUT DEFECT OR SECURE; THAT ANY DEFECTS OR
      ERRORS WILL BE CORRECTED; THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER
      HARMFUL COMPONENTS; OR THAT THE SERVICES WILL BE COMPATIBLE WITH YOUR
      DEVICE OR CARRIER.
    </p>
    <p>
      Cloaked does not warrant, endorse, guarantee, or assume responsibility for
      any product or services advertised or offered by a third party. Cloaked
      does not have control of, or liability for, goods or services that are
      paid for using the Services.
    </p>
    <p>
      Any third-party software, hardware and other products or services
      included, sold or purchased through or in connection with the Services are
      provided solely according to the warranty and other terms specified by the
      manufacturer, merchant or provider, who is solely responsible for service
      and support for its product. For service, support, or warranty assistance,
      you should contact the third-party product provider directly. CLOAKED
      MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT
      TO SUCH THIRD-PARTY PRODUCTS, AND EXPRESSLY DISCLAIMS ANY WARRANTY OR
      CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
      PARTICULAR PURPOSE.
    </p>
    <p>
      Cloaked shall not be liable for any delay or failure to perform resulting
      from causes outside its reasonable control, including but not limited to,
      acts of God, war, terrorism, riots, embargos, acts of civil or military
      authorities, fire, floods, accidents, strikes or shortages of
      transportation facilities, fuel, energy, labor or materials.
    </p>
    <h1>14. Indemnification</h1>
    <p>
      You agree to indemnify and hold the Indemnified Parties harmless from and
      against losses arising inconnection with the services provided under this
      Agreement, except for losses arising out of our own gross negligence or
      willful misconduct. You further agree to hold the Indemnified Parties
      harmless from losses arising out of actions taken or omitted in good faith
      by any Indemnified Party in reliance upon instructions from you. The
      Indemnified Parties are not responsible for any actions or omissions by
      any third party. If you give us instructions that we believe may expose us
      to potential liability or are in violation of applicable law, in our sole
      discretion, we may refuse to follow your instructions and we will not be
      liable to you if we refuse to follow your instructions. If we do choose to
      follow your instructions, we may ask you for certain protections such as a
      surety bond or an indemnity agreement in a form that is satisfactory to
      us. This section will survive termination of your Account and termination
      of this Agreement.
    </p>
  </div>
</template>

<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $color-primary-100;

  thead {
    th {
      text-align: left;
      padding: 10px;
      border-bottom: 1px solid $color-primary-100;
      font-weight: 600;
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid $color-primary-100;
        border-left: 1px solid $color-primary-100;

        &:first-of-type {
          border-left: none;
        }

        ul {
          margin-top: 15px !important;
          margin-left: 15px;

          li {
            margin-top: 5px;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: -15px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $color-primary-100;
              transform: translateY(-50%);
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

h1 {
  font-size: 14px;
  font-weight: 600;
}

* {
  margin-top: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  &:first-child {
    margin-top: 0;
  }
}
</style>
