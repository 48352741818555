export const STEP_FORWARDING_EMAIL = "forwarding-email";
export const STEP_VERIFY_EMAIL = "verify-email";
export const STEP_PHONE_2FA = "phone-2fa";
export const STEP_FORWARDING_PHONE = "forwarding-phone";
export const STEP_FIRST_STEPS = "first-steps";

export const ONBOARDING_FORM_STEPS = [
  STEP_PHONE_2FA,
  STEP_FORWARDING_PHONE,
  STEP_FORWARDING_EMAIL,
  STEP_VERIFY_EMAIL,
  STEP_FIRST_STEPS,
];

export const FORM_VALIDATION_DEBOUNCE_TIMEOUT = 500;
