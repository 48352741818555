<script setup>
import { useAttrs } from "vue";
import DataDeletePagePlanFamily from "@/components/feature/data-delete/DataDeletePagePlanFamily.vue";
import DataDeletePagePlanOptIn from "@/components/feature/data-delete/DataDeletePagePlanOptIn.vue";
import { usePostHogFeatureFlag } from "@/composables";
import { PH_FEATURE_FLAG_PRICING_EXPERIMENT_V2 } from "@/scripts/posthogEvents";

const attrs = useAttrs();

const { hasLoadedFeatureFlag, featureFlag } = usePostHogFeatureFlag(
  PH_FEATURE_FLAG_PRICING_EXPERIMENT_V2
);
</script>

<template>
  <DataDeletePagePlanFamily
    v-if="hasLoadedFeatureFlag && featureFlag === 'bulk_plan'"
    v-bind="$attrs"
  >
    <template #title v-if="$route.name === 'SubcriptionUpFront'">
      Choose a plan and get started today
    </template>
  </DataDeletePagePlanFamily>
  <DataDeletePagePlanOptIn v-else-if="hasLoadedFeatureFlag" v-bind="attrs" />
</template>

<style lang="scss">
@import "@/components/feature/data-delete/atoms/data-delete-sticky";

.data-delete-plan {
  max-width: 800px;
  margin: 0 auto;

  @media all and (min-width: $screen-xl) {
    margin-top: clamp(40px, 5vh, 150px);
  }

  .subscribe-payment__subscribe {
    @extend .data-delete-sticky;
  }
}
</style>
