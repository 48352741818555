<script setup>
import { computed, ref, reactive, watch } from "vue";
import store from "@/store";
import AppModal from "@/components/ui/AppModal.vue";
import SubscribeModalSubscribed from "@/components/feature/subscribe/SubscribeModalSubscribed.vue";
import SubscribeModalSubscribe from "@/components/feature/subscribe/SubscribeModalSubscribe.vue";
import DownloadRecovery from "@/components/modals/DownloadRecovery.vue";

const isRecoveryOpen = ref(false);
const isSubscribed = computed(() => store.getters["settings/isSubscribed"]);
const isModalOpen = computed(() => store.getters["subscription/isModalOpen"]);

const state = reactive({
  showSubscribedModal: false,
});

watch(
  () => isSubscribed.value,
  (value) => {
    if (value) {
      // NOTE: hack to hide subscribed modal temporarily if user is in DD flow
      setTimeout(() => {
        state.showSubscribedModal = true;
      }, 1000);
    }
  },
  { immediate: true }
);
</script>

<template>
  <AppModal
    :has-outside-click-close="!isSubscribed"
    :value="isModalOpen"
    @input="store.commit('subscription/SET_IS_MODAL_OPEN', $event)"
  >
    <DownloadRecovery
      v-if="isRecoveryOpen"
      @close="store.commit('subscription/SET_IS_MODAL_OPEN', false)"
    />
    <SubscribeModalSubscribed
      v-else-if="state.showSubscribedModal"
      @close="isRecoveryOpen = true"
    />
    <SubscribeModalSubscribe v-else-if="!isSubscribed" />
  </AppModal>
</template>
