<template>
  <p class="app-form-error">
    <slot />
  </p>
</template>

<style lang="scss">
.app-form-error {
  color: $color-alert;
  font-family: $poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
}
</style>
