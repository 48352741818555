<script setup>
import FileWarning from "@/assets/icons/file-warning.svg";
import { computed } from "vue";
import store from "@/store";

const emit = defineEmits(["remove"]);

const fileName = computed(() => store.getters["accountsImporter/getFileName"]);
</script>

<template>
  <span>
    <FileWarning class="import-upload-file__icon" />
    <span class="import-upload-file__content">
      <span class="import-upload-file__label">
        <span class="import-upload-file__title">Ready for review</span>
        {{ fileName }}
        <button
          @click.prevent="emit('remove')"
          class="import-upload-file__action"
        >
          Remove
        </button>
      </span>
    </span>
    <span class="import-upload-file__after-content">
      <span class="import-upload-file__after-content-title">
        The file may have formatting issues.
      </span>
      <span class="import-upload-file__after-content-text">
        <a
          href="https://help.cloaked.app/hc/en-us/sections/19705847998100-Importing-Passwords"
          target="_blank"
          class="import-upload-file__after-content-link"
          >Click here for help resolving the issue.</a
        >
      </span>
    </span>
  </span>
</template>
