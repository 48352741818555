<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";

const props = defineProps({
  link: {
    type: [String, Object],
    default: null,
  },
  nested: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: null,
  },
  button: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
  },
  count: {
    type: Number,
    default: 0,
  },
});

function maxCount(count) {
  if (count > 99) {
    return "99+";
  }
  return count;
}

function handleClick(e) {
  e.stopPropagation();
  if (props.name !== "Get Started") {
    store.dispatch("getStarted/openGetStarted", false);
  }
}
</script>

<template>
  <div class="menu-item" :class="{ nested: nested }" @click="handleClick">
    <router-link
      :to="props.link"
      v-if="props.link"
      active-class="active"
      exact-path
    >
      <div class="name-row">
        <inlineSvg
          class="icon"
          :name="props.icon"
          :key="`icon-${Math.random()}`"
          v-if="props.icon"
        />
        <span>{{ props.name }}</span>
      </div>

      <div v-if="props.count !== 0" class="count">
        {{ maxCount(props.count) }}
      </div>
    </router-link>
    <a v-if="props.button" class="button">
      <inlineSvg :name="props.icon" :key="Math.random()" />
      <span>{{ props.name }}</span>
    </a>
    <slot v-if="!props.link && !props.button"></slot>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0.5px solid $color-surface;
  border-bottom: 0.5px solid $color-surface;
  transition: transform 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  overflow: hidden;
  border-radius: 8px;

  &:has(> a.active) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.nested {
    margin-left: 12px;
    width: calc(100% - 12px);
  }
  &:hover {
    z-index: 4;
    transform: scale(1.02) translate3d(0, 0, 0);
    border-top: 0.5px solid $color-primary-10;
    border-bottom: 0.5px solid $color-primary-10;
    background: $color-primary-5;
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
  &:active {
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transform: scale(1) translate3d(0, 0, 0);
  }

  a {
    padding: 6px 12px;
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    color: $color-primary-100;
    border-left: 2px solid $color-surface;
    width: 100%;
    overflow: hidden;
    &.button {
      justify-content: flex-start;
    }

    .name-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      overflow: hidden;
    }

    &.active {
      background: $color-primary-5;
      border-left: 2px solid $color-primary-100;
      overflow: hidden;

      svg {
        color: $color-primary-100;
      }

      span {
        color: $color-primary-100;
        font-weight: 600;
      }
    }

    .icon {
      width: 13px;
      height: 13px;
      color: $color-primary-60;
    }

    span {
      color: $color-primary-70;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .count {
      color: $white !important;
      padding: 0 6.5px;
      background: $color-info;
      border-radius: 999px;
      font-size: 12px;
      line-height: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
