<template>
  <h2 class="subscribe-section-title">
    <slot />
  </h2>
</template>

<style lang="scss">
.subscribe-section-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  margin-bottom: 2px;
}
</style>
