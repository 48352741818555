<script setup></script>
<template>
  <div class="title-wrapper">
    <h1 class="title">Data Removal</h1>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    color: $color-primary-100;
  }
}
</style>
