<template>
  <svg
    :width="width || 12"
    :height="height || 11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.52344 5.99219H5.36719V9.83594C5.36719 10.1719 5.65625 10.4688 6 10.4688C6.34375 10.4688 6.625 10.1719 6.625 9.83594V5.99219H10.4766C10.8125 5.99219 11.1016 5.70312 11.1016 5.35938C11.1016 5.01562 10.8125 4.72656 10.4766 4.72656H6.625V0.882812C6.625 0.546875 6.34375 0.25 6 0.25C5.65625 0.25 5.36719 0.546875 5.36719 0.882812V4.72656H1.52344C1.17969 4.72656 0.890625 5.01562 0.890625 5.35938C0.890625 5.70312 1.17969 5.99219 1.52344 5.99219Z"
      fill="currentColor"
    />
    />
  </svg>
</template>

<script>
export default {
  name: "PlusIcon",
  props: ["width", "height"],
};
</script>
