<script setup>
import DataDeleteCard from "@/components/feature/data-delete/atoms/DataDeleteCard.vue";
import DataDeleteCardDivider from "@/components/feature/data-delete/atoms/DataDeleteCardDivider.vue";
import DataDeleteCardInfo from "@/components/feature/data-delete/atoms/DataDeleteCardInfo.vue";
import DataDeleteThreatTag from "@/components/feature/data-delete/atoms/DataDeleteThreatTag.vue";
import { computed, watch, defineEmits } from "vue";
import { useDateOfBirthParsing } from "@/components/feature/data-delete/composables";

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const { dateOfBirth } = useDateOfBirthParsing(props.result);

const driversLicense = computed(() => props.result.driversLicenses?.[0]);

const currentAddress = computed(() => props.result.locations?.[0]);
const currentStreet = computed(() => currentAddress.value.street);
const currentCity = computed(() => {
  return [
    currentAddress.value?.city?.toLowerCase(),
    currentAddress.value?.state?.abbreviation,
  ]
    .filter((value) => !!value)
    .join(", ");
});

const previousAddresses = computed(() => {
  const previousStatesAbbreviations = props.result.locations
    ?.slice(1)
    .map((location) => location.state?.abbreviation);

  const states = Array.from(new Set(previousStatesAbbreviations));

  return {
    count: previousStatesAbbreviations.length,
    states: [states.slice(0, -1).join(", "), states[states.length - 1]]
      .filter((value) => !!value)
      .join(" and "),
  };
});

const isCardVisible = computed(
  () =>
    props.result.ssn ||
    dateOfBirth.value ||
    currentAddress.value ||
    driversLicense.value
);

const emit = defineEmits(["update:modelValue"]);
watch(
  () => isCardVisible.value,
  (isVisible) => emit("update:modelValue", !!isVisible),
  { immediate: true }
);
</script>

<template>
  <DataDeleteCard
    type="alert"
    class="data-delete-results-high"
    v-if="isCardVisible"
  >
    <DataDeleteThreatTag threatLevel="high" />
    <div
      class="data-delete-results-high__column"
      v-if="result.ssn || dateOfBirth"
    >
      <div v-if="result.ssn">
        <h3 class="data-delete__subtitle">SSN</h3>
        <span
          class="data-delete-results-high__data data-delete-results-high__data--large"
        >
          {{ result.ssn }}
        </span>
      </div>
      <div v-if="dateOfBirth">
        <h3 class="data-delete__subtitle">Date of Birth</h3>
        <span
          class="data-delete-results-high__data data-delete-results-high__data--large"
        >
          {{ dateOfBirth }}
        </span>
      </div>
    </div>
    <div v-if="currentAddress">
      <h3 class="data-delete__subtitle">Addresses</h3>
      <div
        class="data-delete-results-high__data data-delete-results-high__data--large data-delete__capitalized"
      >
        <template v-if="currentStreet">
          {{ currentStreet }}
        </template>
        <br v-if="currentStreet && currentCity" />
        <template v-if="currentCity">
          {{ currentCity }}
        </template>
      </div>
      <div
        class="data-delete-results-high__data"
        v-if="previousAddresses.count"
      >
        + {{ previousAddresses.count }} previous in
        {{ previousAddresses.states }}
      </div>
    </div>
    <div v-if="driversLicense">
      <h3 class="data-delete__subtitle">Driver's License</h3>
      <span
        class="data-delete-results-high__data data-delete-results-high__data--large"
      >
        {{ driversLicense }}
      </span>
    </div>
    <DataDeleteCardDivider />
    <DataDeleteCardInfo>
      Fraudsters with this information can apply for: a new bank account or
      Credit Card; a Driver's License; an IRS Tax Refund; even a job using your
      name.
    </DataDeleteCardInfo>
  </DataDeleteCard>
</template>

<style lang="scss">
.data-delete-results-high {
  &__column {
    display: flex;
    align-items: start;
    width: 100%;
    flex-wrap: wrap;
    gap: 16px;

    & > * {
      flex: 1;
    }
  }

  &__data {
    color: $color-alert;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.1px;

    &--large {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.5px;

      @media (min-width: $screen-xl) {
        font-size: 24px;
      }
    }
  }
}
</style>
