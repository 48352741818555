<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import DataRemovalRequestsRowDetailsPeopleList from "@/routes/DataDeletion/components/RequestRows/DataRemovalRequestsRowDetailsPeopleList.vue";
import { computed } from "vue";
import { MONTHS, DATA_REMOVAL_STATUS } from "@/scripts/constants";
import { formatAddress } from "@/scripts/format";
const props = defineProps({
  request: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["open"]);

const recordsFormatted = computed(() => {
  return props.request.records.map((record) => {
    const parsedPII = JSON.parse(record.pii);
    parsedPII.addresses = parsedPII.addresses.map((address) => {
      return formatAddress(address);
    });
    return {
      ...record,
      pii: parsedPII,
    };
  });
});

const statusIcon = computed(() => {
  switch (props?.request?.state?.toLowerCase()) {
    case DATA_REMOVAL_STATUS.SCANNING:
      return "scanning";
    case DATA_REMOVAL_STATUS.NO_RECORDS_FOUND:
      return "stars-magic";
    case DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS:
      return "clock-progress";
    case DATA_REMOVAL_STATUS.REMOVED:
      return "checkbox-checked";
    default:
      return "";
  }
});

const statusText = computed(() => {
  switch (props?.request?.state?.toLowerCase()) {
    case DATA_REMOVAL_STATUS.SCANNING:
      return "Scanning";
    case DATA_REMOVAL_STATUS.NO_RECORDS_FOUND:
      return "No records found";
    case DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS:
      return "Removal in progress";
    case DATA_REMOVAL_STATUS.REMOVED:
      return "Removed";
    default:
      return "";
  }
});

const statusClass = computed(() => {
  switch (props?.request?.state?.toLowerCase()) {
    case DATA_REMOVAL_STATUS.SCANNING:
      return "status-scanning";
    case DATA_REMOVAL_STATUS.NO_RECORDS_FOUND:
      return "status-no-records";
    case DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS:
      return "status-in-progress";
    case DATA_REMOVAL_STATUS.REMOVED:
      return "status-removed";
    default:
      return "";
  }
});

const alertText = computed(() => {
  switch (props?.request?.state?.toLowerCase()) {
    case DATA_REMOVAL_STATUS.SCANNING:
      return `Cloaked is scanning this broker for your information...`;
    case DATA_REMOVAL_STATUS.NO_RECORDS_FOUND:
      return `Cloaked found no records on ${props.request?.broker_name} with your information`;
    case DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS:
      return `Cloaked is working to remove your records from ${props.request?.broker_name}`;
    case DATA_REMOVAL_STATUS.REMOVED:
      return `Cloaked removed ${
        props.request?.records_matched === 1 ? "record" : "records"
      } from ${props.request?.broker_name}`;
    default:
      return "";
  }
});

const sentDate = computed(() => {
  if (props.request.sent_at) {
    const sentAtDate = new Date(props.request.sent_at);
    return ` ${
      MONTHS[sentAtDate.getMonth()]
    } ${sentAtDate.getDate()}, ${sentAtDate.getFullYear()}`;
  }
  return "—";
});

const completedDate = computed(() => {
  if (props.request.completed_at) {
    const completedAtDate = new Date(props.request.completed_at);
    return ` ${
      MONTHS[completedAtDate.getMonth()]
    } ${completedAtDate.getDate()}, ${completedAtDate.getFullYear()}`;
  }
  return "—";
});

const estimatedTime = computed(() => {
  if (props.request.completed_at) {
    const completedAtDate = new Date(props.request.completed_at);
    const today = new Date();
    if (today > completedAtDate) {
      return "Complete";
    }
  }
  if (typeof props.request.estimated_days_to_remove_records === "number") {
    const days = props.request.estimated_days_to_remove_records;
    return `${days} ${days === 1 ? "day" : "days"}`;
  }
  return "—";
});
</script>

<template>
  <div
    class="requests-table-row-wrapper"
    :class="{ opened: props.request.opened }"
    @click="emit('open')"
  >
    <div class="requests-table-row">
      <div class="broker-cell">
        <span class="icon">{{ props.request.opened ? "-" : "+" }}</span>
        <span class="broker-name">{{ ` ${request.broker_name}` }}</span>
      </div>
      <div class="status-cell" :class="statusClass">
        <InlineSvg :name="statusIcon" class="status-icon" />
        <span>{{ statusText }}</span>
      </div>
      <div class="sent-on-cell">
        {{ sentDate }}
      </div>
      <div class="completed-on-cell">
        {{ completedDate }}
      </div>
      <div
        class="estimated-time-cell"
        :class="{ 'no-estimate': !estimatedTime.includes('day') }"
      >
        {{ estimatedTime }}
      </div>
    </div>
    <div class="details-row-wrapper" :class="{ opened: props.request.opened }">
      <DataRemovalRequestsRowDetailsPeopleList
        :brokerName="props.request.broker_name"
        :records="recordsFormatted"
        :status="props.request.state"
        :svgIcon="statusIcon"
        :alertText="alertText"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.requests-table-row-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  padding: 24px;
  cursor: pointer;

  @at-root .theme-dark & {
    background-color: $color-primary-5;
  }
  &.opened {
    border: 1px solid $color-primary-100;
    background-color: transparent;
    box-shadow: 0px 18px 34px 0px rgba($color-primary-100-light, 0.15);
    transition: all 0.3s ease-in-out;
    @at-root .theme-dark & {
      box-shadow: 0px 18px 34px 0px rgba($color-primary-50-dark, 0.15);
    }
  }
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.003);
    border: 1px solid $color-primary-100;
  }
  .requests-table-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    div {
      font-size: 15px;
      color: $color-primary-100;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: calc((100% + 50px) / 5);
      text-wrap: wrap;
      @include line-clamp(1);
      .status-icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
      &:first-of-type {
        width: calc(((100% + 50px) / 5) - 24px);
      }
      &:last-of-type {
        width: calc(((100% + 50px) / 5) - 24px);
      }

      &.broker-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        span {
          @include line-clamp(1);
          &.broker-name {
            width: calc(100% - 42px);
          }
        }
        .icon {
          width: 11px;
        }
      }

      &.status-cell {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        span {
          @include line-clamp(1);
        }
        &.status-removed {
          color: $color-success;
        }

        &.status-in-progress {
          color: $color-warning;
        }

        &.status-no-records {
          color: $color-lt-green;
        }
        &.status-scanning {
          color: $color-primary-100;
        }
      }

      &.sent-on-cell,
      .completed-on-cell {
        color: $color-primary-100;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      &.estimated-time-cell {
        font-weight: 600;
        &.no-estimate {
          color: $color-primary-100;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
          opacity: 0.6;
        }
      }
    }
  }
}

.details-row-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 0.3s ease-out, opacity 0.3s ease-out;
  &.opened {
    grid-template-rows: 1fr;
    opacity: 1;
  }
}
</style>
