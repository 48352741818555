import AuthService from "@/api/actions/auth-service";
import router from "@/routes/router";
import { refresh_channel, refreshToken } from "@/scripts/actions/auth";
import { authDecrypt } from "@/scripts/actions/encryption";
import PersonalInfoService from "@/api/settings/personal-services";
import moment from "moment";
import UserService from "@/api/actions/user-service";
import { nextTick, watch } from "vue";
import { useToast } from "@/hooks";
import { DATA_DELETE_REQUESTED } from "@/scripts/userFlags";
const { success, error } = useToast();

const defaultState = () => ({
  auth: null,
  guest: null,
  encryption: null,
  user: null,
  username: null,
  encrypted_username: null,
  migration: null,
  extension: null,
  collections: [],
  MfaMethods: [],
});
let timeout;
export default {
  namespaced: true,
  state: defaultState(),

  mutations: {
    setMfaMethods(state, methods) {
      state.MfaMethods = methods;
    },
    setExtensionAuth: (state, { oauth }) => {
      state.extension = oauth;
    },
    setPayload: (state, { payload }) => {
      state.encryption = payload.encryption;
      state.collections = payload.user.collections;

      state.user = payload.user;
      if (payload.user.account_version === 2) {
        state.encrypted_username = payload.user.username_encrypted;
      }
      const extension_key = Object.keys(payload.auth).filter(
        (k) => k !== window.ENV.VUE_APP_CLIENT_ID
      );

      state.extension = payload.auth[extension_key[0]] || null;
    },
    setAuth(state, { oauth }) {
      state.auth = {
        access_token: oauth.access_token,
        refresh_token: oauth.refresh_token,
        expires_in: typeof (oauth.expires_in === "string")
          ? moment().add(oauth.expires_in, "seconds").format()
          : oauth.expires_in,
      };
      state.migration = null;
    },
    setGuest(state, data) {
      if (data) {
        state.guest = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        };
      } else {
        state.guest = null;
      }
    },
    setUser(state, user) {
      state.user = user;
    },
    setUsername(state, username) {
      state.username = username;
    },
    setMigration(state, payload) {
      state.migration = payload;
    },
    setLogout: (state) => {
      Object.assign(state, defaultState());
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.auth && state.auth.access_token;
    },
    isGuestAuthenticated(state) {
      return !!state.guest && state.guest.access_token;
    },
    username(state) {
      if (state.username) {
        return state.username;
      }
      return (state.user && state.user.username) || "...";
    },
    user(state) {
      return state.user;
    },
    authToken(state) {
      return state.auth.access_token;
    },
    collection: (state) => (collection_name) => {
      return state.collections.find((f) => f.name === collection_name).url;
    },
    isPendingDeletion(state) {
      return state.user.state === "pending_deletion";
    },
  },
  actions: {
    waitForAuthentication({ getters }) {
      return new Promise((resolve) => {
        const unwatch = watch(
          () => getters.isAuthenticated || getters.isGuestAuthenticated,
          (isAuthenticated) => {
            if (!isAuthenticated) {
              return;
            }

            resolve();
            nextTick(() => unwatch());
          },
          { immediate: true }
        );
      });
    },
    handleAcceptInvite: ({ state, dispatch }, inviteCode) => {
      const unwatch = watch(
        () => state.user?.created_at,
        async (newValue) => {
          if (newValue) {
            nextTick(() => unwatch());

            const shouldGetDataDeletion =
              new Date().getTime() -
                new Date(state.user?.created_at).getTime() <
              15 * 60 * 1000;

            try {
              await dispatch(
                "subscription/acceptInvite",
                {
                  code: inviteCode,
                  enable_data_deletion: shouldGetDataDeletion,
                },
                {
                  root: true,
                }
              );

              success("Invite accepted!");

              if (shouldGetDataDeletion) {
                UserService.setFlag({
                  name: DATA_DELETE_REQUESTED,
                  value: true,
                });
              }
            } catch {
              error(`Invitation couldn't be accepted`);
            }
          }
        },
        { immediate: true }
      );
    },
    bootFromMigration: ({ dispatch, commit, state }) => {
      commit("setPayload", { payload: state.migration.payload });
      dispatch("setAccessToken", { oauth: state.migration.oauth });
      router.push({ path: "/" }).catch((e) => e);
    },
    getUsername({ state, commit }) {
      if (state.username && !state.username.match(/^boxSeal/)) {
        return state.username;
      }
      if (state.encrypted_username) {
        return authDecrypt(state.encrypted_username).then((decrypted) => {
          commit("setUsername", decrypted);
          return decrypted;
        });
      }
      return (state.user && state.user.username) || "...";
    },
    getUsernameHash({ state, commit }) {
      if (state.username && !state.username.match(/^boxSeal/)) {
        return state.user.username_hash;
      }
      return state.user?.id;
    },
    setExtensionToken: (
      { dispatch, commit, state },
      { payload, codeVerifier, client_id }
    ) => {
      return dispatch("getAuthToken", {
        payload,
        codeVerifier,
        client_id,
        handler: ({ data }) => {
          commit("setExtensionAuth", { oauth: data });
        },
      });
    },
    setAuthPayload: (
      { dispatch, commit, state },
      { payload, codeVerifier, client_id }
    ) => {
      if (state.user && state.user.id !== payload.user.id) {
        return dispatch("getAuthToken", {
          payload,
          codeVerifier,
          client_id,
          handler: (oauth) => {
            dispatch("logout", {}, { root: true });
            setTimeout(() => {
              commit("setPayload", { payload });
              dispatch("setAccessToken", { oauth: oauth.data });
              refresh_channel.postMessage("refresh");
              window.location.reload();
            }, 500);
          },
        });
      } else {
        return dispatch("getAuthToken", {
          payload,
          codeVerifier,
          client_id,
          handler: (oauth) => {
            commit("setPayload", { payload });
            dispatch("setAccessToken", { oauth: oauth.data });
          },
        });
      }
    },
    getAuthToken(ignore, { payload, codeVerifier, handler, client_id }) {
      const code = payload.auth[client_id]?.code;
      if (code) {
        const data = {
          grant_type: "authorization_code",
          client_id: client_id,
          redirect_uri: payload.auth[client_id].redirect_uri.split("?")[0],
          code: code,
          code_verifier: codeVerifier,
        };

        return AuthService.login(data).then(handler);
      }
      return Promise.reject();
    },
    getUser({ dispatch, commit }) {
      return UserService.getUserDetails().then(({ data }) => {
        const user = data.results[0];
        commit("encryptionStatus", user.has_setup_encryption, { root: true });
        commit("encryptionFeatureFlag", user.flags.encryption, {
          root: true,
        });
        commit("setUser", user);
        setTimeout(() => {
          dispatch("getUsername");
        }, 500);
        return PersonalInfoService.getInfo();
      });
    },
    setTimeout(ignore, expires) {
      if (timeout) {
        clearTimeout(timeout);
      }
      const momentObject =
        expires instanceof moment ? expires : moment(expires);
      const time =
        typeof expires === "number"
          ? expires
          : momentObject.diff(moment(), "seconds") - 30;
      const fireTime = time > 30 ? time : 1;
      timeout = setTimeout(refreshToken, fireTime * 1000);
    },
    setAccessToken({ commit, dispatch }, { oauth }) {
      const expires = oauth.expires_in;
      dispatch("setTimeout", expires);
      commit("setAuth", { oauth });
    },
    setGuestToken({ commit }, data) {
      commit("setGuest", data);
    },
    setRefreshTimeout({ state, dispatch }) {
      const now = moment().add(30, "seconds");
      const expires = state.auth.expires_in;
      const test = state.auth && state.auth.expires_in && now.isAfter(expires);
      if (test) {
        refreshToken();
      } else {
        dispatch("setTimeout", expires);
      }
    },
    logout({ commit }) {
      commit("setLogout");
    },
    setMfaMethods({ commit }, enabledMethods) {
      commit("setMfaMethods", enabledMethods);
    },
  },
};
