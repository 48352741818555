import { computed, ref, watch } from "vue";
import { usePlans } from "@/components/feature/subscribe/composables";
import { toValue } from "@vueuse/core/index";
import store from "@/store";

const selectedPlanOption = ref(null);

export const usePlanOptions = ({
  intent,
  selectedBillingCycle,
  defaultSelectedOption,
}) => {
  const { allPlans, activePlan } = usePlans();

  const allOptions = computed(() => [
    ...allPlans.value
      .filter((plan) => plan.provider === "stripe")
      .map((plan) => {
        const paypalPlan =
          allPlans.value.find(
            (paypalPlan) =>
              paypalPlan.provider === "paypal" &&
              paypalPlan.type === plan.type &&
              paypalPlan.recurring_interval === plan.recurring_interval
          ) ?? null;

        return {
          id: plan.product_id,
          title: plan.displayName,
          afterTitle:
            plan.max_members === 1
              ? "1 member"
              : plan.max_members === 2
              ? "2 members"
              : `up to ${plan.max_members} members`,
          text:
            plan.max_members > 1
              ? `$${plan.displayMonthlyPricePerUser}/member per month`
              : `$${plan.displayMonthlyPricePerUser}/per month`,
          afterText:
            plan.discount &&
            (plan.product_id !== activePlan.value?.product_id ||
              !store.getters["settings/getSubscription"].owner)
              ? `Save ${plan.discount}%`
              : null,
          type: "plan",
          isActive: store.getters["settings/getSubscription"].owner
            ? [plan?.product_id, paypalPlan?.product_id]
                .filter((value) => !!value)
                .includes(activePlan.value?.product_id)
            : false,
          stripePlan: plan,
          paypalPlan,
          intents: ["subscribe", "cancel", "resubscribe"],
        };
      }),
    {
      id: "cancel",
      title: "Leave Plan",
      text: "Downgrade to limited plan",
      type: "cancel",
      intents: ["cancel"],
    },
    {
      id: "stay",
      title: "Stay on limited",
      text: "Read-only experience",
      type: "stay",
      intents: ["resubscribe"],
    },
  ]);

  const selectedPlan = computed(() => selectedPlanOption.value?.stripePlan);
  const selectedPaypalPlan = computed(
    () => selectedPlanOption.value?.paypalPlan
  );

  watch(
    () => allOptions.value,
    (newValue) => {
      if (newValue && !selectedPlanOption.value) {
        selectedPlanOption.value =
          newValue?.find(
            (option) =>
              option?.stripePlan?.recurring_interval ===
                toValue(selectedBillingCycle) &&
              option?.stripePlan?.type === defaultSelectedOption
          ) ?? null;
      }
    },
    { immediate: true }
  );

  const billingCycleOptions = computed(() =>
    allOptions.value.filter(
      (option) =>
        (option.stripePlan?.recurring_interval ===
          toValue(selectedBillingCycle) ||
          option.type !== "plan") &&
        option.intents.includes(toValue(intent))
    )
  );

  watch(
    () => selectedBillingCycle.value,
    (newBillingCycle) => {
      const newOption = allOptions.value.find(
        (option) =>
          option.stripePlan?.type ===
            selectedPlanOption.value?.stripePlan?.type &&
          option.stripePlan?.recurring_interval === newBillingCycle
      );

      if (newOption?.isActive) {
        selectedPlanOption.value = allOptions.value.find(
          (option) => option.stripePlan?.recurring_interval === newBillingCycle
        );

        return;
      }

      if (newOption) {
        selectedPlanOption.value = newOption;
      }
    }
  );

  return {
    selectedPlanOption,
    billingCycleOptions,
    selectedPlan,
    selectedPaypalPlan,
  };
};
