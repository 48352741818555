<script setup>
import FileLoading from "@/assets/icons/file-loading.svg";
import store from "@/store";
import { computed } from "vue";
const emit = defineEmits(["remove"]);

const fileName = computed(() => store.getters["accountsImporter/getFileName"]);
</script>

<template>
  <span>
    <FileLoading class="import-upload-file__icon" />
    <span class="import-upload-file__content">
      <span class="import-upload-file__label import-upload-file__label--loading"
        >Uploading {{ fileName }}... <br />
        <button
          @click.prevent="emit('remove')"
          class="import-upload-file__action"
        >
          Cancel
        </button>
      </span>
    </span>
    <span class="import-upload-file__after-content">
      <span class="import-upload-file__after-content-text">
        Files must be less than 24 mb.
        <a
          href="https://help.cloaked.app/hc/en-us/sections/19705847998100-Importing-Passwords"
          target="_blank"
          class="import-upload-file__after-content-link"
          >Check out our help articles</a
        >
        for file formatting tips.
      </span>
    </span>
  </span>
</template>
