<script setup>
import { computed } from "vue";
import store from "@/store";
import { IdentityIcon } from "@/components";
import { inbox as inboxScripts } from "@/scripts";
import { getFormattedNickname } from "@/scripts/formattedText";
import { posthogCapture } from "@/scripts/posthog.js";

const recentlyUsed = computed(() => {
  if (store.state.localdb?.db_cloaks) {
    return [...store.state.localdb.db_cloaks]
      .sort((a, b) => {
        return new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1;
      })
      .filter((c) => !c.protected)
      .slice(0, 10);
  }
  return [];
});

function timestamp(updatedAt) {
  return inboxScripts.getTimeDisplayInboxList(updatedAt);
}

function openCloakDetails(cloak) {
  store.dispatch("openCloakDetails", { cloak });
  posthogCapture("user_clicked_homepagerecentidentity", {
    name: cloak.nickname,
  });
}
</script>
<template>
  <section class="recents" v-if="recentlyUsed.length">
    <h1 class="title">Recent Identities</h1>
    <div class="identities">
      <div
        class="identity"
        v-for="(identity, id_index) in recentlyUsed"
        :key="`${identity.id}-${id_index}`"
        @click="openCloakDetails(identity)"
      >
        <IdentityIcon
          :identity="identity"
          :override="{ width: '61px', height: '61px', 'align-self': 'center' }"
        />
        <div class="identity-bottom">
          <div class="nickname">{{ getFormattedNickname(identity) }}</div>
          <div class="updatedAt">{{ timestamp(identity.updated_at) }}</div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-style: normal;
  color: $color-primary-100;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}

.identities {
  gap: 8px;
  color: $color-primary-100;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

  .identity {
    cursor: pointer;
    width: 100%;
    height: 158px;
    padding: 20px 10px;
    border-radius: 17px;
    border: 1px solid $color-primary-10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      background-color: $color-primary-5;
    }
    .nickname {
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      margin-top: 10px;
      width: 100%;
      @include line-clamp(1);
      text-overflow: ellipsis;
    }
    .updatedAt {
      margin-top: 1px;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
