<template>
  <h1 class="settings-title"><slot /></h1>
</template>

<style lang="scss">
.settings-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: $color-primary-100;
}
</style>
