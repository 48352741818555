<script setup>
const props = defineProps({
  status: {
    type: String,
    default: null,
    validator: (value) => {
      return ["in-progress", "complete"].includes(value);
    },
  },
});
</script>
<template>
  <div class="data-removal-box">
    <div
      class="data-removal-value"
      :class="{
        'in-progress': !props.status === 'in-progress',
        'color-success': props.status === 'complete',
      }"
    >
      <slot name="number"></slot>
      <div class="total-number-changed" v-if="$slots.bubble">
        <slot name="bubble"></slot>
      </div>
    </div>
    <div class="data-removal-label">
      <slot name="label"></slot>
    </div>
  </div>
</template>
<style scoped lang="scss">
.data-removal-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 78px;
  width: 100%;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  padding: 16px;
  .data-removal-value {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .total-number-changed {
      font-size: 10px;
      font-weight: 600;
      color: $white;
      background-color: $color-success;
      border-radius: 49px;
      padding: 2px 6px;
      text-align: center;
    }
  }
  .data-removal-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.in-progress {
  color: $color-in-progress;
}

.color-success {
  color: $color-success;
}

.blue {
  color: $color-brand-3-100;
}
</style>
