<script setup>
const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  subheader: {
    type: String,
    default: "",
  },
});
</script>
<template>
  <div class="subtitle">
    <h2>
      {{ props.header }}
      <slot />
    </h2>
    <p>
      {{ props.subheader }}
    </p>
  </div>
</template>
<style scoped lang="scss">
.subtitle {
  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    color: $color-primary-100;
    max-width: 66%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    justify-content: flex-start;
  }
  p {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1px;
    margin-top: 8px;
    max-width: 66%;
  }
}
</style>
