<script setup>
import {
  EmailOutlineIcon,
  PhoneOutlineIcon,
  UserOutlineIcon,
  KeyIcon,
  LockShieldIcon,
  LinkChainIcon,
  PeopleIcon,
} from "@/assets/icons";
import TotpIcon from "@/assets/icons/totp.svg";
import CustomFieldIcon from "./CustomFields/CustomFieldIcon.vue";

const props = defineProps(["field"]);
</script>
<template>
  <EmailOutlineIcon v-if="props.field === 'email'" />
  <PhoneOutlineIcon v-else-if="props.field === 'phone'" />
  <KeyIcon v-else-if="props.field === 'password'" />
  <UserOutlineIcon
    v-else-if="props.field === 'username'"
    height="16px"
    width="16px"
  />
  <LockShieldIcon v-else-if="props.field === 'notes'" />
  <LinkChainIcon
    v-else-if="props.field === 'website' || props.field === 'url'"
  />
  <PeopleIcon v-else-if="props.field === 'name'" />
  <TotpIcon
    v-else-if="
      props.field === 'one-time passcode' ||
      props.field === 'totp_url' ||
      props.field === 'totp_secret'
    "
  />
  <CustomFieldIcon v-else :type="props.field" />
</template>
