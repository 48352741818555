<script setup>
import BottomBorderInputText from "@/components/BottomBorderInputText.vue";
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import DataDeleteSticky from "@/components/feature/data-delete/atoms/DataDeleteSticky.vue";
import { PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_AGE_FORM } from "@/scripts/posthogEvents";
import { computed, onMounted, ref, defineEmits } from "vue";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  isForcingNewSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input", "submit"]);

const ageInput = ref("ageInput");
onMounted(() => ageInput.value?.$refs?.input?.focus());

onMounted(() => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_AGE_FORM, {
    isForcingNewSearch: props.isForcingNewSearch,
  });
});

const errors = ref([]);

const isFormValid = computed(() => props.value.age && !errors.value?.length);

const validateAge = () => {
  const parsedAge = parseInt(props.value.age);

  if (props.value.age && !Number.isInteger(parsedAge)) {
    errors.value = ["Please enter a valid age"];
  } else if (props.value.age && parsedAge < 18) {
    errors.value = [
      "We're sorry, but you must be at least 18 years old to proceed",
    ];
  } else {
    errors.value = [];
  }
};

const onSubmit = () => {
  validateAge();

  if (isFormValid.value) {
    emit("submit");
  }
};
</script>

<template>
  <div class="data-delete-additional-search">
    <p class="data-delete__text">
      Let's verify some info before we display results
    </p>
    <h1 class="data-delete__title">Confirm your age</h1>
    <div class="data-delete-additional-search__body">
      <fieldset class="delete-additional-search__body__fieldset">
        <BottomBorderInputText
          placeholder="Age"
          :value="value.age"
          @input="$emit('input', { ...value, age: $event })"
          :maxlength="2"
          inputmode="numeric"
          @keydown.enter="isFormValid && $emit('submit')"
          ref="ageInput"
          :errors="errors"
        />
      </fieldset>
      <DataDeleteSticky class="data-delete-additional-search__cta">
        <Button
          type="primary"
          size="2xl"
          @click="onSubmit"
          :disabled="!value.age"
          class="data-delete-additional-search__cta-button"
        >
          Continue
          <inlineSvg name="arrow-right" />
        </Button>
      </DataDeleteSticky>
    </div>
    <span class="data-delete-additional-search__footer"
      >Cloaked securely searches your phone number across the dark web and 100+
      data brokers. Cloaked will not store or sell your information to these
      parties.</span
    >
  </div>
</template>
