<script setup>
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import DataDeleteSticky from "@/components/feature/data-delete/atoms/DataDeleteSticky.vue";
import { StateList } from "@/scripts/countries/states";
import { PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_STATE_FORM } from "@/scripts/posthogEvents";
import { computed, onMounted, defineEmits } from "vue";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  isForcingNewSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input", "submit"]);

onMounted(() => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_STATE_FORM, {
    isForcingNewSearch: props.isForcingNewSearch,
  });
});

const isFormValid = computed(() => props.value.state);
</script>

<template>
  <div class="data-delete-additional-search">
    <p class="data-delete__text">
      Let's verify some info before we display results
    </p>
    <h1 class="data-delete__title">What state are you from?</h1>
    <div class="data-delete-additional-search__body">
      <fieldset class="delete-additional-search__body__fieldset">
        <select
          :value="props.value.state"
          @input="$emit('input', { ...value, state: $event.target.value })"
          @keydown.enter="isFormValid && $emit('submit')"
          class="data-delete-additional-search__select"
        >
          <option value="">Select state</option>
          <option
            v-for="state in StateList"
            :key="state.value"
            :value="state.value"
          >
            {{ state.label }}
          </option>
        </select>
      </fieldset>
      <DataDeleteSticky class="data-delete-additional-search__cta">
        <Button
          type="primary"
          size="2xl"
          @click="$emit('submit')"
          :disabled="!isFormValid"
          class="data-delete-additional-search__cta-button"
        >
          Continue
          <inlineSvg name="arrow-right" />
        </Button>
      </DataDeleteSticky>
    </div>
    <span class="data-delete-additional-search__footer"
      >Cloaked securely searches your phone number across the dark web and 100+
      data brokers. Cloaked will not store or sell your information to these
      parties.</span
    >
  </div>
</template>
