import { beginningOfEveryWord, matchInWord } from "@/scripts/regex";
import { getIdentityNickname } from "@/scripts/format";

const boldenMatch = (text, query) => {
  const regex = matchInWord(query);
  return text.replace(regex, "<span class='highlight-bold'>$1</span>");
};

export const searchLocalCloaksGrouped = (cloaks, query) => {
  const groupedResults = {
    name: [],
    website: [],
    email: [],
    phone_number: [],
    other: [],
  };
  if (cloaks.length) {
    cloaks.filter((cloak) => {
      query = (query && query.toLowerCase()) || "";

      const regex = beginningOfEveryWord(query);
      const identityNickname = getIdentityNickname(cloak);
      if (identityNickname?.toLowerCase()?.match(regex)) {
        identityNickname.toLowerCase().startsWith(query)
          ? groupedResults.name.unshift({
              ...cloak,
              display: boldenMatch(identityNickname, query),
            })
          : groupedResults.name.push({
              ...cloak,
              display: boldenMatch(identityNickname, query),
            });
      } else if (
        cloak.website_url &&
        cloak.website_url.toLowerCase().match(regex) &&
        !cloak.website_url.toLowerCase().includes("cloaked.app")
      ) {
        cloak.website_url.toLowerCase().startsWith(query)
          ? groupedResults.website.unshift({
              ...cloak,
              display: boldenMatch(cloak.website_url, query),
            })
          : groupedResults.website.push({
              ...cloak,
              display: boldenMatch(cloak.website_url, query),
            });
      } else if (cloak.email && cloak.email.toLowerCase().match(regex)) {
        cloak.email.toLowerCase().startsWith(query)
          ? groupedResults.email.unshift({
              ...cloak,
              display: boldenMatch(cloak.email, query),
            })
          : groupedResults.email.push({
              ...cloak,
              display: boldenMatch(cloak.email, query),
            });
      } else if (cloak.phone && cloak.phone.match(regex)) {
        cloak.phone.toLowerCase().startsWith(query)
          ? groupedResults.phone_number.unshift({
              ...cloak,
              display: boldenMatch(cloak.phone, query),
            })
          : groupedResults.phone_number.push({
              ...cloak,
              display: boldenMatch(cloak.phone, query),
            });
      } else if (cloak.username && cloak.username.toLowerCase().match(regex)) {
        cloak.username.toLowerCase().startsWith(query)
          ? groupedResults.other.unshift({
              ...cloak,
              display: boldenMatch(cloak.username, query),
            })
          : groupedResults.other.push({
              ...cloak,
              display: boldenMatch(cloak.username, query),
            });
      }
    });
  }
  return groupedResults;
};

export const searchLocalCloaksSingleList = (cloaks, query) => {
  const matches = [];
  if (cloaks.length) {
    cloaks.filter((cloak) => {
      query = query.toLowerCase();

      const regex = beginningOfEveryWord(query);
      const identityNickname = getIdentityNickname(cloak);

      if (identityNickname?.toLowerCase()?.match(regex)) {
        identityNickname.toLowerCase().startsWith(query)
          ? matches.unshift(cloak)
          : matches.push(cloak);
      } else if (
        cloak.website_url &&
        cloak.website_url.toLowerCase().match(regex) &&
        !cloak.website_url.toLowerCase().includes("cloaked.app")
      ) {
        cloak.website_url.toLowerCase().startsWith(query)
          ? matches.unshift(cloak)
          : matches.push(cloak);
      } else if (cloak.email && cloak.email.toLowerCase().match(regex)) {
        cloak.email.toLowerCase().startsWith(query)
          ? matches.unshift(cloak)
          : matches.push(cloak);
      } else if (cloak.phone && cloak.phone.match(regex)) {
        cloak.phone.toLowerCase().startsWith(query)
          ? matches.unshift(cloak)
          : matches.push(cloak);
      } else if (cloak.username && cloak.username.toLowerCase().match(regex)) {
        cloak.username.toLowerCase().startsWith(query)
          ? matches.unshift(cloak)
          : matches.push(cloak);
      }
    });
  }
  return matches;
};
