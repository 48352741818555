import api from "@/api/api";
import store from "@/store";

export default class SubscriptionService {
  static async getSubscription() {
    let endpoint = `/api/v1/subscription/`;

    return await api()
      .get(endpoint)
      .then(async ({ data }) => {
        if (data.message) {
          await store.dispatch("settings/saveSubscription", null);
        } else {
          await store.dispatch("settings/saveSubscription", data);
        }
        return data;
      });
  }

  static async registerPaypal(subscription_id) {
    let endpoint = "/api/v2/subscription/";
    const payload = {
      subscription_id: subscription_id,
      provider: "paypal",
    };
    return await api()
      .post(endpoint, payload)
      .then(({ data }) => {
        return data;
      });
  }

  static async checkPromoCode(product_id, promo_code) {
    let endpoint = "/api/v1/subscription/stripe/validate-promo-code/";
    return await api()
      .post(endpoint, { product_id, promo_code })
      .then(({ data }) => {
        return data;
      });
  }

  static async getStripeInfo() {
    const userId = store.state.authentication?.user?.id;
    const endpoint = `/api/v1/user/${userId}/stripe_email/`;

    return await api()
      .get(endpoint)
      .then(({ data }) => {
        store.dispatch("settings/saveStripe", data);
        return data;
      });
  }

  static async getSubscriptionPlans() {
    let endpoint = "/api/v2/subscription/products/";
    return await api()
      .get(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async getPlanLimits() {
    let endpoint = "/api/v1/limits/";
    return await api()
      .get(endpoint)
      .then(async ({ data }) => {
        await store.dispatch("settings/saveLimits", data);
        return data;
      });
  }

  static async getPaymentIntent(product_id, promo = null) {
    let endpoint = "/api/v2/subscription/stripe/create-payment-intent/";
    const payload = {
      product_id: product_id,
    };
    if (promo) {
      payload.promo_code = promo;
    }
    return await api()
      .post(endpoint, payload)
      .then(({ data }) => {
        return data;
      });
  }

  static async getSetupIntent(product_id, promo = null) {
    let endpoint = "/api/v2/subscription/stripe/create-setup-intent/";
    const payload = {
      product_id: product_id,
    };
    if (promo) {
      payload.promo_code = promo;
    }
    return await api()
      .post(endpoint, payload)
      .then(({ data }) => {
        return data;
      });
  }

  static async getSubscriptionDetails() {
    let endpoint = "/api/v2/subscription/stripe/get-subscription-details/";
    return await api()
      .get(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async payNow() {
    let endpoint = "/api/v2/subscription/stripe/pay-now/";
    return await api()
      .post(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async getPlanInvitations() {
    let endpoint = "/api/v1/subscription/invitation/";
    return await api()
      .get(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async invitePlanMember(email) {
    let endpoint = "/api/v1/subscription/invitation/invite_user/";
    return await api()
      .post(endpoint, { email })
      .then(({ data }) => {
        return data;
      });
  }

  static async removePlanMember(id) {
    let endpoint = `/api/v1/subscription/invitation/${id}/`;
    return await api()
      .delete(endpoint)
      .then(({ data }) => {
        return data;
      });
  }

  static async acceptInvitation(payload) {
    let endpoint = "/api/v1/user/invitation/accept/";
    return await api()
      .post(endpoint, payload)
      .then(({ data }) => {
        return data;
      });
  }

  static async removeSubscription() {
    let endpoint = "/api/v1/subscription/remove/";
    return await api()
      .post(endpoint)
      .then(({ data }) => {
        return data;
      });
  }
}
