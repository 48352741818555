import store from "@/store";
import { computed, ref, watch } from "vue";
import { merge } from "lodash-es";
import UserService from "@/api/actions/user-service";
import { toValue } from "@vueuse/core";

const appearanceBase = {
  theme: "flat",
  variables: {
    colorDanger: "#F24141",
    fontFamily: "Poppins, system-ui, sans-serif",
    spacingUnit: "4px",
    borderRadius: "8px",
    gridColumnSpacing: "8px",
    tabSpacing: "8px",
    gridRowSpacing: "12px",
  },
  rules: {
    ".Label": {
      fontSize: "13px",
    },
    ".Error": {
      fontSize: "13px",
    },
    ".Input": {
      border: "none",
      transition: "none",
    },
    ".Input:focus": {},
    ".Tab": {
      border: "none",
    },
  },
};

const appearanceLightMode = merge(JSON.parse(JSON.stringify(appearanceBase)), {
  variables: {
    colorPrimary: "black",
    colorText: "black",
    colorTextPlaceholder: "rgba(0, 0, 0, 0.5)",
    tabIconSelectedColor: "black",
    colorPrimaryText: "black",
  },
  rules: {
    ".Input": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    ".Input:focus": {
      outline: "2px solid black",
    },
    ".Tab": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    ".Tab--selected": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      outline: "3px solid black",
      border: "none",
    },
    ".Tab--selected:focus": {
      outline: "3px solid black",
    },
    ".Block": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
});

const appearanceDarkMode = merge(JSON.parse(JSON.stringify(appearanceBase)), {
  variables: {
    colorPrimary: "white",
    colorText: "white",
    colorTextPlaceholder: "rgba(255, 255, 255, 0.5)",
    tabIconSelectedColor: "white",
    colorPrimaryText: "white",
  },
  rules: {
    ".Input": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    ".Input:focus": {
      outline: "2px solid white",
    },
    ".Tab": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    ".Tab--selected": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      outline: "3px solid white",
      border: "none",
    },
    ".Tab--selected:focus": {
      outline: "3px solid white",
    },
    ".Block": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
});

export const usePaymentProviderStripe = (
  plan,
  onSubscribed,
  stripeIntent,
  contactInformation
) => {
  const isLoadingStripeWidget = ref(false);
  const isProcessingStripePayment = ref(false);
  const stripeError = ref(null);
  const stripeRef = ref(null);

  let elements = null;

  const stripe = computed(() => store.getters["subscription/getStripe"]);

  const loadWidget = () => {
    if (!stripeIntent.value || !stripeRef.value) {
      return;
    }

    isLoadingStripeWidget.value = true;

    elements = stripe.value.elements({
      // https://docs.stripe.com/elements/appearance-api
      appearance: store.getters["colorScheme/isDarkMode"]
        ? appearanceDarkMode
        : appearanceLightMode,
      fonts: [
        {
          cssSrc:
            "https://fonts.googleapis.com/css2?family=Poppins&display=swap",
        },
      ],
      clientSecret: stripeIntent.value.client_secret,
    });

    elements
      .create("payment", {
        layout: "tabs",
        paymentMethodOrder: ["card", "apple_pay", "google_pay"],
        terms: {
          card: "never",
        },
      })
      .on("ready", () => {
        isLoadingStripeWidget.value = false;
        stripeRef.value.style.minHeight = window.getComputedStyle(
          stripeRef.value
        ).height;
      })
      .mount(stripeRef.value);
  };

  const subscribeWithStripe = async () => {
    try {
      isProcessingStripePayment.value = true;
      stripeError.value = null;

      const subscriptionPayload = {
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: "dashboard user",
              email:
                contactInformation?.value?.email ?? stripeIntent.value.email,
            },
          },
        },
      };
      const result =
        stripeIntent.value.type === "payment"
          ? await stripe.value.confirmPayment(subscriptionPayload)
          : await stripe.value.confirmSetup(subscriptionPayload);

      if (result.error) {
        stripeError.value = "Could not process payment. Please try again.";
        return;
      }

      if (stripeIntent.value.type === "payment") {
        await store.dispatch("subscription/awaitSubscriptionChange");
      }

      onSubscribed?.(toValue(plan));
      try {
        if (store.state.authentication?.user?.state === "pending_deletion") {
          const userId = store.state.authentication?.user?.id;
          const payload = {
            state: "active",
            immediate_delete: false,
          };
          const { data } = await UserService.deleteUserAccount({
            userId,
            payload,
          });
          store.commit("authentication/setUser", data);
        }
      } catch (e) {
        throw new Error("User state not updated please contact support");
      }
    } catch (error) {
      if (error === "User state not updated please contact support") {
        stripeError.value = "User state not updated please contact support";
        return;
      }
      stripeError.value = "Could not process payment. Please try again.";
    } finally {
      isProcessingStripePayment.value = false;
    }
  };

  // watch effect kept being re-triggered with store changes
  watch(() => stripeIntent.value, loadWidget);

  watch(
    () => plan.value,
    () => (stripeError.value = null)
  );

  return {
    isLoadingStripeWidget,
    isProcessingStripePayment,
    stripeError,
    stripeRef,
    subscribeWithStripe,
  };
};
