<script setup>
import BottomBorderInputText from "@/components/BottomBorderInputText.vue";
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import DataDeleteSticky from "@/components/feature/data-delete/atoms/DataDeleteSticky.vue";
import { PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_NAME_FORM } from "@/scripts/posthogEvents";
import { computed, onMounted, ref, defineEmits } from "vue";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
  isForcingNewSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input", "submit"]);

const firstNameInput = ref("firstNameInput");
onMounted(() => firstNameInput.value?.$refs?.input?.focus());

onMounted(() => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_NAME_FORM, {
    isForcingNewSearch: props.isForcingNewSearch,
  });
});

const isFormValid = computed(
  () => props.value.firstName && props.value.lastName
);
</script>

<template>
  <div class="data-delete-additional-search">
    <p class="data-delete__text">
      Let's verify some info before we display results
    </p>
    <h1 class="data-delete__title">What is your name?</h1>
    <div class="data-delete-additional-search__body">
      <fieldset class="delete-additional-search__body__fieldset">
        <BottomBorderInputText
          placeholder="First name"
          :value="value.firstName"
          @input="$emit('input', { ...value, firstName: $event })"
          autocomplete="given-name"
          @keydown.enter="isFormValid && $emit('submit')"
          ref="firstNameInput"
        />
        <BottomBorderInputText
          placeholder="Last name"
          :value="value.lastName"
          @input="$emit('input', { ...value, lastName: $event })"
          autocomplete="family-name"
          @keydown.enter="isFormValid && $emit('submit')"
        />
      </fieldset>
      <DataDeleteSticky class="data-delete-additional-search__cta">
        <Button
          type="primary"
          size="2xl"
          @click="$emit('submit')"
          :disabled="!isFormValid"
          class="data-delete-additional-search__cta-button"
        >
          Continue
          <inlineSvg name="arrow-right" />
        </Button>
      </DataDeleteSticky>
    </div>
    <span class="data-delete-additional-search__footer"
      >Cloaked securely searches your phone number across the dark web and 100+
      data brokers. Cloaked will not store or sell your information to these
      parties.</span
    >
  </div>
</template>
