<script setup>
import { Button, ModalTemplate } from "@/components";
import PreferencesInput from "@/routes/modals/preferences/PreferencesInput.vue";
import { onBeforeUnmount, reactive, computed, onMounted } from "vue";
import store from "@/store";

const state = reactive({
  loadingOnClick: false,
  inputValue: "",
});

onMounted(() => {
  window.document.addEventListener("keydown", handleKeyPress);
});

onBeforeUnmount(() => {
  window.document.removeEventListener("keydown", handleKeyPress);
});

const modals = computed(() => store.state.modal.modals);
const visibleModals = computed(() => store.state.modal.visibleModals);

function handleClickAction($event, modal) {
  if (typeof modal.button.onClick === "function") {
    const maybePromise = modal.button.onClick($event);

    if (modal.closeAfterOnClick && maybePromise instanceof Promise) {
      state.loadingOnClick = true;

      return maybePromise
        .then(() => {
          state.loadingOnClick = false;
          handleCloseModal(modal);
        })
        .finally(() => {
          state.loadingOnClick = false;
        });
    }
  }

  handleCloseModal(modal);
}

function handleKeyPress($event) {
  if ($event?.key?.toLowerCase() === "escape") {
    handleCloseModal();
  }
  if ($event?.key?.toLowerCase() === "enter") {
    $event.stopPropagation();
    $event.preventDefault();
    const topModal = modals.value[modals.value.length - 1];
    if (topModal && topModal.button && topModal.button.onClick) {
      topModal.button.onClick($event);
      handleCloseModal();
    }
  }
}

function handleCloseModal(modal) {
  if (state.loadingOnClick) {
    return;
  }

  if (typeof modal?.cancelAction === "function") {
    modal.cancelAction();
  }

  state.inputValue = "";
  if (modal?.preventClose) {
    return;
  }
  store.dispatch("closeModal", modal);
}

function isPrimaryBtnDisabled(modal) {
  /* We need this because our modal props
      don't change dynamically, ie if button changes from
      disabled to enabled, without this, the button
      stays disabled */
  if (state.loadingOnClick) {
    return true;
  } else if (state.inputValue) {
    /* For example, buttons that need to change from disabled
        to enabled based on the existance of a password */
    return false;
  } else if (modal.button?.disabled) {
    return true;
  } else {
    return false;
  }
}

function renameEvents(customTemplate) {
  const eventsObject = { ...customTemplate.events };
  const eventNames = Object.keys(eventsObject);
  eventNames.forEach((eventName) => {
    const firstLetter = eventName.charAt(0).toUpperCase();
    eventsObject[`on${firstLetter}${eventName.substring(1)}`] =
      eventsObject[eventName];
  });
  return { ...eventsObject, ...customTemplate.props };
}
</script>

<template>
  <div class="global-modal">
    <template v-for="modal in modals">
      <component
        v-if="modal.customTemplate"
        v-bind="renameEvents(modal.customTemplate)"
        :key="modal.id"
        :is="modal.customTemplate.template"
        :params="modal.customTemplate.params"
      />

      <ModalTemplate
        v-else
        :show-close-in-header="modal.showCloseInHeader"
        :show="visibleModals[modal.id]"
        :key="modal?.id"
        :width="modal.width"
        @close="() => handleCloseModal(modal)"
        :preventClose="modal.preventClose"
      >
        <template #header>
          <h1 v-if="modal.header">{{ modal.header }}</h1>
        </template>

        <template #body>
          <div
            v-if="modal.paragraphs || modal.subheader"
            class="modal-paragraph-wrapper"
          >
            <p v-if="modal.subheader" v-html="modal.subheader"></p>
            <p v-for="paragraph in modal.paragraphs" v-bind:key="paragraph">
              {{ paragraph }}
            </p>
          </div>
        </template>

        <template #input>
          <PreferencesInput
            v-if="!!modal.input"
            :value="state.inputValue"
            :label="modal?.input?.label || undefined"
            :type="modal?.input?.type || 'text'"
            :placeholder="modal?.input?.placeholder || ''"
            :disabled="modal?.input?.disabled || false"
            :error="modal?.input?.error"
            @input="
              (e) => {
                state.inputValue = e;
                modal?.input?.handleInput(e);
              }
            "
            @focus="
              modal?.input?.handleFocus
                ? modal?.input?.handleFocus(state.inputValue)
                : undefined
            "
            @blur="
              modal?.input?.handleFocus
                ? modal?.input?.handleBlur(state.inputValue)
                : undefined
            "
          />
        </template>

        <template v-if="!modal.hideFooter" #footer class="full-width">
          <Button
            aria-id="CancelButton"
            v-if="modal.showCancel"
            type="secondary"
            :disabled="state.loadingOnClick"
            @click="() => handleCloseModal(modal)"
          >
            {{ modal.cancelText || "Cancel" }}
          </Button>

          <Button
            aria-id="ConfirmButton"
            v-if="modal.button && modal.button.text"
            :type="modal.button.danger ? 'danger' : 'primary'"
            :disabled="isPrimaryBtnDisabled(modal)"
            :loading="state.loadingOnClick"
            @click="handleClickAction($event, modal)"
          >
            {{ modal.button.text }}
          </Button>
        </template>
      </ModalTemplate>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.modal-paragraph-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.full-width {
  width: 100%;
}
</style>
