<script setup>
import _ from "lodash-es";
import { ref, onUnmounted, onMounted, nextTick, computed } from "vue";

import store from "@/store";
import { useRoute } from "@/hooks";
import { headers } from "@/api/api";

import { generatePkceRequirements } from "@/scripts/actions/auth";
import UserService from "@/api/actions/user-service";
import router from "@/routes/router";
import { useTrackingQueryParameters } from "@/composables/useTrackingQueryParameters";

defineExpose({
  getAccount,
  setupUser,
});

const props = defineProps({
  hasToken: Boolean,
});

const emits = defineEmits([
  "error",
  "user-ready",
  "iframe-ready",
  "show-captcha",
]);

const { withTrackingParams, clearTrackingParams } =
  useTrackingQueryParameters();

const route = useRoute();

const challenge = ref(null);
const verifier = ref(null);
const iframe = ref(null);

onUnmounted(() => {
  window.removeEventListener("message", iframeListener);
});

onMounted(() => {
  nextTick(() => {
    generateCodeChallenge();
  });
  window.addEventListener("message", iframeListener);
});

function generateCodeChallenge() {
  generatePkceRequirements().then(([verifierValue, challengeValue]) => {
    challenge.value = challengeValue;
    verifier.value = verifierValue;
  });
}

let authPayload;

async function iframeListener(message) {
  const childWindow = iframe?.value?.contentWindow;

  if (message.source === childWindow) {
    const payload = message.data.data;

    if (message.data.event === "api-status") {
      if (payload.status === "success") {
        emits("iframe-ready");
      }
    }
    /* Listen for backend to tell us whether captcha is required or not,
    then we don't need a separate frontend flag */
    if (message.data.event === "captcha-status") {
      emits("show-captcha", payload?.showCaptcha);
    }

    if (message.data.event === "init-user-response") {
      if (payload.status === "success") {
        setTimeout(() => {
          getToken();
          clearTrackingParams();
        }, 500);
      }
    }
    if (message.data.event === "request-code-response") {
      if (!props.hasToken) {
        await store.dispatch("authentication/getAuthToken", {
          payload: payload,
          client_id: window.ENV.VUE_APP_HEADLESS_ID,
          codeVerifier: verifier.value,
          handler: (authToken) => {
            store.dispatch("authentication/setGuestToken", authToken.data);

            UserService.getUserDetails().then(({ data }) => {
              store.dispatch("subscription/init");
              emits("user-ready", data.results[0]);
            });
          },
        });
      }
    }
    if (message.data.event === "login-success") {
      if (payload.user.posthog_uuid && window.$posthog) {
        window.$posthog?.identify(payload.user?.posthog_uuid);
      }
      await store.dispatch("authentication/setAuthPayload", {
        payload: message.data.data,
        client_id: window.ENV.VUE_APP_HEADLESS_ID,
        codeVerifier: verifier.value,
      });

      await Promise.all([
        store.dispatch("authentication/getUser"),
        UserService.getFlags(),
      ]);

      router.push({ path: "/" }).catch((e) => e);
    }
    if (message.data.event === "register-user-response") {
      if (payload.status === "error") {
        if (payload.error_code === "invalid_username") {
          emits("error", {
            type: "username",
            message: "Username already exists",
          });
        } else {
          emits("error", {
            type: "generic",
            message: "We experienced an error, please try again.",
          });
        }
      } else {
        loginUser({ ...authPayload, salt: message.data.data.salt });
      }
    }
  }
}

const src = computed(() => {
  const queries = Object.keys(route.query).map((k) => {
    return `${k}=${route.query[k]}`;
  });
  const getHeaders = headers();
  delete getHeaders["Authorization"];
  delete getHeaders["content-type"];
  let params = [
    `cloaked_client_id=${global.ENV.VUE_APP_HEADLESS_ID}`,
    `cloaked_code_challenge=${challenge.value}`,
    `secret=${global.ENV.VUE_APP_SECRET}`,
    `cloaked_redirect_uri=${encodeURIComponent(
      global.ENV.VUE_APP_REDIRECT_URI
    )}`,
    ...queries,
  ];
  Object.keys(getHeaders).map((k) => {
    params.push(`${_.snakeCase(k)}=${getHeaders[k]}`);
  });
  params.push("enable_delete=true");

  return withTrackingParams(
    `${window.ENV.VUE_APP_API}auth/headless-create-user/?${params.join("&")}`
  );
});

const auth_status = ref(0);
const targetOrigin = window.ENV.VUE_APP_API;

function getAccount(captcha) {
  if (auth_status.value === 0) {
    auth_status.value = 1;
    iframe.value.contentWindow.postMessage(
      { event: "init-user", data: { captcha } },
      targetOrigin
    );
  }
}

function setupUser({ username, password, email }) {
  authPayload = { username, password, email };
  emits("error", null);
  iframe.value.contentWindow.postMessage(
    {
      event: "register-user",
      data: { username, password, email },
    },
    targetOrigin
  );
}

function loginUser({ username, password, salt }) {
  emits("error", null);
  iframe.value.contentWindow.postMessage(
    { event: "login-user", data: { username, password, salt } },
    targetOrigin
  );
}

function getToken() {
  emits("error", null);
  iframe.value.contentWindow.postMessage(
    { event: "request-code" },
    targetOrigin
  );
}
</script>
<template>
  <iframe
    v-if="challenge"
    ref="iframe"
    :src="src"
    allow="clipboard-read; clipboard-write"
    frameborder="0"
  ></iframe>
</template>

<style lang="scss" scoped>
iframe {
  background-color: green;
  position: fixed;
  z-index: 1000;
  top: -10px;
  left: -10px;
  border: none;
  width: 1px;
  height: 1px;
}
</style>
