<script setup>
import { ArrowRight } from "@/assets/icons";
import AppFormRadio from "@/components/AppFormRadio.vue";
import { computed, onMounted } from "vue";
import { PH_EVENT_USER_VIEWED_SETUPONBOARDING_INTENT } from "@/scripts/posthogEvents";
import store from "@/store";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  value: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["input", "back", "finished"]);
const firstSteps = computed({
  get: () => props.value.firstSteps,
  set: (value) => emit("input", { ...props.value, firstSteps: value }),
});

const ddInAppSearchEnabled = computed(
  () => store.getters["dataDelete/getDdInAppSearchEnabled"]
);

onMounted(() => {
  posthogCapture(PH_EVENT_USER_VIEWED_SETUPONBOARDING_INTENT);
});
</script>

<template>
  <div class="onboarding-form__content">
    <h1 class="onboarding-form__title">What can we help you with first?</h1>
    <fieldset class="onboarding-form__fieldset">
      <AppFormRadio
        :value="firstSteps"
        @input="firstSteps = $event"
        name="first-steps"
        id="Data deletion"
        v-if="ddInAppSearchEnabled"
      >
        Delete my data from data brokers and the dark web
      </AppFormRadio>
      <AppFormRadio
        :value="firstSteps"
        @input="firstSteps = $event"
        name="first-steps"
        id="Masked information"
      >
        Create email and phone aliases
      </AppFormRadio>
      <AppFormRadio
        :value="firstSteps"
        @input="firstSteps = $event"
        name="first-steps"
        id="Communicate"
      >
        Privately send and receive texts and emails
      </AppFormRadio>
      <AppFormRadio
        :value="firstSteps"
        @input="firstSteps = $event"
        name="first-steps"
        id="Import"
      >
        Migrate from another password manager
      </AppFormRadio>
      <AppFormRadio
        :value="firstSteps"
        @input="firstSteps = $event"
        name="first-steps"
        id="Store data"
      >
        Store sensitive data
      </AppFormRadio>
    </fieldset>
    <footer class="onboarding-form__footer">
      <button
        type="button"
        class="onboarding-form__secondary"
        @click="emit('back')"
      >
        Back
      </button>
      <button
        type="button"
        class="onboarding-form__primary"
        @click="emit('finished')"
      >
        Go to Dashboard
        <ArrowRight />
      </button>
    </footer>
  </div>
</template>
