<script setup>
import { ref } from "vue";
import inlineSvg from "@/components/InlineSvg";
import Button from "@/components/Button.vue";
import {
  BILLING_CYCLE,
  PAYMENT_PROVIDER,
} from "@/composables/useSubscriptionPlans";
import {
  useSubscriptionPlans,
  usePaymentProviderStripe,
  usePaymentProviderPaypal,
  usePromoCode,
  useDiscount,
  usePaymentIntent,
  useScrollToElement,
} from "@/composables";
import SubscribeSectionTitle from "@/components/feature/subscribe/SubscribeSectionTitle.vue";
import SubscribeInputPromo from "@/components/feature/subscribe/SubscribeInputPromo.vue";
import SubscribeToggle from "@/components/feature/subscribe/SubscribeToggle.vue";
import SubscribeCard from "@/components/feature/subscribe/SubscribeCard.vue";
import SubscribePayment from "@/components/feature/subscribe/SubscribePayment.vue";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});

const {
  isLoadingPlans,
  selectedBillingCycle,
  selectedPaymentProvider,
  annualPlan,
  monthlyPlan,
  selectedPlan,
} = useSubscriptionPlans(BILLING_CYCLE.ANNUAL, PAYMENT_PROVIDER.STRIPE);

const {
  isValidatingPromoCode,
  promoCodeInput,
  promoCodeOffer,
  promoCodeError,
  validatePromoCode,
} = usePromoCode(selectedPlan);

const { isLoadingIntent, paymentIntent } = usePaymentIntent(
  selectedPlan,
  promoCodeOffer
);

const { discountedTotalPrice, discountedMonthlyAmount } = useDiscount(
  selectedPlan,
  paymentIntent
);

const emit = defineEmits(["subscribed"]);
const onSubscribed = () => emit("subscribed");

const {
  stripeRef,
  stripeError,
  isProcessingStripePayment,
  subscribeWithStripe,
} = usePaymentProviderStripe(selectedPlan, onSubscribed, paymentIntent);

const { paypalRef, paypalError } = usePaymentProviderPaypal(
  selectedPlan,
  onSubscribed,
  props.user
);

const isPromoCodeInputVisible = ref(false);

const errorMessageRef = ref(null);
useScrollToElement(errorMessageRef);
</script>

<template>
  <SubscribePayment
    class="subscribe-payment"
    :class="{
      'subscribe-payment--provider-selected': !!selectedPaymentProvider,
    }"
  >
    <section class="subscribe-payment__section">
      <header class="subscribe-payment__section-header">
        <SubscribeSectionTitle>Select billing cycle</SubscribeSectionTitle>
        <button
          v-if="
            selectedPaymentProvider === PAYMENT_PROVIDER.STRIPE &&
            selectedBillingCycle
          "
          class="subscribe-payment__promo-toggle"
          @click="isPromoCodeInputVisible = !isPromoCodeInputVisible"
        >
          Promo code?
        </button>
      </header>
      <SubscribeCard class="subscribe-payment__billing-cycle">
        <div
          v-show="isPromoCodeInputVisible"
          class="subscribe-payment__promo-code"
        >
          <h3 class="subscribe-payment__promo-code-heading">Promo code</h3>
          <SubscribeInputPromo
            v-model="promoCodeInput"
            @validate="validatePromoCode"
            :is-valid="
              !!promoCodeOffer && promoCodeInput === promoCodeOffer.code
            "
            :is-validating="
              isValidatingPromoCode || (promoCodeOffer && isLoadingIntent)
            "
            :error="promoCodeError"
            class="subscribe-payment__promo-code-input"
          />
        </div>
        <SubscribeToggle
          v-model="selectedBillingCycle"
          :name="BILLING_CYCLE.ANNUAL"
        >
          Annual
          <span class="subscribe-payment__discount"> 20% </span>
          <span
            v-if="isLoadingPlans"
            class="subscribe-payment__product-price subscribe-payment__product-price--skeleton"
          />
          <span
            v-else
            class="subscribe-payment__product-price subscribe-payment__product-price--discounted"
          >
            ${{ annualPlan?.monthlyPrice }} per month
          </span>
        </SubscribeToggle>
        <SubscribeToggle
          v-model="selectedBillingCycle"
          :name="BILLING_CYCLE.MONTHLY"
        >
          Monthly
          <span
            v-if="isLoadingPlans"
            class="subscribe-payment__product-price subscribe-payment__product-price--skeleton"
          />
          <span v-else class="subscribe-payment__product-price">
            ${{ monthlyPlan?.monthlyPrice }} per month
          </span>
        </SubscribeToggle>
        <template v-if="!!selectedBillingCycle">
          <h3 class="subscribe-payment__details-title">Subtotal</h3>
          <span class="subscribe-payment__details-value">
            <span
              v-if="isLoadingPlans"
              class="subscribe-payment__details-value--skeleton"
            />
            <span v-else> ${{ selectedPlan?.monthlyPrice }} per month </span>
            <span class="subscribe-payment__details-note">
              Billed
              {{
                selectedBillingCycle === BILLING_CYCLE.ANNUAL
                  ? "annually"
                  : "monthly"
              }}
            </span>
          </span>
          <template v-if="!!promoCodeOffer && !isLoadingIntent">
            <h3 class="subscribe-payment__details-title">Promo code</h3>
            <span class="subscribe-payment__details-value"
              >{{ promoCodeOffer.code }}
              <span
                class="subscribe-payment__details-note"
                v-if="promoCodeOffer.terms"
              >
                {{ promoCodeOffer.terms }}
              </span>
              <span
                class="subscribe-payment__details-note"
                v-if="discountedMonthlyAmount"
              >
                -${{ discountedMonthlyAmount }} per month
              </span>
            </span>
          </template>
          <footer class="subscribe-payment__section-footer">
            <h3>Total</h3>
            <span
              v-if="isLoadingPlans"
              class="subscribe-payment__total-price--skeleton"
            />
            <span v-else>
              <template v-if="discountedTotalPrice">
                <span class="subscribe-payment__original-price"
                  >${{ selectedPlan?.totalPrice }}</span
                >
                ${{ discountedTotalPrice }}
              </template>
              <template v-else> ${{ selectedPlan?.totalPrice }} </template>
              + tax
            </span>
          </footer>
        </template>
      </SubscribeCard>
    </section>

    <section class="subscribe-payment__section">
      <header class="subscribe-payment__section-header">
        <SubscribeSectionTitle>Payment details</SubscribeSectionTitle>
        <span class="subscribe-payment__secure-form">
          Secure form
          <inlineSvg name="lock" class="subscribe-payment__secure-form-icon" />
        </span>
      </header>
      <SubscribeCard class="subscribe-payment__payment-type">
        <SubscribeToggle
          v-model="selectedPaymentProvider"
          :name="PAYMENT_PROVIDER.STRIPE"
        >
          <inlineSvg
            name="credit-card-outline"
            class="subscribe-payment__provider-icon"
          />
          <br />
          Card
        </SubscribeToggle>
        <SubscribeToggle
          v-model="selectedPaymentProvider"
          :name="PAYMENT_PROVIDER.PAYPAL"
          @click="isPromoCodeInputVisible = false"
        >
          <inlineSvg name="paypal" class="subscribe-payment__provider-icon" />
          <br />
          Paypal
        </SubscribeToggle>
        <div
          ref="stripeRef"
          v-show="
            selectedPaymentProvider === PAYMENT_PROVIDER.STRIPE &&
            selectedBillingCycle
          "
          class="subscribe-payment__stripe-form"
        />
      </SubscribeCard>
    </section>

    <div
      class="subscribe-payment__subscribe"
      v-show="
        selectedPaymentProvider !== PAYMENT_PROVIDER.PAYPAL ||
        !selectedBillingCycle
      "
    >
      <Button
        type="hero-green"
        class="subscribe-payment__subscribe-button-stripe"
        @click="subscribeWithStripe"
        :disabled="
          !selectedBillingCycle ||
          selectedPaymentProvider !== PAYMENT_PROVIDER.STRIPE
        "
      >
        <template v-if="isProcessingStripePayment">
          Completing purchase
          <inlineSvg name="spinner" key="spinner" />
        </template>
        <template v-else>
          Complete purchase
          <inlineSvg name="arrow-right" key="arrow " />
        </template>
      </Button>
    </div>
    <div
      class="subscribe-payment__subscribe"
      v-show="
        selectedPaymentProvider === PAYMENT_PROVIDER.PAYPAL &&
        !!selectedBillingCycle
      "
    >
      <div ref="paypalRef" class="subscribe-payment__subscribe-button-paypal" />
    </div>

    <p
      v-if="stripeError || paypalError"
      ref="errorMessageRef"
      class="subscribe-payment__error"
    >
      {{ stripeError || paypalError }}
    </p>
  </SubscribePayment>
</template>
