<script setup>
import AuthIframe from "./AuthIframe";
import Iframe from "./Iframe";
import { useRoute } from "@/hooks";
import { onMounted, ref } from "vue";
import router from "@/routes/router";
const route = useRoute();
const props = defineProps({
  version: {
    type: Number,
  },
});
const prevRoute = ref(route.query.prevRoute);

onMounted(() => {
  router.replace({
    query: {
      ...route.query,
      prevRoute: undefined,
    },
  });
});
</script>

<template>
  <Iframe
    v-if="props.version === 2"
    useLegacyRoute
    source="auth/login"
    :prevRoute="prevRoute"
  />
  <AuthIframe v-else source="auth/login" :prevRoute="prevRoute" />
</template>
