<script setup>
import { useAttrs } from "vue";
import AppModal from "@/components/ui/AppModal";
import AppModalClose from "@/components/ui/AppModalClose";
import AppModalTitle from "@/components/ui/AppModalTitle";
import AppModalContent from "@/components/ui/AppModalContent";
import AppModalImage from "@/components/ui/AppModalImage";
import AppModalParagraph from "@/components/ui/AppModalParagraph";
import AppModalFooter from "@/components/ui/AppModalFooter";
import Button from "@/components/Button.vue";
import { computed } from "vue";
import store from "@/store";

const attrs = useAttrs();
const emit = defineEmits(["close"]);

const title =
  "Generate phone numbers, email addresses, and passwords on the go";

const showMobileAppModal = computed(() => {
  return store.state.ui.modals.mobileAppModal;
});

function close() {
  store.dispatch("toggleMobileAppModal", false);
}
</script>

<template>
  <AppModal @input="close" :value="showMobileAppModal">
    <AppModalContent>
      <AppModalClose v-bind="attrs" is-absolute @close="close" />
      <AppModalImage
        :src="require('@/assets/images/gradient-qr.png')"
        :alt="title"
      />
      <AppModalTitle>
        {{ title }}
      </AppModalTitle>
      <AppModalParagraph>
        Scan this QR code with your phone camera or go to
        <a href="https://download.cloaked.app" target="_blank" class="qr-link"
          >download.cloaked.app</a
        >
        on your mobile browser to install the Cloaked mobile app.
      </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="close">Got it</Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.qr-link {
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
}
</style>
