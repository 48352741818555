<script setup>
import { computed, markRaw } from "vue";
import store from "@/store";

import DetailSection from "@/components/Wallet/RightPanel/DetailSection.vue";
import PatchFundingSource from "@/components/modals/Wallet/PatchFundingSource.vue";

const sources = computed(() => {
  return store.state.cards?.fundingSources.results;
});

function description(source) {
  if (source.nickname) {
    return "•••• " + source.pan_last_four + " • " + source.nickname;
  } else {
    return "•••• " + source.pan_last_four;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function openSource(source) {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(PatchFundingSource),
      props: {
        isVisible: true,
        source: source,
      },
    },
  });
}
</script>

<template>
  <div class="sources">
    <DetailSection
      v-for="source in sources"
      :key="source.id"
      :title="capitalizeFirstLetter(source.card_brand)"
      :description="description(source)"
      icon="bank"
      link
      @click="openSource(source)"
    />
  </div>
</template>

<style scoped>
.sources {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
