<script setup>
import SubscribeBenefits from "@/components/feature/subscribe/SubscribeBenefits.vue";
import SubscribePaymentDefault from "@/components/feature/subscribe/SubscribePaymentDefault.vue";
import SubscribeGuarantee from "@/components/feature/subscribe/SubscribeGuarantee.vue";
import { computed } from "vue";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["subscribed"]);

const paymentComponentProps = computed(() => ({
  user: props.user,
  class: "subscribe__payment",
  onSubscribed: (payload) => emit("subscribed", payload),
}));
</script>

<template>
  <div class="subscribe">
    <slot name="header">
      <SubscribeGuarantee class="subscribe__guarantee" />
      <h1 class="subscribe__title">Cloaked Protection Plan</h1>
    </slot>
    <div class="subscribe__content">
      <slot name="benefits">
        <SubscribeBenefits class="subscribe__benefits" />
      </slot>
      <slot name="payment" :slotProps="paymentComponentProps">
        <SubscribePaymentDefault v-bind="paymentComponentProps" />
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
.subscribe {
  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: center;
  }

  &__content {
    display: grid;
    gap: 24px;
    margin-top: 24px;

    @media all and (min-width: $screen-xl) {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      margin-top: 32px;
    }
  }

  &__guarantee {
    display: block;
    margin: 0 auto;
  }

  &__benefits {
    opacity: 0;
    animation: appear-bottom-5 0.5s forwards ease-out;
  }

  &__payment {
    width: 100%;

    & > * {
      &:nth-child(1) {
        opacity: 0;
        animation: appear-bottom-5 0.5s 0.05s forwards ease-out;
      }

      &:nth-child(2) {
        opacity: 0;
        animation: appear-bottom-5 0.5s 0.1s forwards ease-out;
      }

      &:nth-child(3) {
        opacity: 0;
        animation: appear-bottom-5 0.5s 0.2s forwards ease-out;
      }

      &:nth-child(4) {
        opacity: 0;
        animation: appear-bottom-5 0.5s 0.2s forwards ease-out;
      }
    }

    &.subscribe-payment--provider-selected > * {
      animation-duration: 0.35s;
      animation-delay: 0.2s;
    }
  }
}
</style>
