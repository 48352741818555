<script setup>
import { CloakForwardToggleRow } from "@/components/cloakDetails";
import { CheckIcon, ChevronDown } from "@/assets/icons";
import { UiMenu, UiMenuButton, UiEmailOptionLabel } from "@/components";
import { computed } from "vue";

const COMM_FILTERS_MAP = {
  email: {
    block: "Block all emails",
    manual_screen: false,
    allow_all: "Unrestricted",
  },
  message: {
    block: "Block new senders",
    manual_screen: "Request approval",
    allow_all: "Unrestricted (not recommended)",
  },
  call: {
    block: "Block new callers",
    manual_screen: "Request approval",
    allow_all: "Unrestricted (not recommended)",
  },
};
const SECTION_TITLE = {
  email: "New emails",
  message: "New texts",
  call: "New calls",
};
const COMM_NOTICES_MAP = {
  email: {
    block: "New senders will be blocked",
    manual_screen: false,
    allow_all: "Emails will be stored in your Cloaked app",
  },
  message: {
    block: "Any new senders will be blocked",
    manual_screen: "Texts will be stored in your Cloaked app",
    allow_all: "Texts will be stored in your Cloaked app",
  },
  call: {
    block: "New callers will be blocked",
    manual_screen:
      "Calls will be sent to voicemail and stored in your Cloaked app",
    allow_all: "Calls will be sent to voicemail and stored in your Cloaked app",
  },
};

const props = defineProps({
  field: {
    type: String,
    default: "",
  },
  selected: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  allowed: {
    type: Boolean,
    default: false,
  },
  defaultValue: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  rules: {
    type: Object,
    default: () => ({}),
  },
  unverifiedEntries: {
    type: Boolean,
  },
  locked: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "select",
  "deselect",
  "sentToSettings",
  "updateFilterRule",
]);

function getFilterDisplay(filterKey) {
  return COMM_FILTERS_MAP[props.field][filterKey];
}
function handleSelectSetting(filterKey) {
  emit("updateFilterRule", filterKey);
}

const sectionTitle = computed(() => {
  return SECTION_TITLE[props.field];
});
const commFilters = computed(() => {
  if (props.field === "message" || props.field === "call") {
    return Object.keys(COMM_FILTERS_MAP[props.field]).filter((key) => {
      /* If the selected setting is already block, show block, if not, do not add it to the list of available */
      if (selectedFilterRuleKey.value === "block") {
        return key;
      }
      return key !== "block";
    });
  }

  return Object.keys(COMM_FILTERS_MAP[props.field]).filter(
    (key) => COMM_FILTERS_MAP[props.field][key]
  );
});
const noticeDisplay = computed(() => {
  if (props.field && props.rules && props.rules.filter_rule) {
    return COMM_NOTICES_MAP[props.field][props.rules.filter_rule];
  }
  return "";
});
const selectedFilterRuleDisplay = computed(() => {
  if (props.rules) {
    return COMM_FILTERS_MAP[props.field][props.rules.filter_rule];
  } else if (props.field === "email") {
    return;
  }
  return "";
});
const selectedFilterRuleKey = computed(() => {
  if (props.rules) {
    return props.rules.filter_rule;
  }
  return "";
});
</script>

<template>
  <section class="fwd-section-wrapper">
    <h2>{{ props.title }}</h2>

    <CloakForwardToggleRow
      :aria-id="`CloakForwardToggleRow.${props.field || ''}`"
      :field="props.field"
      :selected="props.selected"
      :options="props.options"
      :allowed="props.allowed"
      :defaultValue="props.defaultValue"
      :unverified-entries="props.unverifiedEntries"
      @select="(selection) => $emit('select', selection)"
      @deselect="$emit('deselect')"
      @sentToSettings="$emit('sentToSettings')"
    />
    <div v-if="props.locked" class="fwd-toggle-wrapper__label">
      <div class="fwd-toggle-wrapper__label-title">
        {{ sectionTitle }}
      </div>

      <div
        class="fwd-toggle-wrapper__label-selection"
        :class="{
          'fwd-toggle-wrapper__label-selection--selected': props.selected,
          'fwd-toggle-wrapper__label-selection--disabled': props.locked,
        }"
      >
        <span>{{ selectedFilterRuleDisplay }}</span>
      </div>
    </div>
    <UiMenu v-else width="263px" placement="bottom-start">
      <div class="fwd-toggle-wrapper__label">
        <div class="fwd-toggle-wrapper__label-title">
          {{ sectionTitle }}
        </div>

        <div
          class="fwd-toggle-wrapper__label-selection"
          :class="{
            'fwd-toggle-wrapper__label-selection--selected': props.selected,
          }"
        >
          <span>{{ selectedFilterRuleDisplay }}</span>

          <ChevronDown />
        </div>
      </div>

      <template #content>
        <UiMenuButton
          v-for="filterKey in commFilters"
          :key="filterKey"
          :title="getFilterDisplay(filterKey)"
          :active="filterKey === selectedFilterRuleKey"
          @click="() => handleSelectSetting(filterKey)"
        >
          <template v-slot:icon>
            <CheckIcon v-if="filterKey === selectedFilterRuleKey" />
            <span v-else></span>
          </template>

          <template v-if="props.field === 'email'" v-slot:[`props.title`]>
            <UiEmailOptionLabel :email="getFilterDisplay(filterKey)" />
          </template>
          <template v-else v-slot:[`props.title`]>
            <div>{{ getFilterDisplay(filterKey) }}</div>
          </template>
        </UiMenuButton>
      </template>
    </UiMenu>
  </section>
</template>

<style lang="scss" scoped>
.fwd-section-wrapper {
  border: 1px solid $color-primary-5;
  border-radius: 15px;
  padding: 20px;
  margin-top: 12px;
  > h2 {
    color: $color-primary-100;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }
  .comm-notice {
    background-color: $color-primary-100-10;
    border-radius: 11px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin-top: 12px;

    > h3 {
      line-height: 18px;
      color: $color-primary-100;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }
}
</style>
