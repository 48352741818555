export const BrazilStatesList = [
  { label: "Acre", value: "BR-AC" },
  { label: "Alagoas", value: "BR-AL" },
  { label: "Amapá", value: "BR-AP" },
  { label: "Amazonas", value: "BR-AM" },
  { label: "Bahia", value: "BR-BA" },
  { label: "Ceará", value: "BR-CE" },
  { label: "Distrito Federal", value: "BR-DF" },
  { label: "Espírito Santo", value: "BR-ES" },
  { label: "Goiás", value: "BR-GO" },
  { label: "Maranhão", value: "BR-MA" },
  { label: "Mato Grosso", value: "BR-MT" },
  { label: "Mato Grosso do Sul", value: "BR-MS" },
  { label: "Minas Gerais", value: "BR-MG" },
  { label: "Pará", value: "BR-PA" },
  { label: "Paraíba", value: "BR-PB" },
  { label: "Paraná", value: "BR-PR" },
  { label: "Pernambuco", value: "BR-PE" },
  { label: "Piauí", value: "BR-PI" },
  { label: "Rio de Janeiro", value: "BR-RJ" },
  { label: "Rio Grande do Norte", value: "BR-RN" },
  { label: "Rio Grande do Sul", value: "BR-RS" },
  { label: "Rondônia", value: "BR-RO" },
  { label: "Roraima", value: "BR-RR" },
  { label: "Santa Catarina", value: "BR-SC" },
  { label: "São Paulo", value: "BR-SP" },
  { label: "Sergipe", value: "BR-SE" },
  { label: "Tocantins", value: "BR-TO" },
];
