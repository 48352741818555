<script setup>
import { debounce } from "lodash-es";
import AppTable from "@/components/ui/AppTable.vue";
import {
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  useAttrs,
  useSlots,
} from "vue";
const el = ref(null);

const state = reactive({
  hasOverflowLeft: false,
  hasOverflowRight: false,
  isMaximized: false,
});

const attrs = useAttrs();

function updateOverflows() {
  if (!el?.value) return;
  debounce(
    () => {
      state.hasOverflowLeft = el.value.$el.scrollLeft > 0;
      state.hasOverflowRight =
        el.value.$el.scrollWidth -
          el.value.$el.clientWidth -
          el.value.$el.scrollLeft >
        0;
    },
    50,
    { maxWait: 50 }
  );
}

onMounted(() => {
  window.addEventListener("resize", updateOverflows);
});

onBeforeMount(() => {
  window.removeEventListener("resize", updateOverflows);
});

const slots = useSlots();
</script>

<template>
  <AppTable
    v-bind="attrs"
    class="import-table"
    ref="el"
    :class="{
      'import-table--overflow-left': state.hasOverflowLeft,
      'import-table--overflow-right': state.hasOverflowRight,
      'import-table--maximized': state.isMaximized,
    }"
  >
    <template v-for="(index, name) in slots" v-slot:[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </AppTable>
</template>

<style lang="scss">
@import "@/assets/scss/recursive/_mixins.scss";

@mixin table-scroll-bar() {
  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-30;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-primary-0;

    @at-root .theme-dark & {
      background-color: $color-primary-10;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

.import-table {
  &.app-table {
    overflow: scroll;
    @include table-scroll-bar();
  }
}
</style>
