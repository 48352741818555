<template>
  <p class="app-modal-paragraph">
    <slot />
  </p>
</template>

<style lang="scss">
.app-modal-paragraph {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;
  padding: 0 32px;
  margin-top: 16px;
}
</style>
