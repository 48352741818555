<script setup>
import { ref, onMounted, computed, watch } from "vue";
import CardSingle from "./CardsListing/CardSingle";
import CardsServices from "@/api/actions/cards-services";
import store from "@/store";
import { UiMenu, UiMenuButton } from "@/components";
import inlineSvg from "@/components/InlineSvg.vue";
import router from "@/routes/router";

const emit = defineEmits(["type"]);

const cards = computed(() => {
  return store.state.cards.cards;
});

const cloakedLite = computed(() => {
  return store.state.authentication.user.flags.cloaked_lite;
});

const loading = ref(true);

const active = computed(() => {
  return store.state.cards.rightPanel.show;
});

watch(cards, () => {
  if (cards.value.results.length < 0) {
    loading.value = true;
  }
});

onMounted(() => {
  CardsServices.getCardList().then(() => {
    loading.value = false;
  });
});

const filterOption = ref("Active");

const cardsActive = () => {
  filterOption.value = "Active";
  loading.value = true;
  emit("type", { type: "Active" });
  CardsServices.getCardList().then(() => {
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  });
  router.push("/wallet");
};

const cardsCanceled = () => {
  filterOption.value = "Canceled";
  loading.value = true;
  emit("type", { type: "Canceled" });
  CardsServices.getCancelCardList().then(() => {
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  });
  router.push("/wallet");
};

const cardsGifted = () => {
  filterOption.value = "Gifted";
  loading.value = true;
  emit("type", { type: "Gifted" });
  CardsServices.getGiftCardList().then(() => {
    setTimeout(() => {
      loading.value = false;
    }, 1000);
  });
  router.push("/wallet");
};
</script>

<template>
  <div class="cards" :class="{ loaded: !loading, active: active }">
    <div class="title">
      <h1>Cloaked cards</h1>

      <div class="sort-item">
        <UiMenu placement="bottom-end">
          <div class="selected-sort-item">
            <span class="filter-label">Filter: </span>
            <p>{{ filterOption || "Active" }}</p>
            <inlineSvg name="chevron-down" width="13" />
          </div>
          <template #content>
            <UiMenuButton
              key="active"
              title="Active"
              @click="() => cardsActive()"
              :active="filterOption === 'Active'"
            >
              <template #icon>
                <inlineSvg
                  name="checkmark_gray"
                  v-if="filterOption === 'Active'"
                />
              </template>
            </UiMenuButton>
            <UiMenuButton
              key="canceled"
              title="Canceled"
              @click="() => cardsCanceled()"
              :active="filterOption === 'Canceled'"
            >
              <template #icon>
                <inlineSvg
                  name="checkmark_gray"
                  v-if="filterOption === 'Canceled'"
                />
              </template>
            </UiMenuButton>
            <UiMenuButton
              key="gifted"
              title="Gifted"
              @click="() => cardsGifted()"
              :active="filterOption === 'Gifted'"
              v-if="cloakedLite"
            >
              <template #icon>
                <inlineSvg
                  name="checkmark_gray"
                  v-if="filterOption === 'Gifted'"
                />
              </template>
            </UiMenuButton>
          </template>
        </UiMenu>
      </div>
    </div>

    <div class="list-details" v-if="cards?.results?.length">
      <div class="list" v-if="!loading">
        <CardSingle
          :card="card"
          v-for="card in cards.results"
          :key="card.id"
          :sent="filterOption === 'Active'"
        />
      </div>
    </div>

    <div class="loader" v-if="loading">
      <inlineSvg name="loading-small" />
    </div>

    <div v-if="!cards?.results?.length && !loading" class="empty">
      <p>No cards found</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cards {
  width: 400px;
  height: calc(100vh - 60px);
  overflow: hidden;
  position: fixed;
  @include custom-scroll-bar();
  padding-bottom: 30px;
  transition: all 0.4s ease-in-out;
  @include transform(translateX(0px));
  border-right: 1px solid $color-primary-10;

  &.loaded {
    overflow: auto;
  }

  .title {
    position: sticky;
    top: 0;
    background-color: $color-surface;
    z-index: 3;
    padding: 22px 22px 10px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color-primary-100;
    }

    .sort-item {
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:first-of-type {
        margin-left: 10px;
      }
      .selected-sort-item {
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 6px 8px;
        z-index: 99;
        &:hover {
          background: $color-primary-10;
        }
        span {
          color: $color-primary-50;
        }
        p {
          text-decoration: underline;
          font-weight: 500;
          margin: 0 6px 0 3px;
          color: $color-accent;
        }
        color: $color-accent;
        svg {
          margin-top: 2px;
        }
      }
      .filter-label {
        color: $color-primary-90;
      }
      .selected {
        color: $color-primary-10;
        font-weight: 600;
      }
    }
  }

  .list-details {
    padding: 7px 22px 0 22px;
    position: relative;

    .list {
      .card {
        --distance: calc(222px - 95px);

        opacity: 0;
        animation: fadein 0.3s;
        animation-fill-mode: forwards;

        @keyframes fadein {
          0% {
            opacity: 0;
            top: 80px;
          }
          100% {
            opacity: 1;
            top: 0;
          }
        }

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            animation-delay: #{$i * 0.09}s;
          }
        }
      }
    }
  }

  .loader,
  .empty {
    width: 100%;
    height: calc(100vh - 179px);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      color: $color-primary-30;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.1px;
      color: $color-primary-70;
    }
  }
}
</style>
