<template>
  <div class="settings-child-page">
    <aside class="settings-child-page__aside">
      <slot name="aside" />
    </aside>

    <div class="settings-child-page__content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.settings-child-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
  width: 100%;

  @media (min-width: 768px) {
    padding: 40px 80px;
  }

  @media (min-width: $screen-xxl) {
    flex-direction: row;
  }

  &__aside {
    width: 100%;

    @media (min-width: $screen-xl) {
      max-width: 600px;
    }

    @media (min-width: $screen-xxl) {
      width: 388px;
    }

    .settings-title + .settings-paragraph {
      margin-top: 8px;
    }
  }

  &__content {
    width: 100%;

    @media (min-width: $screen-xl) {
      max-width: 700px;
    }

    @media (min-width: $screen-xxl) {
      margin-top: 0;
      margin-left: 80px;
      width: calc(100% - 388px);
    }

    .preferences-panel {
      padding: 0;
    }

    .value-display {
      // + .preferences-title {
      //   margin-top: 53px;
      // }

      + .button {
        margin-top: 40px;
      }
    }

    .preferences-title {
      + .preferences-paragraph {
        margin-top: 16px;
      }
    }

    .preferences-paragraph {
      + .value-display {
        margin-top: 16px;
      }

      + .button {
        margin-top: 24px;
      }
    }
  }
}
</style>
