import DataDeletePageAdditionalSearch from "@/components/feature/data-delete/DataDeletePageAdditionalSearch.vue";
import DataDeletePageLoader from "@/components/feature/data-delete/DataDeletePageLoader.vue";
import DataDeletePageResults from "@/components/feature/data-delete/DataDeletePageResults.vue";
import DataDeleteSetCredentials from "@/components/feature/data-delete/DataDeleteSetCredentials.vue";
import DataDeletePagePlan from "@/components/feature/data-delete/DataDeletePagePlan.vue";
import { markRaw } from "vue";

export const sensitiveParams = [
  "first_name",
  "last_name",
  "phone",
  "email",
  "state",
  "age",
  "city",
];

export function sanitizeRouterQuery(query) {
  const clonedQuery = { ...query };

  sensitiveParams.forEach((param) => {
    if (clonedQuery[param]) {
      delete clonedQuery[param];
    }
  });
  return clonedQuery;
}

export const additionalSearchSteps = ["initial", "name", "age", "state"];

export const stepToComponent = {
  StepSearch: markRaw(DataDeletePageAdditionalSearch),
  StepLoader: markRaw(DataDeletePageLoader),
  StepResults: markRaw(DataDeletePageResults),
  StepPayment: markRaw(DataDeletePagePlan),
  StepUsername: markRaw(DataDeleteSetCredentials),
};
