<script setup>
import { useAttrs } from "vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import AppModal from "@/components/ui/AppModal.vue";
import Button from "@/components/Button.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";

defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["input", "delete"]);

const attrs = useAttrs();
</script>

<template>
  <AppModal v-bind="attrs">
    <AppModalContent>
      <AppModalTitle>
        Are you sure you want to delete this information?
      </AppModalTitle>
      <AppModalParagraph>
        You will not be able to recover it.
      </AppModalParagraph>
      <AppModalFooter>
        <Button type="secondary" @click="$emit('input', false)">
          Cancel
        </Button>
        <Button type="danger" @click="$emit('delete')" :loading="isLoading">
          Yes, delete
        </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
