<script setup>
import ValueDisplay from "@/components/ui/value-display.vue";
import { RadioButton } from "@/components";
import { TrashOutline } from "@/assets/icons";

const emit = defineEmits(["update", "delete"]);

const props = defineProps({
  inputValue: [String, Number, Boolean],
  value: [String, Number, Boolean],
  label: String,
  subLabel: String,
  groupName: String,
  disabled: Boolean,
  deletable: Boolean,
});

function handleClick() {
  if (props.disabled || props.value === props.inputValue) {
    return;
  }
  emit("update", props.inputValue);
}

function handleDelete() {
  emit("delete", props.inputValue);
}
</script>

<template>
  <ValueDisplay
    class="preferences-radio"
    :label="props.label"
    :value="props.subLabel"
    :disabled="props.disabled"
    dark-label
    light-value
    @click="handleClick"
  >
    <template v-slot:actions>
      <button
        v-if="props.deletable"
        class="value-display__action-button value-display__action-button--delete"
        @click.stop="handleDelete"
      >
        <TrashOutline />
      </button>

      <RadioButton
        :name="props.groupName"
        :checked="props.value === props.inputValue"
        :value="props.inputValue"
        :disabled="props.disabled"
      />
    </template>
  </ValueDisplay>
</template>

<style lang="scss">
.preferences-radio {
  .value-display__action-button--delete {
    display: none;
  }

  &:not(.value-display--disabled):hover {
    .value-display__action-button--delete {
      display: flex;
    }
  }
}
</style>
