import { computed } from "vue";
import store from "@/store";
import { useFormattedPrice } from "@/composables";

const planTypeToDisplayName = {
  individual: "Individual",
  couple: "Couple",
  family: "Family",
};

const withDisplayInfo = (plans) =>
  plans.map((plan) => {
    const monthlyPrice =
      plan.recurring_interval === "annually" ? plan.price / 12 : plan.price;

    const monthlyPricePerUser = monthlyPrice / plan.max_members;

    const type =
      plan.max_members === 1
        ? "individual"
        : plan.max_members === 2
        ? "couple"
        : "family";

    return {
      ...plan,
      monthlyPricePerUser,
      type,
      displayName: planTypeToDisplayName[type],
      displayMonthlyPricePerUser: useFormattedPrice(monthlyPricePerUser),
      displayTotalPrice: useFormattedPrice(plan.price),
    };
  });

const withDiscounts = (plans) =>
  plans.reduce((result, plan, index, originalPlans) => {
    const planToCompareAgainst = originalPlans.find(
      (originalPlan) =>
        originalPlan.type === "individual" &&
        originalPlan.recurring_interval === "monthly"
    );

    const discount =
      plan.type === "individual"
        ? 0
        : Math.ceil(
            (1 -
              plan.monthlyPricePerUser /
                planToCompareAgainst.monthlyPricePerUser) *
              100
          );

    return [...result, { ...plan, discount }];
  }, []);

export const usePlans = () => {
  const allPlans = computed(() =>
    withDiscounts(
      withDisplayInfo(store.getters["subscription/getPlans"] ?? [])
    ).sort((a, b) => a.max_members - b.max_members)
  );

  const isLoadingPlans = computed(() => !allPlans.value?.length);

  const activePlan = computed(() =>
    allPlans.value.find(
      (plan) =>
        plan.product_id ===
        store.getters["settings/getSubscription"]?.product_identifier
    )
  );

  return {
    isLoadingPlans,
    allPlans,
    activePlan,
  };
};
