<script setup>
import { SettingsParagraph, SettingsTitle } from "@/components";
import { usePlanMembers } from "@/components/feature/subscribe/composables";
import SubscriptionFamilyPlansMembersOwner from "@/routes/modals/preferences/SubscriptionFamilyPlansMembersOwner.vue";
import SubscriptionFamilyPlansMembersMember from "@/routes/modals/preferences/SubscriptionFamilyPlansMembersMember.vue";
import SubscriptionFamilyPlansMembersInvited from "@/routes/modals/preferences/SubscriptionFamilyPlansMembersInvited.vue";
import SubscriptionFamilyPlansMembersGuest from "@/routes/modals/preferences/SubscriptionFamilyPlansMembersGuest.vue";
import SubscriptionFamilyPlansMembersAdd from "@/routes/modals/preferences/SubscriptionFamilyPlansMembersAdd.vue";

const { planMembers } = usePlanMembers();
</script>

<template>
  <section class="family-plans-members">
    <SettingsTitle>Members</SettingsTitle>
    <template v-if="$store.getters['settings/getSubscription'].owner">
      <SubscriptionFamilyPlansMembersAdd />
      <SettingsParagraph class="family-plans-members__text">
        Members on your plan receive all of the same benefits as you. You may
        remove them or they may remove themselves at anytime. For any billing
        questions, you may reach out to
        <a
          href="mailto:support@cloaked.app"
          target="_blank"
          class="family-plans-members__link"
          >support@cloaked.app</a
        >.
      </SettingsParagraph>
    </template>
    <ul
      class="family-plans-members__list"
      v-if="$store.getters['settings/getSubscription'].owner"
    >
      <li v-for="member in planMembers" :key="member.created_at">
        <SubscriptionFamilyPlansMembersMember
          :member="member"
          v-if="member.state === 'accepted'"
        />
        <SubscriptionFamilyPlansMembersInvited
          :member="member"
          v-if="member.state === 'pending'"
        />
      </li>
      <li v-if="$store.getters['settings/getSubscription']" key="you">
        <SubscriptionFamilyPlansMembersOwner
          :member="{
            joined_at: $store.getters['settings/getSubscription'].purchase_date,
          }"
        />
      </li>
    </ul>
    <SubscriptionFamilyPlansMembersGuest
      v-else
      class="family-plans-members__guest-member"
      :member="{
        joined_at: $store.getters['settings/getSubscription'].purchase_date,
      }"
    />
  </section>
</template>

<style lang="scss">
.family-plans-members {
  margin-top: 36px;

  &__text {
    margin-top: 16px;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }

  &__list {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .plan-member {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    text-align: center;

    @media all and (min-width: $screen-lg) {
      flex-direction: row;
      text-align: left;
      gap: 18px;
    }

    &__icon {
      height: 24px;
      flex: 0 0 24px;

      @media all and (min-width: $screen-lg) {
        height: 32px;
        flex: 0 0 32px;
      }

      &--neutral {
        color: $color-primary-100;
      }
    }

    &__name {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color-primary-100;
      @include line-clamp(1);
      word-break: break-all;
    }

    &__joined-at,
    &__invited-at {
      margin-top: 4px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $color-primary-100;
    }

    &__invite-status {
      text-transform: capitalize;
      color: $color-primary-100;
    }

    &__actions {
      display: flex;
      gap: 8px;
      flex-shrink: 0;
      margin-top: 8px;

      @media all and (min-width: $screen-lg) {
        margin-left: auto;
        margin-top: 0;
      }
    }
  }

  &__guest-member {
    margin-top: 16px;
  }
}
</style>
