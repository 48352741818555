import api from "@/api/api";

export default class PhoneService {
  static getUserPhoneNumbers() {
    return api().get("/api/v1/phone/");
  }

  static getUserPhoneNumbersByCreatedAt() {
    return api().get("/api/v1/phone/?ordering=created_at");
  }

  static getPhoneById(phoneId) {
    return api().get(`/api/v1/phone/${phoneId}/`);
  }

  static addPhone(payload) {
    return api().post(`/api/v1/phone/`, payload);
  }

  static sendVerificationCode(phoneId) {
    return api().get(`api/v1/phone/${phoneId}/verify/`);
  }

  static verifyVerificationCode(phoneId, payload) {
    return api().patch(`api/v1/phone/${phoneId}/verify/`, payload);
  }

  static makePrimary(phoneId) {
    return api().patch(`/api/v1/phone/${phoneId}/`, { primary: true });
  }

  static deletePhone(phoneUrl) {
    return api().delete(phoneUrl);
  }
}
