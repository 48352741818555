<script setup>
import { defineEmits } from "vue";
import ModalTemplate from "@/components/ModalTemplate.vue";
import inlineSvg from "@/components/InlineSvg.vue";

const emit = defineEmits(["done", "closeModal"]);

function handleClose() {
  emit("closeModal");
  emit("done");
}
function linkToSchedule() {
  handleClose();
  window.open(
    "https://calendly.com/d/ckdh-h9j-2gv/call-with-cloaked",
    "_blank"
  );
}
</script>
<template>
  <ModalTemplate
    class="data-delete-schedule-call-modal"
    :show="true"
    @close="handleClose"
    :width="512"
  >
    <template #header>
      <h2 class="modal-title">Get your first month on us</h2>
    </template>
    <template #body>
      <p class="modal-body">
        Set up a quick, 15-minute chat with a Cloaked representative about your
        experience and we'll waive your first month's subscription fee.
      </p>
    </template>

    <template #footer>
      <div class="modal-footer">
        <button class="btn btn-primary" @click="linkToSchedule">
          Scehdule now
          <inlineSvg name="calendar" />
        </button>
        <div class="btn btn-secondary" @click="handleClose">No thanks</div>
      </div>
    </template>
  </ModalTemplate>
</template>
<style lang="scss" scoped>
@media (max-width: 512px) {
  @at-root .modal-container .content {
    position: absolute !important;
    bottom: -20px !important;
  }
}
.modal-title {
  color: $color-primary-100;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  letter-spacing: -0.5px;
}
.modal-body {
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
}
.modal-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.btn {
  display: flex;
  width: 13px;
  height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  flex-shrink: 0;
  color: $color-primary-100;
  width: 100%;
  height: 44px;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn-primary {
  color: $white;
  border-radius: 99px;
  border: 1px solid $color-primary-10;
  background: $cloaked-gradient;
  svg {
    height: 15px;
    width: auto;
  }
}
</style>
