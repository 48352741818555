<script setup>
import { ref } from "vue";
import inlineSvg from "@/components/InlineSvg.vue";

const props = defineProps({
  title: String,
  toggleState: {
    type: Boolean,
    default: false,
  },
});

const toggle = ref(props.toggleState);

function toggleMenu() {
  toggle.value = !toggle.value;
}
</script>

<template>
  <div class="menu-block">
    <div class="menu-title" v-if="props.title" @click="toggleMenu()">
      <span class="title-text">{{ props.title }}</span>
      <span class="icon">
        <inlineSvg
          name="chevron-down"
          key="chevron-down"
          :class="{ active: toggle }"
          class="toggle"
        />
      </span>
    </div>

    <div class="menu-items" :class="{ toggle: toggle }">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-block {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 16px;
    color: $color-primary-100;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    .icon {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $color-primary-30;

        transition: all 0.3s ease-in-out;
      }
      svg {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        width: auto;
        height: 15px;
        z-index: 2;
        color: $color-primary-100;
        transition: all 0.3s ease-in-out;

        &.active {
          transition: all 0.3s ease-in-out;
          @include transform(rotate(-180deg));
        }
      }
    }
  }

  .menu-items {
    opacity: 1;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, max-height 0.5s ease-in-out;
    padding: 8px 0px 8px 16px;
    max-height: 700px;

    &.toggle {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: opacity 0.8s ease-in-out, max-height 0.8s ease-in-out;
    }
  }
}
</style>
