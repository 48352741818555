<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";
import InlineSvg from "@/components/InlineSvg.vue";
import { computed, ref } from "vue";
import { useToast } from "@/hooks";
import { useFormattedPrice } from "@/composables";
import { SubscriptionService } from "@/api";
import store from "@/store";

const toast = useToast();
const emit = defineEmits(["input"]);
const isProcessingPayment = ref(false);

const planDetails = computed(
  () => store.getters["subscription/getSubscriptionDetails"]
);

const onPayNow = async () => {
  try {
    isProcessingPayment.value = true;
    await SubscriptionService.payNow();
    await store.dispatch("subscription/fetchSubscriptionDetails");
    emit("input", false);
    toast.success("Upgrade successful!");
  } catch {
    toast.error(
      "There was an error processing your request. Please try again."
    );
  } finally {
    isProcessingPayment.value = false;
  }
};

const formattedPrice = computed(() =>
  useFormattedPrice(planDetails.value?.price)
);

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "long",
  year: "numeric",
});

const formattedNextBillingDate = computed(() =>
  dateFormatter.format(new Date(planDetails.value.next_billing_date))
);
</script>

<template>
  <AppModal v-bind="$attrs">
    <AppModalContent class="pay-now-modal">
      <AppModalTitle> Upgrade now </AppModalTitle>
      <AppModalParagraph>
        We’ll charge the card we have on file for the plan you selected.
      </AppModalParagraph>
      <div class="pay-now-modal__payment">
        <div class="pay-now-modal__payment-icon">
          <InlineSvg name="card" class="pay-now-modal__payment-icon-svg" />
        </div>
        <span class="pay-now-modal__payment-carrier">
          {{ planDetails.brand }}
        </span>
        <span class="pay-now-modal__payment-price">
          ${{ formattedPrice }}
        </span>
        <span class="pay-now-modal__payment-card">
          •••• {{ planDetails.card }}
        </span>
        <span class="pay-now-modal__payment-plan">
          {{ planDetails.subscription_name }} • Renews
          {{ formattedNextBillingDate }}
        </span>
      </div>
      <AppModalFooter>
        <Button type="secondary" @click="$emit('input', false)">
          Cancel
        </Button>
        <Button type="primary" @click="onPayNow">
          <template v-if="isProcessingPayment">
            <InlineSvg name="spinner" /> Upgrading...
          </template>
          <template v-else> Confirm </template>
        </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.pay-now-modal {
  width: 580px !important;

  &__payment {
    margin: 16px 32px 0;
    border-radius: 12px;
    border: 1px solid $color-primary-10;
    padding: 20px;
    display: grid;
    column-gap: 16px;
    grid-template-columns: 48px 120px 1fr;
    align-items: center;

    &-icon {
      grid-row: 1/3;
      justify-self: center;
      border-radius: 10px;
      border: 2px solid $color-primary-10;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &-svg {
        width: 24px;
        height: 24px;
      }
    }

    &-carrier {
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      text-transform: capitalize;
    }

    &-price {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      justify-self: end;
      text-align: right;
    }

    &-plan {
      justify-self: end;
      text-align: right;
    }

    &-card,
    &-plan {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
</style>
