<template>
  <div class="data-delete-background" />
</template>

<style lang="scss">
.data-delete-background {
  position: fixed;
  inset: 0;
  background-color: $color-primary-0;
  z-index: 1;

  &::before {
    content: "";
    height: 65vh;
    width: 90vw;
    position: fixed;
    z-index: 1;
    transition: all ease-out 200ms;
    transform: skew(-15deg, -25deg) translate3d(10vw, 15vh, 0);
    background: linear-gradient(
      6deg,
      rgba(255, 122, 0, 0.65) 5.3%,
      rgba(240, 83, 107, 0.65) 26.61%,
      rgba(142, 131, 234, 0.65) 56.26%,
      rgba(0, 196, 154, 0.65) 82.21%,
      rgba(41, 190, 232, 0.65) 94.26%
    );
    filter: blur(92.4361801147461px);

    @at-root .theme-dark & {
      opacity: 0.3;
    }

    @at-root .theme-light & {
      opacity: 0.1;
    }

    @media all and (min-width: $screen-lg) {
      height: 120vh;
      width: 120vw;
      transform: skew(-15deg, -15deg) translate3d(-10vw, -10vw, 0);
      filter: blur(144px);
    }
  }
}
</style>
