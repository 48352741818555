<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import ButtonPlans from "@/components/feature/subscribe/ButtonPlans.vue";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  price: {
    type: String,
    default: null,
  },
  hasPlan: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["choose-plan"]);

const buttonElement = ref(null);
const buttonElementWidth = ref(0);

const onWindowResize = () => {
  buttonElementWidth.value = buttonElement.value?.$el?.offsetWidth ?? 0;
};

onMounted(() => {
  window.addEventListener("resize", onWindowResize, { passive: true });
  onWindowResize();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onWindowResize);
});

const isConcise = computed(() => {
  return buttonElementWidth.value < 350;
});

const buttonText = computed(() => {
  const { option, price, hasPlan, isLoading } = props;

  if (option.type === "cancel") {
    if (isConcise.value) {
      return isLoading ? "Downgrading plan" : "Downgrade plan";
    } else {
      return isLoading
        ? "Downgrading to limited plan"
        : "Downgrade to limited plan";
    }
  }

  if (option.type === "stay") {
    if (isConcise.value) {
      return isLoading ? "Stay on limited" : "Stay on limited";
    } else {
      return isLoading ? "Stay on limited plan" : "Stay on limited plan";
    }
  }

  if (hasPlan) {
    if (isConcise.value) {
      return isLoading
        ? `Switching plan`
        : `Switch • $${price}/${
            option.stripePlan?.recurring_interval === "annually"
              ? "year"
              : "month"
          }`;
    } else {
      return isLoading
        ? `Switching to ${option.stripePlan?.displayName} plan`
        : `Switch to ${option.stripePlan?.displayName} plan • $${price}/${
            option.stripePlan?.recurring_interval === "annually"
              ? "year"
              : "month"
          }`;
    }
  }

  if (isConcise.value) {
    return isLoading
      ? `Purchasing plan`
      : `Purchase • $${price}/${
          option.stripePlan?.recurring_interval === "annually"
            ? "year"
            : "month"
        }`;
  } else {
    return isLoading
      ? `Purchasing ${option.stripePlan?.displayName} plan`
      : `Purchase ${option.stripePlan?.displayName} plan • $${price}/${
          option.stripePlan?.recurring_interval === "annually"
            ? "year"
            : "month"
        }`;
  }
});
</script>

<template>
  <ButtonPlans
    ref="buttonElement"
    :type="option.stripePlan?.type ?? option.type"
    class="choose-plan-cta"
    @click="$emit('choose-plan', option)"
    v-bind="$attrs"
  >
    {{ buttonText }}
    <InlineSvg name="spinner" class="choose-plan-cta__icon" v-if="isLoading" />
    <InlineSvg name="arrow-right" class="choose-plan-cta__icon" v-else />
  </ButtonPlans>
</template>

<style lang="scss">
.choose-plan-cta {
  &__icon {
    width: 14px;
    height: 14px;
  }
}
</style>
