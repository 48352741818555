<script setup>
import { computed, ref, watch, reactive } from "vue";
import { ModalTemplate } from "@/components";
import store from "@/store";
import Button from "@/components/Button.vue";
import { StateList } from "@/scripts/countries/states";
import inlineSvg from "@/components/InlineSvg.vue";
import CardsService from "@/api/actions/cards-services";
import { useToast } from "@/hooks";

const toast = useToast();

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 10000,
  },
});

const state = reactive({
  loading: false,
});

function closeModal() {
  store.dispatch("closeModal");
}

async function save(value) {
  state.loading = true;
  CardsService.patchTransactionNote(transaction.value.id, {
    note: value,
  })
    .then(() => {
      store.dispatch("updateTransactions", {
        ...transaction.value,
        note: value,
      });

      state.loading = false;
      closeModal();
    })
    .catch((error) => {
      state.loading = false;
    });
}

const transaction = computed(() => {
  return store.state.cards.rightPanel?.transaction;
});

const inputText = ref(transaction.value.note);

const notesValueChanged = computed(() => {
  return transaction.value.note !== inputText.value;
});
</script>

<template>
  <ModalTemplate
    :show="isVisible"
    :large="true"
    :showCloseInHeader="true"
    @close="closeModal"
  >
    <template #header>
      <h1>Transaction Note</h1>
    </template>

    <template #input>
      <textarea
        v-model="transaction.note"
        rows="10"
        cols="50"
        @keydown.enter.stop
        :maxlength="props.maxlength"
        autocomplete="off"
      ></textarea>
    </template>
    <template #footer>
      <div class="textarea-actions">
        <div class="buttons">
          <Button
            type="secondary"
            @click="closeModal"
            :disabled="state.loading"
          >
            Cancel
          </Button>
          <Button
            @click="save(transaction.note)"
            :loading="state.loading"
            :disabled="state.loading || !notesValueChanged"
          >
            Save
          </Button>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  background: none;
  color: $color-primary-100;
  font-size: 16px;
  resize: none;
  font-family: $poppins;
  outline: none;
}

.textarea-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
</style>
