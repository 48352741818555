<template>
  <div>
    <p>Promises aren’t enough.</p>
    <p>
      We built Cloaked because we lost trust in companies. We grew tired of
      waiting for them to do the right thing with our data.
    </p>
    <p>Privacy by design.</p>
    <p>
      Cloaked was built with your data ownership its core. Your data is
      encrypted even from our own view.
    </p>
    <p>A simpler policy.</p>
    <p>
      Cloaked does not... read your emails, read your texts, listen to your
      calls, sell your data ...it's that simple.
    </p>
    <p>
      What is 'Cloaking'? 'Cloaking' (including grammatically inflected forms)
      is the practice of creating proxy-based identifiers corresponding to
      personally identifiable information.
    </p>

    <p>
      What information does Cloaked collect? Summary: We collect the categories
      of information listed below. We do not have access to passwords, which are
      salted and hashed, or encrypted.
    </p>

    <p>
      Depending on your use of the Service, we may collect information about
      you, the Service may generate cloaked identifiers to mask categories of
      information listed below. We will have access to those cloaked data
      elements. &#8205; &bull; Account and contact information, including your
      name, username, passwords (name, username, password, as stored: salted +
      hashed, or encrypted), contact information, and other specifics based on
      product type. &#8205; &bull; Billing and tokenized credit card
      information. &#8205; &bull; For Cloaked Pay, Certain know-your-customer
      information, including date of birth, gender, and financial account data.
      Cloaked Pay GLBA Notice &#8205; &bull; Device information, We store
      requested and referring URL, browser/IP information, and analytical data
      on use of the service.
    </p>

    <p>
      How is information collected with Cloaked? Summary: We collect information
      as part of payment authorization and fraud screening. Cookies provide
      Service functionality. Information in log files provide and improve our
      Service.
    </p>

    <p>
      We collect information directly from you as well as from third parties,
      like those providing payment processing and fraud prevention
      services.&#8205;
    </p>

    <p>
      Registering to Use and While Using the Service You may create an account
      to use features of the Service. You may provide information to us and
      directly to our service providers, like our payment services provider.
    </p>

    <p>
      Cookies &#8205;When you visit our Site, cookies may be placed on your
      device in order to personalize and optimize your experience on the Site.
      This Privacy Policy does not govern cookies or action tags by third
      parties on their websites or services through the Service.
    </p>

    <p>
      Log Files &#8205;In addition to cookie data, we collect Device Information
      via log files to administer and monitor the Service.
    </p>

    <p>
      Financial Institutions and Data Aggregators &#8205;We may use data
      aggregators to link your financial institution accounts with Cloaked
      and/or Cloaked Card. Currently, we use Plaid, Inc. and/or Stripe, Inc.
      Plaid and Stripe may collect your financial institution account
      credentials, such as username, password, and multi-factor authentication
      methods you use, to connect your financial institution with us or for
      payment purposes. Our privacy policy does not cover how data aggregators,
      such as Plaid or Stripe, collect and share your information. By applying
      for and/or using Cloaked or Cloaked Card, you acknowledge that the terms
      of Plaid or Stripe's privacy policy will govern use of such information.
      When you connect your financial institution accounts with us through Plaid
      or Stripe, we collect Financial Data as described above.
    </p>

    <p>
      Does Cloaked collect information from children under 13 years of age? No:
      The Service is not permitted, designed for, or directed to children under
      the age of 13. We do not collect information from any person we actually
      know is under the age of 13. If you believe your child has provided us
      with personal information, please alert us at privacy@cloaked.id. If we
      learn that we have collected personal information from a minor, we will
      promptly take steps to delete such information. Cloaked Card is not
      permitted for persons under the age of 18.&#8205;
    </p>

    <p>
      What does Cloaked do with the information it collects? European Data
      Protection Laws require us to explain the legal bases for our processing
      of your information. We process your information:
    </p>

    <p>
      (a) where you have given consent to the processing, which consent may be
      withdrawn at any time without affecting the lawfulness of processing based
      on consent prior to withdrawal;
    </p>

    <p>
      (b) where it is necessary to perform the contract we have entered into or
      are about to enter into with you (whether in relation to the provision of
      the Service or otherwise);
    </p>

    <p>
      (c) where it is necessary for us to comply with a legal obligation to
      which we are subject; and/or
    </p>

    <p>
      (d) where it is necessary for the purposes of our legitimate interests (or
      those of a third party) in providing or marketing the Service and your
      interests or fundamental rights and freedoms do not override those
      legitimate interests.
    </p>

    <p>
      To provide some more detail, we use the information collected to: &bull;
      Provide the Service; &bull; Process your application and transactions;
      &bull; Determine your eligibility for the Service; &bull; Verify your
      identity; &bull; Prevent fraud; &bull; Manage and service your account;
      &bull; Operate and improve the Service; and &bull; Contact you for
      customer service and billing purposes.
    </p>

    <p>
      Unless you request otherwise (where permitted by law), we and third
      parties acting on our behalf may subsequently send you electronic
      newsletters or contact you about the Service. If you no longer desire to
      receive these communications, we will provide you with the option to
      change your preferences in each communication we send to you. You may also
      inform us by email: privacy@cloaked.id.
    </p>

    <p>
      If you identify yourself to us by e-mail with questions, we will use your
      information to respond to your questions or comments, and file your
      questions or comments (with your information) for future reference.
    </p>

    <p>
      We also use information collected to send announcements regarding the
      Service or your billing account status. You cannot unsubscribe from
      Service or transactional announcements as they contain important
      information relevant to your use of the Service. &#8205;
    </p>

    <p>
      When does Cloaked disclose information to third parties? Summary: We may
      disclose contact information, and device data, to third-parties to comply
      with relevant law, or for legal reasons. This information may also be
      disclosed to Professional Advisors to provide us with business services.
      In the event of an investment in Cloaked or a sale of our business, we may
      share limited, required information with an investor or purchaser. &#8205;
      We disclose information we gather through the Service to the following
      third parties, as set forth by our Terms of Service, or as separately
      authorized by you:
    </p>

    <p>
      Laws and Legal Rights &#8205;We may disclose your information as required
      by law, to enforce law or contract, or to protect the safety and/or
      security of our users or the Service.
    </p>

    <p>
      Professional Advisors &#8205;We may provide information to our
      Professional Advisors in the course of professional services rendered to
      provide us with business services like accounting or legal services.
    </p>

    <p>
      Outside Contractors &#8205;We may employ independent contractors, third
      party service providers, vendors, and suppliers to provide us with
      business services related to the Service. These Outside Contractors may
      have access to information collected through the Service. We use
      reasonable efforts to ensure that these Outside Contractors (a) limit
      their use of your information only to the extent necessary to provide
      services, and (b) are capable of protecting the security of your
      information.
    </p>

    <p>
      Sale of Business &#8205;We may transfer information to a third party in
      connection with a sale, merger, transfer of all or substantially all of
      the assets of Cloaked.
    </p>

    <p>
      Is the information collected through the service secure? Summary: Your
      information is stored using commercially reasonable security techniques.
    </p>

    <p>
      We want your information to remain secure. We strive to provide
      transmission of your information from your device to our servers that
      align with commercially reasonable standards and to employ administrative,
      physical, and electronic measures to protect your information from
      unauthorized access.
    </p>

    <p>
      There is always risk involved in transmitting information over the
      Internet. As a result, although we strive to protect your information, we
      cannot ensure or warrant the security or privacy of information you
      transmit to us, and you do so at your own risk.&#8205;
    </p>

    <p>
      Could my information be transferred to other countries? Summary: Your
      information may be transferred to the United States. If you are located in
      the European Economic Area or the United Kingdom, although the United
      States has less stringent privacy laws than your home country, where
      required, we ensure the same degree of protection that is required under
      European Economic Area and United Kingdom regulations. &#8205; Information
      collected on the Service may be transferred to countries that may not have
      laws of general applicability regulating the use and transfer of such
      information (such as the United States). By using the Service, you consent
      to the transfer of such information. Further, you hereby expressly grant
      consent to Cloaked to: (a) process and disclose such information in
      accordance with this Privacy Policy; (b) transfer such information to the
      United States; and (c) disclose such information to comply with lawful
      requests by public authorities, including to meet national security or law
      enforcement requirements. To the extent required by applicable law:
      whenever we transfer your personal data (as defined in the European Data
      Protection Laws) to third parties (as described in this Privacy Policy)
      located in the United States, we ensure a similar degree of protection is
      afforded; we may use specific contracts approved by the European
      Commission or the UK Information Commissioner&rsquo;s Office, as
      applicable; and if we rely on another transfer mechanism, we will keep you
      updated or contact you if required. Please contact us if you want further
      information on the specific mechanisms used by us when transferring your
      personal data to the United States. If you are a user accessing the
      Service from a jurisdiction with laws or regulations governing processing
      of personal data that differ from those of the United States, please be
      advised that all aspects of the Service are governed by the internal laws
      of the United States and the Commonwealth of Massachusetts, USA,
      regardless of your location.
    </p>

    <p>
      For how long will my information be kept? We will only retain your
      information for as long as necessary to fulfill the purposes for which we
      collected it or as otherwise permitted by applicable law.
    </p>

    <p>
      To determine the appropriate retention period for information, we consider
      the amount, nature, and sensitivity of that information, regulatory
      requirements, the potential risk of harm, and the purposes for which we
      process your information and achieving those purposes through.
    </p>

    <p>
      What choices do I have regarding my information? Summary: You can opt out
      of marketing communications by contacting us. As outlined below, users in
      the European Economic Area and the United Kingdom may have additional
      rights which can be exercised by contacting us. &#8205; We process
      information as described in this Privacy Policy and our Terms of Service.
      Where appropriate, we will ask for your consent to use your information
      for other purposes.
    </p>

    <p>
      If you have questions, requests, or would like to update information,
      please contact us at privacy@cloaked.id.
    </p>

    <p>
      &#8205;Under certain circumstances and in compliance with the European
      Data Protection Laws, you may have the right to: &#8205; Request access to
      your information (commonly known as &lsquo;data subject access
      request&rsquo;). Request correction of the information that we hold about
      you. Request erasure of your information. Object to processing of your
      information where we are relying on a legitimate interest (or that of a
      third party) and there is something about your particular situation which
      makes you want to object to processing on this ground; Request the
      restriction of processing of your information. Request the transfer of
      your information to another party; and Lodge a complaint with the relevant
      supervisory authority (as defined in the European Data Protection Laws).
    </p>

    <p>
      If you are covered by the European Data Protection Laws and want to
      review, verify, correct or request erasure of your information, object to
      the processing of your information, or request that we transfer a copy of
      your information to another party, please contact privacy@cloaked.id.
    </p>

    <p>
      All users have the right to &quot;opt out&quot; of receiving marketing
      communications by following directions provided in the communication or by
      contacting privacy@cloaked.id.
    </p>

    <p>
      Such updates, corrections, changes and deletions will have no effect on
      other information that we maintain, or information that we have provided
      to third parties in accordance with this Privacy Policy prior to such
      update, correction, change or deletion. To protect your privacy and
      security, we may take reasonable steps (such as requesting a unique
      password) to verify your identity before granting you profile access or
      making corrections. You are responsible for maintaining the secrecy of
      your unique password and account information at all times.
    </p>

    <p>
      DO NOT TRACK We take no action in response to automated Do Not Track
      requests. However, if you wish to stop such tracking, please contact us
      with your request.
    </p>

    <p>
      How will I know if there are any changes to this privacy policy? We may
      revise this Privacy Policy from time to time. If legally required based on
      the changes, we will provide an opt-in. For minor changes, we do not
      require your opt-in. Visit this page periodically for changes.
    </p>

    <p>
      Who do I contact if I have any privacy questions. &#8205;Contact us at:
      privacy@cloaked.id
    </p>

    <p>Policy last updated on: August 15, 2023</p>

    <p>
      <a
        href="https://assets.website-files.com/63ec0f977f0357126ec38bcd/656a03b66119cf2c5ea82208_Cloaked%20-%20GLBA%20notice.docx.pdf"
        target="_blank"
        >Cloaked Pay GLBA Notice</a
      >
    </p>
  </div>
</template>

<style scoped lang="scss">
div {
  * {
    margin-top: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: $color-primary-100;
    text-decoration: underline;

    &:hover {
      color: $color-primary-70;
    }
  }
}
</style>
