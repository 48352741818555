<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";
import { usePlans } from "@/components/feature/subscribe/composables";
import InlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";
import { ref } from "vue";
import { useToast } from "@/hooks";

const { activePlan } = usePlans();

const emit = defineEmits(["input"]);

const isCancellingSubscription = ref(false);

const { success, error } = useToast();

const onCancel = async () => {
  try {
    isCancellingSubscription.value = true;
    await store.dispatch("subscription/cancelSubscription");
    success("You are now on a limited plan");
    emit("input", false);
  } catch {
    error("Failed to cancel subscription");
  } finally {
    isCancellingSubscription.value = false;
  }
};
</script>

<template>
  <AppModal
    v-bind="$attrs"
    @input="$emit('input', $event)"
    :has-outside-click-close="true"
  >
    <AppModalContent>
      <AppModalTitle size="sm">
        Leave Cloaked {{ activePlan?.displayName }} Plan
      </AppModalTitle>
      <AppModalParagraph>
        Your subscription is paid and managed by another Cloaked member.
      </AppModalParagraph>
      <AppModalFooter>
        <Button type="danger" @click="onCancel">
          <template v-if="isCancellingSubscription">
            Downgrading to limited plan <InlineSvg name="spinner" />
          </template>
          <template v-else> Downgrade to limited plan </template>
        </Button>
        <Button type="secondary" @click="$emit('input', false)">
          Cancel
        </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>
