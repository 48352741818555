<script setup>
import { computed } from "vue";
import inlineSvg from "@/components/InlineSvg.vue";
import Button from "@/components/Button.vue";
import store from "@/store";

const props = defineProps({
  identityType: {
    type: String,
    default: "favorite",
    validator: (value) =>
      ["favorite", "ignored", "category", "all"].includes(value),
  },
  callback: {
    type: Function,
    default: () => {},
  },
});

const identitySearch = computed(() => {
  return store.getters.getIdentitySearch;
});

const iconName = computed(() => {
  if (props.identityType === "favorite") {
    return "favorite-outline-thin";
  } else if (props.identityType === "ignored") {
    return "ignored-outline-thin";
  } else if (props.identityType === "category") {
    return "stacked-blocks";
  } else {
    return "blocks";
  }
});
</script>

<template>
  <div class="no-identities">
    <div class="no-identities__content">
      <template v-if="identitySearch.length">
        <inlineSvg :name="iconName" class="no-identities__icon" />
        <h2 class="no-identities__title">
          No results matching "{{ identitySearch }}"
        </h2>
        <p class="no-identities__text">Try adjusting your search</p>
      </template>
      <template v-else-if="props.identityType === 'favorite'">
        <inlineSvg name="favorite-outline-thin" class="no-identities__icon" />
        <h2 class="no-identities__title">No favorites</h2>
        <p class="no-identities__text">
          To add an identity to your favorites, tap on an identity in your “All
          identities” view and click the
          <inlineSvg
            name="favorite-outline-thin"
            class="no-identities__text-icon"
          />
          icon on the details sidebar.
        </p>
      </template>
      <template v-else-if="props.identityType === 'ignored'">
        <inlineSvg name="ignored-outline-thin" class="no-identities__icon" />
        <h2 class="no-identities__title">No ignored identities</h2>
        <p class="no-identities__text">
          You can ignore identities you no longer want to hear from by clicking
          on a Cloaked identity from the “All identities” view and clicking the
          <inlineSvg name="bell" class="no-identities__text-icon" />
          icon in the details sidebar.
        </p>
      </template>
      <template v-else-if="props.identityType === 'category'">
        <inlineSvg name="stacked-blocks" class="no-identities__icon" />
        <h2 class="no-identities__title">No identities in this category</h2>
        <p class="no-identities__text">
          You can add identities to this category by clicking the button below
          or go to "All identities" view and select the category you want from
          the details sidebar.
        </p>
        <Button @click="props.callback" class="no-identities__button"
          >Add identities</Button
        >
      </template>
      <template v-else-if="props.identityType === 'all'">
        <inlineSvg name="blocks" class="no-identities__icon" />
        <h2 class="no-identities__title">You have no identities</h2>
        <p class="no-identities__text">
          You can create a new identity by clicking the button below.
        </p>
        <Button @click="props.callback" class="no-identities__button">
          <inlineSvg name="plus" />
          New identity
        </Button>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.no-identities {
  width: calc(100vw - $ui-left-nav-width);
  height: calc(100vh - $ui-banner-height - $ui-toolbar-height);
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    margin: auto;
    max-width: 550px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin: auto;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    color: $color-primary-100;
  }

  &__title {
    color: $color-primary-100;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
    margin: 24px auto 0;
  }

  &__text {
    color: $color-primary-70;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    margin: 12px auto 0;

    &-icon {
      height: 20px;
      width: 24px;
      vertical-align: middle;
    }
  }

  &__button {
    margin-top: 24px;
  }
}
</style>
