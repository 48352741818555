<script setup>
import { ChevronLeft } from "@/assets/icons";
const emit = defineEmits(["go-back"]);
</script>

<template>
  <header class="preferences-header">
    <div class="preferences-header__left">
      <button class="preferences-header__back-button" @click="emit('go-back')">
        <ChevronLeft />
        <span>Back</span>
      </button>

      <slot name="post-left" />
    </div>

    <div class="preferences-header__right">
      <slot name="right" />
    </div>
  </header>
</template>

<style lang="scss">
.preferences-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  width: 100%;

  &__left,
  &__right {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__back-button {
    background: none;
    display: flex;
    align-items: center;
    color: $color-primary-100;
    border: none;
    padding: 0;
    height: 31px;

    svg + span {
      margin-left: 12px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
