<script setup>
import DataRemovePageDetailInfoRequired from "@/components/feature/data-delete/DataRemovePageDetailInfoRequired.vue";
import DataRemovePageDetailSubmitting from "@/components/feature/data-delete/DataRemovePageDetailSubmitting.vue";
import DataRemovePageDetailSubmitted from "@/components/feature/data-delete/DataRemovePageDetailSubmitted.vue";
import DataRemovePageOverview from "@/components/feature/data-delete/DataRemovePageOverview.vue";
import UserService from "@/api/actions/user-service";
import { HAS_EXITED_DELETE_FLOW } from "@/scripts/userFlags";
import { PH_EVENT_USER_CLICKED_DATA_DELETION_ACCOUNT_ONBOARDING_BUTTON } from "@/scripts/posthogEvents";
import { posthogCapture } from "@/scripts/posthog.js";

defineProps({
  status: {
    type: String,
    required: true,
    validator: (value) =>
      ["review-required", "info-required", "submitting", "submitted"].includes(
        value
      ),
  },
  relatives: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const statusToComponent = {
  "review-required": DataRemovePageOverview,
  "info-required": DataRemovePageDetailInfoRequired,
  submitting: DataRemovePageDetailSubmitting,
  submitted: DataRemovePageDetailSubmitted,
};

function finish() {
  UserService.setFlag({ name: HAS_EXITED_DELETE_FLOW, value: true });
  posthogCapture(PH_EVENT_USER_CLICKED_DATA_DELETION_ACCOUNT_ONBOARDING_BUTTON);
}
</script>

<template>
  <div class="data-remove-detail">
    <Component
      :is="statusToComponent[status]"
      v-bind="$attrs"
      :status="status"
      :relatives="relatives"
      @update-status="$emit('update-status', $event)"
      @done="finish"
    />
  </div>
</template>

<style lang="scss">
.data-remove-detail {
  height: 100%;
  align-self: center;
}
</style>
