<script setup>
import Button from "@/components/Button.vue";
import { sanitize } from "@/scripts";
import { computed, reactive, ref, watch } from "vue";
import MarkdownIt from "markdown-it";
import store from "@/store";

const iframeRef = ref(null);
const rowDetailsRef = ref(null);

const state = reactive({
  iframeHeight: 0,
  iframeWidth: 0,
});

const markdown = new MarkdownIt();
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
  opened: {
    type: Boolean,
    default: false,
  },
  brokers: {
    type: Array,
    default: () => [],
  },
  instructions: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["done", "toggleOpen"]);

const darkModeEnabled = computed(() => {
  return store.getters["colorScheme/isDarkMode"];
});

const fontColor = computed(() => {
  return darkModeEnabled.value ? "#BABCBD" : "#5E6165";
});

const linkColor = computed(() => {
  return darkModeEnabled.value ? "#FFFFFF" : "#191E23";
});

const markdownToHtml = computed(() => {
  const formattedMarkdown = props.instructions
    ?.split(" ")
    .map((word) => {
      if (word.startsWith("http") || word.startsWith("www.")) {
        // dont want to do regex match cus some urls are already formatted in markdown correctly
        return `[${word}](${word})`;
      }
      return word;
    })
    .join(" ");

  let html = markdown.render(formattedMarkdown);

  html = sanitize.safe_html(html);
  html = html.replaceAll(
    "<a",
    `<a style='text-decoration: underline; cursor: pointer; color: ${linkColor.value};' target='_blank' rel='noopener noreferrer'`
  );
  html = html.replaceAll(
    "<li",
    "<li style='display: list-item; list-style-position: inside; margin-top: 16px;'"
  );
  html = html.replaceAll(
    "<ol",
    "<ol style='list-style-type: decimal; margin: 0; padding: 0;'"
  );
  html = `
    <base href="/" target="_blank" />
    <style>
      @font-face { font-family: 'Poppins'; src: local('Poppins'), url(/static-fonts/Poppins-Regular.ttf)  format('truetype'); font-weight: 400; font-style: normal;}
      html, body { font-size: 15px; font-style: normal; font-weight: 400; line-height: normal; letter-spacing: -0.2px; color: ${fontColor.value}; font-family: 'Poppins';}
    </style>
    <html>
      <body>
        ${html}
      </body>
    </html>`;
  return html;
});

function resizeIframe() {
  const body = iframeRef?.value?.contentWindow?.document?.body;
  const html = iframeRef?.value?.contentWindow?.document?.documentElement;

  if (body && html) {
    const height = Math.max(
      body?.scrollHeight,
      body?.offsetHeight,
      html?.clientHeight,
      html?.scrollHeight,
      html?.offsetHeight
    );
    state.iframeHeight = height;
  }
}

watch(rowDetailsRef, (value) => {
  if (value) {
    state.iframeWidth = value.clientWidth;
  }
});
</script>
<template>
  <div
    class="action-row-wrapper"
    :class="{ opened: props.opened }"
    @click.stop="emit('toggleOpen')"
  >
    <div class="action-row">
      <h3 class="name">
        <span class="icon">{{ props.opened ? "-" : "+" }}</span>
        <span class="text">{{
          `${props.name}${props.count ? " (" + props.count + ")" : ""}`
        }}</span>
      </h3>
      <h3 class="review-text">Review instructions</h3>
      <h3>{{ props.date }}</h3>
      <div class="done-button">
        <Button
          type="secondary"
          size="full"
          @click.stop="emit('done')"
          class="long-button"
        >
          Mark as done</Button
        >
        <Button
          type="secondary"
          size="md"
          @click.stop="emit('done')"
          class="short-button"
        >
          Done</Button
        >
      </div>
    </div>
    <div class="action-row-details-wrapper" :class="{ opened: props.opened }">
      <div
        class="action-row-details"
        :class="{ opened: props.opened }"
        ref="rowDetailsRef"
      >
        <div class="warning">
          {{ props.name }} requires some additional information from you in
          order to remove your records.
        </div>
        <h3 v-if="props.count">
          Requesting to remove your info from this {{ props.name }} will also
          remove it from:
        </h3>
        <ol v-if="props.count">
          <li
            v-for="(broker, idx) in props.brokers"
            :key="`${broker?.name}-${idx}`"
          >
            <span class="list-rank">{{ idx + 1 }}</span
            ><span>{{ broker?.name }}</span>
          </li>
        </ol>
        <h3>How to remove your records from {{ props.name }}</h3>
        <iframe
          ref="iframeRef"
          class="instructions"
          @load="resizeIframe"
          :srcdoc="markdownToHtml"
          :style="`height: ${state.iframeHeight}px; width: ${state.iframeWidth}px;`"
          frameborder="0"
          sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.action-row-wrapper {
  padding: 24px;
  border-radius: 12px;
  border: 1px solid $color-primary-10;
  background-color: $color-primary-1;
  @at-root .theme-dark & {
    background-color: $color-primary-5;
  }
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid $color-primary-100;
    transform: scale(1.005);
    transition: all 0.3s ease;
  }
  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 8px;

    h3 {
      color: $color-primary-100;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
      flex-shrink: 0;
      width: calc((100% + 48px) / 4); // width + padding
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-of-type {
        width: calc(((100% + 48px) / 4) - 24px);
        flex-shrink: 0;
      }

      &.name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        flex-wrap: nowrap;
        overflow: auto;
        white-space: wrap;
        .icon {
          width: 11px;
        }
        .text {
          @include line-clamp(2);
        }
      }
      &.review-text {
        font-weight: 600;
        text-decoration-line: underline;
        color: $color-alert;
      }
    }
    .done-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 95%;
      .short-button {
        display: none;
      }
      .long-button {
        display: flex;
      }
      @media (max-width: 1195px) {
        .short-button {
          display: flex;
        }
        .long-button {
          display: none;
        }
      }
    }
  }

  &.opened {
    border: 1px solid $color-primary-100;
    background-color: transparent;
    box-shadow: 0px 18px 34px 0px rgba($color-primary-100-light, 0.15);
    transition: all 0.3s ease-in-out;
    @at-root .theme-dark & {
      box-shadow: 0px 18px 34px 0px rgba($color-primary-50-dark, 0.15);
    }
  }

  .action-row-details-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    transition: grid-template-rows 0.3s ease-out, opacity 0.3s ease-out;
    &.opened {
      grid-template-rows: 1fr;
      opacity: 1;
    }
    .action-row-details {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: $color-primary-100;
      gap: 16px;
      display: flex;
      overflow: hidden;
      &.opened {
        margin-top: 16px;
      }

      .warning {
        background-color: $color-alert-tint;
        color: $color-alert;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        width: 100%;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        border-radius: 16px;
      }
      h3 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
      }
      ol {
        list-style: none;
        color: $color-primary-100;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.1px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          justify-content: flex-start;
          .list-rank {
            background-color: $color-primary-100;
            border-radius: 50%;
            color: $color-primary-0;
            height: 18px;
            width: 18px;
            font-size: 11px;
            font-weight: 700;
            font-style: normal;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .instructions {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
        color: $color-primary-70;
        // NOTE: li has margin-top: 16px;
        margin-top: -16px;
        iframe {
          border: none;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
