<template>
  <div>
    <p>
      The following terms and conditions apply to Cloaked Prepaid Card
      Offerings. This cardholder agreement (“Agreement”) outlines the terms and
      conditions under which Cloaked Pay Cards have been issued to you by
      Patriot Bank, N.A. (the “Bank”), pursuant to licenses from Mastercard®
      International Incorporated. The card can be used everywhere Mastercard is
      accepted. Mastercard is a registered trademark of Mastercard
      International.
    </p>
    <p>
      This Agreement requires any legal disputes between you and us to be
      decided by arbitration and not in a court of law, and it also forbids you
      from participating in a class action against us unless you exercise your
      right to reject arbitration as described in the arbitration provision of
      this Agreement.
    </p>

    <h2>A. Using the Services</h2>
    <p>1. Definitions</p>
    <p>
      In this Agreement "you" and "your" refer to the individual consumer using
      the Card (as defined below). “Issuer” refers to the Bank. “We,” “us,”
      “our,” and “Cloaked” refers to Cloaked Pay, LLC and the Issuer. “Partner
      Banks” refers to our bank partners that provide financial services to us,
      such as the banks we may use to perform ACH debits or payment card
      acquiring transactions to fund your purchases. Depending on the context,
      “Partner Banks” may also include the Issuer.
    </p>
    <p>
      The “Cloaked Card Services” or “Services” consist of Cloaked software and
      technology that provide you with the opportunity to apply for and use a
      Cloaked Card (as defined below), including one or more Cloaked Card
      Network, in connection with your Cloaked Account. The Services include
      Cloaked’s website, mobile app, and other platforms, as applicable. Cloaked
      is not a bank and does not offer banking services as defined by the United
      States Department of Treasury. You must open a Cloaked Account before
      accessing the Cloaked Card Services.
    </p>
    <p>
      “Card” or “Cloaked Card” means the Mastercard payment card issued to you
      by the Issuer. The Card is a prepaid card. The Card is not a gift card,
      nor is it intended for gifting purposes. The Card is also not designed for
      business use. Your account with the Card Issuer is not interest bearing.
      Upon election, the Card may be reloadable.
    </p>
    <p>
      “Account” or “Cloaked Account” is your means of access to the software and
      services account offered by us. Your Account does not hold any funds.
      Instead, all funds are received and held by our Partner Banks.
    </p>
    <p>2. About the Program</p>
    <p>
      By using the Cloaked Pay, you agree to be bound by the Cloaked Terms of
      Services (https://www.cloaked.app/terms-of-service) (the “Terms of
      Service”), this Agreement, and all other terms, policies and guidelines
      applicable to the Services used (including but not limited to the Cloaked
      Privacy Policy: https://www.cloaked.app/privacy-policy ).
    </p>
    <p>
      This Cloaked Card will be linked to your Cloaked Account. Purchases made
      with your Cloaked Card will automatically be listed on your Cloaked
      Account or elsewhere in the Services.
    </p>
    <p>
      The Cloaked Card is designed to make purchases for certain goods and
      services online or wherever Google Pay or Apple Pay is accepted.
    </p>
    <p>
      Cloaked and/or its program partners may restrict transactions based on
      attributes such as, but not limited to, merchant category code, merchant
      ID, merchant name, and/or location.
    </p>
    <p>3. Fees</p>
    <p>
      Cloaked charges a subscription fee of [$10/month] for access to an array
      of Cloaked products and services. This fee is not specific to your Card
      use.
    </p>
    <p>The following fees are assessed on your use of the Cloaked Card.</p>

    <table>
      <thead>
        <tr>
          <th>All Fees</th>
          <th>Amount</th>
          <th>Details</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="3">Get Started</td>
        </tr>

        <tr>
          <td>Card Purchase</td>
          <td>$0</td>
          <td>Fee charged for purchase of the Cloaked Pay Prepaid Card.</td>
        </tr>

        <tr>
          <td>Secondary Card Purchase Fee</td>
          <td>$0</td>
          <td>
            Fee charged for purchase of secondary Cloaked Pay Prepaid Card.
          </td>
        </tr>

        <tr>
          <td colspan="3">Monthly Usage</td>
        </tr>

        <tr>
          <td>Monthly Fee</td>
          <td>$0</td>
          <td>
            Cloaked Card availability is included in your subscription to
            Cloaked, which includes all Cloaked core and Cloaked Card products
            and services. If you are eligible for Cloaked Card, you can gain
            Cloaked Card access with an active and paid Cloaked subscription.
          </td>
        </tr>

        <tr>
          <td colspan="3">Add Money</td>
        </tr>

        <tr>
          <td>Funding from a Bank Account</td>
          <td>$0</td>
          <td></td>
        </tr>

        <tr>
          <td colspan="3">Information</td>
        </tr>

        <tr>
          <td>Automated & Live Agent Phone Calls</td>
          <td>$0</td>
          <td>No fee for each customer service contact with a live agent.</td>
        </tr>

        <tr>
          <td>Email and Text Message Alerts</td>
          <td>$0</td>
          <td>Standard text messaging rates may apply.</td>
        </tr>

        <tr>
          <td>Mobile Application</td>
          <td>$0</td>
          <td>Standard data rates and service carrier fees may apply.</td>
        </tr>

        <tr>
          <td>Mailed Periodic Statement Fee</td>
          <td>$0</td>
          <td>One request per month is included at no charge.</td>
        </tr>

        <tr>
          <td colspan="3">Using your card outside the U.S.</td>
        </tr>

        <tr>
          <td>Foreign Transaction Fee</td>
          <td>$0</td>
          <td>
            You may be charged a fee by any retailers or financial institutions
            involved in your transaction.
          </td>
        </tr>

        <tr>
          <td colspan="3">Other</td>
        </tr>

        <tr>
          <td>Inactivity Fee</td>
          <td>$0</td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <p>
      Your funds are not FDIC insured. Your funds will be held at or transferred
      to the Bank. If the Bank fails, you are not protected by FDIC deposit
      insurance and could lose some or all of your money.
    </p>

    <p>No overdraft/credit feature.</p>
    <p>
      Contact Cloaked by you may contact us through the App, via email at
      support@cloaked.app, or write to us at:
    </p>

    <p>
      Cloaked Pay<br />
      1075 Westford Street<br />
      Suite 305<br />
      Lowell, MA 01851
    </p>

    <p>
      For general information about prepaid accounts, visit cfpb.gov/prepaid. If
      you have a complaint about a prepaid account, call the Consumer Financial
      Protection Bureau at 1-855-411-2372 or visit cfpb.gov/complaint.
    </p>
    <p>
      For purposes of these disclosures, our business days are Monday through
      Friday. Holidays are not included
    </p>
    <p>
      Your purchases may be subject to additional fees from your Funding Account
      provider or from individual merchants. These fees are not assessed or
      received by Cloaked and are not included in the above list.
    </p>
    <p>4. Requirements to use the Card Services</p>
    <p>In order to access the Cloaked Card Services, you represent that:</p>
    <ul>
      <li>
        You are either a legal resident of the United States, a United States
        citizen or a business entity authorized to conduct business by the
        state(s) in which you operate and that you are an authorized signatory
        for the business.
      </li>
      <li>You are at least 18 years old.</li>
      <li>
        You have not been previously banned from using our Services, and that
        you have not previously violated our Terms of Service.
      </li>
      <li>
        You have not been barred from receiving similar services under the laws
        of the United States.
      </li>
    </ul>
    <p>By accepting these terms, you also represent that:</p>
    <ul>
      <li>You have the capacity to be bound by them.</li>
      <li>
        Any information you provide in connection with the Cloaked Services,
        accurately and truthfully represents your identity and the identity of
        any associated business.
      </li>
      <li>
        You and all transactions initiated by you will comply with all federal,
        state, and local laws, rules, and regulations applicable to you.
      </li>
      <li>
        You will not use the Services, directly or indirectly, for any
        fraudulent undertaking or in any manner so as to interfere with the
        operation of the Services.
      </li>
    </ul>
    <p>5. Your Agreement on Card Use</p>
    <p>
      With each transaction you process through the Cloaked Card Services you
      represent, warrant and agree that:
    </p>
    <ul>
      <li>The Card transaction represents a bona fide sale;</li>
      <li>
        The Card transaction accurately describes the goods and/or services
        obtained from the merchant;
      </li>
      <li>
        You will fulfill all of your obligations to the merchant and will
        resolve any dispute or complaint directly with the merchant; and
      </li>
      <li>
        You and the Card transaction comply with all federal, state, and local
        laws, rules, and regulations applicable to you, including any applicable
        tax, wage and hour, and tip laws and regulations.
      </li>
      <li>
        You will not use the service in a fraudulent, disruptive, aggressive,
        manipulative or any other inappropriate matter.
      </li>
    </ul>
    <p>6. Prohibited Uses</p>
    <p>
      You may not, nor may you permit any third party, directly or indirectly,
      to:
    </p>
    <ul>
      <li>
        Export the Services, which may be subject to export restrictions imposed
        by U.S. law, including U.S. Export Administration Regulations (15 C.F.R.
        Chapter VII).
      </li>
      <li>
        Use and benefit from the Services via a rental, lease, timesharing,
        service bureau or other arrangement.
      </li>
      <li>
        Use the Service in conjunction with automated purchasing software
        programs
      </li>
      <li>
        Use the Service to exploit new users, referral programs, promotions
        offered by other merchants, or otherwise use the Service to violate the
        terms and conditions of a merchant.
      </li>
      <li>
        Act as a payment facilitator or otherwise resell the Cloaked Card
        Services to any third party.
      </li>
      <li>
        Use the Cloaked Card Services to handle, process or transmit funds for
        any third party.
      </li>
    </ul>

    <p>
      In addition, you may not, nor may you permit any third party, directly or
      indirectly, to use the Cloaked Card Services for the following businesses
      or activities:
    </p>

    <ul>
      <li>Any illegal activity or goods,</li>
      <li>Paraphernalia that may be used for illegal activity,</li>
      <li>Unauthorized multi-level marketing businesses,</li>
      <li>Rebate based businesses,</li>
      <li>
        Betting, including lottery tickets, sports related gambling, casino
        gaming chips, off-track betting, and wagers at races,
      </li>
      <li>Manual or automated cash disbursements,</li>
      <li>Prepaid cards or checks</li>
      <li>Sales of money-orders or foreign currency,</li>
      <li>Wire transfers or money orders, or</li>
      <li>High-risk products and services, including telemarketing sales.</li>
    </ul>

    <p>7. Authorized Users</p>
    <p>
      You are responsible for all authorized transactions initiated and fees
      incurred by use of your Cloaked Card(s). If you permit another person to
      have access to a Card or Card number, this will be treated as if you
      authorized such use, and you will be liable for all transactions and fees
      incurred by those persons. You are wholly responsible for the use of each
      Card according to the terms and conditions of this Agreement.
    </p>
    <p>8. Payment Authorizations</p>
    <p>A. Adding a Funding Source</p>
    <p>
      In order to use the Cloaked Card Services, you must first add an eligible
      funding source (the “Funding Account”) to your Account via the Cloaked
      website, mobile app, or other Services. By entering your funding source
      information on the Cloaked site or app, you will have signed the ACH
      authorization, as applicable. You may want to print and save a copy for
      your records. You can change a funding source on the Cloaked website, in
      the Cloaked mobile app, or otherwise via the Services.
    </p>
    <p>
      When you add a funding source to your Cloaked Account, you agree that:
    </p>
    <ul>
      <li>You are the owner of the Funding Account</li>
      <li>You are authorized to transfer funds from that Funding Account</li>
      <li>
        You authorize us to initiate debits from your Funding Account according
        to your
      </li>
      <li>instructions</li>
      <li>
        You will maintain a balance or available credit limit in the specified
        Funding Account that is sufficient to fund all payments you initiate
      </li>
    </ul>
    <p>B. Your Electronic Funds Transfer and ACH Debit Authorization</p>
    <p>
      When you use the Cloaked Card Services you authorize the Issuer to
      immediately debit your Funding Account or the funds loaded onto the card,
      as applicable, by the amount of any transactions made using the Card
      including any applicable fees. Your authorizations will remain in full
      force and effect until you close a Card or your Cloaked Account is closed
      or terminated. Any attempt by you to revoke this authorization will result
      in your inability to use the Cloaked Card Services.
    </p>
    <p>
      If you provide us with the ACH routing and account information for your
      Funding Account, you authorize Cloaked and our partner bank to initiate
      electronic transfers (via the Automated Clearing House ("ACH") of Nacha -
      The Electronic Payment Association ("Nacha")) from your Funding Account up
      to your card spending limits, as applicable, on a daily (or such other
      applicable) basis. You agree that ACH transactions you authorize comply
      with all applicable laws.
    </p>
    <p>
      When your checking account is used as your funding source for a
      transaction, you are requesting that the Issuer or another of our Partner
      Banks initiate an electronic transfer on your behalf from your bank
      account. You agree that we may resubmit any ACH debit you authorized that
      is returned for insufficient or uncollected funds, except as otherwise
      provided by Nacha's rules, or applicable law. Where any amount owed by you
      for use of the Card Services is more than your card spending limits, as
      applicable, you authorize us and our Partner Banks to debit your Funding
      Account for the amount owed.
    </p>
    <p>
      For all other payment methods, such as debit card or credit card funding,
      you authorize Cloaked to transmit payment details to a bank acting under
      Cloaked ’s instructions to charge your Funding Account. You agree to
      maintain funds in the specified Funding Account that are sufficient to
      cover all of your activities with the Cloaked Card Services, and agree
      that the failure to maintain funds will be deemed a breach of this
      Agreement.
    </p>
    <p>
      You indemnify and hold us and our Partner Banks harmless from any claims
      by any other owner of the account. You are responsible for confirming the
      accuracy of the information you provide about each payment.
    </p>
    <p>C. Inability to Access Funds from your Funding Account</p>
    <p>
      If we are unable to access funds from your specified Funding Account to
      complete a transaction that you request for any reason (for example, if
      there are insufficient funds or credit available in your Funding Account
      to cover the requested transaction), the transaction may not be completed.
      In such case, you agree:
    </p>
    <ul>
      <li>
        You will reimburse Cloaked immediately, upon demand, for the transaction
        amount to the extent that Cloaked sent a payment to the payee on your
        behalf;
      </li>
      <li>
        You will reimburse Cloaked for any fees imposed on us as a result of the
        failed transaction; and
      </li>
      <li>
        You will reimburse us for any fees we incur in attempting to collect the
        amount of the failed transaction from you.
      </li>
    </ul>
    <p>
      We are not responsible for any overdraft fees, over-the-limit fees, or
      insufficient fund charges (including finance charges, late fees, or
      similar charges) that result from your failure to maintain a balance or
      available credit in the Funding Account that is sufficient to fund all
      payments you initiate.
    </p>
    <p>D. Bank Account Information and Authorized Actions</p>
    <p>
      If you choose to add a bank account as your Funding Account for your
      Cloaked Pay Services, you will be asked to provide access credentials
      (such as a username and password) that allow you to gain online access to
      your account at that third-party financial institution (your “Authorized
      Bank Account”).
    </p>
    <p>
      Cloaked works with third party services, including but not limited to
      Plaid Technologies, Inc (“Plaid”) who will securely store pursuant to
      industry standards any Authorized Bank Account access credentials that you
      provide and will access your Authorized Bank Accounts for the purposes of
      providing and improving the Cloaked Card Services. You may only provide
      account access credentials for and authorize us to access valid accounts
      that you hold in your own name. You may not provide access credentials for
      an account that is held by a third person.
    </p>
    <p>
      If you choose to provide your Authorized Bank Account credentials to us,
      you authorize us to use this information to provide you with the Cloaked
      Card Services. This authorization will remain in effect until you notify
      us that you wish to revoke it, which may affect your ability to receive
      the services. Plaid’s services and use of your information, including on
      or through the Cloaked Card Services, are governed by its Terms of Use and
      its Privacy Policy located at https://plaid.com/legal.
    </p>
    <p>C. Our Rights Regarding the Services</p>
    <p>1. Modifications to this Agreement</p>
    <p>
      You agree that Cloaked and the Issuer may modify this Agreement, or any
      other Cloaked terms, policies, or guidelines at any time in our sole
      discretion. Any new agreement will be effective immediately, by posting a
      new agreement and updating the “Last Updated” date at the top of these
      terms, except as otherwise required by law. You agree that, even if you
      have not personally visited the website with the new terms of this
      Agreement, the new version of the Agreement will have full force
      immediately. To the extent permitted by applicable law, you agree to waive
      any right you may have to receive additional notice of such changes.
    </p>
    <p>
      Your continued use of the Services following the posting of changes or
      modifications will confirm your acceptance of such changes or
      modifications. You should frequently review these Terms and all applicable
      terms, policies and guidelines to understand the terms and conditions that
      apply to your use.
    </p>
    <p>
      If you do not agree to the amended terms, you must stop using the
      Services. Any use of the Services in violation of these terms may result
      in, among other things, termination or suspension of your right to use the
      Services. You agree that your use of the Services will always be subject
      to the most current version of these terms at the time of such use.
    </p>
    <p>2. Limits on Your Use of the Card Services</p>
    <p>
      You acknowledge that Cloaked or our Partner Banks may impose limits on
      your use of the Card Services. We may not allow payment from all types of
      funding sources, and we may otherwise limit payments and/or payees at our
      discretion. You cannot use the Cards in any way beyond their intended
      purpose.
    </p>
    <p>
      For security and fraud protection, Cloaked may impose limits on the dollar
      amount that you can authorize in a transaction, and over a period of time.
      In addition to these set limits, Cloaked and its Partner Banks retain the
      right to impose additional limits at our discretion. The maximum amount
      initially allowable for authorization is $2,500 per calendar month. The
      per month limit is subject to increase or decrease at our discretion based
      on an algorithmic review process and a manual review process. Except as
      required by applicable law, other limits may apply, as may be described on
      or in Cloaked ’s website, dashboard, and/or Terms of Service.
    </p>
    <p>3. Investigations, Account Suspensions and Termination</p>
    <p>
      Cloaked and our Partner Banks reserve the right to investigate your use of
      the Service for compliance with this Agreement and in order to comply with
      law, regulation, legal process or government request.
    </p>
    <p>
      We may refuse to process any transaction that we believe violates the
      terms of this Agreement, the Cloaked Terms of Service, any other
      agreements between you and Cloaked or any applicable network rule or law.
    </p>
    <p>
      The Cloaked Card is the property of the Issuer and must be surrendered
      upon demand. The Card is nontransferable, and it may be canceled,
      repossessed, or revoked at any time without prior notice.
    </p>
    <p>
      If we suspect you of violating any applicable Terms of Service, network
      rule or law, Cloaked or Issuer may suspend or terminate your ability to
      access the Services under any or all of your accounts. Cloaked reserves
      the right to determine whether or not you have access to these services.
    </p>
    <p>4. Identity Verification</p>
    <p>
      To help the government fight the funding of terrorism and money laundering
      activities, federal law requires us to help the Issuer obtain, verify, and
      record information that identifies each person who requests to use the
      Cloaked Card Services.
    </p>
    <p>
      When you register for the Cloaked Card Services, we will ask you for your
      name, address, date of birth, Social Security number, and other
      information that will allow us to identify you. You authorize us and our
      partners to make inquiries that we consider appropriate and use third
      party services to help us verify your identity and determine if we should
      open, maintain, collect or close your account.
    </p>
    <p>
      We may make additional requests for information at any time. We may
      require you to provide all of a taxpayer identification number, take steps
      to confirm ownership of your email address or financial instruments,
      provide a copy of an identifying ID card such as a driver’s license or
      passport, and verify information you provide against third party databases
      or through other sources.
    </p>
    <p>
      We, and our Partner Banks, may also report the status, history and/or
      closure of your account to third-party services.
    </p>
    <p>
      If you do not provide this information or we cannot verify your identity,
      we can refuse to allow you to use the Services, or suspend or terminate
      your account. If you allow someone else to use your Cloaked Account, you
      are responsible and liable for all transactions, withdrawals, deposits and
      fees that arise out of such use.
    </p>
    <p>D. History, Refunds, Errors, Disputes, and Registration</p>
    <p>1. Payment History</p>
    <p>
      When you use your Cloaked Card, a record of the transaction will
      automatically be recorded in the Cloaked dashboard. You should also
      receive a receipt directly from the merchant.
    </p>
    <p>Except as required by law, you are responsible for:</p>
    <ul>
      <li>
        Compiling and retaining permanent records of all transactions and other
        data associated with your Cloaked Account and your use of the Cloaked
        Card Services, and
      </li>
      <li>
        Reconciling all transactional information that is associated with your
        Cloaked Account.
      </li>
    </ul>
    <p>
      You may obtain information about the amount of money you have remaining in
      your Account by checking your Dashboard. This information, along with a
      12-month history of Account transactions, is also available online in your
      Cloaked Dashboard.
    </p>
    <p>
      If your account is registered with us, you also have the right to obtain
      at least 24 months of written history of Account transactions by emailing
      us at support@cloaked.app. You will not be charged a fee for this
      information unless you request it more than once per month.
    </p>
    <p>
      If you believe that there is an error or unauthorized transaction activity
      associated with your Cloaked Account, you must contact us immediately.
    </p>
    <p>2. Refunds and Returns</p>
    <p>
      If you are entitled to a refund for any reason for goods or services
      obtained with your Card, the return and refund will be handled by the
      merchant. If the merchant credits your Card, the credit may not be
      immediately available. While merchant refunds post as soon as they are
      received, please note that we have no control over when a merchant sends a
      credit transaction, and the refund may not be available for a number of
      days after the date the refund transaction occurs. Refunds will be
      recorded along with your transactions in the Cloaked Dashboard.
    </p>
    <p>
      Neither the Issuer nor Cloaked is responsible for the delivery, quality,
      safety, legality or any other aspects of the goods or services you
      purchase from others with the Card. If you have a problem with a purchase
      you made with the Card, or if you have a dispute with the merchant, you
      must handle it directly with the merchant.
    </p>
    <p>3. Processing Errors</p>
    <p>
      In case of errors or questions about your Account, email us at
      support@cloaked.app as soon as you can, if you think an error has occurred
      in your Account. We must allow you to report an error until 60 days after
      the earlier of the date you electronically access your Account, if the
      error could be viewed in your electronic history, or the date we sent the
      FIRST written history on which the error appeared. You may request a
      written history of your transactions at any time by emailing us at
      support@cloaked.app. You will need to tell us:
    </p>
    <ul>
      <li>Your name and Account number.</li>
      <li>
        Why you believe there is an error, and the dollar amount involved.
      </li>
      <li>Approximately when the error took place.</li>
    </ul>
    <p>
      If you tell us orally, we may require that you send us your complaint or
      question in writing within 10 business days.
    </p>
    <p>
      We will determine whether an error occurred within 10 business days after
      we hear from you and will correct any error promptly. If we need more
      time, however, we may take up to 45 days to investigate your complaint or
      question. If we decide to do this, we will credit your account within 10
      business days for the amount you think is in error, so that you will have
      the money during the time it takes us to complete our investigation. If we
      ask you to put your complaint or question in writing and we do not receive
      it within 10 business days, we may not credit your account.
    </p>
    <p>
      For errors involving new Accounts, point-of-sale*, or foreign-initiated
      transactions, we may take up to 90 days to investigate your complaint or
      question. For new accounts, we may take up to 20 business days to credit
      your account for the amount you think is in error.
    </p>
    <p>
      We will tell you the results within three business days after completing
      our investigation. If we decide that there was no error, we will send you
      a written explanation.
    </p>
    <p>
      You may ask for copies of the documents that we used in our investigation.
    </p>
    <p>
      If you need more information about our error-resolution procedures, email
      us at support@cloaked.app.
    </p>
    <p>4. Your Liability for Unauthorized Transfers</p>
    <p>
      If you believe your Cloaked Card has been lost or stolen or you believe a
      transaction has been made without your permission using information from
      your Card, or Cloaked Card Account or you believe your Cloaked Account
      credentials have been used without your permission, tell us AT ONCE, and
      FREEZE the relevant card(s) via the “Cards” section in your Dashboard, and
      tied to specific userIdentity and card-type, where you will be permitted
      to freeze cards from further spend. Reporting your Card lost or stolen and
      any unauthorized or unexpected activity to support@cloaked.app as soon as
      possible is the best way of keeping your possible losses down.
    </p>
    <p>
      We may suspend or cancel your Card and corresponding Cloaked Account in
      the event of excessive reports of Card loss or theft. You agree that any
      unauthorized use does not include use by a person to whom you have given
      authority to use the Card or PIN and that you will be liable for all such
      uses by such person. For example, if you have given your Card or Card
      information to another person to use, such as a friend or relative, you
      are responsible for that person’s transactions with your Card, and if you
      have given your Card information to a merchant for a transaction, you have
      given authority to that merchant to debit the Card for that transaction.
    </p>
    <p>
      If you tell us within two business days after you learn of the loss or
      theft of your Cloaked Card, you can lose no more than $50 if someone used
      your Cloaked Card without your permission. If you do NOT tell us within
      two business days after you learn of the loss or theft of your Cloaked
      Card, and we can prove that we could have stopped someone from using your
      Cloaked Card, without your permission if you had told us, you could lose
      as much as $500.
    </p>
    <p>
      Also, if you become aware of and/or your periodic statement (if
      applicable) or transaction history (if applicable) shows transactions you
      did not make, notify Cloaked at once following the procedures stated in
      the Section of this Agreement titled “Disputes.” If you do not notify
      Cloaked within sixty (60) days after you become aware of the transaction
      and/or after the transaction information was made available to you, you
      may not get back any value you lost after the sixty (60) days if Cloaked
      can prove that we could have stopped someone from taking the value if you
      had notified Cloaked in time. If a good reason (such as a long trip or
      hospital stay) kept you from telling us, Cloaked may extend the time
      periods. If the Card has been lost or stolen, the Card will be blocked to
      keep losses down and/or, if there is a physical card associated with the
      account, a replacement may be sent to you.
    </p>
    <p>
      You agree to cooperate reasonably with us and the Issuer in our attempts
      to recover funds from, and to assist in the prosecution of, any
      unauthorized users of your Card.<br />
      5. Additional Limits on Liability Under Mastercard Rules.<br />
      Under Mastercard Rules, you will not be liable for any unauthorized
      transactions using your Cloaked Card if you can demonstrate that you have
      exercised reasonable care in safeguarding your Card from risk of loss or
      theft. You must notify us within two (2) days after the electronic
      statement was made available to you showing unauthorized transaction(s) in
      order to take advantage of any such limited liability provisions.<br />
      6. Our Liability for Failing to Make a Transfer<br />
      If we do not complete a transfer to or from your account on time or in the
      correct amount according to our agreement with you, we will be liable for
      your losses or damages. However, there are some exceptions. We will not be
      liable, for instance:<br />
      (1) If, through no fault of ours, you do not have enough money in your
      account to make the transfer;<br />
      (2) If the system was not working properly and you knew about the
      breakdown when you started the transfer;<br />
      (3) If circumstances beyond our control (such as fire or flood, terrorist
      attack, or national emergency) prevent the transfer, despite reasonable
      precautions that we have taken;<br />
      (4) if you have reported the Card as lost, stolen, or compromised or the
      Card has been suspended by us, if we have limited or revoked your Card
      privileges, or if we have reason to believe the transaction is
      unauthorized;<br />
      (5) if you attempt to use a Card that has not been activated;<br />
      (6) if the merchant refuses the Card; or<br />
      (7) as otherwise provided in this Agreement. <br />
      7. Right to Stop Payment<br />
      (1) Right to stop payment and procedure for doing so. If you have told us
      in advance to make regular payments with your Card, you can stop any of
      these payments. Here's how:<br />
      Email us at support@cloaked.app in time for us to receive your request 3
      business days or more before the payment is scheduled to be made. If we do
      not receive your request at least three business days before the scheduled
      payment date, we may attempt, in our sole discretion, to stop the payment.
      However, we assume no responsibility for our failure or refusal to do so,
      even if we accept your stop-payment request.<br />
      If you call, we may also require you to put your request in writing and
      get it to us within 14 days after you call. If we do not receive your
      written request within 14 days after you call, we may honor subsequent
      payments to your Card.<br />
      (2) Notice of varying amounts. If these regular payments may vary in
      amount, the person you are going to pay should tell you, 10 days before
      each payment, when it will be made and how much it will be. Note that the
      person you are paying may give you the option to recieve notice only when
      the payment would differ by more than a certain amount from the previous
      payment, or when the amount would fall outside certain limits that you
      set.<br />
      (3) Liability for failure to stop payment of preauthorized transfer. If
      you order us to stop one of these payments 3 business days or more before
      the transfer is scheduled, and we do not do so, we will be liable for your
      losses or damages.
    </p>

    <p>
      E. Other Terms<br />
      1. Communications<br />
      We (or our program partners) may record and/or monitor any telephone
      conversations with you. If we do record, we (or our program partners, as
      applicable) do not have to keep the recordings, unless applicable law says
      that we must. When you give us your mobile number, we (or our program
      partners) have your permission to contact you at that number about your
      account. Your consent allows us and our program partners or service
      providers to use text messaging, artificial or prerecorded voice messages,
      and automatic dialing technology for informational and service calls, but
      not for telemarketing or sales calls. This communication may include
      contact from companies working on our behalf to service your account.
      Message and data rates may apply. You may change these texting and
      messaging preferences by emailing support@cloaked.app. We may contact you
      at any email address you provide.
    </p>

    <p>
      2. Address or Name Changes<br />
      You are responsible for notifying us of any change in your name, physical
      address, mailing address, email address, or phone number. Requests for
      address or name changes may be subject to additional verification
      requirements.<br />
      We will attempt to communicate with you only by use of the most recent
      contact information you have provided to us. You agree that any notice or
      communication sent to you at an address noted in our records shall be
      effective unless we have received an address change notice from you.<br />
      We cannot accept responsibility for any email messages not received by you
      or for any delay in the receipt or delivery of any email notification. If
      you make your email account available to any other individual, you agree
      that you are responsible for any release of any Account information to
      such individual.<br />
      3. Confidentiality<br />
      You acknowledge and agree that the Issuer and Cloaked may, subject to
      applicable law, share information regarding you, your Card, your Cloaked
      Account, your Card request and Card transaction activity as necessary to
      provide customer service, service the Card or your Cloaked Account,
      investigate and act on the Card or your Cloaked Account and/or your Card-
      or Cloaked Account-related claims and as necessary to comply with
      applicable law. Information about the Card, your Cloaked Account, or the
      transactions you make with any of them may be disclosed to third
      parties:<br />
      Where it is necessary for completing transactions;<br />
      In order to verify the existence and condition of the Card for a third
      party, such as a<br />
      merchant;<br />
      In order to comply with government agency, court order, or other legal,
      regulatory or<br />
      administrative reporting requirements;<br />
      In order to prevent, investigate or report possible illegal activity;<br />
      In order to issue authorizations for transactions on the Card;<br />
      If you consent by giving us your written permission;<br />
      To service providers who help us administer and provide the Card and
      related Cloaked Services;<br />
      To our employees, auditors, affiliates, service providers, or attorneys as
      needed;<br />
      As permitted by applicable law; <br />
      As necessary to fulfill our obligations under this Agreement; or<br />
      As set forth in the Cloaked Privacy Policy and Issuer Privacy Policy.
    </p>

    <p>
      5. No Warranty Regarding Goods or Services as Applicable<br />
      <br />
      Cloaked and the Issuer are not responsible for the quality, safety,
      legality, or any other aspect of any goods or services you purchase with a
      Cloaked Card.
    </p>

    <p>6. No Warranty of Availability or Uninterrupted Use</p>

    <p>
      From time to time, the Card services may be inoperative, and when this
      happens, you may be unable to use your Card or obtain information from
      your Card, including your Cloaked Account. Please notify us immediately at
      support@cloaked.app if you have any problems using your Card. You agree
      that the Issuer, Cloaked , and their respective affiliates, employees, or
      agents are not responsible for any interruption of service.<br />
      7. Miscellaneous<br />
      Assignment: To the extent permitted by applicable law, we may assign this
      Agreement without obtaining your consent. You may not assign or transfer
      your Card, or this Agreement without our prior written consent.<br />
      Severability: This Agreement will be enforced to the fullest extent
      permitted under<br />
      applicable law. If any provision of the Agreement is found to be invalid
      or unenforceable by a court of competent jurisdiction, the provision will
      be modified by the court and interpreted so as best to accomplish the
      objectives of the original provision to the fullest extent permitted by
      law, and the remaining provisions of the Agreement will remain in effect.
      Neither the Issuer nor Cloaked waives its rights by delaying or failing to
      exercise them at any time. This Agreement will be governed by the law of
      the State of Connecticut except to the extent governed by federal law.<br />
      Language: This Agreement was drafted in English. In the event that this
      Agreement, or any part thereof, is translated to a language other than
      English, the English-language version shall control in the event of a
      conflict.<br />
      Section Headings: Section headings in this Agreement are for convenience
      of reference only, and shall not govern the interpretation of any
      provision of this Agreement.<br />
      Cancellation: The Issuer or Cloaked may cancel or suspend the Card or this
      Agreement at any time. You may cancel this Agreement by returning the Card
      to the Issuer, or contacting support@cloaked.app. Your termination of this
      Agreement will not affect any of the Issuer’s or Cloaked ’s rights or your
      obligations arising under this Agreement prior to termination. In the
      event your Card is canceled, closed or terminated for any reason by us,
      remaining available funds and/or credits associated with the Card would
      revert to your Funding Account. <br />
      Entire Agreement: This Agreement comprises the entire agreement between
      you and Cloaked with respect to the above subject matter and supersedes
      and merges all prior proposals, understandings and contemporaneous
      communications.<br />
      8. Indemnification<br />
      You agree to defend, indemnify, and hold harmless Cloaked, Issuer, our
      program partners, the parties with whom we contract to offer the Cards,
      the Cloaked Account, and related services, and any parents, subsidiaries,
      and other affiliated companies of any of the foregoing (collectively, the
      “Indemnified Parties”), and all Indemnified Parties’ employees,
      contractors, officers, and directors against any and all claims, suits,
      damages, costs, lawsuits, fines, penalties, liabilities, and expenses
      (including, without limitation, reasonable attorneys’ fees) that arise
      from your violation of this Agreement, applicable law, or any third-party
      rights or your fraud or willful misconduct. We reserve the right to assume
      the exclusive defense and control of any matter subject to indemnification
      by you, in which event you will cooperate in asserting any available
      defenses.<br />
      9. Limitation of Liability<br />
      EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THIS AGREEMENT OR AS OTHERWISE
      REQUIRED BY APPLICABLE LAW, NONE OF Cloaked, ISSUER, OUR PROGRAM PARTNERS,
      THE PARTIES WITH WHOM WE CONTRACT TO OFFER THE CARDS, YOUR Cloaked
      ACCOUNT, AND RELATED SERVICES, AND ANY PARENTS, SUBSIDIARIES, AND OTHER
      AFFILIATES OF ANY OF THE FOREGOING (THE “LIMITED PARTIES”), AND ALL
      LIMITED PARTIES’ EMPLOYEES, CONTRACTORS, OFFICERS, AND DIRECTORS ARE
      NEITHER RESPONSIBLE NOR LIABLE FOR ANY INDIRECT, INCIDENTAL,
      CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES ARISING OUT OF OR
      RELATING IN ANY WAY TO THE CARDS, THE Cloaked CARD SERVICES, ANY PRODUCTS
      OR SERVICES PURCHASED USING A Cloaked CARD, OR THIS AGREEMENT (AS WELL AS
      ANY RELATED OR PRIOR AGREEMENT THAT YOU MAY HAVE HAD WITH US).<br />
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL LIABILITY OF
      THE LIMITED PARTIES IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES
      EARNED BY Cloaked IN CONNECTION WITH YOUR USE OF THE SERVICES DURING THE
      ONE (1) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE
      CLAIM FOR LIABILITY OR (B) $10.<br />
      10. Survival<br />
      The provisions related to indemnification, limitation of liability,
      arbitration and any other provisions necessary to give effect to a party’s
      rights shall survive the termination of the Agreement, the bankruptcy of
      any party, any transfer, sale or assignment of the Card, or expiration of
      the Card. <br />
      11. Arbitration Agreement<br />
      How to Reject Arbitration. You can reject this Arbitration Agreement by
      providing us a written rejection to support@cloaked.app. You must send us
      this written rejection within forty-five (45) days of opening your
      Account. The written rejection must clearly state that you are rejecting
      the Arbitration Agreement and it should include your name, physical
      address and email address associated with your Account, and Account
      number. This is the sole method for rejecting the Arbitration Agreement.
      If your written rejection meets these requirements, the Arbitration
      Agreement will not apply to you except for any Claims pending when you
      send your written rejection. <br />
      Arbitration of Claims. Arbitration is often used to resolve Claims more
      efficiently than a trial, particularly where the amount of the Claims is
      small. This section constitutes your agreement to arbitrate (“Arbitration
      Agreement”) all claims, disputes, or controversies, including initial
      claims, counter-claims, cross-claims, and third-party claims, arising
      under or in connection with (i) this Agreement (including the validity,
      enforceability, interpretation, scope, or application of this Agreement or
      the Arbitration Agreement along with any renewals, extensions, addendums,
      or modifications), your Account, or your relationship with us; (ii) the
      servicing of your Account by our Servicer or other third-party service
      providers; (iii) the marketing or other statements related to your Account
      or this Agreement; (iv) your application for, or the approval or
      establishment of your Account; and (v) any other agreement related to your
      Account or related service (collectively, a “Claims”). Except as stated
      below, all Claims are subject to arbitration, no matter what legal theory
      they are based on (whether based upon contract; tort, intentional or
      otherwise; constitution; statute; common law; or equity) or what remedies
      they seek (damages or injunctive or declaratory relief). Arbitration
      applies to any and all such Claims, whether pre-existing, currently
      existing, or those that arise in the future. This includes Claims made by
      us against you, by you or anyone connected to you against us, our
      Servicer, parties with whom we contract to offer the Card services, or any
      of our subsidiaries, affiliates, agents, employees, predecessors,
      successors, or assignees (collectively, for purposes of this Arbitration
      Agreement only, “we,” “us,” or “our”). <br />
      Trial Waiver and Class Action and Class Arbitration Waiver. YOU ARE
      WAIVING THE RIGHT TO LITIGATE A CLAIM IN COURT AND TO HAVE A JURY TRIAL ON
      ANY CLAIM. ANY ARBITRATION UNDER THIS AGREEMENT WILL ONLY BE ON AN
      INDIVIDUAL BASIS. CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY
      GENERAL ACTIONS, AND CONSOLIDATIONS WITH OTHER ARBITRATIONS ARE NOT
      PERMITTED. AN ARBITRATOR CAN AWARD RELIEF ONLY ON AN INDIVIDUAL BASIS. YOU
      WILL NOT HAVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR AS A PRIVATE
      ATTORNEY GENERAL RELATING TO ANY CLAIM AGAINST US OR THE PARTIES WITH WHOM
      WE CONTRACT TO OFFER THE SERVICES RELATED TO THIS AGREEMENT.<br />
      Procedure. All Claims will be decided finally and exclusively by binding
      individual arbitration with a single arbitrator administered by the
      American Arbitration Association (“AAA”) according to this provision and
      the AAA rules and procedures, including Consumer Arbitration Rules
      (collectively, the “AAA Rules”), which may limit the discovery available
      to you or to us. You can obtain copies of the AAA Rules and forms by
      contacting AAA at 1-800-778-7879 or www.adr.org. The party who wants to
      arbitrate must notify the other party in writing. We will send notice to
      your last address on file. You must send notice to 1075 Westford St #305,
      Lowell, MA 01851. A single arbitrator will resolve the Claims. The
      arbitrator must be a member of the state bar where the arbitration is held
      and with expertise in the substantive laws applicable to the Claim. Unless
      the parties to the arbitration agree otherwise, any arbitration hearings
      will take place in a reasonably convenient location for both parties with
      due consideration of their ability to travel and other pertinent
      circumstances, or via videoconference if permitted by law. If your Claim
      is for $10,000 or less, you may choose whether the arbitration will be
      conducted solely based on documents submitted to the arbitrator, through a
      telephonic hearing, or by an in-person hearing as established by the AAA
      Rules. If your Claim exceeds $10,000, the right to a hearing will be
      determined by the AAA Rules. No matter how the arbitration is conducted,
      the arbitrator will issue a reasoned written decision sufficient to
      explain the essential findings and conclusions on which the award is
      based. The arbitrator will apply applicable substantive law consistent
      with the Federal Arbitration Act, 9 U.S.C. §§ 1-16 (“FAA”) and applicable
      statutes of limitations and will honor claims of privilege recognized at
      law. All aspects of the arbitration proceeding, and any ruling, decision,
      or award by the arbitrator, will be strictly confidential for the benefit
      of all parties. <br />
      Arbitration Costs. We will pay the initial filing fee to commence
      arbitration. All other arbitration fees will be allocated in accordance
      with the applicable AAA Rules. If we prevail, we will not recover our
      arbitration fees unless the arbitrator decides your Claim was frivolous.
      Each party is responsible for its own attorney’s fees, expert fees, and
      any other expenses, unless the arbitrator awards such fees or expenses to
      the other party based on applicable law. <br />
      Arbitration Award and Appeal. The arbitrator’s award will be binding on
      the parties and may be entered as a judgment in any court of competent
      jurisdiction, unless a party exercises any right of appeal provided by the
      FAA. While an arbitrator may award declaratory or injunctive relief, the
      arbitrator may do so only for the individual party seeking relief and only
      as needed to provide relief warranted by the individual party’s Claims.
      The arbitrator’s decision and judgment criteria will not have a
      precedential or collateral estoppel effect on Claims asserted by any
      individual or entity who was not party to the arbitration. <br />
      Severability. If any provision of this Arbitration Agreement is found
      unenforceable, the unenforceable provision will be severed, and the
      remaining arbitration terms will be enforced (but in no case will there be
      a class or representative arbitration). If a court or arbitrator decides
      that this Arbitration Agreement cannot be enforced as to a particular
      Claim for relief, then that Claim (and only that Claim) must be severed
      from the arbitration and may be brought in court.<br />
      Exceptions. Individual Claims filed in small claims court are not subject
      to this Arbitration Agreement if the Claim remains in small claims court.
      Further, while the arbitrator alone will have the authority to interpret
      the scope and enforceability of this Arbitration Agreement, any Claim
      concerning the scope or enforceability of the prohibition on class,
      collective, or representative Claims, or Claims in arbitration for public
      injunctive relief, will be resolved by the state and federal courts in
      Connecticut in accordance with Connecticut law. <br />
      Survival. The Arbitration Agreement will survive repayment of all amounts
      owed to us; the closing, termination, canceling, or suspension of your
      Account; the sale of your Account or amounts owed on your Account to
      another person or entity; and any bankruptcy by you to the extent allowed
      by applicable bankruptcy law. <br />
      12. Limitation of Liability<br />
      You agree that we, our service providers (including the Bank and Cloaked
      Card, LLC, or Creditors), agents, officers, directors, and employees (and
      the same of our service providers, subsidiaries and affiliates themselves)
      (collectively, the “Indemnified Parties”) will not be liable for anything
      that we do when following your instructions. In addition, the Indemnified
      Parties will not be liable if any such Indemnified Party does not follow
      your instructions if we reasonably believe that your instructions are not
      permitted under applicable law or would expose us to potential loss or
      civil or criminal liability, or conflict with customary banking practices.
      To the extent you are entitled to relief under this Agreement, the total
      liability of the Indemnified Parties to you will not exceed $500, except
      pursuant to Section 5(i) and Section 10 or as otherwise required by
      applicable law. THE INDEMNIFIED PARTIES WILL NOT BE LIABLE FOR INDIRECT,
      SPECIAL, OR CONSEQUENTIAL DAMAGES REGARDLESS OF THE FORM OF ACTION AND
      EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF WE
      FAIL TO STOP PAYMENT ON AN ITEM, OR PAY AN ITEM BEARING AN UNAUTHORIZED
      SIGNATURE, FORGED SIGNATURE, OR FORGED ENDORSEMENT OR ALTERATION, OUR
      LIABILITY, IF ANY, WILL BE LIMITED TO THE FACE AMOUNT OF THE ITEM. This
      section will survive termination of your Account and termination of this
      Agreement.<br />
      13. No Warranties<br />
      THE USE OF “CLOAKED” IN THIS SECTION AND THE LIMITATION OF LIABILITY
      SECTION MEANS CLOAKED, ITS PROCESSORS, ITS SUPPLIERS, BANK, CREDITORS, AND
      OTHER PARTNERS, AND ITS LICENSORS (AND THEIR RESPECTIVE SUBSIDIARIES,
      AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES).<br />
      THE SERVICES ARE PROVIDED “AS IS” WITHOUT REPRESENTATION OR WARRANTY,
      WHETHER IT IS EXPRESS, IMPLIED, OR STATUTORY. WITHOUT LIMITING THE
      FOREGOING, CLOAKED SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.<br />
      CLOAKED DOES NOT WARRANT OR GUARANTEE THAT THE SERVICES ARE ACCURATE,
      RELIABLE OR CORRECT; THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; THAT
      THE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
      UNINTERRUPTED, ERROR-FREE, WITHOUT DEFECT OR SECURE; THAT ANY DEFECTS OR
      ERRORS WILL BE CORRECTED; THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER
      HARMFUL COMPONENTS; OR THAT THE SERVICES WILL BE COMPATIBLE WITH YOUR
      DEVICE OR CARRIER.<br />
      Cloaked does not warrant, endorse, guarantee, or assume responsibility for
      any product or services advertised or offered by a third party. Cloaked
      does not have control of, or liability for, goods or services that are
      paid for using the Services.<br />
      Any third-party software, hardware and other products or services
      included, sold or purchased through or in connection with the Services are
      provided solely according to the warranty and other terms specified by the
      manufacturer, merchant or provider, who is solely responsible for service
      and support for its product. For service, support, or warranty assistance,
      you should contact the third-party product provider directly. CLOAKED
      MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT
      TO SUCH THIRD-PARTY PRODUCTS, AND EXPRESSLY DISCLAIMS ANY WARRANTY OR
      CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
      PARTICULAR PURPOSE.<br />
      Cloaked shall not be liable for any delay or failure to perform resulting
      from causes outside its reasonable control, including but not limited to,
      acts of God, war, terrorism, riots, embargos, acts of civil or military
      authorities, fire, floods, accidents, strikes or shortages of
      transportation facilities, fuel, energy, labor or materials.<br />
      14. Indemnification<br />
      You agree to indemnify and hold the Indemnified Parties harmless from and
      against losses arising inconnection with the services provided under this
      Agreement, except for losses arising out of our own gross negligence or
      willful misconduct. You further agree to hold the Indemnified Parties
      harmless from losses arising out of actions taken or omitted in good faith
      by any Indemnified Party in reliance upon instructions from you. The
      Indemnified Parties are not responsible for any actions or omissions by
      any third party. If you give us instructions that we believe may expose us
      to potential liability or are in violation of applicable law, in our sole
      discretion, we may refuse to follow your instructions and we will not be
      liable to you if we refuse to follow your instructions. If we do choose to
      follow your instructions, we may ask you for certain protections such as a
      surety bond or an indemnity agreement in a form that is satisfactory to
      us. This section will survive termination of your Account and termination
      of this Agreement.<br />
    </p>
  </div>
</template>

<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $color-primary-100;

  thead {
    th {
      text-align: left;
      padding: 10px;
      border-bottom: 1px solid $color-primary-100;
      font-weight: 600;
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid $color-primary-100;
        border-left: 1px solid $color-primary-100;

        &:first-of-type {
          border-left: none;
        }

        ul {
          margin-top: 15px !important;
          margin-left: 15px;

          li {
            margin-top: 5px;
            position: relative;

            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: -15px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $color-primary-100;
              transform: translateY(-50%);
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

* {
  margin-top: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  &:first-child {
    margin-top: 0;
  }
}
</style>
