export const ChineseProvincesList = [
  { label: "Anhui (安徽省)", value: "CN-AH" },
  { label: "Beijing (北京市)", value: "CN-BJ" },
  { label: "Chongqing (重庆市)", value: "CN-CQ" },
  { label: "Fujian (福建省)", value: "CN-FJ" },
  { label: "Gansu (甘肃省)", value: "CN-GS" },
  { label: "Guangdong (广东省)", value: "CN-GD" },
  {
    label: "Guangxi Zhuang Autonomous Region (广西壮族自治区)",
    value: "CN-GX",
  },
  { label: "Guizhou (贵州省)", value: "CN-GZ" },
  { label: "Hainan (海南省)", value: "CN-HI" },
  { label: "Hebei (河北省)", value: "CN-HE" },
  { label: "Heilongjiang (黑龙江省)", value: "CN-HL" },
  { label: "Henan (河南省)", value: "CN-HA" },
  { label: "Hubei (湖北省)", value: "CN-HB" },
  { label: "Hunan (湖南省)", value: "CN-HN" },
  { label: "Jiangsu (江苏省)", value: "CN-JS" },
  { label: "Jiangxi (江西省)", value: "CN-JX" },
  { label: "Jilin (吉林省)", value: "CN-JL" },
  { label: "Liaoning (辽宁省)", value: "CN-LN" },
  { label: "Inner Mongolia Autonomous Region (内蒙古自治区)", value: "CN-NM" },
  { label: "Ningxia Hui Autonomous Region (宁夏回族自治区)", value: "CN-NX" },
  { label: "Qinghai (青海省)", value: "CN-QH" },
  { label: "Shaanxi (陕西省)", value: "CN-SN" },
  { label: "Shandong (山东省)", value: "CN-SD" },
  { label: "Shanghai (上海市)", value: "CN-SH" },
  { label: "Shanxi (山西省)", value: "CN-SX" },
  { label: "Sichuan (四川省)", value: "CN-SC" },
  { label: "Tianjin (天津市)", value: "CN-TJ" },
  { label: "Tibet Autonomous Region (西藏自治区)", value: "CN-XZ" },
  {
    label: "Xinjiang Uyghur Autonomous Region (新疆维吾尔自治区)",
    value: "CN-XJ",
  },
  { label: "Yunnan (云南省)", value: "CN-YN" },
  { label: "Zhejiang (浙江省)", value: "CN-ZJ" },
];
