import { computed, ref } from "vue";

export const useBillingCycle = (defaultBillingCycle = "annually") => {
  const selectedBillingCycle = ref(defaultBillingCycle);

  const allBillingCycles = computed(() => [
    { id: "annually", label: "Yearly" },
    { id: "monthly", label: "Monthly" },
  ]);

  return {
    allBillingCycles,
    selectedBillingCycle,
  };
};
