<script setup>
import PreferencesTitle from "./PreferencesTitle";
import PreferencesPanel from "./PreferencesPanel";
import UpgradeButton from "@/components/Navigation/UpgradeButton.vue";
import { CheckActive, LinkOutIcon, CheckCancelled } from "@/assets/icons";
import { SubscriptionService } from "@/api";
import {
  STRIPE_MANAGE_URL,
  SUBSCRIPTION_STORES_FORMATTED,
  PAID_CA_FEATURES,
  PAID_US_FEATURES,
  GENERIC_ALL_FEATURES,
  CA_UPGRADE_FEATURES,
  US_UPGRADE_FEATURES,
  EXPIRED_RESTRICTEDPLAN_FEATURES,
  RESTRICTEDPLAN_FEATURES,
} from "@/scripts/constants";

import store from "@/store";
import { getUserCountry } from "@/scripts/countries/countries";

import { computed } from "vue";

const restrictedPlanFeatures = [
  "Previously created identities",
  "Sending and receiving calls, texts, and emails",
];

const linkText = computed(() => {
  return isRestrictedPlan.value
    ? "Upgrade subscription"
    : "Manage subscription";
});
const header = computed(() => {
  if (isRestrictedPlan.value) {
    return "You are now on a limited plan";
  }
  return "Your plan includes";
});

const isRestrictedPlan = computed(() => store.getters["settings/isCancelled"]);
const expiresIn = computed(() => store.getters["settings/expiresIn"]);
const isSubscribed = computed(() => store.getters["settings/isSubscribed"]);
const isTrial = computed(() => store.getters["settings/isTrial"]);
const isLegacy = computed(() => store.getters["settings/isLegacy"]);
const isStoreStripe = computed(() => store.getters["settings/isStoreStripe"]);
const subStore = computed(() => store.getters["settings/getStore"]);
const isStoreManual = computed(() => store.getters["settings/isStoreManual"]);

const userCountry = computed(() =>
  getUserCountry(store.state.authentication?.user)
);

const userExpiredFeatures = computed(() => {
  if (isLegacy.value || isTrial.value) {
    if (userCountry.value === "US") {
      return US_UPGRADE_FEATURES;
    } else if (userCountry.value === "CA") {
      return CA_UPGRADE_FEATURES;
    } else {
      if (isTrial.value) {
        {
          return ["Unlimited phone numbers", ...GENERIC_ALL_FEATURES];
        }
      }
      //If you are non-US/CA you go here
      return [];
    }
  } else if (isRestrictedPlan.value) {
    if (userCountry.value === "US") {
      return [...EXPIRED_RESTRICTEDPLAN_FEATURES, ...US_UPGRADE_FEATURES];
    }
    if (userCountry.value === "CA") {
      return [...EXPIRED_RESTRICTEDPLAN_FEATURES, ...CA_UPGRADE_FEATURES];
    }
  }
  //Anyone not legacy, paid, trail or is restrict outside US
  return EXPIRED_RESTRICTEDPLAN_FEATURES;
});

const showPhoneFootnote = computed(() => {
  const allFeats = [...usersFeatures.value, ...userExpiredFeatures.value];
  const hasFeat = allFeats.filter((feat) =>
    feat.toLowerCase().includes("phone numbers")
  );
  return !!hasFeat.length;
});

const usersFeatures = computed(() => {
  if (isLegacy.value) {
    return ["Unlimited phone numbers", ...GENERIC_ALL_FEATURES];
  } else if (isSubscribed.value || isStoreManual.value) {
    if (userCountry.value === "US") {
      return ["Unlimited phone numbers", ...PAID_US_FEATURES];
    } else if (userCountry.value === "CA") {
      return ["Unlimited phone numbers", ...PAID_CA_FEATURES];
    } else {
      return ["Unlimited phone numbers", ...GENERIC_ALL_FEATURES];
    }
  } else if (isTrial.value) {
    return ["Limited phone numbers", ...GENERIC_ALL_FEATURES];
  } else if (isRestrictedPlan.value) {
    return RESTRICTEDPLAN_FEATURES;
  }
  return [
    "Currently we are unable to get this information. Please contact customer support at: support@cloaked.app",
  ];
});

const href = computed(() => {
  let stripeUrl = global?.ENV?.VUE_APP_STRIPE_MANAGE_URL;

  if (stripeEmail.value) {
    stripeUrl += `?prefilled_email=${stripeEmail.value}`;
  }
  return stripeUrl;
});
const stripeEmail = computed(() => {
  const stripeUserData = store.getters["settings/getStripe"];
  return stripeUserData?.email || null;
});

const manageSubscription = computed(() => {
  if (isStoreStripe.value)
    return `Your subscription payment is managed via ${storeName.value}. To edit your subscription, click the link and log in using the credentials below. `;
  else if (storeName.value)
    return `Your subscription payment is managed via ${storeName.value}. To edit your subscription, you must go to ${storeName.value}.`;
  else
    return `Currently we are unable to get this information. Please contact customer support at: support@cloaked.app`;
});

const storeName = computed(() => {
  return SUBSCRIPTION_STORES_FORMATTED?.[subStore.value] || null;
});
</script>

<template>
  <PreferencesPanel class="forwarding-pref">
    <PreferencesTitle v-if="isTrial">Free trial</PreferencesTitle>
    <div v-if="isTrial">
      <span class="sub-feat-list__stripe-text"
        >You free trial will end <b>{{ expiresIn }}</b
        >.</span
      >
      <br />
      <br />
      <UpgradeButton :hasIcon="false" customText="Upgrade Now" />
    </div>

    <PreferencesTitle v-if="isRestrictedPlan"
      >Membership Expired</PreferencesTitle
    >
    <div v-if="isRestrictedPlan">
      <span class="sub-feat-list__description-text">
        To regain access to all cloaked features, upgrade today.
      </span>
      <br />
      <br />
      <UpgradeButton :hasIcon="false" customText="Upgrade Now" />
    </div>

    <PreferencesTitle v-if="isLegacy && userExpiredFeatures.length > 0"
      >Upgrade Membership</PreferencesTitle
    >
    <div v-if="isLegacy && userExpiredFeatures.length > 0">
      <span class="sub-feat-list__description-text">
        Upgrade today to unlock the rest of Cloaked
      </span>
      <br />
      <br />
      <UpgradeButton :hasIcon="false" customText="Upgrade Now" />
    </div>

    <PreferencesTitle>{{ header }}</PreferencesTitle>

    <div class="sub-feat-list">
      <h2 v-if="isRestrictedPlan">You will continue to have access to</h2>
      <div
        class="sub-feat-row"
        v-for="(feats, idx) in usersFeatures"
        :key="`sub-feats-${idx}`"
      >
        <CheckActive />
        <span>{{ feats }}</span>
        <sup v-if="feats.toLowerCase().includes('phone numbers')">1</sup>
      </div>
    </div>

    <div class="sub-feat-list" v-if="isRestrictedPlan">
      <h2>You are no longer getting</h2>
      <div
        class="sub-feat-row"
        v-for="(feats, idx) in userExpiredFeatures"
        :key="`sub-feats-${idx}`"
      >
        <CheckCancelled class="cancelled" />
        <span>{{ feats }}</span>
        <sup v-if="feats.toLowerCase().includes('phone numbers')">1</sup>
      </div>
    </div>

    <div
      class="sub-feat-list"
      v-if="(isTrial || isLegacy) && userExpiredFeatures.length > 0"
    >
      <h2>Upgrade to get access to</h2>
      <div
        class="sub-feat-row"
        v-for="(feats, idx) in userExpiredFeatures"
        :key="`sub-feats-${idx}`"
      >
        <CheckCancelled class="cancelled" />
        <span>{{ feats }}</span>
        <sup v-if="feats.toLowerCase().includes('phone numbers')">1</sup>
      </div>
    </div>

    <div class="footnotes-wrapper">
      <div>
        If there are any issues with your subscription please reach out to
        support@cloaked.app
      </div>
      <div v-if="showPhoneFootnote" class="footnotes-row">
        <div>1.</div>
        <div>
          New phone number availability is subject to our bad actor mitigation
          system. Our phone numbers use a combination of daily creation
          behavior, quantity of messages/calls created, and various rules in
          order to prevent abuse on cloaked phone numbers.
        </div>
      </div>
    </div>

    <PreferencesTitle v-if="isSubscribed || isLegacy"
      >Manage Subscription</PreferencesTitle
    >
    <!-- Only Show Below if Paid or Legacy -->
    <div v-if="isSubscribed || isLegacy || isStoreManual">
      <a class="sub-feat-list">
        <!-- The user State is Unknown -->
        <div v-if="isLegacy || isStoreManual" class="sub-feat-list">
          <span class="sub-feat-list__description-text"
            >You do not have a paid subscription.</span
          >
        </div>
        <!-- The user store is stripe -->
        <div v-else-if="isStoreStripe" class="sub-feat-list">
          <span class="sub-feat-list__description-text">
            {{ manageSubscription }}
          </span>
          <br /><br />
          <span class="sub-feat-list__stripe-text">Your email for Stripe </span>
          <br />
          <span class="sub-feat-list__stripe-email">{{ stripeEmail }}</span>
        </div>
        <!-- The user store anything other than stripe -->
        <div v-else class="sub-feat-list">
          <span class="sub-feat-list__description-text">{{
            manageSubscription
          }}</span>
        </div>
      </a>
    </div>
    <a
      v-if="isStoreStripe && isSubscribed"
      class="manage-link"
      :href="href"
      target="_blank"
    >
      <span>{{ linkText }}</span>
      <LinkOutIcon />
    </a>
    <a
      v-if="isStoreStripe && isSubscribed"
      class="manage-link"
      href="https://help.cloaked.app/hc/en-us/articles/21235995580692-Managing-Your-Cloaked-Subscription"
      target="_blank"
    >
      <span>&emsp;FAQ</span>
      <LinkOutIcon />
    </a>
  </PreferencesPanel>
</template>

<style lang="scss">
.sub-feat-list {
  h2 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: $color-primary-100;
    margin-top: 11px;
    margin-bottom: 7px;
  }
  &:not(:first-child) {
    h2 {
      margin-top: 24px;
    }
  }
  .sub-feat-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color-primary-70;
    margin-top: 8px;
    svg {
      color: $color-success;
      height: 16px;
      width: 16px;
      &.cancelled {
        color: $color-alert;
      }
    }
    span {
      margin-left: 6px;
    }
    sup {
      vertical-align: top;
      font-size: 0.6em;
      margin-top: -5px;
      margin-left: 2px;
    }
  }
  &__description-text {
    font-size: 16px;
    color: $color-primary-70;
    font-weight: 400;
  }
  &__stripe-email {
    font-size: 18px;
    color: $color-primary-100;
    font-weight: 500;
  }
  &__stripe-text {
    font-size: 16px;
    color: $color-primary-100;
    font-weight: 400;
  }
}
.manage-link {
  cursor: pointer;
  color: $color-primary-100;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-top: 34px;
  display: inline-block;
  svg {
    margin-left: 10px;
    margin-bottom: -2px;
    color: $color-primary-100;
    display: inline-block;
    height: 12px;
    width: 12px;
  }
}

.footnotes-wrapper {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: $color-primary-70;
  margin-top: 10px;
  .footnotes-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: top;
    margin-top: 10px;
    margin-left: 3px;
    > div:last-child {
      margin-left: 5px;
    }
  }
}
</style>
