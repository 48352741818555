<script setup>
import store from "@/store";

import { ref, computed, onMounted } from "vue";
import UserService from "@/api/actions/user-service";
import inlineSvg from "@/components/InlineSvg";

const buttonRef = ref(null);
const loading = ref(false);

function open(button) {
  store.dispatch("getStarted/openGetStarted", button);
}

const percent = computed(() => {
  return store.getters["getStarted/totalProgressPercentage"];
});

const compStyles = computed(() => {
  return {
    "--current-value": `${percent.value}`,
  };
});

async function initGetStarted() {
  try {
    loading.value = true;
    await UserService.fetchGetStartedCheckList();
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
}

function close() {
  store.dispatch("getStarted/openGetStarted", false);
}
onMounted(initGetStarted);
</script>

<template>
  <div
    class="get-started-button"
    :class="{ loading }"
    :style="compStyles"
    @keydown.esc="close"
  >
    <button @click="open(buttonRef)" ref="buttonRef" id="getStartButton">
      <span>
        <div
          class="progress-bar"
          role="progressbar"
          :aria-valuenow="percent"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <span> Get started </span>
      </span>
      <span v-show="!loading">{{ percent }}%</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.get-started-button {
  padding: 6px 12px 6px 10px;
  border-radius: 4px;
  width: 100%;
  &.loading {
    pointer-events: none;
  }

  @property --progress-value {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }

  @keyframes progress {
    to {
      --progress-value: var(--current-value);
    }
  }
  button {
    cursor: pointer;
    color: $color-primary-70;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.1px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    svg {
      height: 12px;
      width: 12px;
      color: $color-primary-100;
    }
    > span {
      display: flex;
      gap: 10px;
      align-items: center;
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }

    .progress-bar {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: radial-gradient(
          closest-side,
          $color-surface 79%,
          transparent 80% 100%
        ),
        conic-gradient(
          $color-primary-100 calc(var(--current-value) * 1%),
          $color-primary-20 0
        );
      animation: progress 2s 1 forwards;
    }
    .progress-bar::before {
      counter-reset: percentage var(--progress-value);
      animation: progress 2s 1 forwards;
    }
  }
}
</style>
