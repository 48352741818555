<script setup>
import TransactionsListing from "./TransactionsListing.vue";
import CardsListing from "./CardsListing.vue";
import WalletSettings from "./WalletSettings.vue";
import { useRoute } from "@/hooks";
import { ref, onMounted, onBeforeUnmount, watch, computed, markRaw } from "vue";
import store from "@/store";
import { useToast } from "@/hooks";
import CardsServices from "@/api/actions/cards-services";
import RightPanel from "./RightPanel";
import CollectionsPanel from "./CollectionsPanel";
import ListStatements from "@/components/modals/Wallet/ListStatements.vue";
import router from "@/routes/router";

const route = useRoute();
const toast = useToast();

function onCardDetail(message) {
  if (message.origin.indexOf("lithic") > -1) {
    store.commit("currentCard", message.data.data);
  }
}

const collection = computed(() => {
  return store.state.authentication?.user?.card_collections;
});

const collectionsActive = computed(() => {
  if (
    collection.value?.status === "active" ||
    collection.value?.status === "pending"
  ) {
    return true;
  } else {
    return false;
  }
});

const cards = computed(() => {
  return store.state.cards.cards;
});

const currentCard = computed(() => {
  return cards.value.results?.find((card) => card.id === route.params.id);
});

const rightPanelActive = computed(() => {
  return store.state.cards.rightPanel.show;
});

const cardUrl = ref("");

function getCardInformation() {
  cardUrl.value = "";
  store.commit("currentCard", "");
  if (currentCard.value.canceled !== true && type.value === "Active") {
    CardsServices.getSingleIdentityCard(
      currentCard.value.identity_id,
      currentCard.value.id
    )
      .then(({ data }) => {
        cardUrl.value = data.url;
      })
      .catch((error) => {
        toast.error(error);
        cardUrl.value = "";
      });
  }
  if (type.value === "Gifted") {
    CardsServices.getSingleGiftCard(currentCard.value.id)
      .then(({ data }) => {
        cardUrl.value = data.url;
      })
      .catch((error) => {
        toast.error(error);
        cardUrl.value = "";
      });
  }
}

onMounted(() => {
  if (route.params.id) {
    router.push("/wallet");
  }

  CardsServices.getStatements().then(() => {
    if (route.query?.statements === null) {
      store.dispatch("openModal", {
        customTemplate: {
          template: markRaw(ListStatements),
          props: {
            isVisible: true,
          },
        },
      });
    }
  });

  window.addEventListener("message", onCardDetail, true);
  document.body.classList.add("wallet");

  CardsServices.getStatements().then(() => {
    if (route.query?.statements === null) {
      store.dispatch("openModal", {
        customTemplate: {
          template: markRaw(ListStatements),
          props: {
            isVisible: true,
          },
        },
      });
    }
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("message", onCardDetail);
  document.body.classList.remove("wallet");
});

watch(
  route,
  () => {
    if (route.params.id) {
      getCardInformation();
    }
  },
  { deep: true }
);

watch(
  cards,
  () => {
    if (route.params.id && cards.value.results?.length > 0) {
      getCardInformation();
    }
  },
  { deep: true }
);

const type = ref("Active");

function setType(payload) {
  type.value = payload.type;
}
</script>

<template>
  <section>
    <CardsListing v-if="!collectionsActive" @type="setType" />
    <CollectionsPanel v-if="collectionsActive" />

    <main :class="{ rightPanel: rightPanelActive }">
      <div class="container">
        <WalletSettings v-if="!collectionsActive" :listType="type" />
        <TransactionsListing :listType="type" />

        <iframe
          v-if="route.params.id && cardUrl && !collectionsActive"
          :src="cardUrl"
          width="100%"
          height="100%"
          frameborder="0"
          :key="route.params.id"
          class="card-iframe"
        ></iframe>
      </div>
    </main>

    <RightPanel />
  </section>
</template>

<style lang="scss" scoped>
section {
  width: 100%;
  display: flex;
  min-height: calc(100vh - 60px);

  main {
    width: calc(100% - 399px);
    padding: 22px;
    position: relative;
    background-color: $color-surface;
    transition: all 0.4s ease-in-out;
    left: 400px;

    .container {
      width: 100%;
      max-width: 1280px;
      margin: 0 auto;
    }
  }
}

.card-iframe {
  position: absolute;
  top: -9999px;
  left: -9999px;
  border: none;
  z-index: 1;
  width: 1px;
  height: 1px;
}
</style>
