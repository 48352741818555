<script setup>
import NavigationPayNowModal from "@/components/Navigation/NavigationPayNowModal.vue";
import { computed, ref } from "vue";
import moment from "moment";
import store from "@/store";

const isModalOpen = ref(false);

const planDetails = computed(
  () => store.getters["subscription/getSubscriptionDetails"]
);

const daysLeft = computed(() => {
  const dateNow = moment.now();
  const dateNextBilling = moment(planDetails.value.next_billing_date);
  const diff = Math.ceil(dateNextBilling.diff(dateNow, "days", true));
  return diff !== 1 ? `${diff} days` : `${diff} day`;
});
</script>

<template>
  <section>
    <span
      >Your free trial has {{ daysLeft }} left.<a @click="isModalOpen = true"
        >Upgrade now</a
      ></span
    >
    <NavigationPayNowModal
      :value="isModalOpen"
      @input="isModalOpen = $event"
      @close="isModalOpen = false"
    />
  </section>
</template>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background: $cloaked-gradient;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $white;

    a {
      color: $white;
      font-weight: 600;
      text-decoration: underline;
      margin-left: 12px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
