<script setup>
import { computed, nextTick, onMounted, reactive, watch } from "vue";
import store from "@/store";
import router from "@/routes/router";
import { useToast } from "@/hooks";
import { emailCheck } from "@/scripts/regex";
import moment from "moment";
import {
  CloakGenerateEmailFlyout,
  CloakInfoRow,
} from "@/components/cloakDetails";
import { cloakHelpers } from "@/scripts";
import { MoreInfoQIcon } from "@/assets/icons";
import { createPassword } from "@/scripts/actions/crypto";
import UiTooltip from "@/components/ui/ui-tooltip";
import { phone as phone_standard } from "phone";
import { phone as isValidPhone } from "@/scripts/validation";
import { getUserCountry } from "@/scripts/countries/countries";
import { authDecrypt } from "@/scripts/actions/encryption";
import { formatter as cloakFormatter } from "@/store/modules/localdb";
import IdentityService from "@/api/actions/identity-service";
import ReuseService from "@/api/actions/reuse-service";
import { phone_format } from "@/scripts/format";
import TOTPContainer from "@/components/cloakDetails/TOTP/TOTPContainer.vue";
import { downloadRecoveryKeyAndCreatePdf } from "@/scripts/tools.js";
import { SubscriptionService } from "@/api";
import EmailService from "@/api/actions/email-service";
import CloakGeneratePasswordFlyout from "@/components/cloakDetails/CloakGeneratePasswordFlyout";
import { DEFAULT_PASSWORD_SETTINGS } from "@/scripts/constants";
const toast = useToast();

const emit = defineEmits(["refresh"]);

const props = defineProps(["cloak", "readOnly", "showLimits"]);

const featureFlag = computed(
  () => store.state.authentication?.user?.flags.hibp_dashboard_v1
);

const state = reactive({
  fetching: {
    phone: false,
    username: false,
    email: false,
    password: false,
  },
  updatingCloak: {
    phone: false,
    username: false,
    email: false,
    password: false,
    totp: false,
  },
  emailIsChangeable: true,
  showFlyout: null,
  decrypted: null,
  userTypedPassword: "",
  visiblePasswordStrength: false,
  visiblePasswordOptions: false,
  cloakCopyForUpdate: null,
  errors: {},
});

const showPhoneLimits = computed(() => props.showLimits && false);

const getPhoneNumberRemaining = computed(
  () => store.getters["settings/getPhoneNumberRemaining"]
);

const hasPhoneRemaining = computed(() => getPhoneNumberRemaining.value > 0);

const willExpire = computed(() => {
  const hasExpire = props.cloak?.cloaked_phone?.expiration;
  if (hasExpire) {
    return moment(props.cloak.cloaked_phone.expiration).format("MMM D, YYYY");
  }
  return false;
});

const helpTooltip = computed(() => {
  const nickname = props.cloak && props.cloak.nickname;

  return `This is the information ${nickname} can use to contact you.`;
});

const email = computed(() => props.cloak.email || "");

const password = computed(() => state.decrypted || password_source.value);

const password_source = computed(() => props.cloak.password || "");

const username = computed(() => props.cloak.username || "");

const phone = computed(() => props.cloak.phone || "" || "");

const formattedPhone = computed(() => phone_format(props.cloak.phone) || "");

const accountUsername = computed(
  () =>
    store.state.authentication?.username ||
    store.state.authentication?.user?.username
);

onMounted(async () => {
  const email = props.cloak.cloaked_email;
  const supportEmail = store.state.localdb.db_cloaks.find(
    (c) => c.protected && c.nickname?.match(/team|support/i)
  )?.email;

  if (email && email.id) {
    try {
      const res = await EmailService.getCloakedEmailChangeable(email.id);
      const { data } = res;
      state.emailIsChangeable = data.changeable;
    } catch (e) {
      toast.error(`Unable to verify if email is changeable`);
    }
  }
  authDecrypt(password_source.value).then((password) => {
    state.decrypted = password;
  });
});

const removeModal = () => {
  store.dispatch("openModal", {
    header: `Delete inactive phone numbers to prevent unwanted spam and tracking`,
    subheader:
      "This will delete the number’s entire history from its identity.",
    button: {
      text: "Yes, Delete",
      onClick: removeNumber,
      danger: true,
    },
    cancelText: "Keep",
    cancelAction: keepNumber,
  });
};
const removeNumber = () => {
  ReuseService.expirePhoneNumbersByIds([props.cloak.cloaked_phone.id])
    .then(() => {
      toast.success(`You removed the phone number`);
      emit("refresh", { ...props.cloak, cloaked_phone: null });
    })
    .catch(() => {
      toast.error(`Something went wrong. Please try again.`);
    });
};
const keepNumber = () => {
  ReuseService.keepPhoneNumberById(props.cloak.cloaked_phone.id)
    .then(() => {
      toast.success(`You kept the phone number`);
      emit("refresh", {
        ...props.cloak,
        cloaked_phone: {
          ...props.cloak.cloaked_phone,
          expiration: null,
          state: "pending_expiration",
        },
      });
    })
    .catch(() => {
      toast.error(`Something went wrong. Please try again.`);
    });
};
const savePhone = (newVal, isAutofill) => {
  if (isValidPhone(newVal)) {
    save("phone_number", newVal, isAutofill);
    state.errors.phone_number = false;
  } else {
    toast.error("Please enter a valid phone number");
    state.errors = {
      ...state.errors,
      phone_number: true,
    };
    setTimeout(() => {
      state.errors.phone_number = false;
    }, 3000);
  }
};

const saveEmail = (newVal, isAutofill) => {
  if (newVal && !emailCheck(newVal)) {
    toast.error("Please enter a valid email address");
    state.errors.email = true;
    setTimeout(() => {
      state.errors.email = false;
    }, 3000);
    return;
  }
  save("email", newVal, isAutofill);
  email.value = newVal;
};
const getFieldType = (field) => {
  return cloakHelpers.getLatestDetailType(field, props.cloak);
};
const showConfirmUsernameModal = () => {
  if (username.value) {
    state.showFlyout = null;
    return store.dispatch("openModal", {
      header: `Are you sure you want to replace this username?`,
      subheader: "Adding a new username will delete the old one.",
      button: {
        text: "Replace",
        onClick: () => generateUsername(),
      },
    });
  }
  return generateUsername();
};
const showConfirmPhoneModal = () => {
  if (phone.value) {
    state.showFlyout = null;
    return store.dispatch("openModal", {
      header: `Are you sure you want to replace this phone?`,
      subheader: "Adding a new phone will delete the old one.",
      button: {
        text: "Replace",
        onClick: generatePhone,
      },
    });
  }
  return generatePhone();
};
const showConfirmEmailModal = (email_type) => {
  if (email.value) {
    state.showFlyout = null;
    return store.dispatch("openModal", {
      header: `Are you sure you want to replace this email?`,
      subheader: "Adding a new email will delete the old one.",
      button: {
        text: "Replace",
        onClick: () => generateEmail(email_type),
      },
    });
  }
  return generateEmail(email_type);
};
const handleGenerateEmail = () => {
  if (email.value) {
    if (!store.getters.isV2User) {
      state.showFlyout = "email";
      return;
    } else {
      return generateEmail("random");
    }
  }
  if (!store.getters.isV2User) {
    return generateEmail("random");
  }
  return generateEmail();
};
const showPasswordFlyout = ({ strength = false, options = true } = {}) => {
  state.visiblePasswordStrength = strength;
  state.visiblePasswordOptions = options;
  state.showFlyout = "password";
};
const hidePasswordFlyout = () => {
  state.showFlyout = null;
};
const handlePasswordInput = (value) => {
  state.userTypedPassword = value;

  if (!state.userTypedPassword) {
    hidePasswordFlyout();
  }
};
const handlePasswordTyping = () => {
  showPasswordFlyout({ strength: true, options: false });
};

const handleGeneratePassword = () => {
  if (password.value) {
    showPasswordFlyout({ strength: false, options: true });
    return;
  }

  return generatePassword(DEFAULT_PASSWORD_SETTINGS);
};
const generatePhone = async () => {
  setFieldLoading("phone");
  updateCloakCopy();
  try {
    const res = await IdentityService.generateCloakedPhoneForIdentity(
      props.cloak.id
    );
    const { data } = res;
    updateCloakCopy({ replace_number: data.replace_number });
    setFieldLoadingDone("phone");
    SubscriptionService.getPlanLimits();
    state.errors.phone_number = false;
  } catch (e) {
    setFieldLoadingDone("phone");
    const message =
      e?.response?.data?.errors ||
      e?.response?.data?.message ||
      e?.response?.data?.detail ||
      "Something went wrong, please try again later";
    if (message.includes("verified")) {
      const userCountry = getUserCountry(store.state.authentication?.user);
      store.dispatch("openModal", {
        header: `Please verify your ${userCountry} phone number`,
        subheader: `To generate this Cloaked phone number, you will need to verify your ${userCountry} non-VOIP phone number.`,
        button: {
          text: "Go to Settings",
          onClick: () => {
            nextTick(() => {
              store.commit("openPreference", {
                selected: "account",
                right: "recovery",
              });
              router.push({
                name: "settings.account",
              });
            });
          },
        },
        cancelText: "I’ll add one later",
      });
      return;
    }
    toast.error(message);
  }
};
const generateEmail = async (email_type) => {
  updateCloakCopy();

  state.showFlyout = null;
  if (state.emailIsChangeable) {
    const email = props.cloak.cloaked_email;
    setFieldLoading("email");
    if (props.cloak.is_cloaked_email) {
      const payload = {
        email_local_part: "",
        email_type,
      };

      try {
        const res = await EmailService.updateCloakedEmail({
          emailId: email.id,
          payload,
        });
        const { data } = res;
        setFieldLoadingDone("email");
        updateCloakCopy({
          email: data.cloaked_email.email,
          cloaked_email: data.cloaked_email,
        });
      } catch (e) {
        setFieldLoadingDone("email");
      }
    } else {
      try {
        const res = await IdentityService.generateCloakedEmailForIdentity({
          id: props.cloak.id,
          payload: email_type,
        });
        const { data } = res;
        setFieldLoadingDone("email");
        updateCloakCopy({
          email: data.cloaked_email.email,
          cloaked_email: data.cloaked_email,
        });
      } catch (e) {
        setFieldLoadingDone("email");
      }
    }
  }
};
const generateUsername = () => {
  const newUsername = createPassword({
    size: 3,
    words: true,
    symbols: false,
    letters: true,
  });

  save("username", newUsername, true, true);
};
const generatePassword = (settings) => {
  state.showFlyout = null;
  state.userTypedPassword = null;
  const newPassword = createPassword(settings);
  save("password", newPassword, false);
};

const save = (field, value, isAutofill, wasGenerated) => {
  // if its not autofill then its cloaked_email or cloaked_phone
  // and should already be saved
  value = value.trim();
  const cloakData = {};
  const payload = {
    cloak: state.cloakCopyForUpdate || props.cloak,
    updatedValueType: isAutofill ? "user_defined" : "cloaked",
    field,
  };
  if (field === "username" && wasGenerated) {
    payload.updatedValueType = "cloaked";
  }
  if (field === "password") {
    cloakData[field] = value;
    setFieldLoading("password");
    updateAutofill(field, value).then(() => {
      setFieldLoadingDone("password");
    });
  } else if (isAutofill) {
    updateAutofill(field, value);
    field = field.includes("phone") ? "phone_number" : field;
    cloakData[field] = value.trim();
  }
  updateCloakCopy(cloakData);
};

const updateCloakCopy = (updateData) => {
  const cloakCopy = {
    ...(state.cloakCopyForUpdate ? state.cloakCopyForUpdate : props.cloak),
    ...updateData,
  };
  state.cloakCopyForUpdate = { ...cloakCopy };
};
const patchAutofill = (name, payload) => {
  return IdentityService.patchAutofill(props.cloak.id, payload)
    .then(({ data }) => {
      setFieldLoadingDone(name);
      updateCloakCopy({ stored_autofill: data });
    })
    .catch(() => {
      setFieldLoadingDone(name);

      state.errors[name] = true;
      toast.error("There was an error saving, please try again");
    });
};
const updateAutofill = (name, value) => {
  const userAccountVersion = store.state.authentication?.user?.account_version;
  setFieldLoading(name);

  // password, email, phone, notes, username need this
  if (value !== undefined) {
    name = name.toLowerCase().includes("phone") ? "phone_number" : name;
    const payloadKey = `autofill_${name}`;

    if (
      name.toLowerCase().includes("phone") &&
      phone_standard(value) &&
      phone_standard(value).phoneNumber
    ) {
      value = phone_standard(value).phoneNumber;
    }

    const payload = { [payloadKey]: value };
    if (name === "password") {
      payload.encrypted = false;
    } else if (userAccountVersion > 1) {
      payload.encrypted = true;
    }
    payload.encrypted_version = userAccountVersion;

    return patchAutofill(name, payload);
  }
};
const deleteEmail = async () => {
  setFieldLoading("email");

  if (props.cloak.is_cloaked_email) {
    try {
      await IdentityService.deleteCloakedFieldAtUrl(
        props.cloak.cloaked_email_url
      );
      updateAutofill("email", "");
    } catch (e) {
      setFieldLoadingDone("email");
    }
  } else {
    updateAutofill("email", "");
  }

  return updateCloakCopy({
    cloaked_email: null,
    is_cloaked_email: null,
    email: null,
  });
};
const deletePassword = async () => {
  setFieldLoading("password");

  if (props.cloak.password_url) {
    try {
      await IdentityService.deleteCloakedFieldAtUrl(props.cloak.password_url);
      updateAutofill("password", "");
    } catch (e) {
      setFieldLoadingDone("password");
    }
  } else {
    updateAutofill("password", "");
  }
  updateCloakCopy({
    stored_password: null,
    password: "",
  });
};
const deletePhone = async () => {
  setFieldLoading("phone");
  updateCloakCopy({
    cloaked_phone: null,
    number_for_personal: null,
    number_for_app: null,
    is_cloaked_phone: null,
    phone: null,
  });

  if (props.cloak.is_cloaked_phone) {
    try {
      await IdentityService.deleteCloakedPhoneOnIdentity(props.cloak.id);
      updateAutofill("phone", "");
    } catch (e) {
      setFieldLoadingDone("phone");
    }
  } else {
    updateAutofill("phone", "");
  }
};
const deleteUsername = () => {
  updateCloakCopy({
    username: "",
  });

  return updateAutofill("username", "");
};
const setFieldLoading = (field) => {
  if (field === "phone_number") {
    field = "phone";
  }
  state.fetching = {
    ...state.fetching,
    [field]: true,
  };
  state.updatingCloak = {
    ...state.updatingCloak,
    [field]: true,
  };

  state.updatingCloak;
};
const setFieldLoadingDone = (field) => {
  state.errors[field] = false;
  if (field === "phone_number") {
    field = "phone";
  }
  state.fetching = {
    ...state.fetching,
    [field]: false,
  };
};

watch(
  () => password_source.value,
  (value) => {
    authDecrypt(value).then((password) => {
      state.decrypted = password;
    });
  },
  { deep: true }
);
watch(
  () => state.fetching,
  (newValue, oldValue) => {
    const isLoadingNew = Object.values(newValue).includes(true);
    const isLoadingOld = Object.values(oldValue).includes(true);
    if (isLoadingOld && !isLoadingNew) {
      emit("refresh", cloakFormatter({ ...state.cloakCopyForUpdate }), () => {
        if (!Object.values(state.fetching).includes(true)) {
          state.updatingCloak = {
            phone: false,
            username: false,
            email: false,
            password: false,
          };
        }
      });
    }
  },
  { deep: true }
);
</script>
<template>
  <section class="cloak-identifier-section">
    <header class="cloak-identifier-section__header">
      <h3 class="cloak-identifier-section__header-title">
        <span>Your identity</span>

        <UiTooltip
          :title="helpTooltip"
          position="top"
          max-width="192"
          align-x="center"
        >
          <MoreInfoQIcon />
        </UiTooltip>
      </h3>
    </header>

    <div
      class="cloak-identifier-section__group cloak-identifier-section__group--no-separator"
    >
      <div
        v-if="showPhoneLimits"
        class="number-remaining"
        :class="{ none: !hasPhoneRemaining }"
      >
        You have {{ hasPhoneRemaining ? getPhoneNumberRemaining : 0 }} phone
        numbers left
      </div>
      <CloakInfoRow
        field="phone"
        placeholder="Enter a phone number"
        :initialValue="formattedPhone"
        :loading="state.updatingCloak.phone"
        :fieldType="getFieldType('phone')"
        :error="!!state.errors.phone_number"
        :readOnly="props.readOnly"
        :nickname="props.cloak.nickname"
        :phoneObject="props.cloak.cloaked_phone"
        :identityId="props.cloak.id"
        @generate="showConfirmPhoneModal"
        @save="savePhone"
        @delete="deletePhone"
        @refresh="$emit('refresh')"
      />
      <div class="alert-phone-expire" v-if="willExpire">
        <span>This number will be auto-deleted on {{ willExpire }}</span>
        <button @click="removeModal"><MoreInfoQIcon /></button>
      </div>
      <CloakInfoRow
        field="email"
        placeholder="Enter an email address"
        :initialValue="email"
        :loading="state.updatingCloak.email"
        :fieldType="getFieldType('email')"
        :error="
          !!state.errors.email || (featureFlag && email === 'email@email.com')
        "
        :readOnly="props.readOnly"
        :identityId="props.cloak.id"
        @generate="handleGenerateEmail"
        @save="saveEmail"
        @delete="deleteEmail"
      >
        <template #input-before>
          <CloakGenerateEmailFlyout
            :visible="state.showFlyout === 'email'"
            @generate="showConfirmEmailModal"
            @close="state.showFlyout = null"
          />
        </template>
      </CloakInfoRow>

      <div
        class="breached-alert"
        v-if="email === 'email@email.com' && featureFlag"
      >
        <span>Your email has been breached! 🤯 </span>
      </div>

      <CloakInfoRow
        field="username"
        placeholder="Enter a username"
        :initialValue="username"
        :loading="state.updatingCloak.username"
        :fieldType="getFieldType('username')"
        :error="!!state.errors.username"
        :readOnly="props.readOnly"
        :identityId="props.cloak.id"
        @generate="showConfirmUsernameModal"
        @save="(newVal, isAutofill) => save('username', newVal, isAutofill)"
        @delete="deleteUsername"
      />

      <CloakInfoRow
        field="password"
        placeholder="Enter a password"
        :initialValue="password"
        :loading="state.updatingCloak.password"
        fieldType="user_defined"
        :error="
          !!state.errors.password || (featureFlag && password === 'password123')
        "
        :readOnly="props.readOnly"
        :identityId="props.cloak.id"
        @input="handlePasswordInput"
        @keypress="handlePasswordTyping"
        @generate="handleGeneratePassword"
        @save="(newVal, isAutofill) => save('password', newVal, isAutofill)"
        @delete="deletePassword"
        :breached="featureFlag && password === 'password123'"
      >
        <template #input-before>
          <CloakGeneratePasswordFlyout
            :visible="state.showFlyout === 'password'"
            :showGenerateOptions="true"
            @generate="generatePassword"
            @close="state.showFlyout = null"
          />
        </template>
      </CloakInfoRow>

      <div
        class="breached-alert"
        v-if="password === 'password123' && featureFlag"
      >
        <span>Your password has been breached! 🤯 </span>
      </div>

      <TOTPContainer
        v-if="store.getters.isV2User"
        :identity="props.cloak"
        :loading="state.updatingCloak.totp"
        :readOnly="props.readOnly"
        @set-loading="state.updatingCloak.totp = $event"
        :errors="state.errors.totp"
        @set-errors="state.errors.totp = $event"
        @refresh="emit('refresh', $event)"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.breached-alert {
  padding-left: 110px;
  color: $color-alert;
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0;
}
.number-remaining {
  border-radius: 29px;
  background-color: $color-primary-10;
  color: $color-primary-100;
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &.none {
    color: $color-surface-dark;
    background-color: $color-alert-tint;
  }
}
.alert-phone-expire {
  border-radius: 10px;
  border: 1px solid $color-warning;
  background: $color-warning-20;
  padding: 8px 10px 8px 12px;
  gap: 8px;
  font-size: 11.5px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: $color-primary-100;
  }
  button {
    color: $color-primary-100;
    background-color: transparent;
    border: none;
    cursor: pointer;
    svg {
      position: relative;
      top: 3px;
    }
  }
}
.cloak-identifier-section {
  &__header {
    display: flex;
    align-items: center;
    padding: 20px 24px 8px 24px;
    margin-top: 0;

    + .cloak-identifier-section__group {
      padding-top: 0;
    }
  }

  &__header-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-100;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__group {
    border-top: 1px solid $color-primary-100-10;
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &--no-separator {
      border-top: none;
    }
  }
}
</style>
