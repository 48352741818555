<script setup>
import { computed, onMounted } from "vue";
import {
  PH_EVENT_USER_CLICKED_DATA_DELETION_SEARCH_RESULTS_CONTINUE_BUTTON,
  PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_RESULTS,
} from "@/scripts/posthogEvents";
import DataDeleteScanResultsScanning from "@/components/feature/data-delete/DataDeleteScanResultsScanning.vue";
import DataDeleteScanResultsDone from "@/components/feature/data-delete/DataDeleteScanResultsDone.vue";
import DataDeleteScanResultsTable from "@/components/feature/data-delete/DataDeleteScanResultsTable.vue";
import UserService from "@/api/actions/user-service";
import { DATA_DELETE_SEARCHED_EXPOSURES } from "@/scripts/userFlags";
import { posthogCapture } from "@/scripts/posthog";

const props = defineProps({
  progress: {
    type: Object,
    default: null,
  },
  records: {
    type: Array,
    default: () => [],
  },
});

const sortedRecords = computed(() =>
  [...props.records].sort(
    (a, b) => JSON.stringify(b).length - JSON.stringify(a).length
  )
);

const emit = defineEmits(["setup"]);

const onDelete = () => {
  emit("setup");
  posthogCapture(
    PH_EVENT_USER_CLICKED_DATA_DELETION_SEARCH_RESULTS_CONTINUE_BUTTON
  );
};

onMounted(async () => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_RESULTS, {
    searchType: "brokers",
  });

  UserService.setFlag({
    name: DATA_DELETE_SEARCHED_EXPOSURES,
    value: true,
  });
});
</script>

<template>
  <div class="data-delete__page data-delete-scan-results">
    <DataDeleteScanResultsScanning
      v-if="!progress.isScanFinished"
      :progress="progress"
      @delete="onDelete"
    />
    <DataDeleteScanResultsDone v-else :progress="progress" @delete="onDelete" />
    <slot name="before-table" />
    <DataDeleteScanResultsTable
      v-if="sortedRecords.length"
      :records="sortedRecords"
      class="data-delete-scan-results__table"
      @delete="onDelete"
    />
  </div>
</template>

<style lang="scss">
.data-delete-scan-results {
  &__table {
    margin-top: 32px;
    opacity: 0;
    animation: appear-bottom-5 0.2s forwards ease-out;

    @media all and (min-width: $screen-xl) {
      margin-top: 80px;
    }
  }

  @media all and (min-width: $screen-xl) {
    margin-top: clamp(40px, 5vh, 150px);
  }
}
</style>
