<script setup>
import store from "@/store";
import { phone } from "@/scripts/validation";
import { phone_package, phone_format } from "@/scripts/format";
import ModalTemplate from "@/components/ModalTemplate.vue";
import { Button } from "@/components";
import OnboardingInputPhone from "@/components/feature/onboarding/OnboardingInputPhone";
import OnboardingInputCode from "@/components/feature/onboarding/OnboardingInputCode";
import PhoneService from "@/api/actions/phone-service";
import { getUserCountry } from "@/scripts/countries/countries";
import { useToast } from "@/hooks";

import { computed, onMounted, reactive, watch, ref, useAttrs } from "vue";

const toast = useToast();
const emit = defineEmits(["cancel", "phone-verified", "phone-created"]);
const attrs = useAttrs();

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  alert: {
    type: Boolean,
    default: false,
  },
  setPrimary: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
});

const state = reactive({
  phoneDirty: false,
  confirming: false,
  token: null,
  code: "",
  codeError: null,
  country: getUserCountry(store.state.authentication?.user),
  verifying: null,
  verified: false,
  saving: null,
  savingPhone: false,
  phone: "",
  phoneError: null,
  phoneVerified: false,
  loading: true,
});

const phoneInputRef = ref(null);
const verifyRef = ref(null);

onMounted(() => {
  phoneInputRef.value?.$el?.querySelector("input")?.focus();
});

watch(
  () => state.phone,
  () => {
    state.phoneVerified = false;
  },
  { deep: true }
);

const validPhone = computed(() => {
  if (state.phone) {
    return phone(state.phone, state.country);
  }
  return false;
});

const validCode = computed(() => {
  if (state.code) {
    return state.code.match(/[0-9]{6}/i);
  }
  return false;
});

const formattedPhoneNumber = computed(() => {
  return phone_format(state.phone, state.country);
});

function setPhone({ value, country }) {
  if (phone(value, country)) {
    const formatted = phone_package(value, country);
    state.phone = formatted.phone_number || value;
    state.country = country;
  } else {
    state.phone = "";
    state.country = "us";
  }
}

function handleCancel() {
  emit("cancel");
  handleClose();
}

function handleClose() {
  store.dispatch("closeModal");
}

async function savePhone() {
  if (validPhone.value && !state.savingPhone) {
    state.phoneError = null;
    state.savingPhone = true;
    const phone_payload = phone_package(state.phone, state.country);

    const payload = {
      ...phone_payload,
      primary: !!props.setPrimary,
      collection_name: "phone",
      user: store.state.authentication?.user?.url,
      collection: store.getters["authentication/collection"]("phone"),
    };

    try {
      const { data: phone } = await PhoneService.addPhone(payload);
      state.phoneDirty = false;
      if (!phone.verified) {
        confirmContact(phone);
      } else {
        await PhoneService.makePrimary(phone.id);
        emit("phone-created", { ...phone, primary: true });
        handleClose();
        toast.success("Phone number added.");
      }
    } catch (err) {
      state.savingPhone = false;
      const { response } = err;
      const { data } = response;
      let errors = data.errors || data;
      if (errors.phone_number) {
        errors = errors.phone_number;
      }
      if (Array.isArray(errors)) {
        state.phoneError = errors.join("\n");
      } else {
        state.phoneError = errors;
      }
    }
  }
}

function confirmContact(phone) {
  state.saving = phone;
  state.loading = true;
  state.confirming = true;
  PhoneService.sendVerificationCode(phone.id)
    .then((verify_response) => {
      toast.success("Verification code sent.");
      if (!verify_response.data.verified) {
        state.loading = false;
        state.token = verify_response.data.session_token;
        setTimeout(() => {
          if (verifyRef.value) {
            verifyRef?.value?.$el.focus();
          }
        }, 100);
      }
    })
    .catch(() => {
      toast.error("Error resending verification code.");
    });
}

function resend() {
  state.loading = true;
  state.codeError = null;
  state.code = "";

  PhoneService.sendVerificationCode(state.saving.id)
    .then((verify_response) => {
      toast.success("Verification code sent.");
      if (!verify_response.data.verified) {
        state.token = verify_response.data.session_token;
      }
      state.loading = false;
      setTimeout(() => {
        if (verifyRef.value) {
          verifyRef.value.$el?.focus();
        }
      }, 100);
    })
    .catch(() => {
      toast.error("Error resending verification code.");
    });
}

async function verify() {
  if (state.code && !state.verifying && !state.verified) {
    state.codeError = null;
    state.verifying = true;

    try {
      await PhoneService.verifyVerificationCode(state.saving.id, {
        security_code: state.code,
        phone_number: state.saving.phone_number,
        session_token: state.token,
      });

      await PhoneService.makePrimary(state.saving.id);

      state.verifying = false;
      state.verified = true;
      handleClose();
      emit("phone-verified", {
        ...state.saving,
        verified: true,
      });
      toast.success("Verification successful.");
    } catch (e) {
      toast.error("Error with verification.");
      state.verifying = false;
      state.verified = false;
      state.codeError = "Verification code is incorrect.";
    }
  }
}
</script>

<template>
  <ModalTemplate
    class="add-verify-phone-modal"
    :show="true"
    @close="handleCancel"
  >
    <template #header>
      <div v-if="!state.confirming">
        <h1>{{ title || "Add a phone number" }}</h1>
      </div>

      <div v-else>
        <h1>Verify your phone number</h1>
      </div>
    </template>

    <template #body>
      <div v-if="!state.confirming">
        <OnboardingInputPhone
          ref="phoneInputRef"
          :value="state.phone"
          @input="setPhone"
          placeholder="Phone number"
        />
      </div>
      <div v-else class="subheader">
        Enter the 6-digit code sent to
        <strong>{{ formattedPhoneNumber }}</strong
        ><br />
        <OnboardingInputCode
          v-bind="attrs"
          ref="verifyRef"
          :value="state.code"
          @input="($event) => (state.code = $event)"
        />
      </div>
      <div class="input_error" v-if="state.phoneError || state.codeError">
        {{ state.phoneError || state.codeError }}
      </div>
    </template>

    <template #footer>
      <template v-if="!state.confirming">
        <Button type="secondary" @click="handleCancel"> Cancel </Button>

        <Button @click="savePhone" :disabled="!validPhone"> Continue </Button>
      </template>

      <template v-else>
        <Button type="secondary" @click="resend">
          <span v-if="state.loading" class="spin-loader" />
          Resend code
        </Button>

        <Button @click="verify" :disabled="!validCode"> Verify </Button>
      </template>
    </template>
  </ModalTemplate>
</template>

<style lang="scss">
.add-verify-phone-modal {
  .content {
    max-width: 512px !important;
  }
  .modal-body {
    overflow: unset !important;
  }

  .ui-input {
    position: relative;
    background-color: $color-primary-10;
  }

  .vue-tel-input {
    box-shadow: none;
    border: none;
    width: 100%;

    .vti__input {
      background: none;
      color: $color-primary-100;
    }

    .vti__dropdown {
      position: unset;
      background: $color-primary-5;
    }

    .vti__dropdown-list {
      left: 0;
      top: 100%;
      transform: translateY(6px);
      width: 100%;
      z-index: 100;
      box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
        13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
      border-radius: 8px;
      border: 1px solid $color-primary-5;
      background: $color-background;

      .vti__dropdown-item {
        color: $color-primary-100 !important;
      }
    }

    .vti__dropdown-item {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $color-primary-100;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.1px;

      strong {
        font-weight: 600;
      }
    }
  }

  .vue-tel-input:focus-within {
    box-shadow: none;
    border: none;
  }

  .ui-confirm-code {
    margin-top: 32px;
  }

  .error {
    font-size: 12px;
    color: red;
  }

  .input_error {
    font-size: 12px;
    color: $color-alert;
    border: 1px solid $color-alert;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0px 5px 0px;
    background-color: #f82f2810;
  }
}
.subheader {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.2px;
  color: $color-primary-100;
}
</style>
