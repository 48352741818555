<template>
  <div class="data-delete" v-bind="$attrs">
    <slot />
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/animations";

.data-delete {
  color: $color-primary-100;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__page {
    position: relative;
    z-index: 2;
    padding: 40px 22px 25px;
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 8px;

    @media all and (min-width: $screen-xl) {
      font-size: 48px;
      line-height: 115%;
    }

    &-icon {
      width: 32px;
      height: 32px;

      @media all and (min-width: $screen-xl) {
        width: 72px;
        height: 72px;
      }
    }
  }

  &__subtitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    margin-bottom: 2px;

    @media (min-width: $screen-xl) {
      font-size: 18px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    opacity: 0.9;
    margin-top: 8px;
    font-size: 12px;

    @media all and (min-width: $screen-xl) {
      font-size: 15px;
      letter-spacing: -0.1px;
    }
  }

  &__capitalized {
    text-transform: capitalize;
  }
}
</style>
