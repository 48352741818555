<script setup>
import { tools, validation } from "@/scripts";
import { computed, reactive } from "vue";
import { useToast } from "@/hooks";
import ReferralService from "@/api/actions/referral-service";
import inlineSvg from "@/components/InlineSvg.vue";
import store from "@/store";
import { useRoute } from "@/hooks";
import Button from "@/components/Button.vue";
import { posthogCapture } from "@/scripts/posthog.js";

const route = useRoute();

const toast = useToast();
const emit = defineEmits(["sent"]);

const props = defineProps({
  showInviteInput: {
    type: Boolean,
    default: false,
  },
});

const referralLink = computed(() => {
  return store.state.authentication?.user?.referral_link;
});

const state = reactive({
  sending: false,
  input: "",
});

const emailIsValid = computed(() => {
  if (state.input) {
    return validation.email(state.input);
  }
  return false;
});

const canSend = computed(() => {
  return emailIsValid.value;
});

const pageName = computed(() => {
  return route?.name;
});

function send() {
  if (emailIsValid.value) {
    state.sending = true;
    ReferralService.createReferrals(state.input)
      .then(() => {
        state.input = "";
        emit("sent");
      })
      .catch(({ response }) => {
        let error = "Could not add your invite, please try again";
        if (response.data) {
          error = response.data.message;
        }
        toast.error(error);
      })
      .finally(() => {
        state.sending = false;
      });
  }
}

function copyReferralLink() {
  posthogCapture("user_clicked_referralLink", {
    page: pageName.value,
  });
  tools.copyToClipboard(referralLink.value);
  toast.success("Link copied");
}
</script>
<template>
  <div class="referral-hero" :class="{ large: props.showInviteInput }">
    <div class="referral-hero-overlay"></div>
    <div
      class="referral-hero-content"
      :class="{ large: props.showInviteInput }"
    >
      <div>
        <div class="referral-hero-wrap">
          <h1>Give $25, get $25</h1>
        </div>
        <h3>
          Refer a friend to Cloaked and each of you will receive a gift card of
          up to $25 when they subscribe.
        </h3>

        <div class="hero-actions">
          <input
            v-show="props.showInviteInput"
            type="text"
            :value="state.input"
            @input="(event) => (state.input = event.target.value)"
            @keyup.enter="send"
            placeholder="Invite email address"
          />
          <div class="hero-actions-buttons">
            <Button
              v-show="props.showInviteInput"
              @click="send"
              :class="{
                active: canSend && !state.sending,
                disabled: !canSend || state.sending,
              }"
            >
              <span><inlineSvg name="invite-sent" /></span>
              <span>{{ state.sending ? "Sending..." : "Send invite" }}</span>
            </Button>

            <Button @click="copyReferralLink">
              <span><inlineSvg name="link-chain" /></span>
              <span>Copy referral link</span>
            </Button>
            <Button v-show="!props.showInviteInput" class="secondary">
              <router-link :to="{ name: 'Referrals' }">
                <span>Learn more</span>
              </router-link>
            </Button>
          </div>
        </div>
      </div>
      <img
        :src="require(`@/assets/images/girl-and-computerhead.png`)"
        alt="sharing photo"
        :class="{ large: props.showInviteInput }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.referral-hero {
  height: 300px;
  width: 100%;
  background-color: black;
  position: sticky;
  &.large {
    height: 420px;
  }
  .referral-hero-overlay {
    color: $color-primary-100-dark;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      86deg,
      rgba(0, 255, 252, 0.25) 11.59%,
      rgba(40, 154, 255, 0.25) 27.15%,
      rgba(181, 66, 255, 0.25) 46.52%,
      rgba(255, 64, 96, 0.25) 67.39%,
      rgba(255, 225, 18, 0.25) 87.51%
    );
    filter: $background-rainbow-filter;
  }

  .referral-hero-content {
    color: $color-primary-100-dark;
    width: 100%;
    height: 300px;
    position: relative;
    top: -300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 64px;
    overflow: hidden;
    gap: 60px;
    &.large {
      height: 420px;
      top: -420px;
    }

    > img {
      margin-top: 300px;
      height: 500px;
      width: auto;
      &.large {
        margin-top: 150px;
      }
    }

    h1 {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 63px; /* 131.25% */
      letter-spacing: -1.5px;
    }
    h3 {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
      max-width: 400px;
    }
    .referral-hero-wrap {
      max-width: 500px;
      margin-bottom: 16px;
    }
    .hero-actions {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      &.disabled {
        opacity: 0.5;
        transform: none;
        transition: none;
      }
      input {
        border-radius: 12px;
        background-color: $color-surface-light;
        color: $color-primary-100-light;
        border: 1px solid $color-primary-0-light;
        padding: 12px 16px;
        min-width: 330px;
        max-width: 400px;
        outline: none;
        &:focus {
          outline: none;
          border: 1px solid $color-primary-100-light;
        }
      }
      .hero-actions-buttons {
        display: flex;
        flex-direction: row;
        gap: 4px;

        button {
          border-radius: 999px;
          color: $color-primary-0-dark;
          padding: 6px 20px;
          border: 1px solid $color-primary-100-dark;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          flex-direction: row;
          gap: 6px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 48px;
          cursor: pointer;
          background-color: $color-primary-100-dark;
          min-width: 137px;
          &:first-child {
            span {
              > svg {
                margin-right: -6px;
                margin-left: -6px;
                position: relative;
                top: 2px;
                transform: scale(0.5);
              }
            }
          }
          &.secondary {
            background-color: transparent;
            color: $color-primary-100-dark;
            border: 1px solid $color-primary-100-dark;
          }

          &.disabled {
            opacity: 0.3;
            transform: none;
            transition: none;
          }
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .referral-hero-content {
      padding-right: 150px;
      > img {
        height: 600px;
        margin-top: 350px;
        &.large {
          margin-top: 150px;
          height: 500px;
          width: auto;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .referral-hero-content {
      gap: 20px;
      > img {
        margin-top: 300px;
        height: 400px;
        width: auto;
      }
      h1 {
        font-size: 38px;
        overflow: visible;
        white-space: nowrap;
      }
      h3 {
        font-size: 15px;
      }
    }
  }
}
</style>
