<script setup>
import moment from "moment/moment";
import { onMounted, computed, watch, nextTick, markRaw, ref } from "vue";
import DataDeleteService from "@/api/actions/data-delete-service.js";
import inlineSvg from "@/components/InlineSvg.vue";

import ActionRequiredModal from "@/components/modals/data-delete/ActionRequiredModal.vue";
import DataRemovalGraphSummarySection from "@/routes/DataDeletion/components/DataRemovalGraphSummarySection.vue";
import DataRemovalRequestsSection from "@/routes/DataDeletion/components/DataRemovalRequestsSection.vue";
import DataRemovalSkeletonLoader from "@/routes/DataDeletion/components/DataRemovalSkeletonLoader.vue";
import store from "@/store";
import DataRemovalFaqSection from "@/routes/DataDeletion/components/DataRemovalFaqSection.vue";
import ActionRequiredMarkedDoneModal from "@/components/modals/data-delete/ActionRequiredMarkedDoneModal.vue";
import DataRemovalEnrollmentProfileModal from "@/components/modals/data-delete/DataRemovalEnrollmentProfileModal.vue";
import {
  PH_EVENT_DATA_REMOVAL_USER_CLICKED_REVIEW,
  PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE,
  PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE_CONFIRMATION,
} from "@/scripts/posthogEvents";
import UiActionRow from "@/routes/DataDeletion/UiActionRow.vue";
import UiActionRowWrapper from "@/routes/DataDeletion/UiActionRowWrapper.vue";
import { posthogCapture } from "@/scripts/posthog.js";

onMounted(async () => {
  DataDeleteService.getRemovalLog().catch(() => {});
  DataDeleteService.getEnrollmentData().catch(() => {});
  DataDeleteService.getGraphData()
    .then((response) => {
      rawGraphData.value = response?.data;
      if (!response?.data?.graph_data || !response?.data?.graph_data?.length) {
        hideGraph.value = true;
      }
    })
    .catch(() => {
      // only hide graph if no data is saved AND no data was returned
      // this way if second api call failed but first one succeeded, user can still see graph data
      if (rawGraphData.value === null) {
        hideGraph.value = true;
      }
    });
  DataDeleteService.getEnrollmentProfile().catch(() => {});
});

const hideGraph = ref(false);

const rawGraphData = ref(store.getters["dataDelete/getGraphData"] || {});

const removalDate = computed(() => {
  return store.getters["dataDelete/removalLogData"]?.removalDate ?? new Date();
});

const brokerList = computed(() => {
  return store.getters["dataDelete/removalLogData"]?.brokerList ?? [];
});

const removalLogFetched = computed(() => {
  return store.getters["dataDelete/removalLogData"]?.removalLogFetched;
});

const enrollmentDataFetched = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched;
});

const actionRequiredFamilies = computed(() => {
  return store.getters["dataDelete/actionRequiredFamilies"];
});

const formattedDate = computed(() => {
  return moment(removalDate.value).format("MMMM Do, YYYY");
});

const actionRequiredItems = computed(() => {
  return brokerList.value.filter(
    (broker) => broker.state === "action_required"
  );
});

const nonActionItems = computed(() => {
  return brokerList.value.filter(
    (broker) => broker.state !== "action_required"
  );
});

const scanningSites = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.scanningSites ?? 0;
});

function showActionsRequiredInfoModal() {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(ActionRequiredModal),
    },
  });
}

function showEnrollmentDetailsModal() {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(DataRemovalEnrollmentProfileModal),
    },
  });
}

function fetchActionsRequired() {
  DataDeleteService.getActionItems().catch(() => {});
}

function showMarkDoneConfirmation(brokerFamily) {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(ActionRequiredMarkedDoneModal),
      props: {
        name: brokerFamily?.name,
      },
      events: {
        done: () => markDone(brokerFamily),
        review: () => toggleOpenBrokerFamily(brokerFamily, true),
      },
    },
  });

  posthogCapture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE, {
    broker_name: brokerFamily.name,
  });
}

function markDone(brokerFamily) {
  DataDeleteService.markBrokerFamilyDone(brokerFamily.id)
    .then(() => {
      const actionRequiredFamiliesCopy = [
        ...actionRequiredFamilies.value,
      ]?.filter((bf) => bf.id !== brokerFamily.id);
      store.dispatch(
        "dataDelete/setActionRequiredItems",
        actionRequiredFamiliesCopy
      );
      return DataDeleteService.getRemovalLog().catch(() => {});
    })
    .catch(() => {});
  posthogCapture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_MARK_DONE_CONFIRMATION, {
    broker_name: brokerFamily.name,
  });
}

function toggleOpenBrokerFamily(brokerFamily, forceOpen = false) {
  const actionRequiredFamiliesCopy = [...actionRequiredFamilies.value]?.map(
    (bf) => {
      if (bf.id === brokerFamily.id) {
        bf.opened = forceOpen ? forceOpen : !bf.opened;
        if (!bf.opened) {
          posthogCapture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_REVIEW);
        }
      } else {
        // Only one should be opened at a time
        bf.opened = false;
      }
      return bf;
    }
  );
  return store.dispatch(
    "dataDelete/setActionRequiredItems",
    actionRequiredFamiliesCopy
  );
}

const unwatchActionsRequired = watch(
  // () => actionRequiredItems?.value,
  () => removalLogFetched?.value,
  (removalFetched) => {
    if (actionRequiredItems?.value?.length && removalFetched) {
      fetchActionsRequired();
      nextTick(() => unwatchActionsRequired());
    } else {
      store.dispatch("dataDelete/setActionRequiredItems", []);
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="page-wrapper">
    <div class="section-wrapper">
      <h1 class="title">
        <span>
          Monitoring {{ scanningSites }} sites
          <inlineSvg
            :name="'fuzzy-green-dot'"
            :key="'fuzzy-green-dot'"
            class="pulsing-svg"
          />
        </span>
      </h1>
      <h2 class="subtitle">
        Cloaked continually monitors all {{ scanningSites }} data broker sites
        for any new records and actively requests to have them removed.
      </h2>

      <DataRemovalSkeletonLoader v-if="!rawGraphData && !hideGraph" />
      <DataRemovalGraphSummarySection
        v-else-if="!hideGraph"
        :rawGraphData="rawGraphData"
        :enrollmentDataFetched="enrollmentDataFetched"
      />
    </div>
    <DataRemovalSkeletonLoader v-if="actionRequiredFamilies === null" />
    <div class="section-wrapper" v-else>
      <h1 class="title">
        <span
          >Action required
          <inlineSvg
            name="fuzzy-red-dot"
            key="fuzzy-red-dot"
            class="pulsing-svg"
          /> </span
        ><span @click="showActionsRequiredInfoModal"
          ><inlineSvg
            name="more-info-q-no-size"
            key="more-info-q-no-size"
            class="help-circle"
        /></span>
      </h1>
      <div class="box-wrapper">
        <h2>
          {{
            `${actionRequiredFamilies?.length} submission${
              actionRequiredFamilies?.length === 1 ? "" : "s"
            } require${
              actionRequiredFamilies?.length === 1 ? "s" : ""
            } manual removal`
          }}
        </h2>
        <UiActionRowWrapper
          v-if="actionRequiredFamilies?.length"
          class="top-border"
        >
          <UiActionRow
            v-for="(brokerFamily, idx) in actionRequiredFamilies"
            :key="`${brokerFamily?.name}-${idx}`"
            :name="brokerFamily?.name"
            :count="brokerFamily?.brokers?.length"
            :opened="brokerFamily?.opened"
            :brokers="brokerFamily?.brokers"
            :instructions="brokerFamily?.removal_steps"
            :date="formattedDate"
            @done="showMarkDoneConfirmation(brokerFamily)"
            @toggleOpen="toggleOpenBrokerFamily(brokerFamily)"
          />
        </UiActionRowWrapper>
      </div>
    </div>

    <DataRemovalSkeletonLoader v-if="!removalLogFetched" />
    <div class="section-wrapper" v-else>
      <h1 class="title">
        <span>
          Removal requests
          <inlineSvg
            name="fuzzy-orange-dot"
            key="fuzzy-orange-dot"
            class="pulsing-svg"
          />
        </span>
        <span @click="showEnrollmentDetailsModal"
          ><inlineSvg
            name="more-info-q-no-size"
            key="more-info-q-no-size"
            class="help-circle"
        /></span>
      </h1>
      <DataRemovalRequestsSection
        :requests="nonActionItems"
        :actionRequiredCount="actionRequiredItems.length"
      />
    </div>

    <div class="section-wrapper">
      <h1 class="title">FAQ</h1>
      <DataRemovalFaqSection />
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 40px 80px;
  @media (max-width: 1024px) {
    padding: 40px 40px;
  }

  .section-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  color: $color-primary-100;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;

  span {
    svg {
      height: 15px;
      width: auto;
    }
  }

  margin-bottom: 8px;
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $color-primary-70;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  svg {
    width: 17px;
    height: auto;
  }
}

.box-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  color: $color-primary-100;
  margin-top: 16px;
  width: 100%;
  // min-width: 795px;

  .box-title {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    color: $color-primary-100;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
  }
  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    justify-content: flex-start;
  }
  h3 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-primary-70;
  }
  .progress-boxes {
    gap: 12px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .data-removal-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 78px;
      width: 100%;
      border: 1px solid $color-primary-10;
      border-radius: 12px;
      .data-removal-value {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
      }
      .data-removal-label {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.wrapper-boxes {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.error {
  color: $color-alert;
}
.text-link {
  text-decoration-line: underline;
  cursor: pointer;
}

.broker-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 11px;
  .broker-name {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $color-primary-100;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
  }

  .status-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 125px;
    text-align: right;
  }
  .action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 13px;
    svg {
      height: 15px;
      width: auto;
    }
  }
}

.scanning {
  color: $color-primary-50;
}
.in-progress {
  color: $color-in-progress;
}
.no-records {
  color: $color-lt-green;
}
.color-success {
  color: $color-success;
}

.color-blue {
  color: $color-brand-3-100;
}

.load-more-button {
  margin-top: 19px;
}

.pulsing-svg {
  animation: pulse-svg 2s infinite ease-in-out;
}

@keyframes pulse-svg {
  0% {
    transform: scale(1.3);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0.9;
  }
}
.help-circle {
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  filter: brightness(95%);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 5px 5px 0px rgba($color-primary-100-light, 0.1);
    @at-root .theme-dark & {
      box-shadow: 0px 5px 5px 0px rgba($color-primary-100-dark, 0.1);
    }
    filter: brightness(100%);
    transform: scale(1.1) translate3d(0, 0, 0);
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }
}

.top-border {
  border-top: 1px solid $color-primary-10;
  margin-top: 24px;
}
</style>
