<script setup>
// TODO: refactor shared logic using composables
import {
  computed,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  nextTick,
  watch,
  reactive,
} from "vue";

import store from "@/store";
import { useRoute } from "@/hooks";

import AppCaptcha from "@/components/AppCaptcha.vue";
import HeadlessIframe from "@/routes/guest/HeadlessIframe";
import DataDeleteService from "@/api/actions/data-delete-service";

import { stepToComponent } from "./utils";
import DataDeletePageBackground from "@/components/feature/data-delete/DataDeletePageBackground.vue";
import { SubscriptionService } from "@/api";
import DataDeletePage from "@/components/feature/data-delete/DataDeletePage.vue";
import DataDeletePageAlreadyStarted from "@/components/feature/data-delete/DataDeletePageAlreadyStarted.vue";
import FreeScanPageCloakedProtection from "@/components/feature/data-delete/FreeScanPageCloakedProtection.vue";
import { useThemeQueryParameter } from "@/composables";
import {
  useDataDeleteFormatting,
  useDataDeleteInput,
  useDataDeleteSessionStorage,
} from "@/components/feature/data-delete/composables";
import { getPosthog } from "@/scripts/posthog";

const route = useRoute();
const iframeChannel = ref(null);
const error = ref(null);
const hasSearchError = ref(false);
const step = ref(stepToComponent.StepLoader);
const searchStep = ref("initial");
const searchResults = ref([]);
const numTotalResults = ref(0);

useThemeQueryParameter();

const { dataDeleteInputForm } = useDataDeleteInput();
const { formattedPhoneNumber, formattedUserName } =
  useDataDeleteFormatting(dataDeleteInputForm);

const { clearSearchProgressFromSessionStorage } = useDataDeleteSessionStorage(
  dataDeleteInputForm,
  searchResults
);

onBeforeMount(() => {
  clearSearchProgressFromSessionStorage();

  getPosthog().then((posthog) => {
    posthog?.identify(route?.query?.vid);
    posthog?.startSessionRecording();
  });
});

onBeforeUnmount(() => {
  getPosthog().then((posthog) => {
    posthog?.stopSessionRecording();
  });
});

const statusState = reactive({
  hasAlreadyStartedDD: false,
});

async function searchPublicRecords({
  firstName,
  lastName,
  state,
  phoneNumber,
  age,
  email,
  useArray = false,
}) {
  if (!(firstName && lastName) && !phoneNumber) {
    forceNewSearch();
    return;
  }

  isFetching.value = true;
  const { data } = await DataDeleteService.getPublicRecords({
    firstName,
    lastName,
    state,
    phoneNumber,
    age,
    email,
    useArray,
  });

  if (data.in_progress) {
    statusState.hasAlreadyStartedDD = true;
    return;
  }

  if (data.hasError) {
    hasSearchError.value = true;
    step.value = stepToComponent.StepResults;
    return;
  }

  // for security reasons data.results always contains at most 1 result
  numTotalResults.value = data.numTotalResults ?? 0;

  if (data?.results?.length) {
    step.value = stepToComponent.StepLoader;
    searchComplete.value = true;
    searchResults.value = data.results;
  } else {
    searchAlternateOptions({
      firstName,
      lastName,
      state,
      phoneNumber,
      age,
      useArray,
    });
  }

  isFetching.value = false;
}

const searchAlternateOptions = ({
  firstName,
  lastName,
  state,
  age,
  useArray = false,
}) => {
  step.value = stepToComponent.StepSearch;
  if (!firstName && !lastName) {
    searchStep.value = "name";
  } else if (!age) {
    searchStep.value = "age";
  } else if (!state) {
    searchStep.value = "state";
  } else {
    step.value = stepToComponent.StepLoader;
    searchComplete.value = true;
    useArray = true;
    // todo: implement array
  }
};

const searchComplete = ref(false);
const isFetching = ref(false);
const isForcingNewSearch = ref(false);

function forceNewSearch() {
  isForcingNewSearch.value = true;
  searchComplete.value = false;
  searchResults.value = [];
  dataDeleteInputForm.value = {};
  searchAlternateOptions(dataDeleteInputForm.value);
}

function setStep(value) {
  step.value = value;
}

const hasToken = computed(
  () => !!store.state.authentication.guest?.access_token
);

watch(
  () => hasToken.value,
  (value) => {
    if (value && initialized.value) {
      nextTick(() => {
        performSearch();
        SubscriptionService.getSubscription();
      });
    }
  }
);

function performSearch() {
  searchPublicRecords({
    firstName: dataDeleteInputForm.value.firstName,
    lastName: dataDeleteInputForm.value.lastName,
    state: dataDeleteInputForm.value.state,
    age: dataDeleteInputForm.value.age,
    email: dataDeleteInputForm.value.email,
    phoneNumber: formattedPhoneNumber,
  });
}

const initialized = ref(false);
onMounted(() => {
  store.dispatch("authentication/setGuestToken", null);
  nextTick(() => {
    initialized.value = true;
  });
});

const captcha = ref({});
const showCaptcha = ref(null);

const iframe = computed(() => iframeChannel.value);

const isIframeReady = ref(false);

watch(
  [
    () => showCaptcha.value,
    () => iframe.value,
    () => captcha.value,
    () => isIframeReady.value,
  ],
  ([showCaptchaValue, iframeValue, captchaValue, isIframeReadyValue]) => {
    if (iframeValue && isIframeReadyValue) {
      if (showCaptchaValue === true) {
        if (captchaValue?.token) {
          iframeChannel.value.getAccount(captchaValue.token);
          return;
        }
        return;
      } else if (showCaptchaValue === false) {
        /* Only do this if captcha is not required (false), not null */
        iframeChannel.value.getAccount("-");
      }
    }
    return;
  }
);

function setupUser(payload) {
  iframeChannel.value.setupUser(payload);
}

const isSubscribed = computed(() => {
  return store.getters["settings/isSubscribed"];
});

function setSetup() {
  step.value = FreeScanPageCloakedProtection;
}

function setPaid() {
  step.value = stepToComponent.StepUsername;
}
function setSearchStep(value) {
  searchStep.value = value;
}

const compRef = ref(null);

function handleError(message) {
  error.value = message;
  if (message && compRef.value.hasError) {
    compRef.value.hasError();
  }
}

const headlessUser = ref(null);
</script>

<template>
  <DataDeletePage>
    <DataDeletePageBackground />
    <AppCaptcha
      v-model="captcha"
      v-if="showCaptcha === true && isIframeReady && iframe"
    />
    <DataDeletePageAlreadyStarted v-if="statusState.hasAlreadyStartedDD" />
    <Transition mode="out-in" appear v-else>
      <Component
        :is="step"
        class="data-delete__page"
        :userName="formattedUserName"
        :phone="dataDeleteInputForm.phone"
        :searchResults="searchResults"
        :is-fetching="isFetching"
        :value="dataDeleteInputForm"
        :numTotalResults="numTotalResults"
        :searchStep="searchStep"
        :isForcingNewSearch="isForcingNewSearch"
        :isFreeScan="true"
        @setStep="setStep"
        @input="dataDeleteInputForm = $event"
        @setSearchStep="setSearchStep"
        @searchPublicRecords="searchPublicRecords"
        @setup="setSetup"
        @complete="setSetup"
        @setUser="setupUser"
        :hasToken="hasToken"
        :error="error"
        :hasError="hasSearchError"
        :isSubscribed="isSubscribed"
        :headlessUser="headlessUser"
        @subscribed="setPaid"
        @signup="setPaid"
        ref="compRef"
        @force-new-search="forceNewSearch"
        :searchComplete="searchComplete"
      />
    </Transition>
    <HeadlessIframe
      ref="iframeChannel"
      @error="handleError"
      @iframe-ready="isIframeReady = true"
      @user-ready="headlessUser = $event"
      @show-captcha="showCaptcha = $event"
    />
  </DataDeletePage>
</template>
