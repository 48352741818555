<script setup>
import { useAttrs } from "vue";
import TOTPToken from "@/components/cloakDetails/TOTP/TOTPToken.vue";
import { CloakInfoRow } from "@/components/cloakDetails";
import AppTooltipIntro from "@/components/ui/AppTooltipIntro.vue";
defineEmits(["set-totp-token", "set-is-totp-intro-displayed"]);
const attrs = useAttrs();
</script>

<template>
  <AppTooltipIntro
    :value="attrs.isTotpIntroDisplayed"
    @input.self="$emit('set-is-totp-intro-displayed', $event)"
    :has-click-open="false"
    :has-click-close="false"
    :has-outside-click-close="true"
    class="totp-identifier"
  >
    <CloakInfoRow
      v-bind="attrs"
      field="one-time passcode"
      placeholder="Add one-time passcode"
      :fieldType="'totp'"
      :initialValue="attrs.totp?.value"
      :copy-value="attrs.totpToken"
      :loading="attrs.loading"
      :error="!!attrs.errors"
      :identityId="attrs.identityId"
    >
      <template #input="{ isEditable }">
        <TOTPToken
          v-if="attrs.totp?.value && !isEditable"
          :url="
            attrs.totp?.value.startsWith('otpauth://')
              ? attrs.totp?.value
              : undefined
          "
          :secret="
            attrs.totp?.value.startsWith('otpauth://')
              ? undefined
              : attrs.totp?.value
          "
          @new-token="$emit('set-totp-token', $event)"
        />
      </template>

      <template #tooltip v-if="!attrs.totp?.value">
        Type a secret key or otpauth link
      </template>
    </CloakInfoRow>
    <template #content>
      Type or paste in your one-time passcode secret key or otpauth link.<br />
      <button @click="$emit('set-is-totp-intro-displayed', false)">
        Got it
      </button>
    </template>
  </AppTooltipIntro>
</template>

<style lang="scss">
.totp-identifier {
  .app-tooltip-intro__chevron {
    top: 32px;
  }
}
</style>
