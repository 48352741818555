<script setup>
import AppFormInput from "@/components/AppFormInput.vue";
import Button from "@/components/Button.vue";
import DataDeletePrivacyPolicy from "@/components/feature/data-delete/DataDeletePrivacyPolicy.vue";
import { computed, onMounted, ref, watch } from "vue";
import inlineSvg from "@/components/InlineSvg.vue";
import {
  PH_EVENT_USER_SUBMITTED_DATA_DELETION_SUBMISSION_FORM,
  PH_EVENT_USER_VIEWED_DATA_DELETION_SUBMISSION_FORM,
  PH_EVENT_USER_UNABLE_TO_ENROLL_AGE_RESTRICTION,
} from "@/scripts/posthogEvents";
import { validation } from "@/scripts";
import {
  countryConfig,
  LAUNCHED_COUNTRIES,
} from "@/scripts/countries/countries.js";
import { UiMenu, UiMenuButton } from "@/components";
import { posthogCapture } from "@/scripts/posthog.js";
import DataDeleteAgeRestrictionModal from "@/components/feature/data-delete/DataDeleteAgeRestrictionModal.vue";
import UserService from "@/api/actions/user-service";
import { HAS_EXITED_DELETE_FLOW } from "@/scripts/userFlags";

const props = defineProps({
  request: {
    type: Object,
    required: true,
  },
});

const showAgeRestrictionModal = ref(false);

const emit = defineEmits(["update-request", "submit-request"]);

const firstName = computed({
  get() {
    return props.request.firstName?.toLowerCase();
  },
  set(value) {
    emit("update-request", { ...props.request, firstName: value });
  },
});

const lastName = computed({
  get() {
    return props.request.lastName?.toLowerCase();
  },
  set(value) {
    emit("update-request", { ...props.request, lastName: value });
  },
});

const age = computed({
  get() {
    return props.request.age;
  },
  set(value) {
    emit("update-request", { ...props.request, age: value });
  },
});

const email = computed({
  get() {
    return props.request.email;
  },
  set(value) {
    emit("update-request", { ...props.request, email: value });
  },
});

const phone = computed({
  get() {
    return props.request.phone;
  },
  set(value) {
    emit("update-request", { ...props.request, phone: value });
    phoneModified.value = true;
  },
});

const city = computed({
  get() {
    return props.request.city?.toLowerCase();
  },
  set(value) {
    emit("update-request", { ...props.request, city: value });
  },
});

const postalCode = computed({
  get() {
    return props.request.postalCode?.toUpperCase();
  },
  set(value) {
    emit("update-request", { ...props.request, postalCode: value });
  },
});

const state = computed({
  get() {
    return props.request.state;
  },
  set(value) {
    emit("update-request", { ...props.request, state: value });
  },
});

const country = computed({
  get() {
    return props.request.country;
  },
  set(value) {
    if (country.value !== value) {
      emit("update-request", { ...props.request, country: value, state: "" });
    }
  },
});

const supportedCountries = computed(() =>
  LAUNCHED_COUNTRIES.map((abbrev) => ({
    ...countryConfig[abbrev],
    value: abbrev,
  }))
);

const phoneModified = ref(false);

const isFormValid = computed(
  () =>
    firstName.value &&
    lastName.value &&
    ageValid.value &&
    email.value &&
    emailValid.value &&
    phone.value &&
    phoneValid.value &&
    city.value &&
    state.value &&
    country.value &&
    postalCode.value
);

const ageValid = computed(() => age.value >= 13);
const emailValid = computed(() => validation.email(email.value));
const phoneValid = computed(() => validation.phone(phone.value));

onMounted(() => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SUBMISSION_FORM);
});

const handleSubmit = () => {
  if (age.value >= 13 && age.value < 18) {
    showAgeRestrictionModal.value = true;
    return;
  }
  return onSubmit();
};

function continueWithoutEnrollment() {
  showAgeRestrictionModal.value = false;
  UserService.setFlag({ name: HAS_EXITED_DELETE_FLOW, value: true });
  posthogCapture(PH_EVENT_USER_UNABLE_TO_ENROLL_AGE_RESTRICTION);
}

function onSubmit() {
  emit("submit-request");
  posthogCapture(PH_EVENT_USER_SUBMITTED_DATA_DELETION_SUBMISSION_FORM);
}

const currentCountry = computed(() =>
  supportedCountries.value.find(
    (supportedCountry) => supportedCountry.value === country.value
  )
);

const currentStateOrProvince = computed(() =>
  currentCountry.value?.addressLevelOneList.find(
    (countryStateOrProvince) => countryStateOrProvince.value === state.value
  )
);

const hasAllRequiredData = ref(
  Object.values(props.request).every(
    (value) => value !== null && value !== undefined && value !== ""
  )
);
</script>

<template>
  <div class="data-remove-info-required">
    <DataDeleteAgeRestrictionModal
      v-if="showAgeRestrictionModal"
      @close="showAgeRestrictionModal = false"
      @continue="continueWithoutEnrollment"
    />
    <h1 class="data-remove__title">
      {{ hasAllRequiredData ? "Confirm your details" : "Enter your details" }}
    </h1>
    <p class="data-remove__text">
      Please complete the submission form to submit your info for deletion.
    </p>
    <fieldset class="data-remove-info-required__fieldset">
      <AppFormInput
        placeholder="First name*"
        :value="firstName"
        @input="firstName = $event"
        label="First name"
      />

      <AppFormInput
        placeholder="Last name*"
        :value="lastName"
        @input="lastName = $event"
        label="Last name"
      />

      <AppFormInput
        placeholder="Age*"
        :value="age"
        :error="!ageValid"
        @input="age = $event"
        label="Age"
      >
        <template #after-input v-if="!ageValid && !!age">
          <div class="error-message">
            Cloaked isn't intended for use by individuals under 13 years of age.
            Please reach out to
            <a href="mailto:support@cloaked.app" class="underline">support</a>
            to continue.
          </div>
        </template>
      </AppFormInput>
      <AppFormInput
        placeholder="Email address*"
        :value="email"
        @input="email = $event"
        :error="!emailValid"
        label="Email"
        type="email"
      />

      <AppFormInput
        placeholder="Phone number*"
        :value="phone"
        @input="phone = $event"
        :error="phoneModified && !phoneValid"
        label="Phone number"
      />

      <UiMenu placement="top-end" max-height="300px">
        <AppFormInput
          placeholder="Country*"
          :value="currentCountry?.name"
          :isButton="true"
          label="Country"
        >
          <template #after-input>
            <inlineSvg name="chevron-down" />
          </template>
        </AppFormInput>
        <template #content>
          <UiMenuButton
            v-for="supportedCountry in supportedCountries"
            :title="supportedCountry.name"
            @click="country = supportedCountry.value"
            :key="`${supportedCountry.name}-dd`"
          ></UiMenuButton>
        </template>
      </UiMenu>
      <UiMenu placement="top-end" max-height="300px">
        <AppFormInput
          :placeholder="`${currentCountry.addressLevelOneLabel}*`"
          :value="currentStateOrProvince?.label"
          :isButton="true"
          :label="currentCountry.addressLevelOneLabel"
        >
          <template #after-input>
            <inlineSvg name="chevron-down" />
          </template>
        </AppFormInput>
        <template #content>
          <UiMenuButton
            v-for="stateOrProvince in currentCountry.addressLevelOneList"
            :title="stateOrProvince.label"
            @click="state = stateOrProvince.value"
            :key="`${stateOrProvince.label}-dd`"
          ></UiMenuButton>
        </template>
      </UiMenu>
      <AppFormInput
        placeholder="City*"
        :value="city"
        @input="city = $event"
        label="City"
      />
      <AppFormInput
        placeholder="Postal Code*"
        :value="postalCode"
        @input="postalCode = $event"
        label="Postal Code"
      />
    </fieldset>

    <DataDeletePrivacyPolicy class="data-remove-info-required__privacy-policy">
      Cloaked protects your privacy.
    </DataDeletePrivacyPolicy>

    <div class="data-remove-info-required__cta">
      <Button
        type="danger"
        size="2xl"
        :disabled="!isFormValid"
        @click="handleSubmit"
      >
        Submit request
        <inlineSvg name="data-delete/send" />
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
.data-remove-info-required {
  padding: 24px;
  max-width: 548px;
  height: 100%;
  align-self: center;
  @media screen and (max-width: $screen-lg) {
    padding-bottom: 100px;
  }

  .data-remove__text {
    margin-top: 4px;
  }

  &__fieldset {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    border: 0;
    margin-top: 16px;
  }

  &__cta {
    width: 100%;

    padding: 16px 0;
    margin-top: 32px;
    background-color: $color-surface;

    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $screen-lg) {
      position: fixed;
      bottom: 0;
      left: 0;
      margin: 0;
      border-top: 1px solid $color-primary-20;
    }
    button {
      min-width: 100%;
      max-width: 100%;
      margin: 0;
      @media screen and (max-width: $screen-lg) {
        margin: 0;
        max-width: 500px;
        min-width: 100px;
        width: calc(100% - 48px);
      }
    }
  }

  &__privacy-policy {
    margin-top: 16px;

    .data-delete-privacy-policy__icon {
      color: $color-success;
    }
  }
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.error-message {
  color: $color-primary-100;
  font-size: 12px;
  padding: 10px 4px;
}
</style>
