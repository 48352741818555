<script setup>
import PasswordText from "@/components/ui/PasswordText.vue";
import SecretToggle from "@/components/ui/SecretToggle.vue";
import { reactive } from "vue";
const props = defineProps({
  password: {
    type: String,
    required: true,
  },
});
const state = reactive({
  isHidden: true,
});
</script>
<template>
  <div class="password-preview">
    <template v-if="props.password">
      <SecretToggle
        :value="state.isHidden"
        @input="(event) => (state.isHidden = event)"
        class="password-preview__toggle"
      />
      <PasswordText
        :password="props.password"
        :is-hidden="state.isHidden"
        class="password-preview__text"
      />
    </template>
    <template v-else> - </template>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/recursive/_mixins.scss";

.password-preview {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &__toggle {
    .secret-toggle__icon {
      padding: 10px 10px 10px 0;
    }
  }

  &__text {
    .password-text__password {
      @include line-clamp(2);
    }
  }
}
</style>
