<script setup>
import ModalTemplate from "@/components/ModalTemplate.vue";
import InlineSvg from "@/components/InlineSvg.vue";
import Button from "@/components/Button.vue";
import { posthogCapture } from "@/scripts/posthog.js";

import { ref, computed, watch } from "vue";

const emit = defineEmits(["close"]);

const props = defineProps({
  survey: {
    type: Object,
    required: true,
  },
});

const questionIndex = ref(0);
const showDoneModal = ref(false);

const currentQuestion = computed(() => {
  return props.survey?.questions[questionIndex.value];
});

function selectChoice(choice) {
  posthogCapture("survey_submitted", {
    $survey_id: props.survey?.id,
    $survey_response: choice,
  });
  questionIndex.value++;
}

watch(
  () => currentQuestion.value,
  (newVal, oldVal) => {
    if (!!oldVal && !newVal) {
      // then there are no more questions
      showDoneModal.value = true;
    }
  }
);
</script>
<template>
  <ModalTemplate
    :show="true"
    @close="emit('close')"
    :preventClose="!showDoneModal"
  >
    <template #header>
      <h1 v-if="!showDoneModal">{{ currentQuestion?.question }}</h1>
      <h1 v-else>Thank you for helping to improve Cloaked!</h1>
    </template>
    <template #body>
      <div v-if="!showDoneModal" class="body-wrapper extra-padding">
        <h3>{{ currentQuestion?.description }}</h3>

        <p
          v-for="(choice, idx) in currentQuestion?.choices"
          :key="`${idx}-${choice}`"
          class="choice"
          @click="selectChoice(choice)"
        >
          <span>{{ choice }}</span>
          <span><InlineSvg name="arrow-right" /></span>
        </p>
      </div>
      <div v-else>
        <h3>Your response has been recorded.</h3>

        <img
          src="@/assets/images/onboarding-new/completed.png"
          alt="completed icon"
          width="517"
          height="517"
          class="body-wrapper img"
        />
      </div>
    </template>

    <template #footer v-if="showDoneModal">
      <Button type="secondary" @click="emit('close')" fullWidth class="button"
        >Close</Button
      >
    </template>
  </ModalTemplate>
</template>

<style scoped lang="scss">
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0;
  &.extra-padding {
    padding-bottom: 20px;
  }
  &.img {
    margin-top: 0px;
    margin-bottom: -30px;
  }
  .choice {
    padding: 20px 16px;
    border: 1px solid $color-primary-10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background: $color-primary-10;
      transform: scale(1.02);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    span {
      svg {
        height: 16px;
        width: auto;
      }
    }
  }
}

.button {
  margin: 0;
  padding: 0;
}
</style>
