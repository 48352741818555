<template>
  <p class="signup-terms">
    By creating an account you agree to the
    <a
      href="https://www.cloaked.app/terms-of-service"
      target="_blank"
      class="signup-terms__link"
      >Terms &amp; Conditions</a
    >
    and
    <a
      href="https://www.cloaked.app/privacy-policy"
      target="_blank"
      class="signup-terms__link"
      >Privacy Policy</a
    >
  </p>
</template>

<style lang="scss">
.signup-terms {
  text-align: center;
  margin: 0 auto;
  max-width: 360px;
  color: $color-primary-100;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;

  &__link {
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
