<script setup>
import { computed } from "vue";
import inlineSvg from "@/components/InlineSvg.vue";

const props = defineProps({
  completed: {
    type: Boolean,
    required: false,
  },
  icon: {
    type: String,
    required: true,
  },
  wrapperStyleOverride: {
    type: Object,
    default: () => {},
  },
  statusText: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(["click"]);

const statusText = computed(() => {
  if (props.statusText) {
    return props.statusText;
  }
  return null;
});

const wrapperStyle = computed(() => {
  return {
    ...props.wrapperStyleOverride,
  };
});

function onClick() {
  if (!props.completed) {
    emit("click");
  }
}
</script>
<template>
  <div
    class="tile-wrapper"
    :class="{ completed: props.completed }"
    :style="wrapperStyle"
    @click="onClick"
  >
    <img
      :src="require(`@/assets/images/onboarding-new/${props.icon}.png`)"
      :alt="`${props.icon} icon`"
      width="240"
      height="160"
      class="floating-icon"
    />
    <div class="title"><slot /></div>
    <div
      class="status"
      :class="{ completed: props.completed }"
      v-if="statusText"
    >
      {{ statusText }}
      <inlineSvg name="checkmark-plain" v-if="props.completed" />
      <inlineSvg name="arrow-right" v-else />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.tile-wrapper {
  border-radius: 12px;
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  width: 343px;
  height: 149px;
  flex-shrink: 0;
  color: $color-primary-100;
  position: relative;
  overflow: hidden;
  background: rgba($color-primary-100-light, 0.1);
  box-shadow: 0px 10px 10px 0px rgba($color-primary-0-light, 0.1);
  transition: opacity 0.3s;
  &.completed {
    background: rgba($color-primary-100-light, 0.3);
  }
  @at-root .theme-dark & {
    background: rgba($color-primary-100-dark, 0.1);
    box-shadow: 0px 10px 10px 0px rgba($color-primary-0-dark, 0.1);
    &.completed {
      background: rgba($color-primary-100-dark, 0.3);
    }
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.3s;
    &.completed {
      cursor: unset;
      opacity: 1;
    }
  }
  .floating-icon {
    position: absolute;
    top: -25px;
    right: -80px;
  }
  .title {
    color: $color-primary-100;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.177px;
    max-width: 170px;
  }
  .status {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    border: 1px solid $color-primary-100;
    color: $color-primary-100;
    height: 30px;
    padding: 8px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &.completed {
      background-color: $color-surface;
      border: $color-surface;
      color: $color-primary-100;

      svg {
        height: 14px;
        color: $color-success;
      }
    }
  }
}
</style>
