export const AustralianTerritoriesList = [
  { label: "New South Wales", value: "NSW" },
  { label: "Northern Territory", value: "NT" },
  { label: "Queensland", value: "QLD" },
  { label: "Australian Capital Territory", value: "ACT" },
  { label: "South Australia", value: "SA" },
  { label: "Tasmania", value: "Tas" },
  { label: "Victoria", value: "Vic" },
  { label: "Western Australia", value: "WA" },
];
