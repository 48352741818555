<script setup>
import { defineProps, computed } from "vue";

import inlineSvg from "@/components/InlineSvg.vue";
import DataRemovalGraphShared from "@/routes/DataDeletion/components/RequestGraph/DataRemovalGraphShared.vue";
import store from "@/store";
import { MONTHS } from "@/scripts/constants";

const props = defineProps({
  rawGraphData: {
    type: Object,
    required: true,
  },
  enrollmentDataFetched: {
    type: Boolean,
    required: true,
  },
});

const nextScanDate = computed(() => {
  const nextScanDateTime =
    store.getters["dataDelete/enrollmentData"]?.nextScanDate;
  if (nextScanDateTime) {
    const nextScan = new Date(nextScanDateTime);
    return `${MONTHS[nextScan.getMonth()].slice(0, 3)} ${nextScan.getDate()}`;
  }

  return null;
});

const totalItemsRemoved = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.totalItemsRemoved ?? 0;
});

const totalItemsRemovedToday = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.totalItemsRemovedToday ?? 0
  );
});

function openRecordsRemovedModal() {
  store.dispatch("openModal", {
    header: "Total Records Removed",
    paragraphs: [
      "A record can be your name, email address(es), phone number(s), address(es), or a relative(s). We submit requests to have this data removed on your behalf. Some data brokers may have more than one profile on you with multiple records within each.",
      "Cloaked calculates your individual pieces of information found on a data broker to display your Total Records Removed number.",
    ],
    showCancel: false,
    button: {
      text: "Close",
      onClick: () => store.dispatch("closeModal"),
    },
  });
}

function openNextScanModal() {
  store.dispatch("openModal", {
    header: "Next scan",
    paragraphs: [
      "Cloaked continually monitors your data across 129 data brokers and automatically submits requests to remove them on your behalf.",
      "This process is ongoing and restarts every 30 days.",
    ],
    showCancel: false,
    button: {
      text: "Close",
      onClick: () => store.dispatch("closeModal"),
    },
  });
}
</script>

<template>
  <div class="box-wrapper" v-show="props.enrollmentDataFetched">
    <div class="data-summary">
      <div class="data-removal-graph-data-container">
        <div class="total-records-number-container">
          <span class="total-number data-removal-graph-summary-large-label">{{
            totalItemsRemoved
          }}</span>
          <span class="total-number-changed" v-if="totalItemsRemovedToday > 0"
            >+{{ totalItemsRemovedToday }} Today</span
          >
        </div>
        <div class="data-removal-graph-data-label-container">
          <span class="data-removal-graph-data-label"
            >Total records removed</span
          >
          <inlineSvg
            name="question-mark-circle"
            class="text-link"
            @click="openRecordsRemovedModal"
          />
        </div>
      </div>
      <div class="data-removal-graph-data-container" v-if="nextScanDate">
        <span class="data-removal-graph-summary-large-label">{{
          nextScanDate
        }}</span>
        <div class="data-removal-graph-data-label-container">
          <span class="data-removal-graph-data-label">Next Scan</span>
          <inlineSvg
            name="question-mark-circle"
            class="text-link"
            @click="openNextScanModal"
          />
        </div>
      </div>
    </div>
    <DataRemovalGraphShared :rawGraphData="props.rawGraphData" />
  </div>
</template>

<style scoped lang="scss">
.box-wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  color: $color-primary-100;
  margin-top: 16px;
  width: 100%;
}

.data-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.data-removal-graph-data-container {
  display: flex;
  flex: 50%;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  @media (max-width: 768px) {
    align-items: center;
  }
}

.total-records-number-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: top;
  gap: 8px;
}

.data-removal-graph-summary-large-label {
  font-size: 50px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.5px;
}

.total-number {
  color: $color-success;
}

.total-number-changed {
  font-size: 13px;
  font-weight: 600;
  color: $color-primary-0;
  background-color: $color-success;
  border-radius: 49px;
  padding: 2px 6px;
  text-align: center;
}

.data-removal-graph-data-label-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  svg {
    filter: brightness(95%);
    cursor: pointer;

    &:hover {
      box-shadow: 0px 5px 5px 0px rgba($color-primary-100-light, 0.1);
      @at-root .theme-dark & {
        box-shadow: 0px 5px 5px 0px rgba($color-primary-100-dark, 0.1);
      }
      filter: brightness(100%);
      transform: scale(1.1) translate3d(0, 0, 0);
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    }
  }
}

.data-removal-graph-data-label {
  font-size: 12px;
  color: $color-primary-70;
}
</style>
