import { computed } from "vue";
import { BILLING_CYCLE } from "@/composables/useSubscriptionPlans";
import { useFormattedPrice } from "@/composables/useFormattedPrice";

export const useDiscount = (plan, paymentIntent) => {
  // prices in intents are strings
  const originalPrice = computed(() =>
    paymentIntent.value?.original_price
      ? parseInt(paymentIntent.value.original_price)
      : null
  );

  // prices in intents are strings
  const discountedPrice = computed(() =>
    paymentIntent.value?.price ? parseInt(paymentIntent.value.price) : null
  );

  const hasDiscount = computed(
    () => discountedPrice.value < originalPrice.value
  );

  const discountedMonthlyPrice = computed(() =>
    hasDiscount.value
      ? plan.value?.recurring_interval === BILLING_CYCLE.ANNUAL
        ? useFormattedPrice(discountedPrice.value / 12)
        : useFormattedPrice(discountedPrice)
      : null
  );

  const discountedTotalPrice = computed(() =>
    hasDiscount.value ? useFormattedPrice(discountedPrice) : null
  );

  const discountAmount = computed(() =>
    Math.max(0, discountedPrice.value ?? 0 - (originalPrice.value ?? 0))
  );

  const discountedMonthlyAmount = computed(() =>
    hasDiscount.value
      ? plan.value?.recurring_interval === BILLING_CYCLE.ANNUAL
        ? useFormattedPrice(discountAmount.value / 12)
        : useFormattedPrice(discountAmount)
      : null
  );

  const discountedTotalAmount = computed(() =>
    hasDiscount.value ? useFormattedPrice(discountAmount) : null
  );

  return {
    discountedMonthlyPrice,
    discountedTotalPrice,
    discountedMonthlyAmount,
    discountedTotalAmount,
  };
};
