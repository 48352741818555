<script setup>
import {
  useBillingCycle,
  usePlanOptions,
  usePlans,
} from "@/components/feature/subscribe/composables";
import ChoosePlanCta from "@/components/feature/subscribe/ChoosePlanCta.vue";
import Button from "@/components/Button.vue";
import SubscribeInputPromo from "@/components/feature/subscribe/SubscribeInputPromo.vue";
import InlineSvg from "@/components/InlineSvg.vue";
import {
  useDiscount,
  usePaymentIntent,
  usePaymentProviderPaypal,
  usePaymentProviderStripe,
  usePromoCode,
} from "@/composables";
import { ref, watch } from "vue";
import { useToast } from "@/hooks";
import store from "@/store";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  intent: {
    type: String,
    default: "subscribe",
  },
  default: {
    type: String,
    default: null,
    validator: (value) => ["individual", "couple", "family"].includes(value),
  },
});

const { activePlan, subscribe } = usePlans();

const { selectedBillingCycle } = useBillingCycle("annually");

const { selectedPlanOption, selectedPlan, selectedPaypalPlan } = usePlanOptions(
  {
    intent: props.intent,
    selectedBillingCycle,
    defaultSelectedOption: props.default,
  }
);

const {
  isValidatingPromoCode,
  promoCodeInput,
  promoCodeOffer,
  promoCodeError,
  validatePromoCode,
} = usePromoCode(selectedPlan);

const { paymentIntent, isLoadingIntent } = usePaymentIntent(
  selectedPlan,
  promoCodeOffer
);

const { discountedTotalPrice } = useDiscount(selectedPlan, paymentIntent);

const emit = defineEmits(["subscribed", "cancelled"]);

const { success, error } = useToast();

const onSubscribed = (plan) => {
  if (plan) {
    success(`You are now on Cloaked ${plan.displayName} plan`);
  }

  // uncomment once BE fixes switching between subscriptions
  // store.commit("subscription/SET_INTENTS", []);

  emit("subscribed", plan);
};

const {
  stripeRef,
  stripeError,
  isProcessingStripePayment,
  subscribeWithStripe,
} = usePaymentProviderStripe(selectedPlan, onSubscribed, paymentIntent);

const { paypalRef, paypalError, updateUser } = usePaymentProviderPaypal(
  selectedPaypalPlan,
  onSubscribed,
  props.user,
  false
);

watch(
  () => props.user,
  (newUser) => {
    updateUser(newUser);
  }
);

const isCancellingSubscription = ref(false);

const onChoosePlan = async (option) => {
  if (option.type === "plan") {
    await subscribeWithStripe();
    await store.dispatch("subscription/fetchInvitations");
    return;
  }

  if (option.type === "cancel") {
    try {
      isCancellingSubscription.value = true;
      await store.dispatch("subscription/cancelSubscription");
      success("You are now on a limited plan");
      emit("cancelled");
    } catch {
      error("Failed to cancel subscription");
    } finally {
      isCancellingSubscription.value = false;
    }
  }
};

const isPromoCodeInputVisible = ref(false);
</script>

<template>
  <div class="choose-plan-payment" v-show="selectedPlanOption?.type === 'plan'">
    <section
      class="choose-plan-payment__express"
      v-show="selectedPlanOption?.paypalPlan"
    >
      <div class="choose-plan-payment__express-title">
        Express checkout • ${{
          selectedPlanOption?.paypalPlan?.displayTotalPrice
        }}
      </div>
      <div ref="paypalRef" />
      <p v-if="paypalError" class="choose-plan-payment__error">
        {{ paypalError }}
      </p>
    </section>

    <section class="choose-plan-payment__section">
      <header class="choose-plan-payment__section-header">
        <div class="choose-plan-payment__section-title">
          {{
            selectedPlanOption?.paypalPlan
              ? "Or enter payment info"
              : "Enter payment info"
          }}
        </div>
        <button
          class="choose-plan-payment__promo-toggle"
          @click="isPromoCodeInputVisible = !isPromoCodeInputVisible"
        >
          Promo code?
        </button>
      </header>
      <div
        v-show="isPromoCodeInputVisible"
        class="choose-plan-payment__promo-code"
      >
        <h3 class="choose-plan-payment__promo-code-heading">Promo code</h3>
        <SubscribeInputPromo
          v-model="promoCodeInput"
          @validate="validatePromoCode"
          :is-valid="!!promoCodeOffer && promoCodeInput === promoCodeOffer.code"
          :is-validating="
            isValidatingPromoCode || (promoCodeOffer && isLoadingIntent)
          "
          :error="promoCodeError"
          class="choose-plan-payment__promo-code-input"
        />
      </div>
      <div ref="stripeRef" class="choose-plan-payment__stripe-form" />
      <p v-if="stripeError" class="choose-plan-payment__error">
        {{ stripeError }}
      </p>
      <p class="choose-plan-payment__stripe-protected">
        Checkout protected by <InlineSvg name="stripe-logo" />
      </p>
    </section>
    <ChoosePlanCta
      v-if="selectedPlanOption"
      :option="selectedPlanOption"
      :price="
        discountedTotalPrice ?? selectedPlanOption.stripePlan?.displayTotalPrice
      "
      :has-plan="!!activePlan"
      :is-loading="isProcessingStripePayment || isCancellingSubscription"
      @choose-plan="onChoosePlan"
      class="choose-plan-payment__cta"
    />
  </div>
</template>

<style lang="scss">
.choose-plan-payment {
  &__express {
    border-radius: 16px;
    border: 1px solid $color-primary-10;
    padding: 16px;
    margin-bottom: 32px;

    @media all and (min-width: $screen-lg) {
      padding: 24px 16px;
    }

    &-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: center;
      margin-bottom: 16px;
    }
  }

  &__section {
    display: none;
    flex-direction: column;
    overflow: hidden;

    &:has(iframe) {
      display: flex;
    }

    &-header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 16px;
    }

    &-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }

  &__promo-toggle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    color: $color-primary-100;

    &:hover {
      opacity: 0.8;
    }
  }

  &__promo-code {
    margin: 0 2px 12px;

    &-heading {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.15;
    }

    &-input {
      margin: 4px 0 0;
      display: block;
    }
  }

  &__stripe {
    &-form {
      margin: 0 2px;
    }

    &-protected {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
    }
  }

  &__error {
    color: $color-alert;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  &__cta {
    width: 100%;
    margin-top: 16px;
  }
}
</style>
