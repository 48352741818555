<script setup>
import { ref } from "vue";
import ModalTemplate from "@/components/ModalTemplate";
import UiSwitchInput from "@/components/UiSwitchInput";
import CardsServices from "@/api/actions/cards-services";

const props = defineProps({
  source: {
    type: Object,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const emit = defineEmits(["close"]);

function submitForm() {
  const payload = {
    auto_debit: autopay.value,
  };
  CardsServices.patchUpdateCardDetails(props.source.id, payload).then(() => {
    emit("close");
  });
}

const autopay = ref(true);

function changeToggle() {
  autopay.value = !autopay.value;
}
</script>

<template>
  <ModalTemplate :show="props.show" no-close width="375px">
    <template #body>
      <div class="modal-content">
        <div class="header">
          <h1>AutoPay enrollment</h1>
          <p>
            Automatically process Cloaked Pay transactions from this selected
            funding source by enrolling in AutoPay.
          </p>
        </div>

        <div class="detail-section">
          <span>AutoPay</span>
          <UiSwitchInput :value="autopay" @change="changeToggle()" />
        </div>

        <p class="terms" v-if="autopay === false">
          By electing not to AutoPay, all amounts owed must be paid for
          continued use of Cloaked Pay. Outstanding virtual card balances must
          be settled by the end of your payment cycle.
        </p>

        <div class="footer">
          <button @click="submitForm()">Save</button>
          <a
            href="https://assets.website-files.com/63ec0f977f0357126ec38bcd/65739549713435a950a7520d_AutoPay%20Authorization%20Terms.pdf"
            target="_blank"
            >AutoPay Terms &amp; Conditions</a
          >
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<style lang="scss" scoped>
.modal-content {
  padding: 38px 0px;

  .header {
    margin-bottom: 24px;
    text-align: center;

    h1 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
      color: $color-primary-100;
    }

    p {
      margin-top: 8px;
      color: $color-primary-70;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
    }
  }

  .detail-section {
    border: 1px solid $color-primary-10;
    border-radius: 12px;
    padding: 24px 16px;
    position: relative;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color-primary-100;
    }

    .ui-switch-input {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .terms {
    color: $color-primary-70;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 8px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 24px;
    width: 100%;

    button {
      width: 100%;
      background-color: $color-primary-100;
      color: $color-primary-0;
      padding: 11px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: 0;
      border-radius: 40px;

      &:hover {
        background-color: $color-primary-90;
        cursor: pointer;
      }
    }

    a {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      text-decoration-line: underline;
      display: inline-block;
      margin-top: 16px;
    }
  }
}
</style>
