<script setup>
import {
  CheckActive,
  CheckInactive,
  CheckSquareActive,
  CheckSquareInactive,
} from "@/assets/icons";

const emit = defineEmits(["input"]);

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  square: {
    type: Boolean,
    default: false,
  },
});

function handleChange() {
  emit("input", !props.value);
}
</script>

<template>
  <label
    class="check-button"
    :class="{
      'check-button--checked': !!props.value,
      'check-button--disabled': props.disabled,
      'check-button--square': props.square,
    }"
    @click.stop="() => {}"
  >
    <input
      type="checkbox"
      :checked="!!props.value"
      :disabled="props.disabled"
      @change="handleChange"
    />

    <template v-if="!props.square">
      <CheckActive v-if="!!props.value" />
      <CheckInactive v-else />
    </template>

    <template v-if="props.square">
      <CheckSquareActive v-if="!!props.value" />
      <CheckSquareInactive v-else />
    </template>
  </label>
</template>

<style scoped lang="scss">
.check-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  color: $color-primary-30;
  display: inline-block;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
  }

  input {
    visibility: hidden;

    &:checked {
      + ::before {
        border-color: currentColor;
      }

      + ::after {
      }
    }
  }

  &--checked {
    color: $color-primary-100;
  }

  &--disabled {
    color: $color-primary-90;
  }

  &--square {
    width: 19px;
    height: 19px;
  }

  &:not(&--disabled):hover {
    cursor: pointer;
  }
}
</style>
