<script setup>
import { computed, onMounted, watch, onBeforeMount } from "vue";

import store from "@/store";
import { useRoute } from "@/hooks";
import Upgrade from "@/routes/guest/Upgrade";
import Boot from "@/routes/Boot";
import Guest from "@/routes/Guest";
import Shared from "@/routes/Shared";
import { auth_channel, refresh_channel, logout } from "@/scripts/actions/auth";
import { Toast } from "@/components";

const route = useRoute();

const isOldExtension = computed(() => {
  return route?.query?.code;
});
const authenticated = computed(() => {
  return store.getters["authentication/isAuthenticated"];
});
const auth_route = computed(() => {
  return Boolean(route.path.match(/auth/gi));
});

const isExtension = computed(() => {
  return route.query.cloaked_client_id;
});

const share = computed(() => {
  return route.path.match(/share/gi);
});

const darkMode = computed(() => {
  return store.getters["colorScheme/isDarkMode"];
});

onBeforeMount(() => {
  store.dispatch("colorScheme/detectColorScheme");
});

onMounted(() => {
  const url = new URL(window.location.href);
  if (url.searchParams.get("isExtensionCommsDummy")) {
    return;
  }
  auth_channel.onmessage = () => {
    logout(true);
  };
  refresh_channel.onmessage = () => {
    window.location.reload();
  };
});

watch(
  () => [darkMode.value, auth_route.value],
  ([isDarkMode, isAuthRoute]) => {
    /* Auth pages do not support dark mode */
    if (isAuthRoute) {
      if (document.body?.classList?.contains("theme-dark")) {
        document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-light");
        return;
      } else if (!document.body?.classList?.contains("theme-light")) {
        document.body.classList.add("theme-light");
        return;
      }
    }
    const newDarkModeClass = isDarkMode ? "theme-dark" : "theme-light";
    const oldDarkModeClass = isDarkMode ? "theme-light" : "theme-dark";
    document.body.classList.remove(oldDarkModeClass);
    document.body.classList.add(newDarkModeClass);
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <div>
    <Upgrade v-if="isOldExtension" />
    <Shared v-else-if="share" />
    <Guest v-else-if="!authenticated || isExtension" />
    <Boot v-else />
    <Toast />
    <div id="root" />
    <div id="modals" />
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
