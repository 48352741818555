<script setup>
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const onToggle = () => {
  const currentOptionIndex = props.options.findIndex(
    (option) => option.id === props.modelValue
  );
  const nextOptionIndex =
    currentOptionIndex < props.options.length - 1 ? currentOptionIndex + 1 : 0;
  emit("update:modelValue", props.options[nextOptionIndex].id);
};
</script>

<template>
  <div class="button-toggle" @click="onToggle">
    <button
      v-for="option in options"
      :key="option.id"
      class="button-toggle__option"
      :class="{ 'button-toggle__option--selected': modelValue === option.id }"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<style lang="scss">
.button-toggle {
  border: 1px solid $color-primary-10;
  border-radius: 100px;
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  &__option {
    background: none;
    border: none;
    color: $color-primary-100;
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 100px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    flex-grow: 1;

    @at-root .button-toggle:hover & {
      opacity: 0.9;
    }

    &:nth-child(2) {
      margin-left: -4px;
    }

    &--selected {
      color: $color-primary-0;
      background-color: $color-primary-100;
    }
  }
}
</style>
