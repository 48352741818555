<script setup>
import { onBeforeMount, reactive } from "vue";
import InboxService from "@/api/actions/inbox-service";
import router from "@/routes/router";
import { inbox as inboxScripts, constants, formattedText } from "@/scripts";
import store from "@/store";
import inlineSvg from "@/components/InlineSvg.vue";
import IdentityIcon from "@/components/ui/IdentityIcon";
import HomeInboxLoadingSkeleton from "@/components/home/HomeInboxLoadingSkeleton.vue";
import { posthogCapture } from "@/scripts/posthog.js";

const state = reactive({
  activity: [],
  loaded: false,
});

onBeforeMount(() => {
  const allInboxParams = {
    contact_status: "approved",
    muted: false,
    page_size: 5,
    page: 1,
  };
  const useCache = false;
  InboxService.getInbox(allInboxParams, useCache)
    .then((response) => {
      state.activity = response?.data?.results;
      state.loaded = true;
    })
    .catch(() => {
      state.loaded = true;
    });
});

function getIdentity(activity) {
  const id = parseInt(activity.identity.match(/\/(\d+)\/$/)[1], 10);
  const identity = store.state.localdb.db_cloaks.find((i) => i.id === id);
  return identity;
}

function getIdentityName(activity) {
  const identity = getIdentity(activity);
  return formattedText.getFormattedNickname(identity);
}

function openThread(activity) {
  const activityType = activity.email ? "emails" : "messages";
  posthogCapture("user_clicked_homepageinboxactivity");
  router.push({ path: `/inbox/${activityType}/${activity.thread_id}/` });
}

function timestamp(updatedAt) {
  const small = true;
  return inboxScripts.getTimeDisplayInboxList(updatedAt, small);
}

function getActivityType(activity) {
  if (activity.message) {
    return constants.INBOX_TYPES.MESSAGE;
  }
  if (activity.call) {
    return constants.INBOX_TYPES.CALL;
  }
  return constants.INBOX_TYPES.EMAIL;
}

function isRead(activity) {
  const activityType = getActivityType(activity);
  if (activityType === constants.INBOX_TYPES.EMAIL) {
    return activity.email.read;
  }
  if (activityType === constants.INBOX_TYPES.MESSAGE) {
    return activity.message.read;
  }
  if (activityType === constants.INBOX_TYPES.CALL) {
    return activity.call.read;
  }
  return false;
}

function getIcon(activity) {
  const activityType = getActivityType(activity);
  let iconType = "phone";
  if (activityType === constants.INBOX_TYPES.EMAIL) {
    iconType = "mail";
  } else if (activityType === constants.INBOX_TYPES.MESSAGE) {
    iconType = "text";
  }
  const iconState = isRead(activity) ? "outline" : "filled";
  return `${iconType}-${iconState}`;
}

function getName(activity) {
  const activityType = getActivityType(activity);
  if (activityType === constants.INBOX_TYPES.EMAIL) {
    const senderName = inboxScripts.getFullContactNameStr(
      activity?.email?.sender_contact
    );
    return (
      (senderName.length && senderName) ||
      activity?.email?.sender_name ||
      getIdentityName(activity)
    );
  } else if (activityType === constants.INBOX_TYPES.CALL) {
    const senderName = inboxScripts.getFullContactNameStr(
      activity?.call?.sender_contact
    );
    return (
      (senderName.length && senderName) ||
      activity?.call?.sender_name ||
      getIdentityName(activity)
    );
  } else if (activityType === constants.INBOX_TYPES.MESSAGE) {
    const senderName = inboxScripts.getFullContactNameStr(
      activity?.message?.sender_contact
    );
    return (
      (senderName.length && senderName) ||
      activity?.message?.sender_name ||
      getIdentityName(activity)
    );
  }
  return getIdentityName(activity);
}

function getTitle(activity) {
  const activityType = getActivityType(activity);
  if (activityType === constants.INBOX_TYPES.EMAIL) {
    return activity.email.subject || "(no subject)";
  }
  if (activityType === constants.INBOX_TYPES.MESSAGE) {
    return activity.message.body || "(no message)";
  }
  if (activityType === constants.INBOX_TYPES.CALL) {
    return activity.call.sender_contact?.location || "Unknown";
  }
  return null;
}

function openInbox() {
  router.push({ name: "Inbox" });
}
</script>
<template>
  <section class="inbox">
    <div class="title-row">
      <h1 class="title">Latest Messages</h1>
      <div
        @click="openInbox"
        class="link"
        v-if="state.loaded && state.activity?.length"
      >
        See all
      </div>
    </div>
    <div class="inbox-wrapper" v-if="state.loaded && state.activity?.length">
      <div
        class="inbox-item-wrapper"
        v-for="activity in state.activity"
        :key="activity.thread_id"
        @click="openThread(activity)"
      >
        <div class="inbox-item">
          <div class="top-row">
            <IdentityIcon
              :identity="getIdentity(activity)"
              :override="{
                width: '16px',
                height: '16px',
                'align-self': 'center',
              }"
              :noGradient="true"
            />
            <div class="name" :class="{ bold: !isRead(activity) }">
              {{ getName(activity) }}
            </div>
            <div class="row-footer">
              <div class="timestamp" :class="{ bold: !isRead(activity) }">
                {{ timestamp(activity.created_at) }}
              </div>
              <inlineSvg
                :class="{ bold: !isRead(activity) }"
                :name="getIcon(activity)"
              />
            </div>
          </div>
          <div class="title">{{ getTitle(activity) }}</div>
        </div>
      </div>
    </div>

    <div
      class="inbox-empty"
      v-else-if="state.loaded && !state.activity?.length"
    >
      <inlineSvg name="inbox" />
      <h3>No messages</h3>
    </div>
    <div class="inbox-loading" v-else>
      <HomeInboxLoadingSkeleton />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  color: $color-primary-100;

  .title {
    font-size: 24px;
    font-style: normal;
    color: $color-primary-100;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  .link {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    text-decoration-line: underline;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      opacity: 0.7;
      transform: scale(1.01);
    }
  }
}

.inbox-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid $color-primary-10;
  width: 100%;
  overflow: hidden;
  .inbox-item-wrapper {
    width: 100%;
    height: auto;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    border-bottom: 1px solid $color-primary-10;
    overflow: hidden;

    .inbox-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 12px;
      width: 100%;
      height: auto;
      gap: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        box-shadow: 0px 0px 28px 0px $color-box-shadow;
        transform: scale(1.01) translate3d(0, 0, 0);
        transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
        z-index: 2;
      }

      .top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 8px;
        .name {
          flex-grow: 1;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.1px;
          color: $color-primary-70;
          flex-shrink: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.bold {
            font-weight: 600;
            color: $color-primary-100;
          }
        }
        .row-footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;
          .timestamp {
            font-size: 10px;
            font-style: normal;
            line-height: normal;
            font-weight: 400;
            color: $color-primary-70;
            width: 50px;
            text-align: right;
            margin-right: 4px;
            &.bold {
              font-weight: 600;
              color: $color-primary-100;
            }
          }
          > svg {
            color: $color-primary-70;
            height: 12px;
            width: 12px;
            &.bold {
              color: $color-primary-100;
            }
          }
        }
      }
      .title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $color-primary-70;
        margin-left: 24px;
        width: calc(100% - 24px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.inbox-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 324px;
  border-radius: 15px;
  border: 1px solid $color-primary-10;
  color: $color-primary-50;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}
</style>
