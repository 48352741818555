<script setup>
import { computed, ref, watch, nextTick } from "vue";
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import DataDeleteInput from "@/components/feature/data-delete/atoms/DataDeleteInput.vue";
import DataDeleteInputPassword from "@/components/feature/data-delete/atoms/DataDeleteInputPassword.vue";
import InputValidationError from "@/components/InputValidationError.vue";
import DataDeleteSignupTerms from "@/components/feature/data-delete/atoms/DataDeleteSignupTerms.vue";
import { debounce } from "lodash-es";
import { email as isEmail } from "@/scripts/validation";

const props = defineProps({ error: Object, defaultCredentials: Object });
const emit = defineEmits(["setUser"]);
defineExpose({
  hasError,
});
function update() {
  isSubmitting.value = true;
  nextTick(() => {
    emit("setUser", {
      username: form.value.email,
      password: form.value.password,
      email: form.value.email,
    });
  });
}

function hasError() {
  isSubmitting.value = false;
}

const FORM_VALIDATION_TIMEOUT = 500;

const form = ref({
  email: props.defaultCredentials?.email ?? null,
  password: null,
  passwordConfirm: null,
});

const formErrors = ref({
  email: null,
  password: null,
  passwordConfirm: null,
});

const isSubmitting = ref(false);
const isValidationPending = ref(false);
const isFormValid = computed(
  () =>
    !isValidationPending.value &&
    !formErrors.value.email &&
    !formErrors.value.password &&
    !formErrors.value.passwordConfirm &&
    form.value.email &&
    form.value.password &&
    form.value.passwordConfirm
);

const validatePassword = debounce(() => {
  const password = form.value.password;

  if (!password?.length) {
    return (formErrors.value.password = null);
  }

  if (!(password.length > 11 && password.length <= 256)) {
    return (formErrors.value.password =
      "Password must contain between 12 and 256 characters");
  }

  if (!password.match(/[A-Z]+/)) {
    return (formErrors.value.password =
      "Password must contain at least 1 uppercase letter");
  }

  if (!password.match(/[a-z]/)) {
    return (formErrors.value.password =
      "Password must contain at least 1 lowercase letter");
  }

  if (!password.match(/[0-9]/)) {
    return (formErrors.value.password =
      "Password must contain at least 1 number");
  }

  if (!password.match(/[` !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/)) {
    return (formErrors.value.password =
      "Password must contain at least 1 special character");
  }

  formErrors.value.password = null;
}, FORM_VALIDATION_TIMEOUT);

const validatePasswordConfirm = debounce(() => {
  const passwordConfirm = form.value.passwordConfirm;

  if (!passwordConfirm?.length) {
    return (formErrors.value.passwordConfirm = null);
  }

  if (passwordConfirm !== form.value.password) {
    return (formErrors.value.passwordConfirm = "Passwords do not match");
  }

  formErrors.value.passwordConfirm = null;
}, FORM_VALIDATION_TIMEOUT);

const validateEmail = debounce(() => {
  const email = form.value.email;

  if (!email?.length) {
    return (formErrors.value.email = null);
  }

  if (!isEmail(email)) {
    return (formErrors.value.email = "Please, provide a valid email address");
  }

  formErrors.value.email = null;
}, FORM_VALIDATION_TIMEOUT);

const validateForm = debounce(() => {
  isValidationPending.value = false;
}, FORM_VALIDATION_TIMEOUT);

watch(
  () => form.value,
  () => {
    isValidationPending.value = true;
    validateForm();
  },
  { deep: true }
);
watch(() => form.value.email, validateEmail);
watch(
  () => form.value.password,
  () => {
    validatePassword();
    validatePasswordConfirm();
  }
);
watch(() => form.value.passwordConfirm, validatePasswordConfirm);

const usernameError = computed(() => {
  if (props.error && props.error.type === "username") {
    return props.error.message;
  }
  return null;
});
</script>

<template>
  <div class="data-delete-username theme-dark">
    <h1 class="data-delete__title data-delete-username__title">
      Let’s set up your Cloaked account
    </h1>
    <div class="data-delete-username__form">
      <DataDeleteInput
        v-model="form.email"
        placeholder="Enter your email address"
        :disabled="isSubmitting"
        :error="formErrors.email"
        type="email"
        autocomplete="email"
      >
        <template #after>
          <InputValidationError v-if="formErrors.email || usernameError">
            {{ formErrors.email || usernameError }}
          </InputValidationError>
        </template>
      </DataDeleteInput>
      <DataDeleteInputPassword
        v-model="form.password"
        placeholder="Master Password"
        :disabled="isSubmitting"
        :error="formErrors.password"
        autocomplete="new-password"
      >
        <template #after>
          <InputValidationError v-if="formErrors.password">
            {{ formErrors.password }}
          </InputValidationError>
        </template>
      </DataDeleteInputPassword>
      <DataDeleteInputPassword
        v-model="form.passwordConfirm"
        placeholder="Confirm Password"
        :disabled="isSubmitting"
        :error="formErrors.passwordConfirm"
        autocomplete="new-password"
      >
        <template #after>
          <InputValidationError v-if="formErrors.passwordConfirm">
            {{ formErrors.passwordConfirm }}
          </InputValidationError>
        </template>
      </DataDeleteInputPassword>
    </div>
    <div class="data-delete-username__cta-wrapper">
      <Button
        type="hero-green"
        @click="update"
        size="2xl"
        :disabled="isSubmitting || !isFormValid"
        class="data-delete-username__cta"
      >
        <template v-if="isSubmitting">
          Creating account
          <inlineSvg name="spinner" key="spinner" />
        </template>
        <template v-else>
          Create account
          <inlineSvg name="arrow-right" key="arrow-right" />
        </template>
      </Button>
    </div>
    <DataDeleteSignupTerms class="data-delete-username__terms" />
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/animations";

.data-delete-username {
  color: $color-primary-100;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 560px;
  margin: 0 auto;

  @media all and (min-width: $screen-lg) {
    margin-top: clamp(50px, 5vh, 250px);
  }

  &__title {
    opacity: 0;
    animation: appear-bottom-5 0.4s forwards ease-out;
  }

  &__form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > * {
      opacity: 0;
      animation: appear-bottom-5 0.3s forwards ease-out;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.05}s;
        }
      }
    }
  }

  &__cta {
    margin-top: 8px;
    width: 100%;

    &-wrapper {
      opacity: 0;
      animation: appear-bottom-5 0.5s 0.1s forwards ease-out;
    }
  }

  &__terms {
    opacity: 0;
    animation: appear-bottom-5 0.5s 0.2s forwards ease-out;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__spinner {
    animation: spin 0.4s infinite linear;
    transform-origin: center center;
  }
}
</style>
