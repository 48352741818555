export default {
  namespaced: true,
  state: {
    browserSupported: false,
    pluginDetected: false,
  },

  mutations: {
    setBrowserSupported(state, status) {
      state.browserSupported = status;
    },
    setPluginDetected(state, status) {
      state.pluginDetected = status;
    },
  },
  getters: {
    browserSupported: (state) => state.browserSupported,
    pluginDetected: (state) => state.pluginDetected,
  },
  actions: {
    saveBrowserSupported({ commit }, status) {
      commit("setBrowserSupported", status);
    },
    savePluginDetected({ commit }, remove) {
      commit("setPluginDetected", !remove);
    },
  },
};
