<script setup>
import HomeRecent from "@/components/home/HomeRecent.vue";
import HomeActions from "@/components/home/HomeActions.vue";
import HomeInbox from "@/components/home/HomeInbox.vue";
import HomeBreaches from "@/components/home/HomeBreaches.vue";
import HomeIdentities from "@/components/home/HomeIdentities.vue";
import HomeQuickLinks from "@/components/home/HomeQuickLinks.vue";
import HomeDataRemoval from "@/components/home/HomeDataRemoval.vue";
import HomeDownloadMobile from "@/components/home/HomeDownloadMobile.vue";
import HomeNewOnboarding from "@/components/home/HomeNewOnboarding.vue";
import { onMounted, ref, onBeforeUnmount, computed } from "vue";
import store from "@/store";
import {
  COMPLETED_DD_ONBOARDING_PHONE_EMAIL,
  COMPLETED_DD_ONBOARDING_DATA_BREACHES,
  COMPLETED_DD_ONBOARDING_PASSWORD_GENERATOR,
} from "@/scripts/userFlags";
import UserService from "@/api/actions/user-service";
import { PH_FEATURE_ENABLE_BREACHES_FLOW_ENABLED } from "@/scripts/posthogEvents";

const SCREEN_SIZE_MEDIA = 1200;
const currentScreenSize = ref(window.innerWidth);

const isLargeScreen = computed(() => {
  return currentScreenSize.value > SCREEN_SIZE_MEDIA;
});

onMounted(async () => {
  try {
    await UserService.getNewOnboardingFlags();
  } catch (e) {}
  window.addEventListener("resize", onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

const showNewOnboarding = computed(() => {
  return store.state.authentication?.user?.flags
    ?.show_dd_post_payment_onboarding;
});

const ddOnboardingEnabled = computed(() => {
  return store.getters["dataDelete/getDdOnboardingEnabled"];
});

const phoneAndEmailCompleted = computed(() => {
  return store.getters.getNewOnboardingFlag(
    COMPLETED_DD_ONBOARDING_PHONE_EMAIL
  );
});

const dataBreachesFlowEnabled = computed(() => {
  return (
    store.state.authentication?.user?.flags &&
    store.state.authentication?.user?.flags[
      PH_FEATURE_ENABLE_BREACHES_FLOW_ENABLED
    ]
  );
});

const dataBreachesCompleted = computed(() => {
  if (dataBreachesFlowEnabled.value) {
    return store.getters.getNewOnboardingFlag(
      COMPLETED_DD_ONBOARDING_DATA_BREACHES
    );
  }
  return true;
});
const passwordCompleted = computed(() => {
  return store.getters.getNewOnboardingFlag(
    COMPLETED_DD_ONBOARDING_PASSWORD_GENERATOR
  );
});

const newOnboardingCompleted = computed(() => {
  return (
    phoneAndEmailCompleted.value &&
    dataBreachesCompleted.value &&
    passwordCompleted.value
  );
});

const showNewOnboardingSection = computed(() => {
  return (
    ddOnboardingEnabled.value &&
    showNewOnboarding.value &&
    !newOnboardingCompleted.value
  );
});

function onResize() {
  currentScreenSize.value = window.innerWidth;
}
</script>

<template>
  <div class="homescreen-outer">
    <div class="homescreen-left">
      <HomeDataRemoval style="margin-bottom: 48px" />
      <HomeNewOnboarding
        style="margin-bottom: 48px"
        v-if="showNewOnboardingSection"
      />
      <HomeActions style="margin-bottom: 48px" />
      <HomeRecent style="margin-bottom: 48px" />
      <HomeInbox v-show="!isLargeScreen" style="margin-bottom: 48px" />
      <HomeDownloadMobile v-show="!isLargeScreen" style="margin-bottom: 48px" />
      <HomeBreaches style="margin-bottom: 48px" />
      <HomeIdentities style="margin-bottom: 48px" />
    </div>
    <div class="homescreen-right">
      <HomeInbox v-show="isLargeScreen" />
      <HomeDownloadMobile v-show="isLargeScreen" />
      <HomeQuickLinks />
    </div>
  </div>
</template>

<style scoped lang="scss">
.homescreen-outer {
  display: flex;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  padding: 40px 24px;
  .homescreen-left {
    flex: 2;
    height: 100%;
  }
  .homescreen-right {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 309px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0px;
    .homescreen-left {
      flex: 1;
      border: none;
    }
    .homescreen-right {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
