<script setup>
import moment from "moment/moment";

import { computed, onBeforeMount, onBeforeUnmount } from "vue";
import store from "@/store";
import inlineSvg from "@/components/InlineSvg.vue";

const props = defineProps({
  recording: {
    type: Object,
    required: true,
  },
  idx: {
    type: Number,
    required: true,
  },
  inbound: {
    type: Boolean,
    required: true,
  },
});

const playing = computed(() => {
  return store.state.media.playing;
});

onBeforeMount(() => {
  store.dispatch("media/register", {
    recording: props.recording,
    id: `${props.idx}-vm`,
  });
});
onBeforeUnmount(() => {
  store.dispatch("media/unregister", {
    recording: props.recording,
    id: `${props.idx}-vm`,
  });
});

function durationDisplay(voicemail) {
  const duration = moment.duration(voicemail.duration);
  if (playing.value && playing.value.id === voicemail.id) {
    const played = store.state.media.progress;
    duration.subtract(played, "milliseconds");
  }
  const min = duration.minutes().toString().padStart(2, "0");
  const sec = duration.seconds().toString().padStart(2, "0");
  return `${min}:${sec}`;
}

function pauseVoicemail(recording) {
  store.dispatch("media/playAudio", recording);
}

function playVoicemail(recording) {
  store.dispatch("media/playAudio", recording);
}

function isTruncated(idx) {
  const el = document.getElementById(`vm-body-${idx}`);
  if (!el) return false;
  return el.scrollHeight > el.clientHeight;
}

function expandTranscript(idx) {
  const el = document.getElementById(`vm-body-${idx}`);
  el?.classList?.remove("truncate");
}

function animationLengthSec(recording) {
  const duration = moment.duration(recording.duration);
  return duration.asSeconds();
}
</script>

<template>
  <div
    class="activity-call-display-voicemail"
    @click="expandTranscript(props.idx)"
  >
    <div class="vm-play">
      <button
        v-if="playing && playing.id === props.recording.id"
        class="playing"
        @click="pauseVoicemail(props.recording)"
        :class="{ inbound: props.inbound }"
      >
        <inlineSvg name="activity-pause-icon" />
      </button>
      <button
        v-else
        @click="playVoicemail(props.recording)"
        :class="{ inbound: props.inbound }"
      >
        <inlineSvg name="activity-play-black-circle" />
      </button>
      <div class="vm-line">
        <div
          class="vm-circle"
          :class="{ inbound: props.inbound }"
          :id="`vm-circle-${props.recording.id}`"
          :style="`animation-duration: ${animationLengthSec(props.recording)}s`"
        ></div>
      </div>
      <div class="vm-duration" :class="{ inbound: props.inbound }">
        {{ durationDisplay(props.recording) }}
      </div>
    </div>

    <div
      v-if="props.recording.transcription_status === 'available'"
      class="vm-title"
      :class="{ inbound: props.inbound }"
    >
      Transcription
      {{ !props.recording.transcription_text ? "unavailable" : "" }}
    </div>
    <div
      v-if="props.recording.transcription_status === 'available'"
      class="vm-body truncate"
      :id="`vm-body-${props.idx}`"
      :class="{ inbound: props.inbound }"
    >
      {{ props.recording.transcription_text }}
    </div>
    <div
      v-if="
        props.recording.transcription_status === 'available' &&
        isTruncated(props.idx)
      "
      class="vm-read-more"
      :class="{ inbound: props.inbound }"
      @click="expandTranscript(props.idx)"
    >
      Read more
    </div>
  </div>
</template>

<style lang="scss" scoped>
.activity-call-display-voicemail {
  cursor: pointer;
  margin: 0 24px;
  .vm-play {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 10px;

    button {
      padding: 0;
      background-color: $color-primary-0;
      color: $color-primary-100;
      border: none;
      cursor: pointer;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      align-items: center;
      justify-content: center;
      display: flex;
      &.inbound {
        background-color: $color-primary-100;
        color: $color-primary-0;
      }
    }
    .vm-duration {
      font-weight: 400;
      font-size: 11px;
      text-align: right;
      letter-spacing: -0.1px;
      color: $color-primary-30;
      &.inbound {
        color: $color-primary-70;
      }
    }
    .vm-line {
      height: 2px;
      width: 100%;
      min-width: 125px;
      background-color: $color-primary-30;
      position: relative;
      border-radius: 999px;

      .vm-circle {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: $color-primary-0;
        position: absolute;
        top: -5px;
        left: 0;
        border-radius: 999px;
        animation: animate;
        animation-timing-function: linear;
        animation-play-state: paused;
        &.inbound {
          background-color: $color-primary-100;
        }

        &.playVoicemail {
          animation-play-state: running;
        }
        &.pauseVoicemail {
          animation-play-state: paused;
        }
      }
    }
  }

  .vm-title {
    font-weight: 600;
    font-size: 13px;
    color: $color-primary-0;
    margin-top: 8px;

    &.inbound {
      color: $color-primary-100;
    }
  }

  .vm-body {
    font-weight: 400;
    font-size: 13px;
    color: $color-primary-0;

    &.inbound {
      color: $color-primary-100;
    }
    &.truncate {
      @include line-clamp(3);
    }
  }
  .vm-read-more {
    margin-top: 22.5px;
    font-weight: 500;
    font-size: 11px;
    color: $color-primary-0;

    &.inbound {
      color: $color-primary-100;
    }
  }
}

@keyframes animate {
  0% {
    left: 0%;
  }

  100% {
    left: 100%;
  }
}
</style>
