<template>
  <main>
    <router-view ref="router"></router-view>
  </main>
</template>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;
  padding-top: $top-bar-height;
  padding-left: 0;

  @media (min-width: 768px) {
    padding-left: 240px;
  }

  body.wallet & {
    width: 100% !important;
  }
}

.app--visible-banner {
  main {
    @media (min-width: 768px) {
      padding-top: calc($top-bar-height + $ui-banner-height);
    }
  }
}
</style>
