import api, { headers } from "@/api/api";
import axios from "axios";
import store from "@/store";

function search() {
  return axios.create({
    baseURL: window.ENV.VUE_APP_SEARCH_API,
    headers: { ...headers() },
  });
}

export default class DataDeleteService {
  static async createScan({
    firstName,
    lastName,
    age,
    state,
    city,
    email,
    phone,
  } = {}) {
    return await api().post("/api/v1/data_deletion/scan/", {
      first_name: firstName,
      last_name: lastName,
      age: age,
      city: city,
      state: state,
      postal_code: undefined,
      country: "US",
      email_address: email,
      phone_number: phone,
    });
  }

  static async getScanProgress() {
    return await api().get("/api/v1/data_deletion/scan/");
  }

  static async getPublicRecords({
    firstName,
    lastName,
    state,
    phoneNumber,
    age,
    email,
    useArray = false,
  }) {
    let query = {
      firstName,
      lastName,
      stateAbbreviation: state,
      age,
      email,
      phone: phoneNumber,
    };

    if (useArray) return { data: { results: [] } }; // todo implement array

    return await search()
      .post("/api/v1/data_report_scan42/search/", query)
      .catch(async (error) => {
        // if 409, means user has already searched and results are in progress
        if (error.response.status === 409) {
          return {
            data: {
              in_progress: true,
            },
          };
        }
        // try again if failed and not 409
        return await search()
          .post("/api/v1/data_report_scan42/search/", query)
          .catch((error) => {
            // if 409, means user has already searched and results are in progress
            if (error.response.status === 409) {
              return {
                data: {
                  in_progress: true,
                },
              };
            }
            return {
              data: {
                hasError: true,
                results: [],
              },
            };
          });
      });
  }
  static async getDeleteRequests(page = 1) {
    return await api()
      .get(`/api/v1/data_deletion/?page=${page}`)
      .then(({ data }) => {
        store.dispatch("dataDelete/setRequests", data.results);
      });
  }

  static async createEnrollmentData(pii) {
    pii.first_name = pii.firstName;
    delete pii.firstName;
    pii.last_name = pii.lastName;
    delete pii.lastName;
    pii.phone_number = pii.phone.replaceAll(/\(|\)|-|\s/g, "");
    if (pii.phone_number?.length === 10) {
      // NOTE: enrollment is only supported in US/CA for now, so we can force + 1
      pii.phone_number = `+1${pii.phone_number}`;
    }
    delete pii.phone;
    pii.email_address = pii.email;
    delete pii.email;
    pii.postal_code = pii.postalCode;
    delete pii.postalCode;

    return await api()
      .post("/api/v1/data_deletion/enrollment/", JSON.stringify(pii))
      .then((resp) => {
        store.dispatch("dataDelete/setEnrollmentData", resp);
        store.dispatch("authentication/getUser"); // need to refetch user flags
        return resp;
      });
  }

  static async submitDeleteRequest(pii) {
    return await api()
      .post(`/api/v1/data_deletion/`, {
        pii: JSON.stringify(pii),
      })
      .then((response) => {
        return DataDeleteService.getDeleteRequests();
      });
  }

  static async getMockPhoneData() {
    // return { data: mockData };
  }

  static async getEnrollmentData() {
    return await api()
      .get("/api/v1/data_deletion/enrollment/")
      .then((resp) => {
        store.dispatch("dataDelete/setEnrollmentData", resp);
        return resp;
      })
      .catch(() => {});
  }

  static async getRemovalLog() {
    return await api()
      .get("/api/v1/data_deletion/enrollment/removal_log/")
      .then((resp) => {
        store.dispatch("dataDelete/setRemovalLogData", resp);
        return resp;
      });
  }

  static async getActionItems() {
    return await api()
      .get("/api/v1/data_deletion/enrollment/action_items/")
      .then((response) => {
        store.dispatch(
          "dataDelete/setActionRequiredItems",
          response?.data?.broker_families
        );
        return response;
      })
      .catch((error) => {
        store.dispatch("dataDelete/setActionRequiredItems", []);
        return error;
      });
  }

  static async markBrokerFamilyDone(brokerFamilyId) {
    const payload = {
      broker_family_id: brokerFamilyId,
    };
    return await api()
      .post("/api/v1/data_deletion/enrollment/actions/complete/", payload)
      .then((resp) => {
        return resp;
      });
  }

  static async getGraphData() {
    return await api()
      .get("/api/v1/data_deletion/enrollment/removal_count/")
      .then((resp) => {
        store.dispatch("dataDelete/setGraphData", resp.data);
        return resp;
      })
      .catch(() => {});
  }
  static async getEnrollmentProfile() {
    return await api()
      .get("api/v1/data_deletion/enrollment/profile/")
      .then((resp) => {
        store.dispatch("dataDelete/setEnrollmentProfile", resp?.data);
        return resp;
      })
      .catch(() => {});
  }
}

const mockProfileResponse = {
  name: {
    first: "first",
    last: "lastly",
    prefix: "Dr.",
    middle: "middle",
    suffix: "jr.",
  },
  other_names: [
    {
      first: "other",
      last: "name",
      prefix: "Queen",
      middle: "middleton",
      suffix: "IiI",
    },
    {
      first: "other carington",
      last: "lastname",
      suffix: "i",
    },
  ],
  birth_year: null,
  phone_numbers: ["+16192958443", "+16192958443"],
  email_addresses: [],
  addresses: [
    {
      city: "SAN DIEGO",
      state: "CA",
      country: "US",
      address1: "1234 main st",
      address2: "apt 37",
      postal_code: "23156",
    },
    {
      city: "SAN DIEGO",
      state: "CA",
      country: "US",
      address1: "321 main st",
      address2: "Unit 14",
    },
    {
      city: "SAN DIEGO",
      state: "CA",
      country: "US",
      address1: "376 main st",
      postal_code: "92101",
    },
  ],
};

const mockGraphResponse = {
  data: {
    interval: "daily", // in the future
    graph_data: [
      {
        date: "2024-06-01",
        email: 2,
        phone: 1,
        full_name: 1,
      },
      {
        date: "2024-06-02",
        full_name: 1,
      },
      {
        date: "2024-06-03",
        full_name: 1,
      },
      {
        date: "2024-06-10",
        full_name: 4,
        address: 2,
        relatives: 1,
      },
      {
        date: "2024-06-20",
        full_name: 21,
        address: 22,
      },
      {
        date: "2024-07-01",
        full_name: 20,
        address: 2,
        relatives: 4,
      },
      {
        date: "2024-07-10",
        full_name: 2,
        address: 2,
        relatives: 10,
      },
      {
        date: "2024-07-11",
        full_name: 10,
        address: 2,
      },
      {
        date: "2024-07-12",
        full_name: 2,
        address: 2,
      },
      {
        date: "2024-07-15",
        full_name: 2,
        address: 3,
      },
      {
        date: "2024-07-03",
        full_name: 2,
        address: 2,
      },
      {
        date: "2024-07-04",
        full_name: 2,
        address: 8,
        relatives: 1,
      },
    ],
  },
};

const mockActionItems = {
  data: {
    broker_families: [
      {
        id: "brokerFamily:BeenVerified",
        name: "BeenVerified",
        removal_steps: "1. do something \n2. do something else",
        not_getting_removed: "what is this??",
        brokers: [],
      },
      {
        id: "brokerFamily:secondfamily",
        name: "secondfamily",
        removal_steps: "1. do something 2. do something else",
        not_getting_removed: "what is this??",
        brokers: [{ name: "name3" }, { name: "name4" }],
      },
      {
        id: "brokerFamily:thirdfamily",
        name: "thirdfamily",
        removal_steps: "1. do something 2. do something else",
        not_getting_removed: "what is this??",
        brokers: null,
      },
    ],
  },
};
