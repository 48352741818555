<script setup>
import { ref, computed, watch } from "vue";
import store from "@/store";
import { IdentityIcon } from "@/components";
import inlineSvg from "@/components/InlineSvg";
import { useRoute } from "@/hooks";
import { useToast } from "@/hooks";

const route = useRoute();
const toast = useToast();

function identity(id) {
  const identity = store.state.localdb.db_cloaks.find((item) => item.id === id);
  return identity;
}

const props = defineProps({
  status: {
    type: Boolean,
    default: false,
  },
  card: {
    type: Object,
  },
  sent: {
    type: Boolean,
    default: false,
  },
});

const card = ref(props.card);

const realCardNumbers = computed(() => {
  return store.state.cards.currentCard;
});

const available = computed(() => {
  return card.value.transaction_period_limit - card.value.spent_period;
});

function convertToDollar(amount) {
  return (amount / 100)
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
    .replace(/\.0+$/, "");
}

function lastFourDigits(number) {
  return number.slice(-4);
}

const hiddenPan = (pan) => {
  return "••••••••••••" + lastFourDigits(pan);
};

const splitPan = (pan) => {
  return (
    pan.slice(0, 4) +
    "  " +
    pan.slice(4, 8) +
    "  " +
    pan.slice(8, 12) +
    "  " +
    lastFourDigits(pan)
  );
};

function copyToClipboard(text, data) {
  navigator.clipboard.writeText(data);
  toast.success(text + " copied to clipboard");
}

function openIdentity(identity) {
  if (
    !store.state.rightPanel.cloak ||
    store.state.rightPanel.cloak.id !== identity.id
  ) {
    store.dispatch("fetchPopulatedData", identity).then((data) => {
      store.commit("compose", null);
      store.dispatch("openCloakDetails", {
        cloak: data,
        skipNav: true,
      });
    });
  }
}

const cardNumberVisibility = ref(false);
const cardExpiryVisibility = ref(false);
const cardCvvVisibility = ref(false);

function toggleCardNumberVisibility() {
  cardNumberVisibility.value = !cardNumberVisibility.value;
}

function toggleCardExpiryVisibility() {
  cardExpiryVisibility.value = !cardExpiryVisibility.value;
}

function toggleCardCvvVisibility() {
  cardCvvVisibility.value = !cardCvvVisibility.value;
}

watch(route, () => {
  if (route.params.id) {
    cardNumberVisibility.value = false;
    cardExpiryVisibility.value = false;
    cardCvvVisibility.value = false;
  }
});
</script>

<template>
  <router-link
    :to="'/wallet/card/' + card.id"
    class="card"
    :class="{
      active: route.params.id === card.id,
      'show-gift': card.gift && !card.canceled,
      canceled: card.canceled,
    }"
    v-if="props.card"
  >
    <div class="title">
      <IdentityIcon
        class="icon"
        :identity="identity(JSON.parse(card?.identity_id || '{}'))"
        :override="{ height: '60px', width: '60px' }"
      />
      <div class="info">
        <h1 v-if="card?.identity_id">
          {{ card.gift ? "Gift Card" : card.identity_name || "(Unnamed)" }}

          <inlineSvg
            name="lock-filled"
            v-if="
              card.state === 'locked_by_user' ||
              card.state === 'locked_by_system'
            "
          />

          <inlineSvg
            name="gift"
            v-if="
              card.gift &&
              card.state === 'unlocked' &&
              !card.canceled &&
              !props.sent
            "
          />

          <inlineSvg
            name="invite-sent"
            v-if="
              card.gift &&
              card.state === 'unlocked' &&
              !card.canceled &&
              props.sent
            "
          />

          <inlineSvg name="credit-warning" v-if="card.canceled" />
        </h1>
        <h1 v-else>Physical card</h1>
        <p v-if="card.state === 'unlocked' && !card.canceled">
          {{ convertToDollar(available) }} available
        </p>
      </div>
    </div>

    <div class="card-details">
      <div class="card-number">
        <p
          class="hover"
          v-if="realCardNumbers.pan && route.params.id === card.id"
        >
          <span
            @click="copyToClipboard('Card number', realCardNumbers.pan)"
            v-if="!cardNumberVisibility && !card.canceled"
            >{{ splitPan(hiddenPan(card.pan)) }}</span
          >
          <span
            @click="copyToClipboard('Card number', realCardNumbers.pan)"
            v-else
            >{{ splitPan(realCardNumbers.pan) }}</span
          >
          <span
            class="icon"
            @click="toggleCardNumberVisibility()"
            v-if="!card.canceled"
          >
            <inlineSvg
              :name="cardNumberVisibility ? 'eye-slash' : 'eye'"
              :key="`${card.identity_id}-${cardNumberVisibility}`"
            />
          </span>
        </p>
        <p v-else>
          <span>{{ splitPan(hiddenPan(card.pan)) }}</span>
        </p>
      </div>

      <div class="valid" v-if="!card.canceled">
        <p>Valid thru</p>
        <p
          class="hover"
          v-if="
            realCardNumbers.expMonth &&
            realCardNumbers.expYear &&
            route.params.id === card.id
          "
        >
          <span
            v-if="!cardExpiryVisibility"
            @click="
              copyToClipboard(
                'Valid thru date',
                realCardNumbers.expMonth + '/' + realCardNumbers.expYear
              )
            "
            >••/••</span
          >
          <span
            @click="
              copyToClipboard(
                'Valid thru date',
                realCardNumbers.expMonth + '/' + realCardNumbers.expYear
              )
            "
            v-else
          >
            {{ realCardNumbers.expMonth }}/{{ realCardNumbers.expYear }}
          </span>

          <span class="icon" @click="toggleCardExpiryVisibility()">
            <inlineSvg
              :name="cardExpiryVisibility ? 'eye-slash' : 'eye'"
              :key="`${card.identity_id}-${cardExpiryVisibility}`"
            />
          </span>
        </p>

        <p v-else>••/••</p>
      </div>

      <div class="cvv" v-if="!card.canceled">
        <p>CVV</p>
        <p
          class="hover"
          v-if="realCardNumbers.cvv && route.params.id === card.id"
        >
          <span
            v-if="!cardCvvVisibility"
            @click="copyToClipboard('CVV', realCardNumbers.cvv)"
            >•••</span
          >
          <span @click="copyToClipboard('CVV', realCardNumbers.cvv)" v-else>{{
            realCardNumbers.cvv
          }}</span>

          <span class="icon" @click="toggleCardCvvVisibility()">
            <inlineSvg
              :name="cardCvvVisibility ? 'eye-slash' : 'eye'"
              :key="`${card.identity_id}-${cardCvvVisibility}`"
            />
          </span>
        </p>
        <p v-else>•••</p>
      </div>

      <div class="canceled-message" v-if="card.canceled">
        <p>Card is canceled</p>
      </div>
    </div>

    <inlineSvg class="card-icon" name="mastercard" />
  </router-link>
</template>

<style lang="scss" scoped>
.card {
  --distance: calc(222px - 95px);

  width: 100%;
  height: 222px;
  border-radius: 12px;
  border: 1.009px solid $color-primary-10;

  background: radial-gradient(
    157.8% 94.4% at 54.96% 48%,
    $color-surface 34.38%,
    $color-primary-20 100%
  );
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  padding: 22px 17px;
  overflow: hidden;
  position: relative;
  display: block;

  &:not(:first-child) {
    margin-top: calc(var(--distance) * -1);
  }

  &:hover {
    cursor: pointer;
  }

  &.active ~ .card {
    transform: translateY(calc(var(--distance) + 10px));
  }

  &.active {
    background: radial-gradient(
      157.8% 94.4% at 54.96% 48%,
      $color-primary-100 34.38%,
      $color-primary-90 100%
    );

    &.show-gift {
      background: gold;
    }

    .canceled-message {
      color: $color-primary-20;
    }

    .title {
      .icon {
        pointer-events: unset;
        cursor: pointer;
      }

      h1 {
        color: $color-primary-0;
      }

      p {
        color: $color-primary-0;
      }
    }

    .card-details {
      color: $color-primary-0;
    }

    .card-icon {
      color: $color-primary-0;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;

    .icon {
      pointer-events: none;
    }

    h1 {
      font-size: 18.974px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.422px;
      color: $color-primary-100;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      svg {
        width: auto;
        height: 16px;
        margin-left: 4px;
      }
    }

    p {
      font-size: 12.65px;
      font-style: normal;
      font-weight: 500;
      margin-top: 1px;
      color: $color-primary-100;
    }
  }

  .card-details {
    position: relative;
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    color: $color-primary-100;

    .card-number {
      width: 100%;
      margin-bottom: 13px;

      p {
        font-size: 20.171px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.202px;
        display: inline-flex;
        font-family: Monospace;
        padding-right: 32px;
        position: relative;

        span {
          display: inline-block;
          border-radius: 4px;

          &.icon {
            width: 27px;
            height: 27px;
            margin-left: 5px;
            padding: 5px;
            position: absolute;
            right: 0;
            border-radius: 50px;
            visibility: hidden;
            opacity: 0;

            svg {
              width: 20px;
              height: 20px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        &.hover {
          span {
            &:hover {
              background-color: $color-primary-90;
              cursor: pointer;
            }
          }

          &:hover {
            span {
              &.icon {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .valid {
      margin-right: 57px;
    }

    .valid,
    .cvv {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      p {
        &:first-child {
          font-size: 12.103px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 4px;
          font-family: inherit;
          padding: 0;
        }

        font-size: 16.866px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.202px;
        display: inline-flex;
        font-family: Monospace;
        padding-right: 32px;
        position: relative;

        span {
          display: inline-block;
          border-radius: 4px;

          &.icon {
            width: 27px;
            height: 27px;
            margin-left: 5px;
            padding: 5px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            border-radius: 50px;
            visibility: hidden;
            opacity: 0;

            svg {
              width: 20px;
              height: 20px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        &.hover {
          span {
            &:hover {
              background-color: $color-primary-90;
              cursor: pointer;
            }
          }

          &:hover {
            span {
              &.icon {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .canceled-message {
      position: absolute;
      bottom: -46px;
      left: 0;
      color: $color-primary-20;
      opacity: 0.6;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .card-icon {
    position: absolute;
    bottom: 22px;
    right: 17px;
    width: 64px;
    height: auto;
    color: $color-primary-100;
    opacity: 0.2;
  }

  &.show-gift {
    background: lighten($color-gift, 20%);
    border-color: $color-gift;
    color: $black;

    .title {
      h1 {
        color: $black;
      }

      p {
        color: $black;
      }
    }

    .card-details {
      color: $black;
    }

    &.active {
      background: lighten($color-gift, 2%);
      color: $white;

      .title {
        h1 {
          color: $white;
        }

        p {
          color: $white;
        }
      }

      .card-details {
        color: $white;
      }
    }
  }

  &.canceled {
    background: $color-primary-20;
    border-color: $color-primary-20;
    color: $color-primary-100;

    .title {
      h1 {
        color: $color-primary-100;
      }

      p {
        color: $color-primary-100;
      }
    }

    .card-details {
      color: $color-primary-100;
    }

    &.active {
      background: $color-primary-60;
      color: $color-primary-0;

      .title {
        h1 {
          color: $color-primary-0;
        }

        p {
          color: $color-primary-0;
        }
      }

      .card-details {
        color: $color-primary-0;
      }
    }
  }
}
</style>
