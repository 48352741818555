<script setup>
import { UiBanner } from "@/components";
import Button from "@/components/Button.vue";
import { AddVerifyEmail } from "@/components/modals";
import { confirm_contact } from "@/api/actions/feedback";
import EmailService from "@/api/actions/email-service";
import { useToast } from "@/hooks";
import { markRaw } from "vue";

import store from "@/store";

const toast = useToast();
const emit = defineEmits(["emailVerified"]);

const props = defineProps({
  email: {
    type: Object,
    default: null,
  },
});

function open() {
  confirm_contact({
    type: "email",
    contact: props.email.email,
    id: props.email.id,
    callback: () => {
      const email = { ...props.email };
      EmailService.makePrimary(email.id);
      emit("emailVerified");
      toast.success("Email confirmed");
    },
  });
}

function addVerify() {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(AddVerifyEmail),
      props: {
        setPrimary: true,
      },
      events: {
        "email-verified": () => {
          emit("emailVerified");
        },
      },
    },
  });
}
</script>

<template>
  <UiBanner class="confirm-banner" danger>
    <div v-if="props.email">
      <div>Please verify your recovery email {{ props.email.email }}</div>
      <Button type="danger" @click="open">Verify Email</Button>
    </div>
    <div v-else>
      <div>Add recovery email to keep your account safe</div>
      <Button @click="addVerify">Add Email</Button>
    </div>
  </UiBanner>
</template>

<style lang="scss" scoped>
.confirm-banner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: $color-brand-4-100;
  color: $white;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .button {
    height: 32px;
    color: $white;
    background-color: $color-brand-4-100;
    font-weight: 600;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
