<script setup>
import { computed, onMounted, ref, nextTick, watch, onBeforeMount } from "vue";

import store from "@/store";
import { useRoute } from "@/hooks";
import { getPosthog } from "@/scripts/posthog";

import AppCaptcha from "@/components/AppCaptcha.vue";
import HeadlessIframe from "@/routes/guest/HeadlessIframe";

import { SubscriptionService } from "@/api";
import UserService from "@/api/actions/user-service";
import { DATA_DELETE_REQUESTED } from "@/scripts/userFlags";
import { useThemeQueryParameter } from "@/composables";
import { posthogCapture } from "@/scripts/posthog.js";

import DataDeleteSetCredentials from "@/components/feature/data-delete/DataDeleteSetCredentials.vue";
import DataDeletePagePlan from "@/components/feature/data-delete/DataDeletePagePlan.vue";
import { markRaw } from "vue";
import DataDeletePage from "@/components/feature/data-delete/DataDeletePage.vue";

const stepToComponent = {
  StepPayment: markRaw(DataDeletePagePlan),
  StepUsername: markRaw(DataDeleteSetCredentials),
};

const iframeChannel = ref(null);
const route = useRoute();

useThemeQueryParameter();

const error = ref(null);
const step = ref(stepToComponent.StepPayment);

onMounted(() => {
  getPosthog().then((posthog) => {
    posthog?.identify(route?.query?.vid);
    posthog?.startSessionRecording();
  });
});

function setStep(value) {
  step.value = value;
}

const hasToken = computed(
  () => !!store.state.authentication.guest?.access_token
);

watch(
  () => hasToken.value,
  (value) => {
    if (value && initialized.value) {
      nextTick(() => {
        SubscriptionService.getSubscription();
      });
    }
  }
);

const initialized = ref(false);
onBeforeMount(() => {
  store.dispatch("authentication/setGuestToken", null);
  nextTick(() => {
    initialized.value = true;
  });
});

const captcha = ref({});
const showCaptcha = ref(null);

const iframe = computed(() => iframeChannel.value);

const isIframeReady = ref(false);

watch(
  [
    () => showCaptcha.value,
    () => iframe.value,
    () => captcha.value,
    () => isIframeReady.value,
  ],
  ([showCaptchaValue, iframeValue, captchaValue, isIframeReadyValue]) => {
    if (iframeValue && isIframeReadyValue) {
      if (showCaptchaValue === true) {
        if (captchaValue?.token) {
          iframeChannel.value.getAccount(captchaValue.token);
          return;
        }
        return;
      } else if (showCaptchaValue === false) {
        /* Only do this if captcha is not required (false), not null */
        iframeChannel.value.getAccount("-");
      }
    }
    return;
  }
);

function setupUser(payload) {
  iframeChannel.value.setupUser(payload);
}

const isSubscribed = computed(() => {
  return store.getters["settings/isSubscribed"];
});

function setSetup() {
  if (isSubscribed.value) {
    step.value = stepToComponent.StepUsername;
  } else {
    step.value = stepToComponent.StepPayment;
  }
}

const contactInformation = ref(null);

function setPaid(payload = {}) {
  contactInformation.value = payload.contactInformation ?? null;
  step.value = stepToComponent.StepUsername;
}

const compRef = ref(null);

function handleError(message) {
  error.value = message;
  if (message && compRef.value.hasError) {
    compRef.value.hasError();
  }
}

const headlessUser = ref(null);
watch(
  () => headlessUser.value,
  (value) => {
    if (value) {
      UserService.setFlag({
        name: DATA_DELETE_REQUESTED,
        value: true,
      });
    }
  }
);
</script>

<template>
  <DataDeletePage>
    <AppCaptcha
      v-model="captcha"
      v-if="showCaptcha === true && isIframeReady && iframe"
    />
    <Transition mode="out-in" appear>
      <Component
        :is="step"
        class="subscription-upfront data-delete__page"
        @setStep="setStep"
        @setup="setSetup"
        @complete="setSetup"
        @setUser="setupUser"
        :hasToken="hasToken"
        :error="error"
        :isSubscribed="isSubscribed"
        :headlessUser="headlessUser"
        @subscribed="setPaid"
        ref="compRef"
        :defaultCredentials="contactInformation"
        intent="payment"
      />
    </Transition>
    <HeadlessIframe
      ref="iframeChannel"
      @error="handleError"
      @iframe-ready="isIframeReady = true"
      @user-ready="headlessUser = $event"
      @show-captcha="showCaptcha = $event"
    />
  </DataDeletePage>
</template>

<style lang="scss">
@import "@/assets/scss/animations";

.subscription-upfront {
  color: $color-primary-100;
  min-height: 100vh;

  &.choose-plan-page {
    @media all and (min-width: $screen-xl) {
      margin-top: clamp(40px, 5vh, 150px);
    }
  }

  &__page {
    position: relative;
    z-index: 2;
    padding: 40px 22px 25px;
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 8px;

    @media all and (min-width: $screen-xl) {
      font-size: 48px;
      line-height: 115%;
    }

    &-icon {
      width: 32px;
      height: 32px;

      @media all and (min-width: $screen-xl) {
        width: 72px;
        height: 72px;
      }
    }
  }

  &__subtitle {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    margin-bottom: 2px;

    @media (min-width: $screen-xl) {
      font-size: 18px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    opacity: 0.9;
    margin-top: 8px;
    font-size: 12px;

    @media all and (min-width: $screen-xl) {
      font-size: 15px;
      letter-spacing: -0.1px;
    }
  }

  &__capitalized {
    text-transform: capitalize;
  }
}
</style>
