<script setup></script>
<template>
  <div class="dd-onboarding-ui-header">
    <slot />
  </div>
</template>
<style lang="scss">
.dd-onboarding-ui-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: $color-primary-100;
  width: 100%;
  max-width: 650px;

  h1 {
    font-feature-settings: "clig" off, "liga" off;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.18;
    letter-spacing: -0.5px;

    @media all and (min-width: $screen-md) {
      font-size: 54px;
      letter-spacing: -0.5px;
    }
  }

  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 125% */
    letter-spacing: -0.5px;
  }
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  p {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.1px;
  }
}
</style>
