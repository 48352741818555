<script setup>
import { computed, onMounted } from "vue";
import store from "@/store";
import WalletActivity from "@/components/Wallet/WalletActivity.vue";
import KycFlow from "@/components/Wallet/KycFlow.vue";
import WaitlistView from "../components/Wallet/WaitlistView.vue";
import router from "@/routes/router";
import { constants } from "@/scripts";

const user = computed(() => {
  return store.state.authentication?.user;
});

const userCountry = computed(() => {
  return user?.value?.country_at_registration
    ? user?.value?.country_at_registration
    : user?.value?.current_country;
});

const isSupportedCardCountry = computed(() => {
  return constants.SUPPORTED_CARDS_COUNTRIES.includes(userCountry.value);
});

const payEnabled = computed(() => {
  return user?.value?.cloaked_card_enabled;
});

const kycValidated = computed(() => {
  return store.state.authentication?.user?.cloaked_card_kyc_configured;
});
onMounted(() => {
  if (store.getters["settings/isTrial"] && payEnabled.value) {
    store.dispatch("openModal", {
      header: "Congratulations you're an early user for Cloaked Pay!",
      subheader:
        "To enable Cloaked Pay, please upgrade with this promo code <b>FAM2024</b> for 80% off the monthly subscription.",
      button: {
        text: "Upgrade now",
        onClick: () =>
          store.dispatch("subscription/openSubscriptionModal", {
            promoCode: "FAM2024",
          }),
      },
    });
  }
});
</script>

<template>
  <WalletActivity v-if="payEnabled && kycValidated" />
  <KycFlow v-else-if="payEnabled && !kycValidated" />
  <WaitlistView v-else />
</template>
