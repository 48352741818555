<script setup>
import { computed, defineProps, ref } from "vue";
import DataRemovalRequestsRow from "@/routes/DataDeletion/components/RequestRows/DataRemovalRequestsRow.vue";
import store from "@/store";
import { DATA_REMOVAL_STATUS } from "@/scripts/constants";

const props = defineProps({
  requests: {
    type: Array,
    required: true,
  },
  defaultListSize: {
    type: Number,
    required: false,
    default: 5,
  },
  actionRequiredCount: {
    type: Number,
    required: true,
  },
});

const inProgressRequests = computed(() =>
  props.requests.filter(
    (request) => request.state === DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS
  )
);

const removedRequests = computed(() =>
  props.requests.filter(
    (request) => request.state === DATA_REMOVAL_STATUS.REMOVED
  )
);

const noRecordsRequests = computed(() =>
  props.requests.filter(
    (request) => request.state === DATA_REMOVAL_STATUS.NO_RECORDS_FOUND
  )
);

const scanningRequests = computed(() =>
  props.requests.filter(
    (request) => request.state === DATA_REMOVAL_STATUS.SCANNING
  )
);

const filterButtons = computed(() => {
  const buttons = [
    {
      label: "All requests",
      value: "all",
      count: props.requests.length,
    },
    {
      label: "In progress",
      value: DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS,
      count: inProgressRequests.value.length,
    },
    {
      label: "Removed",
      value: DATA_REMOVAL_STATUS.REMOVED,
      count: removedRequests.value.length,
    },
    {
      label: "No records found",
      value: DATA_REMOVAL_STATUS.NO_RECORDS_FOUND,
      count: noRecordsRequests.value.length,
    },
    {
      label: "Scanning",
      value: DATA_REMOVAL_STATUS.SCANNING,
      count: scanningRequests.value.length,
    },
  ];

  return buttons.sort((a, b) => b.count - a.count);
});

const activeSummaryItem = ref("all");
const showAll = ref(false);

const filteredRequests = computed(() => {
  switch (activeSummaryItem.value) {
    case DATA_REMOVAL_STATUS.SCANNING:
      return scanningRequests.value;
    case DATA_REMOVAL_STATUS.NO_RECORDS_FOUND:
      return noRecordsRequests.value;
    case DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS:
      return inProgressRequests.value;
    case DATA_REMOVAL_STATUS.REMOVED:
      return removedRequests.value;
    default:
      return props.requests;
  }
});

const limitedRequests = computed(() => {
  return showAll.value
    ? filteredRequests.value
    : filteredRequests.value.slice(0, props.defaultListSize);
});

const scanningSites = computed(() => {
  return store.getters["dataDelete/enrollmentData"]?.scanningSites ?? 0;
});

function setActiveSummaryItem(item) {
  activeSummaryItem.value = item;
}

function toggleShowAll() {
  showAll.value = !showAll.value;
}

function toggleOpen(request, forceOpen = false) {
  const requestsCopy = [...props.requests]?.map((req) => {
    if (req.id === request.id) {
      req.opened = forceOpen ? forceOpen : !req.opened;
      if (!req.opened) {
        // posthog.capture(PH_EVENT_DATA_REMOVAL_USER_CLICKED_REVIEW, {
        //   client_platform: "dashboard",
        // });
      }
    } else {
      // Only one should be opened at a time
      req.opened = false;
    }
    return req;
  });
  return store.dispatch("dataDelete/updateRemovalLogBrokerList", requestsCopy);
}
</script>

<template>
  <div class="removal-requests">
    <div class="description">
      <h2 class="title">
        Cloaked has scanned {{ scanningSites }} data broker sites
      </h2>
      <p>
        {{
          `Cloaked discovered your personal information on ${
            scanningSites - noRecordsRequests.length
          } sites and has submitted a removal request on ${
            scanningSites - props.actionRequiredCount - noRecordsRequests.length
          }.`
        }}
      </p>
    </div>
    <div class="summary">
      <div
        v-for="(button, idx) in filterButtons"
        class="summary-item"
        :class="{
          active: activeSummaryItem === button.value,
          disabled: button.count === 0,
        }"
        @click="button.count > 0 && setActiveSummaryItem(button.value)"
        :key="`${idx}-${button.label}`"
      >
        <div
          class="summary-value"
          :class="{
            'in-progress-requests':
              button.value === DATA_REMOVAL_STATUS.REMOVAL_IN_PROGRESS,
            'removed-requests': button.value === DATA_REMOVAL_STATUS.REMOVED,
            'scanning-requests': button.value === DATA_REMOVAL_STATUS.SCANNING,
            'no-records-requests':
              button.value === DATA_REMOVAL_STATUS.NO_RECORDS_FOUND,
            'all-requests': button.value === 'all',
          }"
        >
          {{ button.count }}
        </div>
        <div class="summary-label">{{ button.label }}</div>
      </div>
    </div>
    <div class="requests-table">
      <div class="requests-table-header">
        <div class="requests-table-cell">Broker</div>
        <div class="requests-table-cell">Status</div>
        <div class="requests-table-cell long">Sent on</div>
        <div class="requests-table-cell short">Sent</div>
        <div class="requests-table-cell long">Completed on</div>
        <div class="requests-table-cell short">Completed</div>
        <div class="requests-table-cell long">Est. removal time</div>
        <div class="requests-table-cell short">Est. time</div>
      </div>
      <div class="requests-table-body">
        <DataRemovalRequestsRow
          v-for="(request, index) in limitedRequests"
          :key="`${index}-${request.broker_name}`"
          :request="request"
          @open="() => toggleOpen(request)"
        />
      </div>
    </div>
    <button
      class="toggle-button"
      @click="toggleShowAll"
      v-if="filteredRequests.length > props.defaultListSize"
    >
      {{ showAll ? "Show fewer" : "Show all" }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.removal-requests {
  padding: 20px;
  background-color: $color-surface;
  border: 1px solid $color-primary-10;
  border-radius: 12px;
  margin-top: 8px;
  // min-width: 795px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: $color-primary-100;
  font-size: 18px;
  margin-bottom: 8px;
}

.pulsing-svg {
  margin-left: 8px;
}

.description {
  border-bottom: 1px solid $color-primary-10;
}

.description p {
  margin-bottom: 24px;
  font-size: 12px;
  color: $color-primary-70;
}

.summary {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  gap: 16px;
}
.summary-item {
  text-align: left;
  border: 1px solid $color-primary-100;
  padding: 16px;
  border-radius: 14px;
  cursor: pointer;
  color: $color-primary-100;
  font-size: 12px;
  transition: all 0.3s ease;
  .summary-label {
    @include line-clamp(2);
  }

  &.active {
    background-color: $color-primary-100;
    border: 1px solid $color-primary-100;
    color: $color-primary-0;
    transition: all 0.3s ease;
    .summary-value,
    .summary-label {
      color: $color-primary-0;
      transition: all 0.3s ease;
    }
    &:hover {
      transition: all 0.3s ease;
      transform: scale(1.05);
      opacity: 0.7;
      background-color: $color-primary-100;
    }
  }
  &:hover {
    transition: all 0.3s ease;
    transform: scale(1.05);
    background-color: $color-primary-10;
  }
  &.disabled {
    opacity: 0.5;
    transform: none;
    background-color: transparent;
    cursor: not-allowed;
  }
}

.summary-value {
  font-size: 18px;
  font-weight: 600;
  &.all-requests {
    color: $color-brand-3-100;
  }
  &.in-progress-requests {
    color: $color-in-progress;
  }
  &.removed-requests {
    color: $color-success;
  }
  &.no-records-requests {
    color: $color-lt-green;
  }
  &.scanning-requests {
    color: $color-primary-30;
  }
}

.requests-table {
  width: 100%;
  border-collapse: collapse;
  color: $color-primary-100;

  .requests-table-header {
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .requests-table-cell {
      &.long {
        display: flex;
        @include line-clamp(1);
        @media (max-width: 1195px) {
          display: none;
        }
      }
      &.short {
        display: none;
        @media (max-width: 1195px) {
          display: flex;
          @include line-clamp(1);
        }
      }

      flex: 1;
      text-align: left;
      margin-bottom: 16px;
      width: 20%;
      @include line-clamp(1);
    }
  }

  .requests-table-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.toggle-button {
  width: 100%;
  padding: 10px;
  border: 1px solid $color-primary-100;
  border-radius: 8px;
  background-color: $color-surface;
  cursor: pointer;
  margin-top: 32px;
  color: $color-primary-100;
  transition: all 0.3s ease-in;
  &:hover {
    background-color: $color-primary-100;
    color: $color-primary-0;
    transition: all 0.3s ease-in;
  }
}
</style>
