<!-- style provider component for payment ui variants -->
<template>
  <div>
    <slot />
  </div>
</template>

<style lang="scss">
.subscribe-payment {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 94px + 8px;

  @media all and (min-width: $screen-xl) {
    padding-bottom: 0;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      padding-right: 10px;
      flex-wrap: wrap;
    }

    &-footer {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      grid-column: 1/3;
      border-top: 1px solid $color-primary-100;
      padding: 10px 0;
      margin-top: 8px;
    }
  }

  &__promo-toggle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    color: $color-primary-100;

    &:hover {
      opacity: 0.9;
    }
  }

  &__promo-code {
    grid-column: 1/3;

    &-heading {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.15;
    }

    &-input {
      margin: 4px 0 16px;
      display: block;
    }
  }

  &__secure-form {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-100;
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0.4;

    &-icon {
      width: 14px;
      height: 15px;
    }
  }

  &__billing-cycle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  &__payment-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  &__provider-icon {
    width: 24px;
    height: 24px;
  }

  &__product-price {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin-top: 4px;

    @media all and (min-width: $screen-xl) {
      font-size: 12px;
    }

    &--discounted {
      color: $color-success;

      @at-root .theme-light & {
        color: darken($color-success, 10%);
      }
    }

    &--skeleton {
      margin-top: 6px;
      height: 16px;
      border-radius: 8px;
      @include base-skeleton($color-primary-30);
    }
  }

  &__discount {
    padding: 2px 5px;
    border-radius: 100px;
    background-color: $color-success;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;

    &::before {
      content: "-";

      @media screen and (min-width: 385px) {
        content: "Save";
      }
    }
  }

  &__details {
    &-title {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      margin-top: 16px;
    }

    &-value {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: right;
      margin-top: 16px;

      &--skeleton {
        display: block;
        height: 15px;
        border-radius: 8px;
        margin: 4px 0;
        @include base-skeleton($color-primary-20);
      }
    }

    &-note {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: right;
      display: block;
    }
  }

  &__original-price {
    text-decoration: line-through;
  }

  &__total-price {
    &--skeleton {
      align-self: center;
      width: 60%;
      display: block;
      height: 14px;
      border-radius: 8px;
      margin: 2px 0;
      @include base-skeleton($color-primary-20);
    }
  }

  &__stripe-form {
    grid-column: 1/3;

    @at-root * + & {
      &:has(*) {
        margin-top: 16px;
        min-height: 236px;
      }
    }

    &:first-child {
      min-height: 236px;
    }
  }

  &__subscribe {
    position: sticky;
    bottom: -9px;
    background: $color-surface;
    padding: 24px 0;

    &-button-stripe {
      width: 100%;
    }

    &-button-paypal {
      min-height: 62px;

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &__error {
    color: $color-alert;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  &__full-width {
    grid-column: 1/3;
  }

  &__note {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;

    @media all and (min-width: $screen-xl) {
      font-size: 12px;
    }
  }

  &--annual-only {
    .subscribe-payment__details {
      &-title {
        margin-top: 0;
      }

      &-value {
        margin-top: 0;
      }
    }
  }
}
</style>
