<script setup>
import { UserIcon } from "@/assets/icons";
import { format } from "@/scripts";
import store from "@/store";

const props = defineProps({
  contact: {
    type: Object,
  },
  identity: {
    type: Object,
  },
});

function openCloak() {
  if (props.identity.protected) {
    return;
  }
  if (
    !store.state.rightPanel.cloak ||
    store.state.rightPanel.cloak.id !== props.identity.id
  ) {
    store.dispatch("openCloakDetails", {
      cloak: props.identity,
    });
  }
}
</script>

<template>
  <section class="inbox-request-header">
    <div>
      <div class="user-icon"><UserIcon /></div>
      <h1>{{ format.anonymPhone(props.contact?.original_phone) }}</h1>
      <h2>{{ contact?.location || "Unknown Location" }}</h2>
      <button @click="openCloak">
        View "{{ props.identity?.nickname }}" Identity
      </button>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.inbox-request-header {
  padding: 32px;
  display: flex;
  flex: 1;
  justify-content: center;
  color: $color-primary-100;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .user-icon {
    background-color: $color-primary-10;
    color: $color-primary-100;
    border-radius: 999px;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 35px;
      width: 33px;
    }
  }
  h1 {
    margin: 8px 0 4px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    color: $color-accent;
  }
  h2 {
    margin: 0 0 8px 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $color-primary-50;
  }
  button {
    padding: 4px 12px;
    background-color: $color-primary-5;
    color: $color-primary-100;
    border-radius: 999px;
    border: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
