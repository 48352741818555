<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";
import { usePlanMembers } from "@/components/feature/subscribe/composables";
import InlineSvg from "@/components/InlineSvg.vue";

const { isCancellingInvite, cancelInvite } = usePlanMembers();

const props = defineProps({
  invite: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["input"]);
const onCancelInvite = async () => {
  await cancelInvite(props.invite.id);
  emit("input", false);
};
</script>

<template>
  <AppModal
    v-bind="$attrs"
    @input="$emit('input', $event)"
    :has-outside-click-close="true"
  >
    <AppModalContent class="modal-cancel-invite">
      <AppModalTitle size="sm"> Cancel invite </AppModalTitle>
      <AppModalParagraph>
        Are you sure you want to cancel your invite to
        <span class="modal-cancel-invite__email">
          {{ invite?.recipient_email }} </span
        >?
      </AppModalParagraph>
      <AppModalFooter>
        <Button @click="onCancelInvite">
          <template v-if="isCancellingInvite">
            Cancelling invite <InlineSvg name="spinner" />
          </template>
          <template v-else> Yes, cancel invite </template>
        </Button>
        <Button type="secondary" @click="$emit('input', false)"> Close </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.modal-cancel-invite {
  &__email {
    word-break: break-all;
    font-weight: 600;
  }
}
</style>
