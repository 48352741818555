<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import ChoosePlanPayment from "@/components/feature/subscribe/ChoosePlanPayment.vue";
import router from "@/routes/router";
import { usePlansModal } from "@/components/feature/subscribe/composables";
import ChoosePlanPicker from "@/components/feature/subscribe/ChoosePlanPicker.vue";
import ChoosePlanBenefits from "@/components/feature/ChoosePlanBenefits.vue";
import store from "@/store";
import { computed } from "vue";

defineProps({
  intent: {
    type: String,
    default: "subscribe",
    validate: (value) => ["subscribe", "cancel", "resubscribe"].includes(value),
  },
});

const { isPlansModalOpen } = usePlansModal();

const onSubscribed = (plan) => {
  if (["couple", "family"].includes(plan.type)) {
    router.push("/settings/subscription");
  }

  isPlansModalOpen.value = false;
};

const onCancelled = () => {
  isPlansModalOpen.value = false;
};

const user = computed(() => {
  return store.getters["authentication/user"];
});
</script>

<template>
  <AppModal v-bind="$attrs" :has-outside-click-close="true">
    <AppModalContent class="choose-plan-modal">
      <div class="choose-plan-modal__picker">
        <ChoosePlanPicker />
        <ChoosePlanBenefits class="choose-plan-modal__benefits" />
      </div>
      <ChoosePlanPayment
        class="choose-plan-modal__payment"
        :user="user"
        :intent="intent"
        @subscribed="onSubscribed"
        @cancelled="onCancelled"
      />
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.choose-plan-modal {
  width: 920px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 32px 20px;

  @media all and (min-width: $screen-lg) {
    grid-template-columns: 1fr 1fr;
    padding: 32px;
  }

  &__picker {
    @media all and (min-width: $screen-lg) {
      display: block;
      padding: 32px;
    }
  }

  .choose-plan-picker {
    &__billing-cycle {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;

      @media all and (min-width: $screen-xl) {
        gap: 24px;
      }

      &-label {
        @media all and (min-width: $screen-xl) {
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 125% */
          letter-spacing: -0.5px;
        }
      }
    }
  }

  &__benefits {
    display: none;

    @media all and (min-width: $screen-lg) {
      display: flex;
    }
  }

  &__payment {
    @media all and (min-width: $screen-lg) {
      border-left: 1px solid $color-primary-10;
      padding: 0 32px;
      margin: 32px 0;
    }
  }
}
</style>
