<script setup>
import { onMounted, reactive, ref } from "vue";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const state = reactive({
  isInteractive: false,
  hasCaptchaRendered: false,
});

const emit = defineEmits(["update:modelValue", "verified"]);

const captcha = ref(null);

onMounted(async () => {
  window.onloadTurnstileCallback = () => {
    // https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/#configurations
    /* Go here for site key testing for all modes */
    // https://developers.cloudflare.com/turnstile/troubleshooting/testing/

    /* We listen for backend captcha feature flag in Headlessiframe */

    if (!state.hasCaptchaRendered) {
      const widgetId = window.turnstile.render(captcha?.value, {
        sitekey: window.ENV.VUE_APP_CAPTCHA_KEY,
        appearance: "interaction-only",
        theme: "light",
        tabindex: -1,
        callback: (token) => {
          state.isInteractive = false;
          window.turnstile.remove();
          emit("update:modelValue", {
            ...props.modelValue,
            hasExecuted: true,
            hasError: false,
            token,
          });

          emit("verified", token);
        },
        "error-callback": () => {
          emit("update:modelValue", {
            ...props.modelValue,
            hasExecuted: true,
            hasError: true,
            token: null,
          });
        },
        "timeout-callback": () => {
          emit("update:modelValue", {
            ...props.modelValue,
            hasExecuted: true,
            token: null,
          });
        },
        "before-interactive-callback": () => {
          state.isInteractive = true;
        },
      });

      emit("update:modelValue", { ...props.modelValue, widgetId });
      state.hasCaptchaRendered = true;
    }
  };
});
</script>
<template>
  <div
    class="app-captcha"
    :class="{ 'app-captcha--interactive': state.isInteractive }"
  >
    <!-- hacky way to get around forbidden template with side effects, e.g. script -->
    <Component
      :is="'script'"
      type="application/javascript"
      src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback"
      defer
    />
    <div ref="captcha" />
  </div>
</template>

<style lang="scss">
.app-captcha {
  display: none;

  &--interactive {
    position: fixed;
    z-index: 1000;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($black, 0.3);
    backdrop-filter: blur(15px);
  }
}
</style>
