<script setup>
import { uniq } from "lodash-es";
import { validation, regex } from "@/scripts";
import FileIcon from "@/assets/icons/file-upload.svg";
import FileLoadingIcon from "@/assets/icons/loading-small.svg";
import CloseXIcon from "@/assets/icons/close-x-actionbar.svg";

import { computed, watch } from "vue";
import { useToast } from "@/hooks";

const toast = useToast();

const props = defineProps({
  files: Array,
  error: Boolean,
  errors: [Boolean, String],
});

const emit = defineEmits(["remove"]);

const maxPayload = computed(() => {
  return validation.maxPayload(props.files);
});
const ruleErrors = computed(() => {
  return props.error || props.files.length > 5;
});
const hasErrors = computed(() => {
  return ruleErrors.value || props.files.find((f) => f.error) !== undefined;
});

watch(
  () => hasErrors.value,
  (value) => {
    if (value) {
      let hasBanned;
      if (props.files) {
        hasBanned = uniq(
          props.files
            .filter((file) => {
              return regex.isTypeBanned(file.content_type, file.filename);
            })
            .map((file) => {
              const find = regex.isTypeBanned(file.content_type, file.filename);
              return find.mime;
            })
        );
      }
      toast.error(
        hasBanned.length > 0
          ? `We do not allow attachment of file ${
              hasBanned.length > 1 ? ` types:` : ` type:`
            } ${hasBanned.join(", ")}.`
          : `You can only attach a maximum of 5 files that total. ${
              props.errors || ""
            }`,
        {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        }
      );
    }
  },
  { deep: true }
);

function removeFile(fileKey) {
  emit("remove", fileKey);
}
</script>

<template>
  <div
    v-if="props.files.length > 0"
    class="file-list"
    :class="{ error_m: ruleErrors }"
  >
    <div
      v-for="(file, index) in props.files"
      :key="index"
      class="file"
      :class="{ error: file.error || maxPayload }"
    >
      <button v-if="file.uploading" class="icon">
        <FileLoadingIcon />
      </button>
      <button v-else class="icon"><FileIcon /></button>
      <button @click="removeFile(file.key)" class="delete">
        <CloseXIcon />
      </button>
      <div class="file-name">
        {{ file.filename }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.file-list {
  padding: 5px;
  border-radius: 8px;
  .error_m {
    background-color: $color-alert;
    border: 1px solid $color-alert-tint;
    color: $white;
  }
  .image {
    .file-name {
      display: none;
    }
  }
  .file {
    margin: 5px 0;
    padding: 4px 8px 4px 4px;
    background: $color-surface;
    border-radius: 24px;
    font-size: 12px;
    display: inline-flex;
    gap: 5px;
    color: $color-primary-100;

    &.error {
      background-color: $color-alert;
      border: 1px solid $color-alert-tint;
      color: $white;
      svg {
        filter: invert(100%);
      }
    }

    &:hover {
      .delete {
        display: block;
      }
      .icon {
        display: none;
      }
    }
    button {
      border: none;
      background-color: transparent;
      height: 15px;
      width: 18px;
      svg {
        height: 13px;
        width: auto;
        color: $color-primary-100;
      }
    }
    .delete {
      display: none;
    }
  }
}
button {
  cursor: pointer !important;
}
.search_results {
  flex: 1;
  max-height: 300px;
  overflow-y: auto;
  @include custom-scroll-bar();

  .results {
    .search-items {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .group_title {
      font-weight: bold;
      font-size: 12px;
      color: $color-primary-70;
      text-transform: capitalize;
    }
    .search-item {
      gap: 15px;
      align-items: center;
      border-radius: 50px;
      display: inline-flex;
      padding: 3px 12px;
      background-color: transparent;
      .item-pill {
        h1 {
          margin: 0;
          font-size: 14px;
          color: $color-primary-100-70;
          padding: 0;
        }
        h3 {
          margin: 0;
          padding: 0;
          font-size: 10px;
          color: $color-primary-70;
          line-height: 15px;
          font-weight: normal;
        }
      }
      svg {
        display: none;
      }
      &.active {
        background-color: $color-primary-5;
        svg {
          display: inline-block;
        }
      }
      &.selected {
        svg {
          display: block;
        }
      }
    }
  }
}
.header-line {
  margin-bottom: 5px;
  button {
    cursor: pointer;
  }
}
.new_message_container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.disabled {
  opacity: 0.5;
}
.error_m {
  background-color: #fae3e3 !important;
  transition: background-color 1s ease;
}
.to_row {
  width: 100%;
  border-radius: 8px;
  display: grid;
  align-items: start;
  grid-template-columns: 90% 10%;
  .cc_button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
  }
}
.new_message {
  background: $color-primary-0;
  box-shadow: -22.9px -8.90123px 26.7037px rgba(1, 2, 24, 0.05),
    13.3518px 12.35px 26.7037px rgba(1, 2, 24, 0.16);
  border-radius: 10px;
  position: fixed;
  bottom: 16px;
  right: 20px;
  width: 570px;
  padding: 20px;
  font-family: $poppins;
  z-index: 1000;
  min-height: 400px;
  max-height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  .v-select {
    --vs-border-width: 0;

    width: 100% !important;
    font-size: 11px;
    border: 1px solid transparent !important;
    .vs__dropdown-toggle {
      border: 1px solid transparent !important;
    }
  }
  .vs__dropdown-toggle {
    border: 1px solid transparent !important;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
  }
  label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-align: center;
    color: $color-primary-60;
  }
  .add_from {
    background-color: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.1px;
    color: $color-primary-50;
  }
  .from_email {
    background-color: $color-background;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin: 3px 7px;
    &:hover {
      svg {
        cursor: pointer;
      }
      background-color: $color-background;
    }
    padding: 3px 5px 3px 10px;
    margin: 3px 7px;
    min-width: 50px;
    border-radius: 999px;
    display: flex !important;
    gap: 5px;
    &.pill_error {
      background-color: $color-primary-100;
      color: $color-primary-0;
      svg {
        path {
          fill: #fff !important;
        }
        fill: #fff !important;
      }
    }
    button {
      background-color: transparent;
      border: none;
      display: inline-block;
      text-transform: uppercase;
      height: 14px;
      width: 14px;
      position: relative;
      top: 2px;
      margin-right: 8px;
      text-align: center;
      font-size: 10px;
    }
  }
  .border-bottom {
    padding: 5px 0;
    border-bottom: 1px solid #8b8b8b30;
  }
  .flex-row {
    display: flex;
    align-items: center;
    label {
      position: relative;
      text-align: left;
    }
    &.flex-between {
      justify-content: space-between;
      button {
        background-color: transparent;
        border: none;
      }
    }
    &.flex-end {
      justify-content: flex-end;
    }
  }

  textarea,
  input {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
    text-align: left;
    transition: background-color 1s ease;
    background-color: transparent;
    border: none;
    border-radius: 9px;
    padding: 8px;
    outline: none;
  }

  .full-height {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  textarea {
    font-family: $poppins;
    flex: 1;
    resize: none;
    padding: 8px 0;
  }
  .cancel,
  .send {
    &:active {
      opacity: 0.6;
    }
  }
  .cancel {
    background-color: transparent;
    margin-right: 6px;
  }
  .send {
    &.active {
      background-color: $color-primary-0-dark !important;
    }
    width: 30px;
    height: 30px;
    border: none;
    line-height: 12px;
    border-radius: 24px;
    align-items: center;
    justify-content: center;
    display: flex !important;
    background-color: $color-primary-50 !important;
  }
}
:focus {
  outline: none;
  background-color: $color-primary-100-30;
}
</style>
