<script setup>
import DataDeleteThreatTag from "@/components/feature/data-delete/atoms/DataDeleteThreatTag.vue";
import DataDeleteCard from "@/components/feature/data-delete/atoms/DataDeleteCard.vue";
import DataDeleteCardInfo from "@/components/feature/data-delete/atoms/DataDeleteCardInfo.vue";
import DataDeleteCardDivider from "@/components/feature/data-delete/atoms/DataDeleteCardDivider.vue";
import { computed, watch } from "vue";
import { formatPhone } from "@/scripts/format";

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const isCardVisible = computed(
  () => props.result.phones?.length || props.result.emails?.length
);

const emit = defineEmits(["update:modelValue"]);
watch(
  () => isCardVisible.value,
  (isVisible) => emit("update:modelValue", !!isVisible),
  { immediate: true }
);
</script>

<template>
  <DataDeleteCard type="dark" v-if="isCardVisible">
    <DataDeleteThreatTag threatLevel="low" />
    <div v-if="result.phones?.length">
      <h3 class="data-delete__subtitle">Phone Numbers</h3>
      <div v-for="(phone, index) in result.phones" :key="index">
        {{ formatPhone(phone) }}
      </div>
    </div>
    <div v-if="result.emails?.length">
      <h3 class="data-delete__subtitle">Emails</h3>
      <div v-for="(email, index) in result.emails" :key="index">
        {{ email }}
      </div>
    </div>
    <DataDeleteCardDivider />
    <DataDeleteCardInfo>
      Spammers can use this information to automate phone calls, texts and
      emails, subjecting you to unwanted advertising and dangerous phishing
      attempts.
    </DataDeleteCardInfo>
  </DataDeleteCard>
</template>
