<script setup>
import { UiMenu, UiMenuButton, UiMenuSeparator } from "@/components";
import inlineSvg from "@/components/InlineSvg";
import CategoryService from "@/api/actions/category-service";
import AddEditNewCategory from "@/routes/modals/categories/AddEditNewCategory";
import router from "@/routes/router";
import { useToast } from "@/hooks";
import store from "@/store";
import { computed, markRaw } from "vue";
import AddCloakToCategory from "@/routes/modals/categories/AddCloakToCategory";

const toast = useToast();

const props = defineProps({
  categoryId: {
    type: [Number, String],
    required: true,
  },
});

const category = computed(() => {
  return store.state.categories.custom.find(
    (cat) => cat.id == props.categoryId
  );
});

const allCloaks = computed(() => {
  if (store.state.localdb?.db_cloaks) {
    return store.state.localdb.db_cloaks?.filter((cloak) =>
      cloak?.categories?.includes(parseInt(props.categoryId))
    );
  }
  return [];
});

function openCategoryEditModal() {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(AddEditNewCategory),
      props: {
        category: category.value,
        prefilled: category.value.name,
        isVisible: true,
      },
    },
  });
}

function openDeleteConfirmation() {
  store.dispatch("openGlobalDeleteModal", {
    type: "category",
    paragraphs: [
      "Deleting the category won't delete the cloaks in the category.",
    ],
    onClick: deleteCat,
  });
}
function deleteCat(e) {
  e.stopPropagation();

  CategoryService.deleteCategory(props.categoryId)
    .then(() => {
      toast.success("Category deleted");
      router.push({ name: "Home" });
    })
    .catch(() => {
      toast.error("Error deleting category");
    });
}

function toggleAddCloaksModal(show) {
  if (show) {
    store.dispatch("openModal", {
      customTemplate: {
        template: markRaw(AddCloakToCategory),
        props: {
          category: category.value,
          isModalVisible: true,
          identitiesInCategory: allCloaks.value,
        },
        events: {
          addCloaksToCategory: addCloaksToCategory,
        },
      },
    });
  }
}

function addCloaksToCategory(newCloaks) {
  const identityIds = newCloaks.map((c) => c.id);
  CategoryService.addCloaksToCategory(props.categoryId, identityIds);
}
</script>

<template>
  <UiMenu placement="bottom-start" class="filter-item-row">
    <div class="filter-item small-gap">
      <inlineSvg name="edit-pencil-larger" />
      <p>Edit Category</p>
    </div>
    <template #content>
      <UiMenuButton title="Edit category name" @click="openCategoryEditModal">
        <template v-slot:icon>
          <inlineSvg name="edit-pencil-larger" />
        </template>
      </UiMenuButton>
      <UiMenuButton
        title="Add identities to category"
        @click="toggleAddCloaksModal(true)"
      >
        <template v-slot:icon>
          <inlineSvg name="plus" />
        </template>
      </UiMenuButton>
      <UiMenuSeparator />
      <UiMenuButton
        title="Delete"
        @click="openDeleteConfirmation"
        :danger="true"
      >
        <template v-slot:icon>
          <inlineSvg name="delete" />
        </template>
      </UiMenuButton>
    </template>
  </UiMenu>
</template>
<style scoped lang="scss">
.filter-item-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  .filter-item {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 4px 10px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background: $color-primary-10;
    }

    svg {
      color: $color-accent;
      path {
        color: $color-accent;
      }
    }
    p {
      color: $color-primary-100;
      font-size: 12px;
      font-weight: 500;
    }
    &.no-padding {
      padding: 0;
    }
  }
}
</style>
