<script setup>
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import DataDeleteSticky from "@/components/feature/data-delete/atoms/DataDeleteSticky.vue";
import { StateList } from "@/scripts/countries/states";
import { PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_STATE_AND_CITY_FORM } from "@/scripts/posthogEvents";
import { computed, onMounted, defineEmits } from "vue";
import BottomBorderInputText from "@/components/BottomBorderInputText.vue";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["input", "submit"]);

onMounted(async () => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_STATE_AND_CITY_FORM);
});

const isFormValid = computed(() => props.value.state && props.value.city);
</script>

<template>
  <div class="data-delete-additional-search">
    <h1 class="data-delete__title">Enter your city and state</h1>
    <div class="data-delete-additional-search__body">
      <fieldset class="delete-additional-search__body__fieldset">
        <select
          :value="props.value.state"
          @input="$emit('input', { ...value, state: $event.target.value })"
          @keydown.enter="isFormValid && $emit('submit')"
          class="data-delete-additional-search__select"
        >
          <option value="">Select state</option>
          <option
            v-for="state in StateList"
            :key="state.value"
            :value="state.value"
          >
            {{ state.label }}
          </option>
        </select>
        <BottomBorderInputText
          placeholder="City"
          :value="value.city"
          @input="$emit('input', { ...value, city: $event })"
          @keydown.enter="isFormValid && $emit('submit')"
        />
      </fieldset>
      <DataDeleteSticky class="data-delete-additional-search__cta">
        <Button
          type="primary"
          size="2xl"
          @click="$emit('submit')"
          :disabled="!isFormValid"
          class="data-delete-additional-search__cta-button"
        >
          Continue
          <inlineSvg name="arrow-right" />
        </Button>
      </DataDeleteSticky>
    </div>
    <span class="data-delete-additional-search__footer"
      >Cloaked securely searches your phone number across the dark web and 100+
      data brokers. Cloaked will not store or sell your information to these
      parties.</span
    >
  </div>
</template>
