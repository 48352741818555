<script setup>
import { computed, onMounted, ref, watch } from "vue";

import store from "@/store";
import DataDeleteService from "@/api/actions/data-delete-service";
import DataRemovePageHeader from "@/components/feature/data-delete/DataRemovePageHeader.vue";
import DataRemovePageDetail from "@/components/feature/data-delete/DataRemovePageDetail.vue";
import DataDeleteAgreementModal from "@/components/feature/data-delete/DataDeleteAgreementModal.vue";
import { email as isValidEmail } from "@/scripts/validation";
import { useRoute } from "@/hooks";
const route = useRoute();

import { onBeforeMount } from "vue";

import { useDataDeleteSessionStorage } from "@/components/feature/data-delete/composables";

onBeforeMount(() => {
  DataDeleteService.getDeleteRequests();
});

const { readSearchProgressFromSessionStorage } = useDataDeleteSessionStorage();
const local = readSearchProgressFromSessionStorage();

const username = computed(() => store.getters["authentication/username"]);

const request = ref({
  name: local?.firstName ? `${local?.firstName} ${local?.lastName}` : "",
  firstName: local?.firstName || "",
  lastName: local?.lastName || "",
  age: local?.age || "",
  email: isValidEmail(username.value) ? username.value : "",
  phone: local?.phone || local?.phones?.[0] || "",
  city: local?.city || local?.locations?.[0]?.city || "",
  state: local?.state || local?.locations?.[0]?.state?.abbreviation || "",
  country: "US",
  postalCode: local?.locations?.[0]?.zip,
});

const hasPendingRequest = computed(
  () => store.getters["dataDelete/hasRequest"]
);

const isInsideDashboard = computed(() => {
  return route?.meta?.inside;
});

onMounted(() => {
  if (hasPendingRequest.value) {
    status.value = "submitted";
  } else if (!request.value.name) {
    status.value = "info-required";
  }
});

watch(
  () => hasPendingRequest.value,
  (newValue) => {
    if (newValue) {
      status.value = "submitted";
    }
  }
);

const status = ref(request.value.name ? "review-required" : "info-required");

const isModalOpen = ref(false);

const submitRequest = () => {
  isModalOpen.value = true;
};

const agreeAndSubmit = () => {
  isModalOpen.value = false;
  status.value = "submitting";
  Promise.allSettled([
    DataDeleteService.createEnrollmentData(request.value),
    DataDeleteService.submitDeleteRequest(request.value),
  ]).then(() => {
    status.value = "submitted";
  });
};

const nameOfTheUser = computed(() => {
  return request.value.name
    ? request.value.name
    : [request.value.firstName, request.value.lastName]
        .map(
          (word) =>
            (word?.[0]?.toUpperCase() ?? "") + (word?.substring?.(1) ?? "")
        )
        .join(" ")
        .trim();
});
</script>

<template>
  <div class="data-remove">
    <DataRemovePageHeader v-if="!isInsideDashboard" />
    <DataRemovePageDetail
      :request="request"
      :status="status"
      :relatives="local?.relatives"
      @update-status="status = $event"
      @update-request="request = $event"
      @submit-request="submitRequest"
    />
    <DataDeleteAgreementModal
      :value="isModalOpen"
      :name="nameOfTheUser"
      @input="isModalOpen = $event"
      @agree="agreeAndSubmit"
    />
  </div>
</template>

<style lang="scss">
.data-remove {
  inset: 0;
  z-index: 1000;
  background-color: $color-surface;
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  width: 100%;

  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.5px;
    color: $color-primary-100;
    margin: 0;
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $color-primary-100;
    opacity: 0.9;
    font-size: 15px;
    letter-spacing: -0.1px;

    @media all and (min-width: $screen-xl) {
      font-size: 15px;
      letter-spacing: -0.1px;
    }
  }
}
</style>
