<script setup>
import { ArrowDown } from "@/assets/icons";
import OnboardingPageHeader from "@/components/feature/onboarding/page/OnboardingPageHeader.vue";
import { onMounted } from "vue";
import { PH_EVENT_USER_VIEWED_SETUPONBOARDING_DOWNLOAD_APP } from "@/scripts/posthogEvents";
import { logout } from "@/scripts/actions/auth";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  buttonLabel: {
    type: String,
    default: "Sign out",
  },
  action: {
    type: Function,
    default: () => logout(),
  },
  posthogEvent: {
    type: String,
    default: PH_EVENT_USER_VIEWED_SETUPONBOARDING_DOWNLOAD_APP,
  },
});

onMounted(() => {
  posthogCapture(props.posthogEvent);
});
</script>

<template>
  <div class="download-app">
    <OnboardingPageHeader
      class="download-app__header"
      :buttonLabel="props.buttonLabel"
      :action="props.action"
    />
    <div class="download-app__content">
      <h1 class="download-app__title">Download Cloaked to continue</h1>
      <img
        :src="require('@/assets/images/onboarding/download-app.webp')"
        width="500"
        height="512"
        alt="Download App"
        class="download-app__image"
      />
      <a
        class="download-app__link"
        href="https://download.cloaked.app"
        target="_blank"
      >
        Download
        <ArrowDown class="download-app__link-icon" />
      </a>
      <router-link
        to="/settings/subscription"
        class="download-app__manage-subscription"
      >
        Manage subscription
      </router-link>
    </div>
  </div>
</template>

<style lang="scss">
.download-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow: auto;

  &__header {
    padding: 20px 20px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 40px 20px;
    position: relative;
    margin: auto;
  }

  &__title {
    color: $color-primary-100;
    text-align: center;
    font-family: $poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.5px;
  }

  &__image {
    max-width: 400px;
    width: 100%;
  }

  &__link {
    background: $cloaked-gradient;
    border: none;
    color: $color-primary-0;
    padding: 20px 40px;
    border-radius: 100px;
    font-family: $color-primary-0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    max-width: 275px;
    width: 100%;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      inset: 0;
    }

    &-icon {
      flex-shrink: 0;
    }
  }
  &__manage-subscription {
    color: $color-primary-100;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
