<script setup>
import SettingsChildPage from "./SettingsChildPage.vue";
import { SettingsTitle, SettingsParagraph } from "@/components";
import { Subscription } from "@/routes/modals/preferences";
import SubscriptionFamilyPlans from "@/routes/modals/preferences/SubscriptionFamilyPlans.vue";
import { onBeforeMount } from "vue";
import { SubscriptionService } from "@/api";

onBeforeMount(() => {
  // NOTE: purposefully querying backend every time in case user makes to stripe
  SubscriptionService.getStripeInfo();
});
</script>

<template>
  <template v-if="$store.getters['subscription/getPlans']?.length">
    <SubscriptionFamilyPlans
      v-if="$store.getters['subscription/hasFamilyPlans']"
    />
    <SettingsChildPage class="settings-account-page" v-else>
      <template #aside>
        <SettingsTitle>Subscription</SettingsTitle>
        <SettingsParagraph
          >Manage your subscription settings.</SettingsParagraph
        >
      </template>

      <Subscription />
    </SettingsChildPage>
  </template>
</template>
