<script setup>
import ModalTemplate from "@/components/ModalTemplate.vue";
import Button from "@/components/Button.vue";
import store from "@/store";
function closeModal() {
  store.dispatch("closeModal");
}
</script>

<template>
  <ModalTemplate
    class="recovery-modal"
    :show="true"
    :width="707"
    @close="closeModal"
  >
    <template #header>
      <h1 class="title">Action required</h1>
    </template>
    <template #body>
      <p class="paragraph">
        In some cases, Cloaked is not authorized to act on your behalf to remove
        data from certain websites.
      </p>
      <p class="paragraph">
        These sites require additional action from you to ensure that your data
        is removed properly. We apologize for any inconvenience.
      </p>
      <p class="paragraph">
        For additional info or help, please contact
        <span class="link">support@cloaked.com</span>.
      </p>
    </template>
    <template #footer>
      <Button type="primary" size="xl" class="button" @click="closeModal"
        >Got it</Button
      >
    </template>
  </ModalTemplate>
</template>
<style scoped lang="scss">
.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
  color: $color-primary-100;
}
.paragraph {
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.1px;
  color: $color-primary-100;
  &:not(:first-child) {
    margin-top: 16px;
  }
}

.link {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
  text-decoration-line: underline;
  color: $color-primary-100;
}
</style>
