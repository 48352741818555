<script setup>
import {
  Visa,
  Amex,
  Mastercard,
  Discover,
  Hipercard,
  DinersClub,
  CardOutlineIcon,
} from "@/assets/icons";

import { computed } from "vue";

const props = defineProps({
  cardType: {
    type: String,
    default: "",
  },
});

const allSupportedTypes = [
  "american express",
  "american-express",
  "amex",
  "diners club",
  "diners-club",
  "discover",
  "hipercard",
  "mastercard",
  "visa",
];

const logoShouldFillSpace = computed(() => {
  if (!props.cardType) {
    return true;
  }

  const isSupportedType = allSupportedTypes.includes(props.cardType);
  const isAmex = ["american express", "american-express", "amex"].includes(
    props.cardType.toLowerCase()
  );
  return isAmex || !isSupportedType;
});

const cardIcon = computed(() => {
  const cardTypeFormatted = (props.cardType || "").toLowerCase();

  if (
    ["american express", "american-express", "amex"].includes(cardTypeFormatted)
  ) {
    return Amex;
  } else if (["diners club", "diners-club"].includes(cardTypeFormatted)) {
    return DinersClub;
  } else if (cardTypeFormatted === "discover") {
    return Discover;
  } else if (cardTypeFormatted === "hipercard") {
    return Hipercard;
  } else if (cardTypeFormatted === "mastercard") {
    return Mastercard;
  } else if (cardTypeFormatted === "visa") {
    return Visa;
  } else {
    return CardOutlineIcon;
  }
});
</script>

<template>
  <div
    :class="{ 'add-padding': !logoShouldFillSpace }"
    class="card-icon-wrapper"
  >
    <Component :is="cardIcon" />
  </div>
</template>

<style lang="scss" scoped>
.card-icon-wrapper {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  top: 52px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary-5;
  margin-right: 16px;
  &.add-padding {
    svg {
      padding: 3px;
      color: $color-primary-50;
    }
  }

  svg {
    max-width: 32px;
    max-height: 32px;
  }
}
</style>
