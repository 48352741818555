<script setup>
import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import DataDeletePagePhoneResultsCard from "@/components/feature/data-delete/DataDeletePagePhoneResultsCard.vue";
import DataDeleteSticky from "@/components/feature/data-delete/atoms/DataDeleteSticky.vue";
import DataDeleteThreatLevel from "@/components/feature/data-delete/atoms/DataDeleteThreatLevel.vue";
import {
  PH_EVENT_USER_CLICKED_DATA_DELETION_NOT_ME_BUTTON,
  PH_EVENT_USER_CLICKED_DATA_DELETION_SEARCH_RESULTS_CONTINUE_BUTTON,
} from "@/scripts/posthogEvents";
import FreeScanPageProtectYourself from "@/components/feature/data-delete/atoms/FreeScanPageProtectYourself.vue";
import { computed, ref, defineEmits } from "vue";
import { posthogCapture } from "@/scripts/posthog.js";

const props = defineProps({
  searchResults: {
    type: Array,
    default: () => [],
  },
  phone: {
    type: String,
    default: "",
  },
  isForcingNewSearch: {
    type: Boolean,
    default: false,
  },
  isFreeScan: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["setup", "force-new-search"]);
const bestMatch = computed(() => props.searchResults[0] ?? null);

const onDelete = () => {
  emit("setup");

  sessionStorage.setItem(
    "data-delete",
    JSON.stringify({ ...bestMatch.value, searchDate: new Date() })
  );
  posthogCapture(
    PH_EVENT_USER_CLICKED_DATA_DELETION_SEARCH_RESULTS_CONTINUE_BUTTON,
    {
      isForcingNewSearch: props.isForcingNewSearch,
    }
  );
};

const onProtect = () => {
  emit("setup");

  posthogCapture(
    PH_EVENT_USER_CLICKED_DATA_DELETION_SEARCH_RESULTS_CONTINUE_BUTTON,
    {
      isForcingNewSearch: props.isForcingNewSearch,
    }
  );
};

const onNotMe = () => {
  emit("force-new-search");
  posthogCapture(PH_EVENT_USER_CLICKED_DATA_DELETION_NOT_ME_BUTTON);
};

const threatLevel = ref("low");
</script>

<template>
  <div class="data-delete-results data-delete-phone-results">
    <div class="data-delete-results__column">
      <DataDeleteThreatLevel
        :threat-level="threatLevel"
        :animation-delay="200"
      />
      <p
        class="data-delete__text data-delete-results__supporting-text"
        v-if="threatLevel === 'high'"
      >
        A lot of your vulnerable information was accessible and found on 100+
        data brokers.
      </p>
      <p v-else class="data-delete__text data-delete-results__supporting-text">
        Some of your vulnerable information was accessible and found on 100+
        data brokers.
      </p>
      <FreeScanPageProtectYourself v-if="isFreeScan" @click="onProtect" />
      <DataDeleteSticky class="data-delete-phone-results__cta" v-else>
        <Button
          type="hero-green"
          size="2xl"
          @click="onDelete"
          class="data-delete-phone-results__cta-button"
        >
          Delete your online data
          <inlineSvg name="arrow-right" />
        </Button>
      </DataDeleteSticky>
    </div>
    <div class="data-delete-results__column">
      <DataDeletePagePhoneResultsCard
        :result="bestMatch"
        :phone="phone"
        :isForcingNewSearch="isForcingNewSearch"
        @threat-level="threatLevel = $event"
        @on-not-me="onNotMe"
      />
    </div>
  </div>
</template>

<style lang="scss">
.data-delete-results {
  & &__column {
    align-self: center;

    @media all and (min-width: $screen-xl) {
      align-self: auto;

      &:first-child {
        position: sticky;
        top: 48px;
        z-index: 10;
      }
    }
  }

  & &__supporting-text {
    animation: appear-bottom-5 0.3s forwards ease-out;
    animation-delay: 0.5s;
    opacity: 0;
  }
}

.data-delete-phone-results {
  &__cta {
    margin-top: 16px;
    opacity: 0;
    animation: appear-bottom-5 0.5s 0.65s forwards ease-out;

    &-button {
      width: 100%;
    }
  }
}
</style>
