<script setup>
import OnboardingPageLogo from "@/components/feature/onboarding/page/OnboardingPageLogo.vue";

import Button from "@/components/Button.vue";
import inlineSvg from "@/components/InlineSvg.vue";
import router from "@/routes/router";
import { isMobileDevice } from "@/scripts/regex";

function navToApp() {
  if (isMobileDevice) {
    return window.open("https://try.cloaked.app/vAk1/sgws5hsy", "_blank");
  }
  return router.push({ name: "login" });
}
</script>
<template>
  <div class="data-delete__page flex-col">
    <header class="auth-header">
      <OnboardingPageLogo class="auth-header__logo" />
    </header>
    <img
      src="@/assets/images/data-delete/account-avatar.webp"
      class="monitoring-icon"
      alt="Cloaked Avatar"
      width="300"
      height="174"
    />
    <h1 class="data-delete__title">Your data removal is already in progress</h1>
    <h2 class="data-delete__text">
      Data removal is already in progress for this phone number. You can see all
      the details and track your progress by signing in to the Cloaked app.
    </h2>
    <Button
      type="primary"
      size="2xl"
      class="navto-app-button"
      :class="{ 'is-mobile': isMobileDevice }"
      @click="navToApp"
    >
      Take me to the Cloaked app
      <inlineSvg name="arrow-long-right" />
    </Button>
  </div>
</template>
<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  z-index: 100;
  gap: 14px;
  text-align: center;
  padding: 16px;
  height: 100vh;
  width: 100vw;
  max-width: 500px;
  align-self: center;
}

.auth-header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 32px;
}

.navto-app-button {
  margin: 16px;
  margin-top: 18px;
  &.is-mobile {
    width: calc(100% - 32px);
    position: fixed;
    bottom: 16px;
  }
}

.monitoring-icon {
  width: 205px;
  height: auto;
  filter: drop-shadow(0px 7px 26px rgba(0, 0, 0, 0.2));
}

@media (max-width: 768px) {
  .monitoring-icon {
    width: 150px;
  }
}
</style>
