import { ref } from "vue";

const isPlansModalOpen = ref(false);
const plansModalIntent = ref("subscribe");

export const usePlansModal = () => {
  const openPlansModal = (intent = "subscribe") => {
    plansModalIntent.value = intent;
    isPlansModalOpen.value = true;
  };

  return {
    isPlansModalOpen,
    plansModalIntent,
    openPlansModal,
  };
};
