<script setup>
import moment from "moment";
import { get } from "lodash-es";
import {
  PreferencesHeader,
  PreferencesPanel,
  PreferencesFooter,
  PreferencesParagraph,
  PreferencesCheckParagraph,
  PreferencesTitle,
  PreferencesInput,
} from "@/routes/modals/preferences";
import ValueDisplay from "@/components/ui/value-display";
import { Button } from "@/components";
import { logout } from "@/scripts/actions/auth";
import { password_confirm } from "@/scripts/actions/encryption";
import AuthService from "@/api/actions/auth-service";
import UserService from "@/api/actions/user-service";
import store from "@/store";

import { computed, reactive, watch } from "vue";

import { useToast } from "@/hooks";
const toast = useToast();

const emit = defineEmits(["toggleBack", "refreshUser"]);

const options = {
  RESTORE: "RESTORE",
  DELETE: "DELETE",
};

const state = reactive({
  step: 0,
  loadingRestore: false,
  loadingDelete: false,
  selectedOption: null,
  userUnderstands: false,
  password: "",
  invalidPassword: false,
  loadingPassword: false,
});
const user = computed(() => store.state.authentication?.user);
const preferencesStep = computed(() => store.state.ui.preference.step);

const deleteDateLabel = computed(() => {
  const deleteDate = get(user.value, "deletion_date");
  let then = moment().add(30, "days");

  if (deleteDate) {
    then = moment(deleteDate);
  }

  return then.format("LL");
});

const steps = computed(() => {
  const steps = {
    [options.RESTORE]: ["manage", "restore"],
    [options.DELETE]: ["manage", "delete-verification", "delete-confirmation"],
  };

  if (!state.selectedOption || !steps[state.selectedOption]) {
    return ["manage"];
  }

  return steps[state.selectedOption];
});

const currentStep = computed(() => {
  return steps.value[state.step];
});

watch(
  preferencesStep,
  () => {
    if (preferencesStep.value === "restore") {
      state.selectedOption = options.RESTORE;
      state.step = steps.value.findIndex((item) => item === "restore");
    }
    if (preferencesStep.value === "manage") {
      state.selectedOption = null;
      state.step = 0;
    }
  },
  {
    immediate: true,
  }
);

function insertUser(data) {
  store.commit("authentication/setUser", data);
  emit("refreshUser");
}

function nextStep() {
  let next = state.step + 1;
  const maxStep = steps.value.length - 1;

  if (next >= maxStep) {
    next = maxStep;
  }

  state.step = next;
}

function handleDelete() {
  state.loadingDelete = true;

  const userId = user.value.id;
  const payload = {
    state: "pending_deletion",
    immediate_delete: true,
  };

  UserService.deleteUserAccount({ userId, payload })
    .then(logout)
    .catch(() => {
      toast.error("Error deleting account");
    })
    .finally(() => {
      state.loadingDelete = false;
    });
}

function handleRestore() {
  state.loadingRestore = true;

  const userId = user.value.id;
  const payload = {
    state: "active",
    immediate_delete: false,
  };

  UserService.deleteUserAccount({ userId, payload })
    .then(({ data }) => {
      insertUser(data);
      toast.success("Account restored");
      emit("toggleBack");
    })
    .catch(() => {
      toast.error("Error restoring account");
    })
    .finally(() => {
      state.loadingRestore = false;
    });
}

function setOption(option) {
  state.selectedOption = option;
  nextStep();
}

function handleGoBack() {
  if (state.step > 0) {
    state.step = state.step - 1;
  } else {
    emit("toggleBack");
  }
}

async function validatePassword() {
  const userId = user.value.id;
  let password = state.password;
  if (store.state.authentication?.user?.encryption_status === 3) {
    password = await password_confirm(password);
  }
  state.loadingPassword = true;
  AuthService.confirmPassword(userId, password)
    .then(() => {
      state.invalidPassword = false;
      nextStep();
    })
    .catch(() => {
      state.invalidPassword = true;
      toast.error("Invalid password, please try again");
    })
    .finally(() => {
      state.loadingPassword = false;
    });
}
</script>

<template>
  <PreferencesPanel class="manage-account">
    <template v-slot:header>
      <PreferencesHeader @go-back="handleGoBack" />
    </template>

    <template v-if="currentStep === 'manage'" class="manage-account__body">
      <PreferencesTitle>Manage account</PreferencesTitle>
      <PreferencesParagraph>
        Your account is scheduled to be deleted on
        <strong>{{ deleteDateLabel }}</strong
        >. You can restore your account at any time before this date to continue
        using Cloaked.
      </PreferencesParagraph>

      <PreferencesParagraph>
        You can also delete your account now instead of waiting until
        <strong>{{ deleteDateLabel }}</strong
        >.
      </PreferencesParagraph>

      <div class="manage-account__options">
        <ValueDisplay
          label="Restore my account"
          dark-label
          @click="setOption(options.RESTORE)"
        />

        <ValueDisplay
          label="Permanently delete my account now"
          danger
          @click="setOption(options.DELETE)"
        />
      </div>
    </template>

    <template v-if="currentStep === 'restore'" class="manage-account__body">
      <PreferencesTitle>Restore account</PreferencesTitle>
      <PreferencesParagraph>
        This will cancel the account deletion process and restore your Cloaked
        account. You won’t lose any of your account data and will be able to
        continue using Cloaked.
      </PreferencesParagraph>
    </template>

    <template
      v-if="currentStep === 'delete-verification'"
      class="delete-account__body"
    >
      <PreferencesTitle>Delete account now</PreferencesTitle>
      <PreferencesParagraph>
        To continue, enter your account password.
      </PreferencesParagraph>

      <PreferencesInput
        :value="state.password"
        @input="(event) => (state.password = event)"
        label="Password"
        type="password"
        placeholder="Your Password"
        :error="state.invalidPassword"
        :disabled="state.loadingPassword"
        @save="validatePassword"
      />
    </template>

    <template
      v-if="currentStep === 'delete-confirmation'"
      class="delete-account__body"
    >
      <PreferencesTitle>Delete account now</PreferencesTitle>
      <PreferencesParagraph>
        This will permanently delete all data associated with your Cloaked
        identities including email addresses, phone numbers, passwords,
        usernames, and notes.
      </PreferencesParagraph>

      <PreferencesParagraph>
        You will no longer be able to send and receive emails, texts, and calls
        from any of your Cloaked identities. This may also affect your ability
        to log in to websites associated with your Cloaked identities.
      </PreferencesParagraph>

      <PreferencesParagraph>
        <strong>This action cannot be undone.</strong> You will automatically
        lose access to your Cloaked account.
      </PreferencesParagraph>

      <PreferencesCheckParagraph
        class="disclaimer-row"
        :value="state.userUnderstands"
        @input="(event) => (state.userUnderstands = event)"
      >
        I understand my account, my identities, and all of my data will be
        permanently deleted and cannot be retrieved.
      </PreferencesCheckParagraph>
    </template>

    <template v-slot:footer>
      <PreferencesFooter v-if="currentStep === 'restore'">
        <Button
          @click="handleRestore"
          :loading="state.loadingRestore"
          :disabled="state.loadingRestore"
        >
          Restore my account
        </Button>
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'delete-verification'">
        <Button
          @click="validatePassword"
          :disabled="!state.password || state.loadingPassword"
          >Continue</Button
        >
      </PreferencesFooter>

      <PreferencesFooter v-if="currentStep === 'delete-confirmation'">
        <Button
          type="danger"
          :disabled="!state.userUnderstands || state.loadingDelete"
          @click="handleDelete"
        >
          Delete account now
        </Button>
      </PreferencesFooter>
    </template>
  </PreferencesPanel>
</template>

<style lang="scss">
.manage-account {
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &__options {
    margin-top: 24px;
  }

  .preferences-input {
    margin-top: 37px;
  }
}
</style>
