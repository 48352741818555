<script setup>
import { defineAsyncComponent } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const inlineSvg = defineAsyncComponent(() =>
  import(`@/assets/icons/${props.name}.svg`)
);
</script>

<template>
  <Component :is="inlineSvg" :key="name" />
</template>
