<script setup>
import inlineSvg from "@/components/InlineSvg.vue";
import UiTooltip from "@/components/ui/ui-tooltip";
import { userFlags } from "@/scripts";
import store from "@/store";
import UserService from "@/api/actions/user-service";
import MfaService from "@/api/actions/mfa-service";
import { computed, onBeforeMount, reactive } from "vue";
import router from "@/routes/router";
import OnboardingFirstSteps from "@/components/modals/onboarding/OnboardingFirstSteps";
import { PH_EVENT_HOME_ACTION } from "@/scripts/posthogEvents";
import DataDeleteService from "@/api/actions/data-delete-service";
import { posthogCapture } from "@/scripts/posthog.js";
import { usePlanMembers } from "@/components/feature/subscribe/composables";

const MIN_ACTIONS_TO_SHOW = 3;

const state = reactive({
  showingAll: false,
  mfaMethods: store.state.authentication.MfaMethods || [],
  loaded: false,
  showGetStarted: false,
});

onBeforeMount(async () => {
  // NOTE: for now, keep verify email banner
  if (store.state.authentication?.MfaMethods?.length) {
    state.loaded = true;
  } else {
    Promise.allSettled([
      getMfaMethods(),
      // getEmail();
    ]).then(() => {
      state.loaded = true;
    });
  }
  DataDeleteService.getEnrollmentData();
});

const ddInAppSearchEnabled = computed(
  () => store.getters["dataDelete/getDdInAppSearchEnabled"]
);

const hasDDScan = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched ?? false
  );
});

const usedMobileRecently = computed(() => {
  return store.state.authentication?.user?.is_recent_mobile_user;
});

const showDownloadMobile = computed(() => {
  if (usedMobileRecently.value) {
    return false;
  }
  return !store.getters.getFlag(userFlags.DISMISSED_MOBILE_ACTION);
});

const showImportPasswords = computed(() => {
  const importedCloaks = store.state.localdb.db_cloaks?.filter(
    (cloak) => !!cloak.import_uuid
  );
  if (importedCloaks?.length) {
    return false;
  }
  return !store.getters.getFlag(userFlags.DISMISSED_IMPORT_ACTION);
});

const show2FA = computed(() => {
  if (state.mfaMethods?.length || !state.loaded) {
    return false;
  }
  return !store.getters.getFlag(userFlags.DISMISSED_2FA_ACTION);
});

const showGetStarted = computed(() => {
  return !store.getters.getFlag(userFlags.DISMISSED_GET_STARTED_ACTION);
});

const { freeSpots } = usePlanMembers();

const showSendInvite = computed(() => {
  return (
    freeSpots.value > 0 &&
    store.getters["settings/getSubscription"].owner &&
    !store.getters.getFlag(userFlags.DISMISSED_SEND_INVITE_ACTION)
  );
});

const showDataDeletion = computed(() => {
  if (ddInAppSearchEnabled.value) {
    if (hasDDScan.value) {
      return false;
    }
    return !store.getters.getFlag(userFlags.DISMISSED_DATA_DELETE_ACTION);
  }
  return false;
});

const allActionsObj = computed(() => {
  return {
    showSendInvite: showSendInvite.value,
    showGetStarted: showGetStarted.value,
    showDataDeletion: showDataDeletion.value,
    showDownloadMobile: showDownloadMobile.value,
    showImportPasswords: showImportPasswords.value,
    show2FA: show2FA.value,
  };
});

const numberOfActionsAvailable = computed(() => {
  return Object.values(allActionsObj.value).filter((f) => f)?.length;
});

const visibleActions = computed(() => {
  const actionsToShow = Object.keys(allActionsObj.value).filter(
    (key) => allActionsObj.value[key]
  );
  if (state.showingAll) {
    return actionsToShow;
  }
  return actionsToShow.slice(0, MIN_ACTIONS_TO_SHOW);
});

const showShowAllButton = computed(() => {
  return numberOfActionsAvailable.value > MIN_ACTIONS_TO_SHOW;
});

function toggleItems() {
  state.showingAll = !state.showingAll;
}

function removeRow(userFlag) {
  UserService.setFlag({ name: userFlag, value: true });
}

async function getMfaMethods() {
  return MfaService.getEnabledMfaMethods()
    .then(async (response) => {
      const { enabled_methods } = response.data;
      state.mfaMethods = enabled_methods;
    })
    .catch(() => {
      // NOTE: this isnt really necessary,
      // but vue throws a console error if this doesnt have a catch (on logout)
      state.mfaMethods = [];
    });
}

function onClickMobile() {
  sendPosthogEvent("download_mobile_app");
  store.dispatch("toggleMobileAppModal", true);
}

function onClickImport() {
  sendPosthogEvent("password_importer");
  router.push({ name: "Import" });
}

function onClick2FA() {
  sendPosthogEvent("setup_2fa");
  router.push({ name: "settings.account" });
}

function onClickGetStarted() {
  sendPosthogEvent("get_started");
  state.showGetStarted = true;
}

function onClickSendInvite() {
  sendPosthogEvent("send_invite");
  router.push({ name: "settings.subscription" });
}

function onClickDataDelete() {
  sendPosthogEvent("data_deletion");
  window.dispatchEvent(new CustomEvent("focus-data-deletion-input"));
}

function sendPosthogEvent(eventName) {
  posthogCapture(PH_EVENT_HOME_ACTION, {
    action: eventName,
  });
}
</script>
<template>
  <section class="recents" v-show="numberOfActionsAvailable > 0">
    <OnboardingFirstSteps
      v-if="state.showGetStarted"
      @close="state.showGetStarted = false"
    />

    <div class="header-row">
      <h1 class="title">Action Items</h1>
      <div class="toggle" @click="toggleItems" v-if="showShowAllButton">
        {{ state.showingAll ? "Show Less" : "Show All" }}
      </div>
    </div>
    <div class="actions">
      <div
        class="action-row"
        @click="onClickSendInvite"
        v-if="visibleActions.includes('showSendInvite')"
      >
        <div class="icon green reverse">
          <inlineSvg name="verified" key="homeaction-play" />
        </div>

        <div class="text-section">
          <div class="action-title">Add someone to your Cloaked plan</div>
          <div class="action-subtitle">
            <span>Send invite link</span>
            <inlineSvg name="chevron-right" key="homeaction-right-1" />
          </div>
        </div>
        <UiTooltip
          class="delete"
          @click.stop.prevent="
            removeRow(userFlags.DISMISSED_SEND_INVITE_ACTION)
          "
        >
          <inlineSvg name="delete" key="homeaction-delete-1" />
        </UiTooltip>
      </div>

      <div
        class="action-row"
        @click="onClickGetStarted"
        v-if="visibleActions.includes('showGetStarted')"
      >
        <div class="icon green reverse">
          <inlineSvg name="play-outline" key="homeaction-play" />
        </div>

        <div class="text-section">
          <div class="action-title">Cloaked's 1-minute startup guide</div>
          <div class="action-subtitle">
            <span>Easy step-by-step guide</span>
            <inlineSvg name="chevron-right" key="homeaction-right-1" />
          </div>
        </div>
        <UiTooltip
          class="delete"
          @click.stop.prevent="
            removeRow(userFlags.DISMISSED_GET_STARTED_ACTION)
          "
        >
          <inlineSvg name="delete" key="homeaction-delete-1" />
        </UiTooltip>
      </div>

      <div
        class="action-row"
        @click="onClickDataDelete"
        v-if="visibleActions.includes('showDataDeletion')"
      >
        <div class="icon pink reverse">
          <inlineSvg name="data-delete/remove-data" />
        </div>

        <div class="text-section">
          <div class="action-title">Scan your phone number for data leaks</div>
          <div class="action-subtitle">
            <span>Start free scan</span>
            <inlineSvg name="chevron-right" />
          </div>
        </div>
        <UiTooltip
          class="delete"
          @click.stop.prevent="
            removeRow(userFlags.DISMISSED_DATA_DELETE_ACTION)
          "
        >
          <inlineSvg name="delete" />
        </UiTooltip>
      </div>

      <div
        class="action-row"
        @click="onClickMobile"
        v-if="visibleActions.includes('showDownloadMobile')"
      >
        <div class="icon orange reverse">
          <inlineSvg name="cloaked-filled" key="homeaction-cloaked" />
        </div>

        <div class="text-section">
          <div class="action-title">
            Use Cloaked on-the-go with the mobile app
          </div>
          <div class="action-subtitle">
            <span>Download now</span>
            <inlineSvg name="chevron-right" key="homeaction-right-2" />
          </div>
        </div>
        <UiTooltip
          class="delete"
          @click.stop.prevent="removeRow(userFlags.DISMISSED_MOBILE_ACTION)"
        >
          <inlineSvg name="delete" key="homeaction-delete-2" />
        </UiTooltip>
      </div>

      <div
        class="action-row"
        @click="onClickImport"
        v-if="visibleActions.includes('showImportPasswords')"
      >
        <div class="icon yellow">
          <inlineSvg name="key-filled" key="homeaction-key" />
        </div>

        <div class="text-section">
          <div class="action-title">Import your passwords to Cloaked</div>
          <div class="action-subtitle">
            <span>Get started</span>
            <inlineSvg name="chevron-right" key="homeaction-right-3" />
          </div>
        </div>
        <UiTooltip
          class="delete"
          @click.stop.prevent="removeRow(userFlags.DISMISSED_IMPORT_ACTION)"
        >
          <inlineSvg name="delete" key="homeaction-delete-3" />
        </UiTooltip>
      </div>

      <div
        class="action-row"
        @click="onClick2FA"
        v-if="visibleActions.includes('show2FA')"
      >
        <div class="icon green">
          <inlineSvg name="lock-shield" key="homeaction-lock" />
        </div>

        <div class="text-section">
          <div class="action-title">Set up two-factor authentication</div>
          <div class="action-subtitle">
            <span>Add additional security</span>
            <inlineSvg name="chevron-right" key="homeaction-right-4" />
          </div>
        </div>
        <UiTooltip
          class="delete"
          @click.stop.prevent="removeRow(userFlags.DISMISSED_2FA_ACTION)"
        >
          <inlineSvg name="delete" key="homeaction-delete-4" />
        </UiTooltip>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $color-primary-100;

  .title {
    font-size: 24px;
    font-style: normal;
    color: $color-primary-100;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
  }
  .toggle {
    color: $color-primary-100;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  .action-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $color-primary-10;
    padding: 16px 20px;
    border-radius: 12px;
    gap: 18px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    &:hover {
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      transform: scale(1.009);
      background-color: $color-primary-5;
      .delete {
        visibility: visible;
      }
    }
    .icon {
      height: 48px;
      width: 48px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 50%;
        width: auto;
        color: $white;
      }
      &.reverse {
        svg {
          width: 50%;
          height: auto;
        }
      }
    }
    .text-section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .action-title {
        color: $color-primary-100;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.1px;
      }
      .action-subtitle {
        color: $color-primary-100;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.1px;
        text-decoration-line: underline;
        > svg {
          color: $color-primary-90;
          height: 9px;
          width: auto;
          margin-left: 8px;
        }
      }
    }

    .delete {
      visibility: hidden;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: $color-primary-100;
      > svg {
        width: 50%;
        height: auto;
      }
      &:hover {
        background-color: $color-primary-10;
      }
    }
  }
}

.green {
  background-color: $color-brand-6-100;
}

.blue {
  background-color: $color-brand-4-100;
}

.orange {
  background-color: $color-info;
}

.yellow {
  background-color: $color-warning;
}
.pink {
  background-color: $color-brand-1-100;
}
</style>
