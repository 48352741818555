<script setup>
import _ from "lodash";
import { generatePkceRequirements } from "@/scripts/actions/auth";
import MountEvent from "@/components/MountEvent";
import OnboardingPageLogo from "@/components/feature/onboarding/page/OnboardingPageLogo.vue";
import UserService from "@/api/actions/user-service";
import Loading from "@/components/ui/loading.vue";
import DownloadApp from "@/components/feature/onboarding/DownloadApp.vue";
import { supportsWasm, isInAppBrowser } from "@/scripts/tools";
import { isMobileDevice, isAndroid } from "@/scripts/regex";
import { headers } from "@/api/api";
import store from "@/store";
import { PH_EVENT_USER_CLICKED_SIGN_UP_NO_WASM } from "@/scripts/posthogEvents";
import {
  defineProps,
  ref,
  onUnmounted,
  nextTick,
  computed,
  watch,
  onMounted,
} from "vue";

import router from "@/routes/router";
import { useRoute, useToast } from "@/hooks";

const toast = useToast();

const route = useRoute();

const props = defineProps({
  source: {
    type: String,
    required: true,
  },
  id: {
    type: String,
  },
  useV3Route: {
    type: Boolean,
  },
  prevRoute: {
    type: String,
    default: "/",
  },
});

const intercept = ref(false);
const challenge = ref(null);
const verifier = ref(null);
const extension_challenge = ref(null);
const extension_verifier = ref(null);
const path = ref(props.source);
const keywindow = ref(null);
const iframeLoaded = ref(false);
const iframeWrapper = ref(null);
const screenSize = ref({
  width: window.innerWidth,
  height: window.innerHeight,
});
const iframeDimensions = ref({
  width: "500px",
  height: "500px",
});

const errorFromBackend = ref(null);

const isMobile = computed(
  () => isMobileDevice && store.getters["screen/isSmallScreen"]
);
const urlParams = new URLSearchParams(window.location.search);
const developmentTrigger = urlParams.get("qa");
const shouldShowDownloadApp = computed(
  () =>
    (isMobile.value && isInAppBrowser && !supportsWasm()) ||
    (isMobile.value && (!supportsWasm() || errorFromBackend.value)) ||
    (isMobile.value && developmentTrigger)
);

const signupUrl = computed(() => {
  const url = window.ENV.VUE_APP_AF_SIGNUP_URL;
  /* If we're on Android, attempt to open in system browser
  to avoid in-app browser limitations */
  if (isAndroid) {
    return `intent:${url}#Intent;end`;
  }
  return url;
});

const goToSignup = () => {
  window.location.href = signupUrl.value;
};

const pageConfig = computed(() => {
  if (props.useV3Route) {
    return route.path === "/auth/v3/login"
      ? {
          route: `/auth/v3/signup?prevRoute=${props.prevRoute}`,
          text: "Sign up",
          showSignupImg: false,
        }
      : {
          route: `/auth/v3/login?prevRoute=${props.prevRoute}`,
          text: "Sign in",
          showSignupImg: true,
        };
  }
  return route.path === "/auth/login"
    ? {
        route: `/auth/signup?prevRoute=${props.prevRoute}`,
        text: "Sign up",
        showSignupImg: false,
      }
    : {
        route: `/auth/login?prevRoute=${props.prevRoute}`,
        text: "Sign in",
        showSignupImg: true,
      };
});

function setHistory(pathValue) {
  const params = Object.keys(route.query).map((k) => `${k}=${route.query[k]}`);
  const query = params.join("&");
  window.history.pushState(
    {},
    null,
    query ? `${pathValue}?${query}` : pathValue
  );
}

function popstate(event) {
  event.preventDefault();
  if (intercept.value) {
    if (
      confirm(
        "Are you sure you want to exit? You will lose your current progress"
      )
    ) {
      intercept.value = false;
      // why is this source and not path?
      setHistory(props.source);
      nextTick(() => {
        window.location.reload();
      });
    }
  } else {
    const [, pathValue] = window.location.href.match(/(auth\/[^/?]+)/);
    setHistory(pathValue);
  }
}

onMounted(() => {
  window.addEventListener("resize", resizeListener);
});

onUnmounted(() => {
  window.removeEventListener("message", iframeListener);
  window.removeEventListener("popstate", popstate);
  window.removeEventListener("resize", resizeListener);
});

const isOldExtensionRequest = computed(() => {
  return (
    !!route.query?.cloaked_code_challenge && !!route.query?.cloaked_client_id
  );
});

function onMount() {
  window.addEventListener("beforeunload", (event) => {
    if (intercept.value) {
      event.preventDefault();
      intercept.value = false;
      event.returnValue = "";
    }
  });
  window.addEventListener("popstate", popstate);
  nextTick(() => {
    generatePkceRequirements().then(([verifierValue, challengeValue]) => {
      challenge.value = challengeValue;
      verifier.value = verifierValue;
    });
    if (!isOldExtensionRequest.value) {
      generatePkceRequirements().then(([verifierValue, challengeValue]) => {
        extension_challenge.value = challengeValue;
        extension_verifier.value = verifierValue;
      });
    }
  });
  window.addEventListener("message", iframeListener);
}
watch(iframeWrapper, (value) => {
  if (value) {
    iframeDimensions.value.width = `${value.clientWidth}px`;
    iframeDimensions.value.height = `${value.clientHeight}px`;
  }
});
watch(screenSize, () => {
  if (iframeWrapper.value) {
    iframeDimensions.value.width = `${iframeWrapper.value.clientWidth}px`;
    iframeDimensions.value.height = `${iframeWrapper.value.clientHeight}px`;
  }
});

const resizeListener = () => {
  screenSize.value = { width: window.innerWidth, height: window.innerHeight };
};

async function iframeListener(message) {
  const childWindow = keywindow?.value?.contentWindow;

  if (message.source === childWindow) {
    if (
      ["login-success", "migration-completed", "remind-later"].includes(
        message.data.event
      )
    ) {
      const payload = message.data.data;

      if (payload.user.posthog_uuid && window.$posthog) {
        window.$posthog?.identify(payload.user?.posthog_uuid);
      }
      await store.dispatch("authentication/setAuthPayload", {
        payload: message.data.data,
        client_id: window.ENV.VUE_APP_CLIENT_ID,
        codeVerifier: verifier.value,
      });

      await Promise.all([
        store.dispatch("authentication/getUser"),
        UserService.getFlags(),
      ]);

      if (!isOldExtensionRequest.value) {
        await store.dispatch("authentication/setExtensionToken", {
          payload: message.data.data,
          client_id: window.ENV.VUE_APP_EXTENSION_CLIENT_ID,
          codeVerifier: extension_verifier.value,
        });
      }

      router.push({ path: props.prevRoute }).catch((e) => e);
    }
    if (message.data.event === "url-change") {
      if (props.useV3Route) {
        const routes = {
          "/auth/login": "/auth/v3/login",
          "/auth/signup": "/auth/v3/signup",
          "/auth/forgot-password": "/auth/v3/forgot-password",
          "/auth/forgot-username": "/auth/v3/forgot-username",
        };
        const route = Object.keys(routes).includes(message.data.data)
          ? routes[message.data.data]
          : message.data.data;
        if (!window.location.href.match(new RegExp(route))) {
          setHistory(route);
        }
      } else {
        if (!window.location.href.match(new RegExp(message.data.data))) {
          setHistory(message.data.data);
        }
      }
    }
    if (message.data.event === "go-to-login") {
      /* Store settings/recovery as previous path so we take
      the user there to download their new recovery key asap */
      router.push({
        name: "login",
        query: { prevRoute: "/settings/recovery" },
      });
    }
    if (message.data.event === "reset_recovery_key_sent") {
      toast.success("Email submitted");
    }
    if (message.data.event === "encryption-error") {
      /* If we get this error from auth, show the 'download the app screen' */
      errorFromBackend.value = true;
    }
    return;
  }
}

const src = computed(() => {
  const queries = Object.keys(route.query).map((k) => {
    return `${k}=${route.query[k]}`;
  });
  const getHeaders = headers();
  delete getHeaders["Authorization"];
  delete getHeaders["content-type"];
  let params = [
    `cloaked_client_id=${global.ENV.VUE_APP_CLIENT_ID}`,
    `cloaked_code_challenge=${challenge.value}`,
    `secret=${global.ENV.VUE_APP_SECRET}`,
    `cloaked_redirect_uri=${encodeURIComponent(
      global.ENV.VUE_APP_REDIRECT_URI
    )}`,
    `auth-version=3`,
    ...queries,
  ];
  if (!isOldExtensionRequest.value) {
    params = [
      ...params,
      `cloaked_client_id=${global.ENV.VUE_APP_EXTENSION_CLIENT_ID}`,
      `cloaked_code_challenge=${extension_challenge.value}`,
      `secret=${global.ENV.VUE_APP_SECRET}`,
      `cloaked_redirect_uri=${encodeURIComponent(
        global.ENV.VUE_APP_REDIRECT_URI
      )}`,
    ];
  }
  Object.keys(getHeaders).map((k) => {
    params.push(`${_.snakeCase(k)}=${getHeaders[k]}`);
  });

  const deleteData = sessionStorage.getItem("data-delete");
  if (deleteData && path.value.includes("signup")) {
    params.push("enable_delete=true");
  }

  let newPath = path.value;
  if (newPath.includes("auth/register") || newPath.includes("auth/sign-up")) {
    newPath = "auth/signup";
  } else if (newPath.includes("auth/reset-recovery-key")) {
    const recoveryPath = window.location.pathname.endsWith("/")
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname;
    return `${window.ENV.VUE_APP_API}${recoveryPath.replace(
      "/auth",
      "auth"
    )}/?cloaked_redirect_uri=${encodeURIComponent(
      global.ENV.VUE_APP_REDIRECT_URI
    )}`;
  }
  newPath = newPath.replace("/auth", "auth");
  return `${window.ENV.VUE_APP_API}${newPath}/?${params.join("&")}`;
});
</script>

<template>
  <DownloadApp
    v-if="shouldShowDownloadApp"
    buttonLabel="Sign up"
    :action="goToSignup"
    :posthogEvent="PH_EVENT_USER_CLICKED_SIGN_UP_NO_WASM"
  />
  <MountEvent v-else @mounted="onMount">
    <div class="layout-auth">
      <img
        v-if="pageConfig.showSignupImg"
        src="@/assets/images/auth/hero-1.png"
        sizes="sm:480px md:560px lg:768px xl:1024px xxl:1700px"
        format="webp"
        width="1700"
        height="1039"
        alt="Cloaked Hero"
        class="layout-auth__background"
      />
      <img
        v-else
        src="@/assets/images/auth/hero-2.png"
        sizes="sm:480px md:560px lg:768px xl:1024px xxl:1700px"
        format="webp"
        width="1700"
        height="1039"
        alt="Cloaked Hero"
        class="layout-auth__background"
      />
      <Loading v-if="!iframeLoaded" class="iframe-loader" />
      <header class="auth-header">
        <OnboardingPageLogo class="auth-header__logo" />
        <router-link
          :to="pageConfig.route"
          exact-path
          class="auth-header__link"
        >
          <span>{{ pageConfig.text }}</span>
        </router-link>
      </header>
      <div class="iframe-wrapper" ref="iframeWrapper">
        <iframe
          v-if="challenge"
          :id="props.id || path"
          :key="path"
          ref="keywindow"
          :src="src"
          allow="clipboard-read; clipboard-write"
          frameborder="0"
          @load="iframeLoaded = true"
          :style="{
            width: iframeDimensions.width,
            height: iframeDimensions.height,
          }"
        ></iframe>
      </div>
    </div>
  </MountEvent>
</template>

<style lang="scss" scoped>
body,
main {
  /* Enforce light ground */
  background-color: $color-surface-light;
}
.auth-header {
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $screen-xl) {
    padding: 40px;
  }

  &__logo {
    width: 172px;
    color: #191e23;
  }

  &__link {
    color: #191e23;
    font-family: $poppins;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
    border: 1px solid #191e23;
    border-radius: 100px;
    background-color: white;

    &:hover {
      opacity: 0.8;
    }
  }
  @media (pointer: coarse) {
    display: none;
  }
}
.layout-auth {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 30px;
  position: relative;
  max-width: 1650px;
  margin: auto;
  background-color: $color-surface-light;
  height: 100vh;

  @media (max-width: $screen-sm) {
    /* padding done in backend-core for mobile */
    padding: 0;
  }
  @media (min-width: $screen-xl) {
    padding: 40px;
    display: grid;
    gap: 40px;
    grid-template-columns: minmax(400px, 1fr) minmax(300px, 650px);
    grid-template-rows: minmax(min-content, calc(100vh - 80px));
    align-items: start;
    justify-content: start;
  }

  &__background {
    display: none;

    @media (min-width: $screen-xl) {
      display: block;
      position: fixed;
      margin: 0;
      inset: clamp(130px, 25vh, 200px) -550px 0 -550px;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
}
.iframe-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-loader {
  background-color: $color-background-light;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  border: none;
  width: 100vw;
  height: 100vh;
}
iframe {
  border: none;
}
</style>
