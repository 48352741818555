<script setup>
import { computed } from "vue";

import store from "@/store";
import MenuItem from "./MenuItem.vue";
import MenuBlock from "./MenuBlock.vue";
import GetStartedButton from "@/components/Navigation/NavigationLeftPanel/GetStartedButton";

const hasDDScan = computed(() => {
  return (
    store.getters["dataDelete/enrollmentData"]?.enrollmentDataFetched ?? false
  );
});

const showNewOnboarding = computed(() => {
  return store.state.authentication?.user?.flags
    ?.show_dd_post_payment_onboarding;
});

const ddOnboardingEnabled = computed(() => {
  return store.getters["dataDelete/getDdOnboardingEnabled"];
});

const showGetStartedButton = computed(() => {
  return !(ddOnboardingEnabled.value && showNewOnboarding.value);
});
</script>

<template>
  <MenuBlock title="Home">
    <MenuItem link="/home" name="Home" icon="home" />
    <MenuItem v-if="showGetStartedButton" name="Get Started">
      <GetStartedButton />
    </MenuItem>
    <MenuItem link="/referrals" name="Refer friends" icon="present" />
    <MenuItem
      v-if="hasDDScan"
      link="/data-remove"
      name="Data removal"
      icon="data-delete/remove-data"
    />
  </MenuBlock>
</template>
