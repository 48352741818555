export const MexicanStatesList = [
  { label: "Aguascalientes", value: "MX-AGU" },
  { label: "Baja California", value: "MX-BC" },
  { label: "Baja California Sur", value: "MX-BCS" },
  { label: "Campeche", value: "MX-CAMP" },
  { label: "Chiapas", value: "MX-CHIS" },
  { label: "Chihuahua", value: "MX-CHIH" },
  { label: "Coahuila", value: "MX-COAH" },
  { label: "Colima", value: "MX-COL" },
  { label: "Durango", value: "MX-DGO" },
  { label: "Guanajuato", value: "MX-GTO" },
  { label: "Guerrero", value: "MX-GRO" },
  { label: "Hidalgo", value: "MX-HGO" },
  { label: "Jalisco", value: "MX-JAL" },
  { label: "México", value: "MX-MEX" },
  { label: "Michoacán", value: "MX-MICH" },
  { label: "Morelos", value: "MX-MOR" },
  { label: "Nayarit", value: "MX-NAY" },
  { label: "Nuevo León", value: "MX-NL" },
  { label: "Oaxaca", value: "MX-OAX" },
  { label: "Puebla", value: "MX-PUE" },
  { label: "Querétaro", value: "MX-QRO" },
  { label: "Quintana Roo", value: "MX-QR" },
  { label: "San Luis Potosí", value: "MX-SLP" },
  { label: "Sinaloa", value: "MX-SIN" },
  { label: "Sonora", value: "MX-SON" },
  { label: "Tabasco", value: "MX-TAB" },
  { label: "Tamaulipas", value: "MX-TAM" },
  { label: "Tlaxcala", value: "MX-TLAX" },
  { label: "Veracruz", value: "MX-VER" },
  { label: "Yucatán", value: "MX-YUC" },
  { label: "Zacatecas", value: "MX-ZAC" },
];
