<script setup>
import { ModalTemplate, Button } from "@/components";
import { defineEmits } from "vue";
const emit = defineEmits(["close", "continue"]);
</script>
<template>
  <ModalTemplate :show="true" @close="emit('close')">
    <template #header>
      <h1>Data deletion enrollment age restriction</h1>
    </template>

    <template #body>
      <div>
        Data deletion enrollment is not supported for users under the age of 18.
      </div>
    </template>
    <template #footer>
      <Button @click="emit('continue')">
        Continue without data deletion
      </Button>
    </template>
  </ModalTemplate>
</template>
