export const HAS_ATS = "ats_promo";
export const NEW_USER = "firstTimeUser";
// export const HAS_PLUGIN = "has_plugin"; deprecating in favor of "has_activated_plugin"
export const SHOWN_PIN = "has_shown_pin";
export const CEO_ENABLED = "ceo_enabled";
export const CEO_COMPLETE = "ceo_complete";
export const NEW_MOBILE = "has_new_mobile";
export const SHOW_BANNER = "has_show_banner";
export const NEW_ONBOARD = "has_new_onboard";
export const SHOWN_PLUGIN = "has_shown_plugin";
export const VIDEO_COMPLETE = "video_complete";
export const EMAIL_COMPLETE = "emails_complete";
export const SUPPORT_ENABLED = "support_enabled";
export const MOBILE_COMPLETE = "mobile_complete";
export const MOBILE_HAS_IOS = "has_activated_ios";
export const FORWARD_COMPLETE = "forward_complete";
export const DEFAULT_CLOAKS = "has_default_cloaks";
export const MOBILE_INSTALLED = "mobile_installed";
export const SUPPORT_COMPLETE = "support_complete";
export const WEBSITE_HELPER = "has_website_helper";
export const WEBSITE_COMPLETE = "websites_complete";
export const AUTOFILL_COMPLETE = "autofill_complete";
export const DOWNLOADED_KEYS = "has_downloaded_keys";
export const SHOW_GET_PLUGIN = "has_shown_get_plugin";
export const ONBOARD_COMPLETE = "has_completed_onboard";
export const MOBILE_HAS_ANDROID = "has_activated_android";
export const PERFORMED_TYPEFORM = "has_performed_typeform";
export const FIRST_TIME_MESSAGE = "has_first_time_message";
export const NEEDS_ONBOARDED_V4 = "needs_onboarded_v4";
export const HAS_ONBOARDED_V4 = "has_onboarded_v4";
export const ONBOARD_SELECTED_VALUE = "onboard_selected_value";
export const HAS_SEEN_REQUEST_VIDEO = "has_seen_request_video";
export const HAS_SEEN_CATEGORY_MODAL = "has_seen_category_modal";
export const IMPORT_PASSWORDS_COMPLETE = "import_passwords_complete";
export const PERFORMED_ACTIVITY_SURVEY = "performed_activity_survey";
export const HAS_EXITED_DELETE_FLOW = "has_exited_delete_flow";
export const STEP_IMPORT_STARTED = "STEP_IMPORT_STARTED";
export const STEP_IMPORT_COMPLETED = "STEP_IMPORT_COMPLETED";
export const HAS_SEEN_PASSPHRASE_MODAL = "has_seen_passphrase_modal";
export const HAS_SEEN_IMPORTER_CHEAT_SHEET = "has_seen_importer_cheat_sheet";
export const HAS_SEEN_IMPORTER_GETTING_STARTED_VIDEO =
  "has_seen_importer_getting_started_video";
export const HAS_SEEN_CUSTOM_FIELDS_ANNOUNCEMENT =
  "has_seen_custom_fields_announcement";
export const HAS_SEEN_MFA_ANNOUNCEMENT = "has_seen_mfa_announcement";
export const HAS_SEEN_HOW_DID_YOU_HEAR_ABOUT_US_SURVEY =
  "has_seen_how_did_you_hear_about_us_survey";
export const HAS_SEEN_CLOAKED_DAILY = "has_seen_cloaked_daily";
export const HAS_SEEN_IDENTITY_SHARING_ANNOUNCEMENT =
  "has_seen_identity_sharing_announcement";
export const HAS_SEEN_REFERRALS_ANNOUNCEMENT =
  "has_seen_referrals_announcement";
export const HAS_SEEN_TOTP_ANNOUNCEMENT = "has_seen_totp_announcement";
export const HAS_SEEN_AUTO_CHANGE_ANNOUNCEMENT =
  "has_seen_auto_change_announcement";
export const HAS_SEEN_LOCKED_NUMBERS_ANNOUNCEMENT =
  "has_seen_locked_numbers_announcement";
export const HAS_SEEN_LOCKED_NUMBERS_WARNING =
  "has_seen_locked_numbers_warning";
export const HAS_CREATED_SAMPLE_IDENTITY = "has_created_sample_identity";
export const HAS_COMPLETED_MOBILE_ONBOARDING =
  "has_completed_mobile_onboarding";
export const HAS_SEEN_PLANS_MODAL = "has_seen_plans_modal";
export const HAS_ACTIVATED_WEB = "has_activated_web";
export const HAS_ACTIVATED_PLUGIN = "has_activated_plugin";
export const HAS_ACTIVATED_IOS = "has_activated_ios";
export const HAS_ACTIVATED_ANDROID = "has_activated_android";
export const UI_IDENTITY_LIST_VIEW_TYPE = "ui_identity_list_view_type";

// Flags for HomeActions component
export const DISMISSED_EXT_ACTION = "has_dismissed_download_extension_action";
export const DISMISSED_MOBILE_ACTION = "has_dismissed_download_mobile_action";
export const DISMISSED_IMPORT_ACTION = "has_dismissed_import_action";
export const DISMISSED_2FA_ACTION = "has_dismissed_2fa_action";
export const DISMISSED_GET_STARTED_ACTION = "has_dismissed_get_started_action";
export const DISMISSED_SEND_INVITE_ACTION = "has_dismissed_send_invite_action";
export const DISMISSED_VERIFICATION_ACTION =
  "has_dismissed_email_verification_action";
export const DISMISSED_DATA_DELETE_ACTION = "has_dismissed_data_delete_action";

export const DATA_DELETE_SEARCHED_EXPOSURES =
  "has_searched_data_delete_exposures";
export const DATA_DELETE_REQUESTED = "has_requested_data_delete";
export const DATA_DELETE_IN_APP_REQUESTED = "has_requested_data_delete_in_app";

// data delete meta product user flags
export const SHOW_DD_POST_PAYMENT_ONBOARDING =
  "show_dd_post_payment_onboarding";
export const HAS_SEEN_DD_POST_PAYMENT_ONBOARDING =
  "has_seen_dd_post_payment_onboarding_takeover";
export const COMPLETED_DD_ONBOARDING_PHONE_EMAIL =
  "has_seen_phone_and_email_flow";
export const COMPLETED_DD_ONBOARDING_DATA_BREACHES =
  "has_seen_data_breaches_flow";
export const COMPLETED_DD_ONBOARDING_PASSWORD_GENERATOR =
  "has_seen_password_manager_flow";
export const COMPLETED_DD_ONBOARDING_INVITE_FLOW = "has_seen_invite_flow";
