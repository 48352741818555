import {
  PH_FEATURE_FLAG_DATA_DELETION_IN_APP_SEARCH,
  PH_FEATURE_FLAG_DD_SCHEDULE_CALL,
} from "@/scripts/posthogEvents";
import { getPosthog } from "@/scripts/posthog";

export default {
  namespaced: true,
  state: {
    requests: [],
    removalLogData: {
      brokerList: [],
      brokersInProgress: 0,
      totalBrokers: 0,
      isComplete: false,
      removalDate: new Date(),
      brokersCompleted: 0,
      removalLogFetched: false,
    },
    enrollmentData: {
      enrollmentDataFetched: false,
      brokersCompleted: 0,
      brokersInProgress: 0,
      scanUpdatedDate: new Date(),
      isComplete: false,
      scanningSites: 0,
      totalRecordsRemoved: 0,
      daysProtected: 0,
    },
    actionRequiredFamilies: null,
    graphData: null,
    enrollmentProfile: null,

    // posthog feature flags
    ddInAppSearchEnabled: false,
    ddScheduleCallEnabled: false,
  },

  mutations: {
    setRequests(state, requests) {
      state.requests = requests;
    },
    setRemovalLogData(state, resp) {
      state.removalLogData = {
        ...state.removalLogData,
        brokerList: resp?.data?.brokers ?? 0,
        brokersInProgress: resp?.data?.state?.brokers_in_progress ?? 0,
        totalBrokers:
          (resp?.data?.state?.brokers_in_progress ?? 0) +
          (resp?.data?.state?.brokers_complete ?? 0),
        isComplete: resp?.data?.state?.is_complete,
        removalDate: new Date(),
        brokersCompleted: resp?.data?.state?.brokers_complete ?? 0,
        removalLogFetched: true,
      };
    },
    setEnrollmentData(state, resp) {
      state.enrollmentData = {
        brokersCompleted: resp?.data?.latest_scan?.state?.brokers_complete ?? 0,
        brokersInProgress:
          resp?.data?.latest_scan?.state?.brokers_in_progress ?? 0,
        scanUpdatedDate: new Date(),
        isComplete: resp?.data?.latest_scan?.state?.is_complete,
        scanningSites: resp?.data?.latest_scan?.broker_count ?? 0,
        totalRecordsRemoved:
          resp?.data?.monitoring_status?.total_records_removed ?? 0,
        totalItemsRemoved:
          resp?.data?.monitoring_status?.total_items_removed ?? 0,
        totalItemsRemovedToday:
          resp?.data?.monitoring_status?.items_removed_today ?? 0,
        nextScanDate: resp?.data?.array_rescan_at,
        daysProtected: resp?.data?.monitoring_status?.days_protected ?? 0,
        enrollmentDataFetched: true,
        apiCalledTimestamp: new Date(),
      };
    },
    setInAppSearchFeatureFlag(state, isEnabled) {
      state.ddInAppSearchEnabled = isEnabled;
    },
    setScheduleCallFeatureFlag(state, isEnabled) {
      state.ddScheduleCallEnabled = isEnabled;
    },
    setActionRequiredItems(state, brokerFamilies) {
      state.actionRequiredFamilies = brokerFamilies ?? [];
    },
    setRemovalLogBrokerList(state, brokerList) {
      state.removalLogData.brokerList = brokerList ?? [];
    },
    setGraphData(state, graphData) {
      state.graphData = graphData;
    },
    setEnrollmentProfile(state, profileData) {
      state.enrollmentProfile = profileData;
    },
  },
  getters: {
    hasRequest: (state) => {
      return state.requests.length > 0;
    },
    currentRequest: (state) => {
      return state.requests[state.requests.length - 1];
    },
    removalLogData: (state) => {
      return state.removalLogData;
    },
    enrollmentData: (state) => {
      return state.enrollmentData;
    },
    getDdInAppSearchEnabled: (state) => {
      return state.ddInAppSearchEnabled;
    },
    getDdScheduleCallEnabled: (state) => {
      return state.ddScheduleCallEnabled;
    },
    getDdOnboardingEnabled: (state, getters, rootState) => {
      return rootState.authentication?.user?.flags?.dd_onboarding_enabled;
    },
    actionRequiredFamilies: (state) => {
      return state?.actionRequiredFamilies;
    },
    getGraphData: (state) => {
      return state?.graphData;
    },
    getEnrollmentProfile: (state) => {
      return state?.enrollmentProfile;
    },
  },
  actions: {
    setRequests({ commit }, requests) {
      commit("setRequests", requests);
    },
    setRemovalLogData({ commit }, apiResponse) {
      commit("setRemovalLogData", apiResponse);
    },
    setEnrollmentData({ commit }, apiResponse) {
      commit("setEnrollmentData", apiResponse);
    },
    setActionRequiredItems({ commit }, brokerFamilies) {
      commit("setActionRequiredItems", brokerFamilies);
    },
    async loadFeatureFlags({ commit }) {
      const posthog = await getPosthog();
      posthog?.onFeatureFlags(() => {
        commit(
          "setInAppSearchFeatureFlag",
          posthog?.isFeatureEnabled(PH_FEATURE_FLAG_DATA_DELETION_IN_APP_SEARCH)
        );
        commit(
          "setScheduleCallFeatureFlag",
          posthog?.isFeatureEnabled(PH_FEATURE_FLAG_DD_SCHEDULE_CALL)
        );
      });
    },
    updateRemovalLogBrokerList({ commit }, brokerList) {
      commit("setRemovalLogBrokerList", brokerList);
    },
    setGraphData({ commit }, graphData) {
      commit("setGraphData", graphData);
    },
    setEnrollmentProfile({ commit }, profileData) {
      commit("setEnrollmentProfile", profileData);
    },
  },
};
