<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import Button from "@/components/Button.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import router from "@/routes/router";
import store from "@/store";
import { useRoute } from "vue-router";
import { useToast } from "@/hooks";
import { logout } from "@/scripts/actions/auth";

const username = computed(() => {
  return (
    store.state.authentication?.username ||
    store.state.authentication?.user?.username
  );
});

const route = useRoute();
const iframe = ref(null);
const invitationState = ref(null);
const isInvitationValid = computed(
  () => invitationState.value?.state === "pending"
);
const planType = computed(() =>
  parseInt(invitationState.value?.max_members ?? 0) > 2 ? "Family" : "Couple"
);

const { success } = useToast();

const onAcceptInvite = () => {
  store.dispatch("authentication/handleAcceptInvite", route.params.id);

  if (username.value) {
    router.push({ name: "settings.subscription" });
  } else {
    router.push({ name: "signup" });
  }
};

const iframeListener = (message) => {
  if (
    message.source === iframe?.value?.contentWindow &&
    message.data.event === "user-invitation"
  ) {
    invitationState.value = message.data.data;
  }
};

const onLoad = () => {
  if (iframe.value.contentDocument?.title.contains("404")) {
    invitationState.value = { state: "invalid" };
  }
};

onUnmounted(() => {
  window.removeEventListener("message", iframeListener);
});

onMounted(() => {
  window.addEventListener("message", iframeListener);
});

const src = computed(() => {
  return `${window.ENV.VUE_APP_API}invitation/${route.params.id}/`;
});
</script>

<template>
  <div class="accept-invitation">
    <InlineSvg name="cloaked-logo-full" class="accept-invitation__logo" />
    <div class="accept-invitation__content" v-if="invitationState">
      <template v-if="isInvitationValid">
        <img
          src="@/assets/images/subscription/logo-accept.webp"
          alt="Cloaked Identity"
          width="268"
          height="155"
          class="accept-invitation__icon"
        />
        <h1 class="accept-invitation__title">
          You have been invited to join a Cloaked {{ planType }} plan!
        </h1>
        <p class="accept-invitation__start">Start your Privacy Journey today</p>
        <Button
          class="accept-invitation__cta"
          size="2xl"
          type="cloaked-gradient"
          @click="onAcceptInvite"
        >
          Accept invite
          <InlineSvg name="arrow-right" />
        </Button>
        <p class="accept-invitation__auth-status" v-if="username">
          You are signed in as
          <span class="accept-invitation__username">{{ username }}</span>
          <br />
          <button class="accept-invitation__sign-out" @click="logout">
            Sign out
          </button>
        </p>
      </template>
      <template v-else>
        <img
          src="@/assets/images/subscription/logo-resubscribe.webp"
          alt="Cloaked Identity"
          width="290"
          height="167"
          class="accept-invitation__icon"
        />
        <h1 class="accept-invitation__title">
          We are sorry, this invitation has already expired!
        </h1>
        <Button
          class="accept-invitation__cta"
          size="2xl"
          type="primary"
          @click="$router.push({ name: 'Home' })"
        >
          Go to dashboard
          <InlineSvg name="arrow-right" />
        </Button>
      </template>
    </div>
  </div>
  <iframe
    ref="iframe"
    :src="src"
    @load="onLoad"
    allow="clipboard-read; clipboard-write"
    frameborder="0"
  ></iframe>
</template>

<style lang="scss">
.accept-invitation {
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  background-color: $color-primary-0;

  &__logo {
    color: $color-primary-100;
    width: 100px;
    position: fixed;
    left: 18px;
    top: 18px;
  }

  &::before {
    position: fixed;
    content: "";
    left: 0;
    top: 0;
    height: 100vw;
    width: 100vw;
    background: $background-rainbow;
    filter: $background-rainbow-filter;
    mix-blend-mode: screen;
    opacity: 0.45;
    border-radius: 59.159px;
    transform: rotate(280deg) skew(20deg, 10deg);
    z-index: 998;
  }

  &__content {
    max-width: 440px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px 32px 60px 32px;
    width: 100%;
    z-index: 999;
  }

  &__icon {
    width: clamp(50px, 50vw, 267px);
    filter: drop-shadow(0 9px 34px rgba(0, 0, 0, 0.1));
  }

  &__title {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25;
    color: $color-primary-100;
    margin-top: 18px;
    text-align: center;

    @media all and (min-width: $screen-sm) {
      font-size: 32px;
      font-style: normal;
      letter-spacing: -0.5px;
      margin-top: 24px;
    }
  }

  &__start {
    color: $color-primary-100;
    margin-top: 24px;
  }

  &__auth-status {
    color: $color-primary-100;
    margin-top: 32px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  &__username {
    font-weight: 600;
    word-break: break-all;
  }

  &__sign-out {
    display: block;
    margin: 8px auto 0;
    border: 0;
    background: 0;
    color: $color-primary-100;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.5;

    &:hover {
      opacity: 0.6;
    }
  }

  &__cta {
    margin-top: 32px;
    width: 90%;

    @media all and (min-width: $screen-sm) {
      margin-top: 42px;
    }
  }
}
</style>
