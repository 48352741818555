<script setup>
import ModalWelcomeDefault from "@/components/feature/onboarding/ModalWelcomeDefault.vue";
import ModalWelcomeDiscount from "@/components/feature/onboarding/ModalWelcomeDiscount.vue";
import { markRaw } from "vue";

defineEmits(["input", "subscribe"]);

const props = defineProps({
  type: {
    type: String,
    default: "default",
    validator: (value) => ["default", "discount"].includes(value),
  },
  expires: {
    default: null,
  },
  value: {
    type: Boolean,
    default: false,
  },
});

const modalTypeMap = {
  default: markRaw(ModalWelcomeDefault),
  discount: markRaw(ModalWelcomeDiscount),
};
</script>

<template>
  <Component
    :is="modalTypeMap[type]"
    :expires="expires"
    :has-outside-click-close="false"
    :value="value"
    @input="(value) => $emit('input', value)"
    @subscribe="$emit('subscribe')"
  />
</template>
