<script setup>
import DataDeletePageNoResults from "@/components/feature/data-delete/DataDeletePageNoResults.vue";
import DataDeletePagePhoneResults from "@/components/feature/data-delete/DataDeletePagePhoneResults.vue";
import { PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_RESULTS } from "@/scripts/posthogEvents";
import { onMounted, useAttrs } from "vue";
import DataDeleteTryAgain from "@/components/feature/data-delete/DataDeleteTryAgain.vue";
import UserService from "@/api/actions/user-service";
import { DATA_DELETE_SEARCHED_EXPOSURES } from "@/scripts/userFlags";
import { posthogCapture } from "@/scripts/posthog.js";

const attrs = useAttrs();

onMounted(() => {
  posthogCapture(PH_EVENT_USER_VIEWED_DATA_DELETION_SEARCH_RESULTS, {
    results: attrs.numTotalResults ?? 0,
    searchType: attrs.phone ? "phone" : "name",
    isForcingNewSearch: attrs.isForcingNewSearch,
    hasError: attrs.hasError,
  });

  UserService.setFlag({
    name: DATA_DELETE_SEARCHED_EXPOSURES,
    value: true,
  });
});
</script>

<template>
  <DataDeleteTryAgain v-if="$attrs.hasError" />
  <DataDeletePagePhoneResults
    v-else-if="$attrs.searchResults[0]"
    v-bind="$attrs"
  />
  <DataDeletePageNoResults v-bind="$attrs" v-else />
</template>

<style lang="scss">
.data-delete-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;

  &.data-delete-phone-results {
    padding-bottom: 92px + 8px !important;
  }

  &.data-delete-no-results {
    padding-bottom: 140px + 8px !important;
  }

  @media all and (min-width: $screen-xl) {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
    gap: 90px;
    padding-bottom: 25px !important;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    &:first-child {
      align-items: center;
    }

    @media all and (min-width: $screen-xl) {
      flex-basis: 50%;
      flex-grow: 1;
      max-width: 560px;
      align-self: auto;
      margin-top: clamp(40px, 5vh, 150px);

      &:first-child {
        align-items: flex-start;
      }
    }
  }

  .data-delete__title,
  .data-delete__text {
    @media all and (min-width: $screen-xl) {
      text-align: start;
    }
  }
}
</style>
