<script setup>
import Button from "@/components/Button.vue";
import Ping from "@/assets/icons/ping.svg";
import Help from "@/assets/icons/help.svg";
import { HAS_SEEN_IMPORTER_CHEAT_SHEET } from "@/scripts/userFlags";
import UserService from "@/api/actions/user-service";
import { computed, useAttrs } from "vue";
import store from "@/store";

const props = defineProps({
  withPing: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();

function onClick() {
  if (props.withPing) {
    UserService.setFlag({
      name: HAS_SEEN_IMPORTER_CHEAT_SHEET,
      value: true,
    });
  }
}

const isPingVisible = computed(() => {
  return (
    !store.getters.getFlag(HAS_SEEN_IMPORTER_CHEAT_SHEET) && props.withPing
  );
});
</script>
<template>
  <span class="import-cheat-sheet">
    <Button v-bind="attrs" type="secondary" @click="onClick"
      >Help <Help class="import-cheat-sheet__help"
    /></Button>
    <Transition name="ping">
      <Ping class="import-cheat-sheet__ping" v-if="isPingVisible" />
    </Transition>
  </span>
</template>

<style lang="scss">
.import-cheat-sheet {
  position: relative;
  display: inline-block;

  @keyframes ping {
    0% {
      transform: translate3d(-50%, -50%, 0);
    }

    25% {
      transform: translate3d(-50%, calc(-50% - 3px), 0);
    }

    75% {
      transform: translate3d(-50%, calc(-50% + 3px), 0);
    }

    100% {
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .button__text {
    display: flex;
    align-items: center;
  }

  &__help {
    path {
      fill: $color-primary-100;
    }
  }

  &__ping {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    animation: ping infinite 1000ms linear;
    pointer-events: none;
  }
}

.ping-enter-active,
.ping-leave-active {
  transition: opacity 0.3s ease;
}

.ping-enter,
.ping-leave-to {
  opacity: 0;
}

.ping-enter-to,
.ping-leave {
  opacity: 1;
}
</style>
