<script setup>
import InlineSvg from "@/components/InlineSvg.vue";
import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

defineProps({
  option: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    default: null,
  },
});

const { width } = useWindowSize();
const isMobile = computed(() => width.value <= 768);
</script>

<template>
  <label
    class="choose-plan-option"
    :class="[
      `choose-plan-option--${option.stripePlan?.type ?? option.type}`,
      {
        'choose-plan-option--active': option.isActive,
        'choose-plan-option--disabled': $attrs.disabled,
      },
    ]"
  >
    <span class="choose-plan-option__content">
      <span class="choose-plan-option__title">
        {{ option.title }}
        <span class="choose-plan-option__after-title" v-if="option.afterTitle">
          &nbsp;•&nbsp;
          {{ option.afterTitle }}
        </span>
      </span>
      <span class="choose-plan-option__text">
        <slot name="text">
          {{ option.text }}
        </slot>
      </span>
      <span v-if="option.afterText" class="choose-plan-option__after-text">
        {{ option.afterText }}
      </span>
    </span>
    <input
      :checked="modelValue?.id === option.id"
      v-bind="$attrs"
      @input="$emit('update:modelValue', option)"
      type="radio"
      class="choose-plan-option__input"
    />
    <slot name="icon">
      <span v-if="option.isActive" class="choose-plan-option__current-plan">
        {{ isMobile ? "Current" : "Current plan" }}
      </span>
      <InlineSvg
        v-else-if="option.type !== 'plan'"
        name="open-door"
        class="choose-plan-option__icon"
      />
      <template v-else>
        <InlineSvg
          name="checkmark"
          class="choose-plan-option__icon choose-plan-option__icon--selected"
        />
        <InlineSvg
          name="radio-inactive"
          class="choose-plan-option__icon choose-plan-option__icon--unselected"
        />
      </template>
    </slot>
  </label>
</template>

<style lang="scss">
.choose-plan-option {
  padding: 8px 16px;
  border: 2px solid $color-primary-10;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  &:hover {
    border: 2px solid $color-primary-50;
  }

  &:has(.choose-plan-option__input:checked) {
    border: 2px solid $color-primary-100;
  }

  &--disabled,
  &--active {
    background-color: $color-primary-5;
    border: 2px solid $color-primary-5 !important;
    cursor: default;
  }

  &--disabled {
    .choose-plan-option__icon {
      opacity: 0.2;
    }
  }

  &__title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    display: block;
  }

  &__after-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.9;
  }

  &__text {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__after-text {
    display: block;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @at-root .choose-plan-option--couple & {
      @at-root .theme-dark & {
        color: $color-brand-2-90-light;
      }

      @at-root .theme-light & {
        color: $color-brand-2-50-dark;
      }
    }

    @at-root .choose-plan-option--family & {
      @at-root .theme-dark & {
        color: $color-brand-3-90-light;
      }

      @at-root .theme-light & {
        color: $color-brand-3-70-dark;
      }
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
  }

  &__icon {
    width: 26px;
    height: 26px;
    flex-shrink: 0;

    &--selected {
      display: none;

      @at-root .choose-plan-option__input:checked ~ & {
        display: block;
      }
    }

    &--unselected {
      @at-root .choose-plan-option__input:checked ~ & {
        display: none;
      }
    }
  }

  &__current-plan {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.9;
  }
}
</style>
