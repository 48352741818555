<script setup>
import AppModal from "@/components/ui/AppModal.vue";
import AppModalContent from "@/components/ui/AppModalContent.vue";
import AppModalTitle from "@/components/ui/AppModalTitle.vue";
import AppModalParagraph from "@/components/ui/AppModalParagraph.vue";
import AppModalFooter from "@/components/ui/AppModalFooter.vue";
import Button from "@/components/Button.vue";
import { usePlanMembers } from "@/components/feature/subscribe/composables";
import InlineSvg from "@/components/InlineSvg.vue";
import AppCard from "@/components/ui/AppCard.vue";

const props = defineProps({
  member: {
    type: Object,
    required: true,
  },
});

const { removeMember, isRemovingMember } = usePlanMembers();

const emit = defineEmits(["input"]);
const onRemoveMember = async () => {
  await removeMember(props.member.id);
  emit("input", false);
};
</script>

<template>
  <AppModal
    v-bind="$attrs"
    @input="$emit('input', $event)"
    :has-outside-click-close="true"
  >
    <AppModalContent class="modal-remove-member">
      <AppModalTitle size="sm"> Remove member </AppModalTitle>
      <AppModalParagraph>
        By removing this member from your plan, their account will automatically
        downgrade to a limited plan until they purchase a new plan of their own.
      </AppModalParagraph>
      <AppModalParagraph>
        The following email will receive a notification of their removal from
        your couple plan:
      </AppModalParagraph>
      <AppCard type="background" class="modal-remove-member__card">
        {{ member?.recipient_email }}
      </AppCard>
      <AppModalFooter>
        <Button type="danger" @click="onRemoveMember">
          <template v-if="isRemovingMember">
            Removing member <InlineSvg name="spinner" />
          </template>
          <template v-else> Remove member </template>
        </Button>
        <Button type="secondary" @click="$emit('input', false)">
          Cancel
        </Button>
      </AppModalFooter>
    </AppModalContent>
  </AppModal>
</template>

<style lang="scss">
.modal-remove-member {
  &__card {
    margin: 0 32px;
    margin-top: 8px;
    word-break: break-all;
  }
}
</style>
