<script setup>
import { MagicWand } from "@/assets/icons";
import store from "@/store";
import { onBeforeMount } from "vue";
const props = defineProps({
  hasIcon: {
    type: Boolean,
    default: true,
  },
  customText: {
    type: String,
    default: null,
  },
});

onBeforeMount(() => store.dispatch("subscription/init"));
</script>

<template>
  <button
    class="upgrade-button"
    @click="store.dispatch('subscription/openSubscriptionModal')"
  >
    <MagicWand
      :class="{ 'upgrade-button__no-icon': !props.hasIcon }"
      class="upgrade-button__icon"
    />
    <span class="upgrade-button__copy--short">{{
      props.customText ?? "Upgrade"
    }}</span>
    <span class="upgrade-button__copy--long">
      {{ props.customText ?? "Upgrade today" }}</span
    >
  </button>
</template>

<style lang="scss">
$upgrade-button-breakpoint: 1200px;

.upgrade-button {
  color: $color-primary-100;
  font-family: $poppins;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 2px solid transparent;
  background: linear-gradient($color-surface, $color-surface) padding-box,
    $cloaked-gradient border-box;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  @media (min-width: $upgrade-button-breakpoint) {
    padding: 7px 40px;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__no-icon {
    display: none;
  }

  &__copy {
    white-space: nowrap;

    &--long {
      display: none;

      @media (min-width: $upgrade-button-breakpoint) {
        display: inline-block;
      }
    }

    &--short {
      @media (min-width: $upgrade-button-breakpoint) {
        display: none;
      }
    }
  }
}
</style>
