<script setup>
// TODO: refactor shared logic using composables
import { onBeforeMount, onMounted, ref, watch } from "vue";
const { phone } = require("phone");

import { useRoute } from "@/hooks";

import DataDeleteService from "@/api/actions/data-delete-service";

import { stepToComponent } from "./utils";
import { PH_EVENT_USER_CLICKED_DATA_DELETION_SETUP_ACCOUNT_BUTTON } from "@/scripts/posthogEvents";
import DataDeletePage from "@/components/feature/data-delete/DataDeletePage.vue";
import store from "@/store";
import router from "@/routes/router";
import UserService from "@/api/actions/user-service";
import { DATA_DELETE_REQUESTED } from "@/scripts/userFlags";
import {
  useDataDeleteFormatting,
  useDataDeleteInput,
  useDataDeleteSessionStorage,
} from "@/components/feature/data-delete/composables";
import { posthogCapture } from "@/scripts/posthog.js";

const route = useRoute();

const { dataDeleteInputForm } = useDataDeleteInput();
const { formattedPhoneNumber, formattedUserName } =
  useDataDeleteFormatting(dataDeleteInputForm);

const error = ref(null);
const hasSearchError = ref(null);
const step = ref(stepToComponent.StepSearch);
const searchStep = ref("initial");
const searchResults = ref([]);
const numTotalResults = ref(0);

const {
  clearSearchProgressFromSessionStorage,
  saveSearchProgressToSessionStorage,
} = useDataDeleteSessionStorage(dataDeleteInputForm, searchResults);

onMounted(() => {
  performSearch();
});

onBeforeMount(() => {
  clearSearchProgressFromSessionStorage();
});

async function searchPublicRecords({
  firstName,
  lastName,
  state,
  phoneNumber,
  age,
  useArray = false,
}) {
  if (!(firstName && lastName) && !phoneNumber) {
    forceNewSearch();
    return;
  }

  isFetching.value = true;
  const { data } = await DataDeleteService.getPublicRecords({
    firstName,
    lastName,
    state,
    phoneNumber,
    age,
    useArray,
  });

  if (data.hasError) {
    hasSearchError.value = true;
    step.value = stepToComponent.StepResults;
    return;
  }

  // for security reasons data.results always contains at most 1 result
  numTotalResults.value = data.numTotalResults ?? 0;

  if (data?.results?.length) {
    step.value = stepToComponent.StepLoader;
    searchComplete.value = true;
    searchResults.value = data.results;
  } else {
    searchAlternateOptions({
      firstName,
      lastName,
      state,
      phoneNumber,
      age,
      useArray,
    });
  }

  isFetching.value = false;
}

const searchAlternateOptions = ({
  firstName,
  lastName,
  state,
  age,
  useArray = false,
}) => {
  step.value = stepToComponent.StepSearch;
  if (!firstName && !lastName) {
    searchStep.value = "name";
  } else if (!age) {
    searchStep.value = "age";
  } else if (!state) {
    searchStep.value = "state";
  } else {
    step.value = stepToComponent.StepLoader;
    searchComplete.value = true;
    useArray = true;
    // todo: implement array
  }
};

const searchComplete = ref(false);
const isFetching = ref(false);
const isForcingNewSearch = ref(false);

function forceNewSearch() {
  isForcingNewSearch.value = true;
  searchComplete.value = false;
  searchResults.value = [];
  dataDeleteInputForm.value = {};
  searchAlternateOptions(dataDeleteInputForm.value);
}

function setStep(value) {
  step.value = value;
}

function performSearch() {
  searchPublicRecords({
    phoneNumber: formattedPhoneNumber,
  });
}

const storeSearchProgressInSessionStorage = () => {
  saveSearchProgressToSessionStorage();

  posthogCapture(PH_EVENT_USER_CLICKED_DATA_DELETION_SETUP_ACCOUNT_BUTTON, {
    isForcingNewSearch: isForcingNewSearch.value,
  });
};

function setSetup() {
  storeSearchProgressInSessionStorage();

  const goToDataRemoval = () => {
    UserService.setFlag({
      name: DATA_DELETE_REQUESTED,
      value: true,
    });

    setTimeout(() => {
      if (route.name !== "Home") {
        router.push({ name: "Home" });
      }
    }, 2000);
  };

  if (store.getters["settings/isSubscribed"]) {
    goToDataRemoval();
  } else {
    store.dispatch("subscription/openSubscriptionModal");

    const unwatch = watch(
      () => store.getters["settings/isSubscribed"],
      (isSubscribed) => {
        if (isSubscribed && route.name === "DataDeleteUser") {
          goToDataRemoval();
          unwatch();
        }
      }
    );
  }
}
function setSearchStep(value) {
  searchStep.value = value;
}
</script>

<template>
  <DataDeletePage class="data-delete--user">
    <Transition mode="out-in" appear>
      <Component
        :is="step"
        class="data-delete__page"
        :userName="formattedUserName"
        :phone="dataDeleteInputForm.phone"
        :searchResults="searchResults"
        :is-fetching="isFetching"
        :value="dataDeleteInputForm"
        :numTotalResults="numTotalResults"
        :searchStep="searchStep"
        :has-token="true"
        :isForcingNewSearch="isForcingNewSearch"
        @setStep="setStep"
        @input="dataDeleteInputForm = $event"
        @setSearchStep="setSearchStep"
        @searchPublicRecords="searchPublicRecords"
        @setup="setSetup"
        @complete="setSetup"
        :error="error"
        :hasError="hasSearchError"
        @force-new-search="forceNewSearch"
        :searchComplete="searchComplete"
      />
    </Transition>
  </DataDeletePage>
</template>

<style lang="scss">
.data-delete {
  &--user {
    min-height: unset;

    .data-delete-results {
      @media all and (min-width: $screen-xl) {
        flex-direction: column;
        align-items: center;
        gap: 32px;
      }

      &__column {
        position: static !important;

        @media all and (min-width: $screen-xl) {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
