<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import LineProgress from "@/components/ui/LineProgress.vue";
import Button from "@/components/Button.vue";
import InlineSvg from "@/components/InlineSvg.vue";

const props = defineProps({
  progress: {
    type: Object,
    default: null,
  },
});

const scanProgress = computed(() =>
  props.progress.brokerCountScanned
    ? props.progress.brokerCountScanned / props.progress.brokerCountTotal
    : 0
);

const progressElement = ref(null);
const progressElementWidth = ref(0);

const onWindowResize = () => {
  progressElementWidth.value = progressElement.value?.offsetWidth ?? 0;
};

onMounted(() => {
  window.addEventListener("resize", onWindowResize, { passive: true });
  onWindowResize();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onWindowResize);
});
</script>

<template>
  <div class="scan-results-scanning">
    <h1 class="data-delete__title" v-if="progress.brokerCountWithRecords">
      We found
      <span class="scan-results-done__count">{{
        progress.brokerCountWithRecords
      }}</span>
      {{ progress.brokerCountWithRecords === 1 ? "place" : "places" }} selling
      this information about you
    </h1>
    <h1 class="data-delete__title" v-else>Scanning...</h1>
    <p class="data-delete__text">
      Scanning {{ progress.brokerCountTotal }}
      {{
        progress.brokerCountTotal === 1
          ? "data broker"
          : "sources and data brokers"
      }}
      for your private info...
    </p>
    <div class="scan-results-scanning__progress" ref="progressElement">
      <LineProgress
        :height="12"
        :width="progressElementWidth"
        :progress="scanProgress"
        class="scan-results-scanning__progress-line"
      />
      <div class="scan-results-scanning__progress-next">
        <template v-if="progress.currentBroker">
          Checking: {{ progress.currentBroker.broker_name }}
          <InlineSvg name="spinner" />
        </template>
        <div v-else class="scan-results-scanning__progress-skeleton" />
      </div>
      <div class="scan-results-scanning__progress-count">
        <template
          v-if="progress.brokerCountScanned && progress.brokerCountTotal"
        >
          {{ `${progress.brokerCountScanned}/${progress.brokerCountTotal}` }}
        </template>
        <div v-else class="scan-results-scanning__progress-skeleton" />
      </div>
    </div>
    <Button
      type="hero-green"
      size="2xl"
      class="scan-results-scanning__cta"
      @click="$emit('delete')"
      v-if="progress.brokerCountWithRecords"
    >
      Delete your online data
      <InlineSvg name="arrow-right" />
    </Button>
  </div>
</template>

<style lang="scss">
.scan-results-scanning {
  max-width: 770px;
  margin: 0 auto;

  .data-delete__title {
    opacity: 0;
    animation: appear-bottom-5 0.3s forwards ease-out;
  }

  .data-delete__text {
    opacity: 0;
    animation: appear-bottom-5 0.3s 0.05s forwards ease-out;
  }

  &__progress {
    display: grid;
    grid-template-columns: 1fr 60px;
    grid-gap: 12px;
    opacity: 0;
    animation: appear-bottom-5 0.3s 0.15s forwards ease-out;

    &-line {
      margin-top: 24px;
      grid-column: 1/3;

      @media all and (min-width: $screen-xl) {
        margin-top: 36px;
      }
    }

    &-next {
      justify-self: start;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      display: flex;
      align-items: center;
      gap: 8px;

      @media all and (min-width: $screen-xl) {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.1px;
      }
    }

    &-count {
      justify-self: end;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0.7;
    }

    &-skeleton {
      height: 23px;
      width: 140px;
      border-radius: 100px;

      @at-root .theme-dark & {
        @include base-skeleton($color-primary-10, 0.5, #000, 0.1);
      }

      @at-root .theme-light & {
        @include base-skeleton($color-primary-10);
      }
    }
  }

  & &__cta {
    display: flex;
    margin: 24px auto 0;
    width: 100%;
    max-width: 360px;
    opacity: 0;
    animation: appear-bottom-5 0.2s forwards ease-out;

    @media all and (min-width: $screen-xl) {
      margin: 44px auto 0;
    }
  }
}
</style>
