import { onBeforeMount } from "vue";
import store from "@/store";
import { useRoute } from "@/hooks";

export const useThemeQueryParameter = () => {
  const route = useRoute();

  onBeforeMount(() => {
    if (route.query.theme === "light") {
      return store.dispatch("colorScheme/setLightTheme");
    }

    store.dispatch("colorScheme/setDarkTheme");
  });
};
