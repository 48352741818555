<script setup>
import ModalTemplate from "@/components/ModalTemplate.vue";
import Button from "@/components/Button.vue";
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { formatPhone } from "@/scripts/format";
import DataDeleteService from "@/api/actions/data-delete-service.js";

const enrollmentProfile = computed(() => {
  return store.getters["dataDelete/getEnrollmentProfile"];
});

const showFailedState = ref(false);

onMounted(() => {
  if (enrollmentProfile.value === null) {
    DataDeleteService.getEnrollmentProfile().catch(() => {
      showFailedState.value = true;
    });
  }
});

const birthYear = computed(() => {
  if (enrollmentProfile.value?.birth_year) {
    return enrollmentProfile.value?.birth_year;
  }
  return "—";
});
function closeModal() {
  store.dispatch("closeModal");
}

function formatAddress(address) {
  const addressAttributes = [];
  if (address.address1?.length) {
    addressAttributes.push(address.address1.toLowerCase());
  }
  if (address.address2?.length) {
    addressAttributes.push(address.address2.toLowerCase());
  }
  if (address.city?.length) {
    addressAttributes.push(address.city.toLowerCase());
  }
  if (address.state?.length) {
    addressAttributes.push(address.state.toUpperCase());
  }
  if (address.postal_code?.length) {
    addressAttributes.push(address.postal_code.toLowerCase());
  }
  if (address.country?.length) {
    addressAttributes.push(address.country.toUpperCase());
  }
  if (addressAttributes.length) {
    return addressAttributes.join(", ");
  }
  return "—";
}

function getNameFromDetails(nameObject) {
  let fullNameAttributes = [];
  if (!!nameObject?.prefix?.length) {
    fullNameAttributes.push(nameObject?.prefix?.toLowerCase());
  }
  if (!!nameObject?.first?.length) {
    fullNameAttributes.push(nameObject?.first?.toLowerCase());
  }
  if (!!nameObject?.middle?.length) {
    fullNameAttributes.push(nameObject?.middle?.toLowerCase());
  }
  if (!!nameObject?.last?.length) {
    fullNameAttributes.push(nameObject?.last?.toLowerCase());
  }
  if (!!nameObject?.suffix?.length) {
    if (
      ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"].includes(
        nameObject?.suffix?.toUpperCase()
      )
    ) {
      fullNameAttributes.push(nameObject?.suffix?.toUpperCase());
    } else {
      fullNameAttributes.push(nameObject?.suffix?.toLowerCase());
    }
  }
  if (fullNameAttributes.length) {
    return fullNameAttributes.join(" ");
  }
  return "—";
}
</script>
<template>
  <ModalTemplate :show="true" :width="707" @close="closeModal">
    <template #header>
      <h1 class="title">What we submit for removal:</h1>
    </template>
    <template #body>
      <p class="paragraph">
        Every 30 days, we will resubmit the following information to our list of
        data brokers to have it removed.
      </p>
      <div class="enrollment-data-wrapper" v-if="showFailedState">
        We were unable to retrieve your enrollment profile. Please try again
        later.
      </div>
      <div class="enrollment-data-wrapper" v-else>
        <div class="enrollment-data-row">
          <div class="title">Name:</div>
          <div class="detail titleize">
            <div>
              {{ getNameFromDetails(enrollmentProfile.name) }}
            </div>
            <div
              v-if="enrollmentProfile?.other_names?.length"
              class="detail titleize"
            >
              <div
                v-for="(nameObject, idx) in enrollmentProfile.other_names"
                :key="`othernames ${idx}`"
              >
                {{ getNameFromDetails(nameObject) }}
              </div>
            </div>
          </div>
        </div>
        <div class="enrollment-data-row">
          <div class="title">Birth year:</div>
          <div class="detail">{{ birthYear }}</div>
        </div>
        <div class="enrollment-data-row">
          <div class="title">Location:</div>
          <div class="detail" v-if="enrollmentProfile?.addresses?.length">
            <div
              class="titleize"
              :key="`${idx}-${address}`"
              v-for="(address, idx) in enrollmentProfile.addresses"
            >
              {{ formatAddress(address) }}
            </div>
          </div>
          <div class="detail" v-else>—</div>
        </div>
        <div class="enrollment-data-row">
          <div class="title">Phone number:</div>
          <div class="detail" v-if="enrollmentProfile?.phone_numbers?.length">
            <div
              :key="`${idx}-${phone}`"
              v-for="(phone, idx) in enrollmentProfile.phone_numbers"
            >
              {{ formatPhone(phone) }}
            </div>
          </div>
          <div class="detail" v-else>—</div>
        </div>
        <div class="enrollment-data-row">
          <div class="title">Email addresses:</div>
          <div class="detail" v-if="enrollmentProfile?.email_addresses?.length">
            <div
              :key="`${idx}-${email}`"
              v-for="(email, idx) in enrollmentProfile.email_addresses"
            >
              {{ email }}
            </div>
          </div>
          <div class="detail" v-else>—</div>
        </div>
      </div>
    </template>

    <template #footer>
      <Button type="primary" size="xl" class="button" @click="closeModal"
        >Close</Button
      >
    </template>
  </ModalTemplate>
</template>
<style scoped lang="scss">
.enrollment-data-wrapper {
  background-color: $color-primary-5;
  border: 1px solid $color-primary-10;
  color: $color-primary-100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 16px;
  margin-top: 10px;
  .enrollment-data-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    .title {
      color: $color-primary-100;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .detail {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $color-primary-50;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 4px;
      text-align: right;
    }
  }
}
.titleize {
  text-transform: capitalize;
}
</style>
