<script setup>
import { onMounted, computed, markRaw } from "vue";
import store from "@/store";
import MenuBlock from "./MenuBlock.vue";
import MenuItem from "./MenuItem.vue";
import CategoryService from "@/api/actions/category-service";
import AddEditNewCategory from "@/routes/modals/categories/AddEditNewCategory";

const categories = computed(() => store.getters["getCategories"]);

onMounted(() => {
  CategoryService.getCategories();
});

function openCategoryAddModal() {
  store.dispatch("openModal", {
    customTemplate: {
      template: markRaw(AddEditNewCategory),
      props: { isVisible: true },
    },
  });
}
</script>

<template>
  <MenuBlock title="Categories" :toggleState="true">
    <MenuItem
      v-for="category in categories"
      :key="category.id"
      :link="`/category/${category.id}`"
      :name="category.name"
      icon="stacked-blocks"
    />
    <MenuItem
      name="Add new..."
      icon="plus"
      button
      @click="openCategoryAddModal"
    />
  </MenuBlock>
</template>
